import React, { useEffect, useState } from "react";
import { IoArrowBackSharp } from "react-icons/io5";
import { Col, Row } from "antd";
import ContectInfo from "../../../component/form-modal/contect-info";
import OrganizationInfo from "../../../component/form-modal/organization-info";

const Profile = (props) => {
  const { user_details, update_profile } = props;
  const [defaultData, setDefaultData] = useState(null);

  // we will update you if your domain live msg for domain add
  useEffect(() => {
    setDefaultData({
      ...user_details,
      "organizationFavIcon-pre": user_details?.organizationFavIcon,
      "organizationImage-pre": user_details?.organizationImage,
    });
  }, [user_details]);
  return (
    <>
      <div className="absolute cursor-pointer ">
        <IoArrowBackSharp size={30} onClick={() => window.history.back()} />
      </div>
      <div className="flex flex-col justify-center items-center">
        <p className="text-2xl">Personal info</p>
        <p className="text-lg my-5">
          Info about you and your preferences across Wooffer services
        </p>

        <div className="py-5">
          <Row gutter={[20, 10]}>
            <Col xs={24} sm={12}>
              <ContectInfo
                user_details={user_details}
                update_profile={update_profile}
                defaultData={defaultData}
              />
            </Col>
            <Col xs={24} sm={12}>
              <OrganizationInfo
                user_details={user_details}
                update_profile={update_profile}
                defaultData={defaultData}
                setRefresh={props?.setRefresh}
                theme={props?.theme}
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Profile;
