import { Button, Dropdown, Space } from "antd";
import React from "react";
import { DownOutlined } from "@ant-design/icons";

const MenuDropDown = ({ items = [], onClick = () => {}, activeKey = "" }) => {
  const menuProps = {
    items,
    onClick,
  };

  const currantEnvironmentName = items?.find((env) => env?.key === activeKey);
  return (
    <Dropdown className="" menu={menuProps}>
      <Button>
        <Space>
          {currantEnvironmentName?.name || "Select Environment"}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default MenuDropDown;
