/* eslint-disable no-param-reassign */
import {
  Button,
  Card,
  Col,
  Menu,
  Row,
  Select,
  Spin,
  Switch,
  Tooltip,
  Typography,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { AiOutlineStop } from "react-icons/ai";
import useHttp from "../../../../hooks/use-http";
import CONSTANTS, {
  appRoot,
  projectRoot,
  serviceRoot,
} from "../../../../util/constant/CONSTANTS";
import { apiGenerator } from "../../../../util/functions";
import ModalFormCreator from "../../../../component/common/ModalFormCreator";
import DeleteModal from "./deleteModal";
import TooltipCustom from "../../../../component/common/Tooltip";

const { Text } = Typography;

const ServiceSetting = (props) => {
  const [serviceDetails, setServiceDetails] = useState({});
  const [editServiceDetails, setEditServiceDetails] = useState(null);
  const [allEnvironment, setAllEnvironment] = useState([]);
  const [editEnvironment, setEditEnvironment] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [selectedSection, setSelectedSection] = useState("1");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(null);
  const api = useHttp();
  const updateApi = useHttp();
  const { serviceID, projectId, environmentID } = useParams();
  const navigate = useNavigate();

  const { plan } = props;

  const detials = [
    {
      id: 1,
      label: "Name",
      value: serviceDetails?.name || "-",
    },
    {
      id: 2,
      label: "Slack Webhook",
      value: (
        <p className="overflow-hidden break-all">
          {serviceDetails?.slack || "-"}
        </p>
      ),
    },
    {
      id: 3,
      label: "SID",
      value: serviceDetails?.id || "-",
    },
    {
      id: 4,
      label: "Created",
      value:
        (serviceDetails?.createdAt
          && `${moment(serviceDetails?.createdAt)
            .utc()
            .format("MMM DD")} at ${moment
              .utc(serviceDetails?.createdAt)
              .local()
              .format("hh:mm:ss A")}`)
        || "-",
    },
    {
      id: 5,
      label: "Last Update",
      value:
        (serviceDetails?.updatedAt
          && `${moment(serviceDetails?.updatedAt)
            .utc()
            .format("MMM DD")} at ${moment
              .utc(serviceDetails?.updatedAt)
              .local()
              .format("hh:mm:ss A")}`)
        || "-",
    },
  ];

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const items = [
    getItem("General", "grp 1", null, [
      getItem("Service Information", "1"),
      getItem("Delete Service", "2"),
    ]),
    getItem("Environment", "grp 2", null, [
      getItem("Service Environment", "3"),
    ]),
  ];

  const editServiceHandler = (value) => {
    if (!value?.slack) {
      delete value.slack;
    }

    const EDIT_SERVICE_API = apiGenerator(CONSTANTS.API.service.update, {
      dataId: serviceID,
    });

    api.sendRequest(
      EDIT_SERVICE_API,
      () => {
        setEditServiceDetails(null);
        props?.socket?.emit("updateServiceEnvironmentInformation", {
          serviceToken: environmentID,
          token: props?.user_details?.id,

        });
        setRefresh((prev) => !prev);
      },
      value,
      "Edit Service Details Successfully!!!",
    );
  };

  const deleteServiceHandler = (dataId) => {
    const DELETE_SERVICE_API = apiGenerator(CONSTANTS.API.service.delete, {
      dataId,
    });
    api.sendRequest(DELETE_SERVICE_API, () => {
      // setRefresh(prev => !prev)
      setDeleteModal(null);
      navigate(appRoot);
    });
  };

  const addEnvironmentHandler = (value) => {
    if (CONSTANTS.API.environment.add) {
      api.sendRequest(
        CONSTANTS.API.environment.add,
        (res) => {
          if (res?.status === "success" && res?.data?.id) {
            setRefresh((prev) => !prev);
            setIsModalOpen(false);
          } else {
            notification.error({
              message: "Something Wrong Please Try again",
            });
          }
        },
        {
          ...value,
          serviceId: serviceID,
        },
        "Add Data Successfully!!!",
      );
    }
  };

  const editEnvironmentHandler = (value, environmentIDs) => {
    const EDIT_ENV_HANDLER = apiGenerator(CONSTANTS.API.environment.update, {
      dataId: environmentIDs || editEnvironment?.id,
    });

    updateApi.sendRequest(
      EDIT_ENV_HANDLER,
      () => {
        props?.socket?.emit("updateServiceEnvironmentInformation", {
          serviceToken: environmentIDs,
          token: props?.user_details?.id,

        });
        setEditEnvironment(null);
        setRefresh((prev) => !prev);
      },
      value,
      "Edit a Environment Successfully!!!",
    );
  };

  const deleteEnvHandler = (dataId) => {
    const DELTEE_ENV_HANDLER = apiGenerator(CONSTANTS.API.environment.delete, {
      dataId,
    });
    const currantService = allEnvironment?.find(
      (env) => env?.name === "Production",
    );
    api.sendRequest(
      DELTEE_ENV_HANDLER,
      () => {
        setDeleteModal(null);
        setEditEnvironment(null);
        setRefresh((prev) => !prev);
        navigate(
          `${projectRoot}/${serviceRoot}/${projectId}/${serviceID}/${currantService?.id}/setting`,
        );
      },
      {},
      "Delete a Environment Successfully!!!",
    );
  };

  useEffect(() => {
    const SERVICE_DETAILS_API = apiGenerator(CONSTANTS.API.service.getOne, {
      dataId: serviceID,
    });

    api.sendRequest(SERVICE_DETAILS_API, (res) => {
      setServiceDetails(res?.data);
    });

    const ENV_DETAILS_API = apiGenerator(CONSTANTS.API.environment.getOne, {
      dataId: serviceID,
    });

    api.sendRequest(ENV_DETAILS_API, (res) => {
      setAllEnvironment(res?.data);
    });
  }, [refresh, serviceID]);

  return (
    <div>
      {api.isLoading ? (
        <div className="h-[120rem]  w-full">
          <Spin className="mt-48" tip="Loading" size="large">
            <div className="content" />
          </Spin>
        </div>
      ) : (
        <div className="my-5">
          <ModalFormCreator
            open={editServiceDetails !== null}
            onCreate={editServiceHandler}
            onCancel={() => {
              setEditServiceDetails(null);
            }}
            name="Edit Service Details"
            menu="EDIT_SERVICE_SETTING_MODAL"
            formData={editServiceDetails}
          />
          <DeleteModal details={deleteModal} setDetails={setDeleteModal} />
          <Row gutter={[16, 24]}>
            <Col span={24} lg={8} xl={6} xxl={4}>
              <div>
                <Menu
                  onClick={(e) => {
                    // console.log('clicked', e);
                    setSelectedSection(e.key);
                  }}
                  style={{
                    borderRadius: "8px",
                  }}
                  defaultSelectedKeys={["0"]}
                  defaultOpenKeys={["grp 1", "grp 2"]}
                  className="-ml-1 p-4"
                  mode="inline"
                  items={items}
                />
              </div>
            </Col>
            {selectedSection !== "3" ? (
              <Col span={24} lg={16} xl={18} xxl={20}>
                <Card title="Service Information">
                  {detials?.map((ele) => (
                    <Row
                      gutter={[16, 16]}
                      className="mb-1.5 font-medium "
                      key={ele?.id}
                    >
                      <Col span={5}>
                        {ele?.label}
                        &nbsp;:
                      </Col>
                      <Col span={19}>{ele?.value}</Col>
                    </Row>
                  ))}
                  <Button
                    className="my-4 px-4 textcolor"
                    type="primary"
                    onClick={() => {
                      setEditServiceDetails(serviceDetails);
                    }}
                  >
                    Change Service Details
                  </Button>
                </Card>

                <Card className="my-4" title="Delete Service">
                  <Row className="mb-1.5 -mt-2 font-medium ">
                    The Service will be permanently deleted, including its
                    environments. This action is irreversible and can not be
                    undone.
                  </Row>

                  <Button
                    className="my-2 px-4 hover:opacity-75"
                    type="primary"
                    style={{
                      background: "#EE1818",
                      boxShadow: "none",
                    }}
                    onClick={() => {
                      setDeleteModal({
                        name: serviceDetails?.name,
                        id: serviceDetails?.id,
                        callBack: deleteServiceHandler,
                        title: 'Service',
                        text: `${serviceDetails?.project?.name}/${serviceDetails?.name}`,
                        showText: `This action will lead to the permanent deletion of the service, including its environment, and the generated analytics.`,
                        verificationText: `delete my service`,
                      });
                    }}
                  >
                    Delete
                  </Button>
                </Card>
              </Col>
            ) : (
              <>
                <ModalFormCreator
                  open={editEnvironment !== null}
                  onCreate={editEnvironmentHandler}
                  onCancel={() => {
                    setEditEnvironment(null);
                  }}
                  name="Edit Environment Details"
                  menu="EDIT_ENVIRONMENT_MODAL"
                  formData={editEnvironment}
                />
                <Col span={24} lg={16} xl={18} xxl={20}>
                  <Card
                    title={(
                      <div className="flex justify-between">
                        <div>Service Environment</div>
                        <Tooltip
                          className="cursor-pointer"
                          title={
                            !(plan?.maxEnvironments > allEnvironment?.length)
                              ? "Please Upgrade your Plan to track more Environments."
                              : "Click here to Create a New Environment"
                          }
                        >
                          <Button
                            onClick={() => {
                              setIsModalOpen(true);
                            }}
                            className="textcolor"
                            disabled={
                              !(plan?.maxEnvironments > allEnvironment?.length)
                            }
                            type="primary"
                          >
                            + Add New Environment
                            {" "}
                          </Button>
                        </Tooltip>
                      </div>
                    )}
                  >
                    <ModalFormCreator
                      open={isModalOpen}
                      onCreate={addEnvironmentHandler}
                      onCancel={() => {
                        setIsModalOpen((prev) => !prev);
                      }}
                      name="Add Service Environment"
                      menu="SERVICE_ENVIRONMENT_MODAL"
                    />
                    <Row gutter={[16, 16]}>
                      {allEnvironment?.map((data) => {
                        const buttonMenu = [];
                        if (data?.name !== "Production") {
                          buttonMenu.push({
                            key: `${data?.id}`,
                            label: "Edit",
                            icon: <EditOutlined className="cursor-pointer" />,
                            onClick: () => {
                              setEditEnvironment(data);
                            },
                          });
                          buttonMenu.push({
                            key: `${data?.id}`,

                            label: (
                              <div>Delete</div>
                            ),
                            icon: <DeleteOutlined className="cursor-pointer" />,
                            danger: true,
                            onClick: () => {
                              setDeleteModal({
                                name: data?.name,
                                id: data?.id,
                                callBack: deleteEnvHandler,
                                title: 'Environment',
                                text: `${serviceDetails?.name}/${data?.name}`,
                                showText: `This action will lead to the permanent deletion of all the analytics generated by this environment.`,
                                verificationText: `delete my environment`,
                              });
                            },
                          });
                        }
                        if (data?.name === "Production") {
                          buttonMenu.push({
                            key: `${data?.id}`,
                            label: "Immutable Action",
                            icon: <AiOutlineStop />,
                            onClick: () => { },
                            disabled: true,
                          });
                        }

                        return (
                          <Col
                            key={data?.id}
                            span={24}
                            sm={12}
                            lg={12}
                            xl={8}
                            xxl={6}
                          >

                            <Card
                              title={(
                                <Row className="flex justify-between">
                                  <Text>{data?.name}</Text>
                                  <div className="flex gap-5">
                                    {buttonMenu?.map((menu) => {
                                      return (
                                        <Tooltip
                                          key={menu.key}
                                          id={menu.key}
                                          title={menu.label}
                                        >
                                          <div onClick={menu?.onClick}>
                                            {menu?.icon}
                                          </div>
                                        </Tooltip>
                                      );
                                    })}
                                  </div>
                                </Row>
                              )}
                            >
                              <div className="flex justify-between  items-center gap-2">
                                {" "}
                                <Text>
                                  Email Notification
                                  <TooltipCustom title="Would you like to get email alerts?" />
                                </Text>
                                {" "}
                                <Switch
                                  checkedChildren="Yes"
                                  unCheckedChildren="No"
                                  checked={data?.mailNotification}
                                  onChange={(value) => {
                                    editEnvironmentHandler(
                                      {
                                        mailNotification: value,
                                      },
                                      data?.id,
                                    );
                                  }}
                                />
                              </div>
                              <div className="flex justify-between mt-3 items-center gap-2">
                                {" "}
                                <Text>
                                  Slack Notification
                                  <TooltipCustom title="Do you want to get notifications on Slack?" />
                                </Text>
                                {" "}
                                <Switch
                                  checkedChildren="Yes"
                                  unCheckedChildren="No"
                                  checked={data?.slackNotification}
                                  onChange={(value) => {
                                    editEnvironmentHandler(
                                      {
                                        slackNotification: value,
                                      },
                                      data?.id,
                                    );
                                  }}
                                />
                              </div>
                              <div className="flex justify-between mt-3 items-center gap-2">
                                <Text>
                                  Weekly Report
                                  <TooltipCustom title="Does receiving a weekly analysis of your server's performance sound good?" />
                                  {' '}
                                </Text>
                                <Switch
                                  checkedChildren="Yes"
                                  unCheckedChildren="No"
                                  checked={data?.reportNotification}
                                  onChange={(value) => {
                                    editEnvironmentHandler(
                                      {
                                        reportNotification: value,
                                      },
                                      data?.id,
                                    );
                                  }}
                                />
                              </div>
                              <div className="flex justify-between mt-3 items-center gap-2">
                                <Text>
                                  API Log
                                  <TooltipCustom title="Want to know whenever your server's API logs are updated?" />
                                </Text>
                                <Switch
                                  checkedChildren="Yes"
                                  unCheckedChildren="No"
                                  checked={data?.isAPIEnabled}
                                  onChange={(value) => {
                                    editEnvironmentHandler(
                                      {
                                        isAPIEnabled: value,
                                      },
                                      data?.id,
                                    );
                                  }}
                                />
                              </div>
                              {/* <div className="flex justify-between mt-3 items-center gap-2">
                                <Text>Crash Log <TooltipCustom title="" /></Text>
                                <Switch
                                  checkedChildren="Yes"
                                  unCheckedChildren="No"
                                  checked={data?.isCrashLogEnabled}
                                  onChange={(value) => {
                                    editEnvironmentHandler(
                                      {
                                        isCrashLogEnabled: value,
                                      },
                                      data?.id,
                                    );
                                  }}
                                />
                              </div> */}
                              <div className="flex justify-between mt-3 items-center gap-2">
                                <Text>
                                  Critical Log
                                  <TooltipCustom title="Need critical log updates?" />
                                </Text>
                                <Switch
                                  checkedChildren="Yes"
                                  unCheckedChildren="No"
                                  checked={data?.isCustomLogEnabled}
                                  onChange={(value) => {
                                    editEnvironmentHandler(
                                      {
                                        isCustomLogEnabled: value,
                                      },
                                      data?.id,
                                    );
                                  }}
                                />
                              </div>
                              <div className="flex justify-between mt-3 items-center gap-2">
                                <Text>
                                  Server Activity Log
                                  <TooltipCustom title="Need server activity update?" />
                                </Text>
                                <Switch
                                  checkedChildren="Yes"
                                  unCheckedChildren="No"
                                  checked={data?.isServerActivityLogEnabled}
                                  onChange={(value) => {
                                    editEnvironmentHandler(
                                      {
                                        isServerActivityLogEnabled: value,
                                      },
                                      data?.id,
                                    );
                                  }}
                                />
                              </div>
                              <div className="flex justify-between mt-3 items-center gap-2">
                                <Text>
                                  Process And CPU Usage
                                  <TooltipCustom title="Want to view Real-time analytics of Process and CPU usage?" />
                                </Text>
                                <Switch
                                  checkedChildren="Yes"
                                  unCheckedChildren="No"
                                  checked={data?.isProcessAndCPUUsageEnabled}
                                  onChange={(value) => {
                                    editEnvironmentHandler(
                                      {
                                        isProcessAndCPUUsageEnabled: value,
                                      },
                                      data?.id,
                                    );
                                  }}
                                />
                              </div>
                              <div className="flex justify-between mt-3 items-center gap-2 ">
                                <Text>
                                  CPU Usage Interval
                                  <TooltipCustom title="How often do you want to receive CPU usage updates (in minutes)?" />
                                </Text>
                                <Select
                                  value={data?.cpuUsageInterval}
                                  // defaultValue={data?.cpuUsageInterval}
                                  className="w-28"
                                  onChange={(value) => {
                                    editEnvironmentHandler({ cpuUsageInterval: value }, data?.id);
                                  }}
                                  options={[
                                    { value: '15', label: '15 min' },
                                    { value: '30', label: '30 min' },
                                    { value: '45', label: '45 min' },
                                    { value: '60', label: '60 min' },
                                  ]}
                                />

                              </div>
                            </Card>
                          </Col>
                        );
                      })}
                    </Row>
                  </Card>
                </Col>
              </>
            )}
          </Row>
        </div>
      )}
    </div>
  );
};

export default ServiceSetting;
