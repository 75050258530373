import {
  Button, Form, Input, Spin, Typography,
} from "antd";
import React from "react";
import { LoadingOutlined } from "@ant-design/icons";

const { Text } = Typography;
const antIcon = <LoadingOutlined style={{ fontSize: 15 }} spin />;

const OtpForm = ({ onOtpFormHandler, sendOtpAPI, userEmail = null }) => {
  return (
    <div className="mt-9 w-60">
      <Text>
        Great! We&apos;ve just sent a unique OTP to your email
        {" "}
        <span className="font-semibold">{userEmail}</span>
        . Please check your
        inbox and enter the code below to proceed.
      </Text>
      <Form
        className="mt-10 w-60"
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onOtpFormHandler}
        autoComplete="off"
      >
        {/* //530 c */}
        <Form.Item
          name="otp"
          rules={[
            {
              required: true,
              message: "Please input your email id!",
            },
          ]}
        >
          <Input className="w-full" placeholder="Enter OTP" />
        </Form.Item>

        <Form.Item
          className="mb-4"
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button
            disabled={sendOtpAPI.isLoading}
            type="primary"
            htmlType="submit"
          >
            {sendOtpAPI.isLoading && (
              <Spin size="small" indicator={antIcon} className="me-2" />
            )}
            Verify
          </Button>
        </Form.Item>
      </Form>
      <p className="text-sm mt-0  text-[#f5222d] transition-opacity duration-500">
        Note: If you haven&apos;t found the OTP in your inbox, please check your spam folder.
      </p>
    </div>
  );
};

export default OtpForm;
