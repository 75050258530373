import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { VscGitPullRequestCreate } from "react-icons/vsc";
import { notification } from "antd";
import useHttp from "../../../../hooks/use-http";
import { getServicePath } from "../../../../util/functions";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import MenuDropDown from "../../../../component/common/menu-DropDown";
import ModalFormCreator from "../../../../component/common/ModalFormCreator";
// import { getAuthToken } from "../../../../util/API/authStorage";

const EnvironmentDropdown = ({ environment }) => {
  const location = useLocation();
  const navigate = useNavigate();
  // const [environment, setEnvironment] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { projectID, serviceID, environmentID } = useParams();
  const API = useHttp();

  useEffect(() => {

  }, []);
  // useEffect(() => {
  //   if (getAuthToken() && serviceID && environmentID) {
  //     console.log("gggg");
  //     API.sendRequest(
  //       apiGenerator(CONSTANTS.API.environment.getOne, {
  //         dataId: serviceID,
  //       }),
  //       (res) => {
  //         if (res?.status === "success") {
  //           const menuOption = res?.data?.map((env) => ({
  //             label: env?.name,
  //             key: env?.id,
  //             name: env?.name,
  //           }));
  //           if (plan?.maxEnvironments > res?.data?.length) {
  //             menuOption.push({
  //               label: (
  //                 <div>
  //                   <VscGitPullRequestCreate />
  //                   {' '}
  //                   Create New
  //                 </div>
  //               ),
  //               key: "-1",
  //             });
  //           }
  //           setEnvironment(menuOption);
  //         }
  //       },
  //     );
  //   }
  // }, [environmentID, serviceID]);

  // useEffect(() => {
  //   setEnvironment((prev) => {
  //     if (prev?.length === 0) return [...prev];
  //     const newMenu = [...prev];
  //     const idCreatePlanAdded = newMenu.findIndex((menu) => menu?.key === "-1");
  //     if (idCreatePlanAdded === -1) {
  //       if (plan?.maxEnvironments > newMenu?.length) {
  //         newMenu.push({
  //           label: (
  //             <div>
  //               <VscGitPullRequestCreate />
  //               {' '}
  //               Create New
  //             </div>
  //           ),
  //           key: "-1",
  //         });
  //       }
  //     }
  //     return newMenu;
  //   });
  // }, [environmentID, serviceID, plan]);

  const onEnvChange = (e) => {
    if (e?.key === "-1") {
      setIsModalOpen(true);
      return;
    }
    if (e?.key !== environmentID) navigate(getServicePath(location.pathname, projectID, serviceID, e?.key));
  };

  const addEnvHandler = (value) => {
    if (CONSTANTS.API.environment.add) {
      API.sendRequest(
        CONSTANTS.API.environment.add,
        (res) => {
          if (res?.status === "success" && res?.data?.id) {
            navigate(
              getServicePath(
                location.pathname,
                projectID,
                serviceID,
                res?.data?.id,
              ),
            );
            setIsModalOpen(false);
          } else {
            notification.error({
              message: "Something Wrong Please Try again",
            });
          }
        },
        {
          ...value,
          serviceId: serviceID,
        },
        "Add Data Successfully!!!",
      );
    }
  };

  return (
    <>
      <MenuDropDown
        items={environment}
        activeKey={environmentID}
        onClick={onEnvChange}
      />
      <ModalFormCreator
        open={isModalOpen}
        onCreate={addEnvHandler}
        onCancel={() => {
          setIsModalOpen((prev) => !prev);
        }}
        name="Add Service Environment"
        menu="SERVICE_ENVIRONMENT_MODAL"
      />
    </>
  );
};

export default EnvironmentDropdown;
