/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable import/no-named-as-default */
import {
    Card, Col, DatePicker, Divider, Row,
} from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from "dayjs";
import moment from 'moment';
import LineChart from '../../../component/chart/line-chart';
import DonutChart from '../../../component/chart/donut-chart';
import { StarttoendDateConverter, ifTimeAvailableConvertToLocalTime } from '../../../util/functions';
import useHttp from '../../../hooks/use-http';
import CONSTANTS from '../../../util/constant/CONSTANTS';

const LogAnalytic = () => {
    const [logData, setLogData] = useState([]);
    // console.log(moment()?.format("YYYY-MM-DD"));
    // const cuurrentMonth = StarttoendDateConverter(moment()?.format("YYYY-MM-DD"));
    // console.log(cuurrentMonth);
    const [dateFilter, setDateFilter] = useState(StarttoendDateConverter(moment()?.format("YYYY-MM-DD")));
    const api = useHttp();
    // const listOfLog = [
    //     {
    //         name: "CPU History",
    //         id: "cpuHistory",
    //         value: 2000,
    //         year: ifTimeAvailableConvertToLocalTime("Daily", "2024-05-05 12:01"),
    //         lineChartData: [

    //         ],
    //     },
    //     {
    //         name: "Server Activity Log",
    //         id: "serverActivityLog",
    //         value: 22000,
    //         year: ifTimeAvailableConvertToLocalTime("Daily", "2024-05-05 12:01"),
    //     },
    //     {
    //         name: "Crash Log",
    //         id: "crashLog",
    //         value: 2040,
    //         year: ifTimeAvailableConvertToLocalTime("Daily", "2024-05-05 12:01"),
    //     },
    //     {
    //         name: "Custom Log",
    //         id: "customLog",
    //         value: 2400,
    //         year: ifTimeAvailableConvertToLocalTime("Daily", "2024-05-05 12:01"),
    //     },
    //     {
    //         name: "API Log",
    //         id: "apiLog",
    //         value: 200,
    //         year: ifTimeAvailableConvertToLocalTime("Daily", "2024-05-05 12:01"),
    //     },
    // ];
    const onChange = (date, dateString) => {
        // console.log(dateString);
        setDateFilter(dateString !== "" ? StarttoendDateConverter(dateString) : {});
        // setPaginationData({ page: 1, limit: 10, month: dateString });
    };
    useEffect(() => {
        // if (!dateFilter?.startDate && !dateFilter?.endDate) return;
        api.sendRequest(CONSTANTS.API.logAnatic.get, (res) => {
            setLogData(Object?.keys(res?.allOverData?.[0])?.map((allover) => {
                return {
                    name: CONSTANTS?.labelObject[allover]?.name,
                    id: allover,
                    value: +res?.allOverData?.[0][allover],
                    color: +res?.allOverData?.[0][allover] === 0 ? "#cbcbcb" : CONSTANTS?.labelObject[allover]?.color,
                    lineChartData: res?.data?.sort((a, b) => moment(a?.formattedCreatedAt)?.diff(moment(b?.formattedCreatedAt)))?.map((monthlyTotal) => {
                        const keys = Object?.keys(monthlyTotal);
                        const findkey = keys?.find((el) => el === allover);
                        return {
                            name: CONSTANTS?.labelObject?.[findkey]?.name ?? "",
                            id: findkey,
                            value: +monthlyTotal[allover],
                            year: ifTimeAvailableConvertToLocalTime("Daily", monthlyTotal?.formattedCreatedAt),
                        };
                    }),
                };
            }));
        }, { ...dateFilter });
    }, [dateFilter]);

    return (
        <Row gutter={[16, 16]}>
            <Col span={24} className="my-5 text-base flex justify-between items-center font-semibold">
                <p>Usage Summary</p>

                <div className="flex gap-4">
                    <DatePicker defaultValue={dayjs(moment()?.format("YYYY-MM"))} onChange={onChange} picker="month" />
                </div>
            </Col>

            <Col span={24} md={14}>

                <Card>
                    <Row>
                        <Col className="text-lg font-semibold" span={24}>Usage chart</Col>
                        <Col span={24}>
                            <DonutChart isLoading={api.isLoading} data={logData || []} />
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col span={24} md={10}>
                <Card className="h-full">
                    <Row className="h-full">
                        {
                            logData?.map((data) => (
                                <>
                                    <Col key={data?.id} span={24} className="my-5 text-base flex justify-between items-center font-semibold">
                                        <p>{data?.name}</p>

                                        <div className="flex gap-4">
                                            {data?.value}
                                            {' '}
                                            Logs
                                        </div>

                                    </Col>
                                    <Divider className="m-0" />
                                </>
                            ))
                        }
                    </Row>
                </Card>
            </Col>
            {logData?.map((data) => (
                <Col span={24} md={12}>
                    <Card>
                        <Row>
                            <Col span={24} className="my-5 text-base flex justify-between items-center font-semibold">
                                <p>{data?.name}</p>

                                <div className="flex gap-4">
                                    {data?.value}
                                    {" "}
                                    Logs
                                </div>
                            </Col>
                            <Col span={24}>
                                <LineChart isLoading={api.isLoading} data={data?.lineChartData || []} label={data?.name} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            ))}
        </Row>
    );
};

export default LogAnalytic;
