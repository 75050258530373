/* eslint-disable no-else-return */
/* eslint-disable brace-style */
/* eslint-disable padded-blocks */
/* eslint-disable function-paren-newline */
/* eslint-disable function-call-argument-newline */
/* eslint-disable arrow-parens */
/* eslint-disable dot-notation */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable comma-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable object-curly-newline */
import { Button, Card, Form, notification } from "antd";
import React from "react";
import { VscOrganization } from "react-icons/vsc";
import FormFields from "../common/FormFields";
import { hsvToHex, payloadGenerator } from "../../util/functions";
import CONSTANTS from "../../util/constant/CONSTANTS";
import useHttp from "../../hooks/use-http";

const OrganizationInfo = (props) => {
  const { user_details, update_profile, defaultData, setRefresh, theme } = props;
  const [form] = Form.useForm();
  const API = useHttp();
  const newModalArr = [];
  const onDelete = (dataId) => {
    API.sendRequest(dataId === "organizationFavIcon" ? CONSTANTS.API.auth.deleteorganizationFavIcon : CONSTANTS.API.auth.deleteorganizationImage, () => {
      setRefresh((prev) => !prev);
    },
      {},
      'Delete Data Successfully!!!');

  };
  const onResetColor = (dataId) => {
    if (theme?.token?.defaultColor !== theme?.token?.colorPrimary) {
      update_profile({ [dataId]: theme?.token?.defaultColor });
    }

  };

  CONSTANTS?.FORM_FIELD?.PROFILE_MODAL?.map(el => {
    if (el?.type === "image-crop-upload") {
      newModalArr?.push({
        ...el,
        onDelete: (id) => onDelete(id),
      });
    }
    else if (el?.type === "colorPicker") {
      newModalArr?.push({
        ...el,
        onResetColor: (id) => onResetColor(id),
      });
    }
    else {
      newModalArr?.push({
        ...el
      });
    }
  });
  // useEffect(() => {
  //   // newModalArr?.map(el => {
  //   //   return {
  //   //     ...el,
  //   //     onDelete: () => onDelete(el?.id),
  //   //   };
  //   // });
  // }, []);

  return (
    <>
      <Card className="mb-5 h-full" title={<div className="flex items-center gap-2"><VscOrganization size={20} /> <p>Organization info</p></div>}>
        <FormFields
          formData={defaultData != null ? defaultData : {}}
          // menu="PROFILE_MODAL"
          formFields={newModalArr}
          form={form}
        />

        <Button
          type="primary"
          className="textcolor"
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                // let color = values?.color?.metaColor?.format === "hsv" ? values?.color?.metaColor?.originalInput ? values?.color?.metaColor?.originalInput : null : values?.color?.metaColor?.format === "hex" ? `#${values?.color?.metaColor?.originalInput}` : null;
                const metaColor = values?.color?.metaColor;
                const color = metaColor?.format === "hsv" ? metaColor?.originalInput ? hsvToHex((metaColor.originalInput.h / 360), metaColor?.originalInput?.s, metaColor?.originalInput?.v) : null : metaColor?.format === "hex" ? `#${metaColor?.originalInput}` : null;
                // if (color !== null && !color?.includes("#")) {
                // color = hsvToHex((color.h / 360), color?.s, color?.v);
                values["color"] = color;
                // } else {
                // values["color"] = color;
                // }
                if (
                  user_details?.organizationName === values?.organizationName
                ) {
                  delete values?.organizationName;
                }
                if (user_details?.subDomain === values?.subDomain) {
                  delete values?.subDomain;
                }
                if (user_details?.color === values?.color) {
                  delete values?.color;
                }

                const payload = payloadGenerator(
                  values,
                  CONSTANTS.FORM_FIELD.PROFILE_MODAL,
                  true
                );
                // if (!user_details?.isDomainCreated && user_details?.subDomain === values?.subDomain) {
                // if (!user_details?.isDomainCreated && user_details?.domain?.includes(window.location.host)) {
                //   payload.append("isDomainCreated", true);
                // }
                // if (user_details?.isDomainCreated && user_details?.subDomain !== values?.subDomain) {
                if (user_details?.isDomainCreated && user_details?.subDomain !== values?.subDomain) {
                  payload.append("isDomainCreated", false);
                  // values["isDomainCreated"] = false;
                }
                const obj = Object?.fromEntries(payload);

                if (Object.keys(obj)?.length > 0) {
                  update_profile(payload);
                } else {
                  notification.error({
                    message: "Please Update Organization info",
                  });
                }
              })
              .catch(() => {
                // console.error("Validate Failed:", info);
              });
          }}
        >
          update
        </Button>
      </Card>
    </>
  );
};

export default OrganizationInfo;
