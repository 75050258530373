/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable comma-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable object-curly-newline */
import { Button, Card, Form, notification } from "antd";
import React from "react";
import { MdOutlineContacts } from "react-icons/md";
import FormFields from "../common/FormFields";
import { payloadGenerator } from "../../util/functions";
import CONSTANTS from "../../util/constant/CONSTANTS";

const ContectInfo = (props) => {
  const { user_details, update_profile, defaultData } = props;
  const [form] = Form.useForm();

  return (
    <>
      <Card className="mb-5 h-full" title={<div className="flex items-center gap-2"><MdOutlineContacts size={20} /> <p>Contect info</p></div>}>
        <FormFields
          formData={defaultData != null ? defaultData : {}} // edit
          menu="CONTECT_MODAL"
          // formFields={formFields}
          form={form}
        //   disabled={disabled}
        />
        <Button
          type="primary"
          className="textcolor"
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                if (user_details?.username === values?.username) {
                  delete values?.username;
                }
                if (user_details?.contactNo === values?.contactNo) {
                  delete values?.contactNo;
                }

                const payload = payloadGenerator(
                  values,
                  CONSTANTS.FORM_FIELD.CONTECT_MODAL,
                  false
                );

                if (Object.keys(values)?.length > 0) {
                  update_profile(payload);
                } else {
                  notification.error({
                    message: "Please Update Contect info",
                  });
                }
              })
              .catch(() => {
                // console.error("Validate Failed:", info);
              });
          }}
        >
          update
        </Button>
      </Card>
    </>
  );
};

export default ContectInfo;
