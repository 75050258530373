/* eslint-disable object-curly-newline */
import { Col, Row, Tour } from "antd";
import React, { useEffect, useRef, useState } from "react";

// import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
// import WarningBar from "./component/warning";
import UsageCard from "./component/usage-card";
import ProjectList from "./component/project-list";
import LogList from "./component/log-list";
import useHttp from "../../../hooks/use-http";
import CONSTANTS, { projectRoot } from "../../../util/constant/CONSTANTS";
import ModalFormCreator from "../../../component/common/ModalFormCreator";
import { getDurationMessage, isLogin } from "../../../util/functions";
import Maintenance from "./component/maintenance";

const Project = (props) => {
  const [projects, setProjects] = useState([]);
  // const [service, setService] = useState([]);

  // const [isWarningVisible, setIsWarningVisible] = useState(true);
  const [log, setLog] = useState([]);
  const navigate = useNavigate();
  const plan = props?.plan;

  const [refresh, setRefresh] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const API = useHttp();
  const location = useLocation();
  const currentPath = location.pathname;
  const [tour, setTour] = useState(-1);
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();

  const steps = [
    {
      title: "Create a new Project",
      description: "Click Here to create a new wooffer Project",
      target: () => ref1.current,
    },
    {
      title: "Create Project identifier",
      description:
        "Enter a unique project name and description to distinguish your project.",
      target: () => ref2.current,
    },
    {
      title: "Save Project",
      description: "Click on submit to see create a Project.",
      target: () => ref3.current,
    },
  ];

  useEffect(() => {
    if (!isLogin()) return;
    if (localStorage.getItem("userRole") === "Distributer" && !currentPath?.includes("distribute")) return;
    const getLogAPI = { ...CONSTANTS.API.serviceLog.get };
    // const getServiceAPI = { ...CONSTANTS.API.service.get };
    getLogAPI.endpoint += "?limit=4";
    API.sendBulkRequest([CONSTANTS.API.project.get, getLogAPI,
      // getServiceAPI
    ], (res) => {
      if (res?.length > 0) {
        setProjects(res[0]?.data?.data?.rows);
        if ((!localStorage.getItem("project_tour")) && (res[0]?.data?.data?.rows?.length === 0)) {
          setTour(0);
        } else {
          localStorage.setItem("project_tour", "Done");
        }
        setLog(res[1]?.data?.data);
        // setService(res[2]?.data?.data);
      }
    });
  }, [refresh]);

  // useEffect(() => {
  //   // console.log((!localStorage.getItem("project_tour")) && (projects?.length === 0));
  //   if () {

  //   }
  // }, [projects]);
  useEffect(() => {
    if (ref2?.current && isModalOpen) {
      setTour((prev) => (prev >= 0 ? 1 : -1));
    }
  }, [isModalOpen]);
  const addProjectHandler = (payload) => {
    API.sendRequest(
      CONSTANTS.API.project.add,
      (res) => {
        setIsModalOpen((prev) => !prev);
        localStorage.setItem("project_tour", "Done");
        setRefresh((prev) => !prev);
        if (res?.data?.id) {
          navigate(`${projectRoot}/${res?.data?.id}`);
        }
      },
      payload,
      "Add Data Successfully!!!",
    );
  };

  return (
    <>
      {/* <Helmet>
          <link
            rel="shortcut icon"
            href={
              props?.user_details?.organizationFavIcon
                ? props?.user_details?.organizationFavIcon
                : "https://wooffer-staging.s3.ap-south-1.amazonaws.com/Speech_Bubble_1_8ad412e2a7.png"
            }
          />
        </Helmet> */}
      <Row className="flex-col gap-4">
        {props?.maintenance !== null && Object?.keys(props?.maintenance)?.length > 0 ? (
          <Maintenance
            maintenance={props?.maintenance}
          />
        ) : null}
        {/* <WarningBar
          isVisible={isWarningVisible && plan?.plan?.name === "Basic"}
          setIsWarningVisible={setIsWarningVisible}
          isLoading={API.isLoading}
          user_details={props?.user_details}
        /> */}
        <UsageCard
          plan={plan}
          logLength={log?.count}
          projectsLength={projects?.length}
          // serviceLength={service?.count}
          serviceLength={0}
          isLoading={API.isLoading}
          durationMessage={getDurationMessage(props?.user_details?.createdAt)}
          userName={props?.user_details?.username}
          theme={props?.theme}
        />

        <Row gutter={[10, 10]} className="h-96 mb-10">
          <Col span={24} lg={14}>
            <ProjectList
              projects={projects}
              isLoading={API.isLoading}
              onProjectAdd={addProjectHandler}
              setIsModalOpen={setIsModalOpen}
              isButtonVisible={
                !API.isLoading && plan?.maxProjects > projects?.length
              }
              ref1={ref1}
              theme={props?.theme}
            />
            <Tour
              open={tour >= 0}
              className="mt-10"
              onClose={() => {
                setTour(-1);
                localStorage.setItem("project_tour", "Done");
              }}
              arrow
              onFinish={() => []}
              steps={steps}
              current={tour}
              onChange={(tourCount) => {
                if (tour > tourCount) {
                  setTour(tourCount);
                } else if (tourCount === 1) {
                  setIsModalOpen(true);
                } else {
                  setTour(tourCount);
                }

                if (tourCount === 0) {
                  setIsModalOpen(false);
                }
              }}
            />
          </Col>
          <Col span={24} lg={10}>
            <LogList log={log?.rows || []} isLoading={API.isLoading} />
          </Col>
        </Row>

        <ModalFormCreator
          inputRef={ref2}
          open={isModalOpen}
          onCreate={addProjectHandler}
          onCancel={() => {
            setIsModalOpen(false);
          }}
          name="Add Project"
          menu="PROJECT_MODAL"
          buttonRef={ref3}
        />
      </Row>
    </>
  );
};

export default Project;
