import {
    Button, Card, Form, Rate, Result, notification,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Title from 'antd/es/typography/Title';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useHttp from '../../../hooks/use-http';
import CONSTANTS from '../../../util/constant/CONSTANTS';

const Feedback = () => {
    const [feedbackData, setFeedbackData] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [form] = Form.useForm();
    const api = useHttp();
    const { token } = useParams();

    // console.log(user_details);
    const formSubmissionHandler = () => {
        form.validateFields().then((values) => {
            const FEEDBACK_UPDATE_API = { ...CONSTANTS.API.feedback.updateFeedback };
            FEEDBACK_UPDATE_API.endpoint = `${FEEDBACK_UPDATE_API.endpoint}/${token}?userId=${feedbackData?.userId}`;
            const payload = {
                ...values,
            };
            api.sendRequest(FEEDBACK_UPDATE_API, () => {
                setRefresh((prev) => !prev);
            }, payload);
        }).catch((err) => {
            notification.error({ message: err });
        });
    };

    useEffect(() => {
        const FEEDBACK_API = { ...CONSTANTS.API.feedback.getFeedback };
        FEEDBACK_API.endpoint = `${FEEDBACK_API.endpoint}/${token}`;
        api.sendRequest(FEEDBACK_API, (res) => {
            setFeedbackData(res?.data);
        });
    }, [token, refresh]);

    return (
        <>
            <Card
                className="h-full lg:w-1/2 m-auto"
                title={(
                    <Title className="!m-0 p-0" level={5}>
                        Feedback
                    </Title>
                )}
            >
                {feedbackData?.updatedAt === feedbackData?.createdAt ? (
                    <Form form={form} onFinish={formSubmissionHandler}>
                        <p className="!text-xl mb-3">
                            Rate Your Experience
                        </p>
                        <Form.Item
                            name="rating"
                            rules={[{
                                required: true,
                                message: `Enter Ratings`,
                            }]}
                        >
                            <Rate allowHalf defaultValue={0} />
                        </Form.Item>
                        <Form.Item
                            name="feedback"
                            rules={[{
                                required: true,
                                message: `Enter Feedback`,
                            }]}
                        >
                            <TextArea placeholder="Write about Your Service Experience" rows={4} />
                        </Form.Item>
                        <Form.Item>
                            <Button className="textcolor" type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                ) : (
                    <Result
                        status="success"
                        title="Thank You for Your Feedback!"
                        subTitle="Thanks for sharing your thoughts with us! Your suggestion helps Wooffer get better! We have received your feature request. Our team will prioritize improvements that best serve our users."
                        extra={[
                            <Button type="primary" className="textcolor" key="console" onClick={() => window.location.assign('/app')}>
                                Go Back to Home
                            </Button>,
                        ]}
                    />
                )}

            </Card>

        </>
    );
};

export default Feedback;
