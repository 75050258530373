/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import {
  Badge,
  Button,
  Card,
  Col,
  DatePicker,
  Empty,
  Radio,
  Row,
  Spin,
  Timeline,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import {
  StopOutlined,
  WarningOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { TiArrowUnsorted } from "react-icons/ti";
import { AiOutlineAlert } from "react-icons/ai";
import { PiLinkBreakDuotone, PiClockCountdownDuotone, PiArrowsCounterClockwiseBold } from "react-icons/pi";
import Search from "antd/es/input/Search";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { BsCheckAll } from "react-icons/bs";
import { FaArrowUpShortWide, FaArrowDownShortWide } from "react-icons/fa6";
import useHttp from "../../../../hooks/use-http";
import {
  apiGenerator,
  disabledDate,
  formatAmount,
  getMaxTimeUnit,
  isLogin,
} from "../../../../util/functions";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import Filter from "./filter";
import AnalyticsCountCard from "../../../../component/card/analytics-count-card";

import InfoIcon from "../../../../component/common/info-icon";
// import primaryColor from "../../../../theme.json";

const { RangePicker } = DatePicker;
const { Text } = Typography;
export const STATUS_CONFIG = (status = "") => {
  status = status.toLowerCase();
  switch (status) {
    case "start":
      return {
        color: "green",
        icon: <CheckCircleOutlined />,
      };
    case "stop":
      return {
        color: "red",
        icon: <StopOutlined />,
      };
    case "error":
      return {
        color: "red",
        icon: <PiLinkBreakDuotone />,
      };
    case "alert":
      return {
        color: "orange",
        icon: <AiOutlineAlert />,
      };
    case "success":
      return {
        color: "green",
        icon: <BsCheckAll />,
      };
    case "timediff":
      return {
        color: "grey",
        icon: <TiArrowUnsorted />,
      };

    default:
      return {
        color: "red",
        icon: <WarningOutlined />,
      };
  }
};
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

let page = 1;
const limit = process.env.REACT_APP_LOGLIMIT || 20;

const filterInitValue = {
  Alert: "Alert",
  Success: "Success",
  Fail: "Fail",
  Error: "Error",
};

const ServiceLog = ({ systems_status, error_status, theme }) => {
  const navigate = useNavigate();

  const { serviceID, environmentID } = useParams();
  const [serviceLogDetails, setServiceDetails] = useState([]);
  const [serviceCount, setServiceCount] = useState([]);
  const [serviceDownTime, setServiceDownTime] = useState(-1);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [activeTab, setActiveTab] = useState(1);
  const [filter, setFilter] = useState({ ...filterInitValue });
  const [searchValue, setSearchValue] = useState("");
  const [dateRange, setdateRange] = useState({
    startDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const debounceTimeout = useRef(null);

  const [mode, setMode] = useState("serverActivityLog");

  const API = useHttp();

  const calculateTimeDifference = (obj1, obj2) => {
    const startTime = moment(obj1.createdAt);
    const endTime = moment(obj2.createdAt);
    const diffInDays = endTime.diff(startTime, "days");
    const diffInHours = endTime.diff(startTime, "hours");
    const diffInMinutes = endTime.diff(startTime, "minutes");
    const diffInSeconds = endTime.diff(startTime, "seconds");

    let timeDifference;

    if (diffInDays >= 30) {
      const diffInMonths = Math.floor(diffInDays / 30);
      timeDifference = `${diffInMonths} months`;
    } else if (diffInHours >= 24) {
      timeDifference = `${diffInDays} days`;
    } else if (diffInMinutes >= 60) {
      timeDifference = `${diffInHours} hours`;
    } else if (diffInSeconds >= 60) {
      timeDifference = `${diffInMinutes} minutes`;
    } else {
      timeDifference = `${diffInSeconds} seconds`;
    }

    // Add the time difference to the "start" object
    return timeDifference;
  };
  const getServiceLog = (value) => {
    if (Object.keys(filter)?.length === 0) {
      setServiceDetails([]);
      return;
    }
    let extraPerms = `?page=${page}&sort=timeFrame&limit=${limit}&serviceEnvironmentId=${environmentID}${activeTab === 1 ? "" : `${Object.keys(filter)?.length > 0 ? `&status=${Object.values(filter)?.toString()}` : ""}${value ? `&search=${value}` : ""}`
      }`;

    if (dateRange?.startDate && dateRange?.endDate) {
      extraPerms += `&startDate=${dateRange?.startDate}&endDate=${dateRange?.endDate}`;
    }
    API.sendRequest(
      apiGenerator(
        activeTab === 1
          ? CONSTANTS.API.serviceLog.getStartStopLog
          : CONSTANTS.API.serviceLog.get,
        {},
        extraPerms,
      ),
      (res) => {
        if (res.data == null) return navigate("/unAuthorize");
        if (res?.status === "success") {
          let response = [];

          res?.data?.rows?.forEach((el, i) => {
            let timeDiff = "";
            if (
              el?.status === "Start"
              && i + 1 < res?.data?.rows?.length
              && res?.data?.rows[i + 1]?.status === "Stop"
            ) {
              timeDiff = calculateTimeDifference(res?.data?.rows[i + 1], el);
            }
            response.push(el);
            if (timeDiff !== "") {
              response.push({
                status: "timeDiff",
                value: timeDiff,
              });
            }
          });
          response = response.map((log) => {
            if (activeTab === 1) {
              return {
                ...log,
                detail: log?.title,
              };
            }
            return log;
          });

          if (page === 1) {
            setServiceDetails(response);
          } else {
            setServiceDetails((prev) => {
              if (
                prev?.[prev?.length - 1]?.status === "Start"
                && response?.[0]
                && response?.[0]?.status === "Stop"
              ) {
                const timeDiff = calculateTimeDifference(response?.[0], prev?.[prev?.length - 1]);
                if (timeDiff !== "") {
                  response.unshift({
                    status: "timeDiff",
                    value: timeDiff,
                  });
                }
              }
              return [...prev, ...response];
            });
          }

          if (res?.data?.count <= page * limit) {
            setIsButtonVisible(false);
          } else {
            setIsButtonVisible(true);
          }
        }
      },
    );
  };

  const getServiceLogCount = () => {
    if (activeTab === 2) {
      let extraPerms = `?page=${page}&limit=${limit}&serviceEnvironmentId=${environmentID}`;

      if (dateRange?.startDate && dateRange?.endDate) {
        extraPerms += `&startDate=${dateRange?.startDate}&endDate=${dateRange?.endDate}`;
      }
      API.sendRequest(
        apiGenerator(CONSTANTS.API.serviceLog.getOverview, {}, extraPerms),
        (res) => {
          if (res.data == null) return navigate("/unAuthorize");
          if (res?.status === "success") {
            const logCount = {};
            res?.data?.map((log) => (logCount[log.status] = log.statusCount));

            setServiceCount(logCount);
          }
        },
      );
    }
  };

  const getServiceDownTime = () => {
    let extraPerms = `?serviceEnvironmentId=${environmentID}`;

    if (dateRange?.startDate && dateRange?.endDate) {
      extraPerms += `&startDate=${dateRange?.startDate}&endDate=${dateRange?.endDate}`;
    }
    API.sendRequest(
      apiGenerator(CONSTANTS.API.serviceLog.getDownTime, {}, extraPerms),
      (res) => {
        if (res?.data?.averageDowntime) {
          setServiceDownTime({
            ...res?.data,
            averageDowntime: getMaxTimeUnit(res?.data?.averageDowntime),
            maxDownTime: getMaxTimeUnit(res?.data?.maxDownTime),
            minDownTime: getMaxTimeUnit(res?.data?.minDownTime),
          });
        } else {
          setServiceDownTime({});
        }
      },
    );
  };

  // useEffect(() => {
  //   console.log("sec");
  //   if (!isLogin()) return;
  //   getServiceLog();

  //   if (activeTab === 1) {
  //     getServiceDownTime();
  //   }
  //   if (activeTab === 2) {
  //     getServiceLogCount();
  //   }
  // }, [
  //   systems_status?.[environmentID],
  //   error_status?.[environmentID],
  //   environmentID,
  //   serviceID,
  // ]);

  // const reCallFunction = () => {
  //   console.log("first");
  //   if (!isLogin()) return;
  //   page = 1;
  //   setServiceDetails([]);
  //   getServiceLog();
  //   if (activeTab === 1) {
  //     getServiceDownTime();
  //   }
  //   if (activeTab === 2) {
  //     getServiceLogCount();
  //   }
  // };

  useEffect(() => {
    // console.log("first");
    if (!isLogin()) return;
    page = 1;
    setServiceDetails([]);
    getServiceLog();
    if (activeTab === 1) {
      getServiceDownTime();
    }
    if (activeTab === 2) {
      getServiceLogCount();
    }
  }, [systems_status?.[environmentID],
  error_status?.[environmentID],
    environmentID,
    serviceID, activeTab, dateRange, filter]);

  const SpaceGap = <div className="mt-5 h-0.5" />;

  const tabData = [
    {
      label: `Server Activity Log`,
      key: "serverActivityLog",
      info: "Monitor server activities and performances",
      children: <div />
      ,
    },
    {
      label: `Critical Logs`,
      key: "criticalLogs",
      info: "Monitor user activities and the status of the requests",
      children: <div />,
    },
  ];
  const onDateChange = (value) => {
    if (!(value?.length > 0)) {
      setdateRange(null);
      return;
    }

    setdateRange({
      startDate: value?.[0]?.format("YYYY-MM-DD"),
      endDate: value?.[1]?.format("YYYY-MM-DD"),
    });
    // reCallFunction();
  };

  const handleModeChange = (e) => {
    setMode(e.target.value);
  };

  const onClickFilter = (item) => {
    setFilter((prev) => {
      if (prev?.[item]) {
        delete prev?.[item];
        return { ...prev };
      }
      return { ...prev, [item]: item };
    });
  };

  const searchHandler = (value) => {
    // Clear the previous timeout if it exists
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set a new timeout to call the debounced function after a certain delay
    debounceTimeout.current = setTimeout(() => {
      page = 1;
      getServiceLog(value);
    }, 500); // Adjust the delay as needed
  };
  // console.log(filter);
  return (
    <>
      <div className="flex gap-4 mb-2 mt-2">
        <Radio.Group onChange={handleModeChange} value={mode} className="mr-3">
          {tabData?.map((el, index) => (
            <Radio.Button
              key={el?.key}
              value={el?.key}
              onClick={() => setActiveTab(index + 1)}
            >
              {el?.label}
              {" "}
              <Tooltip className="cursor-pointer" title={el?.info}>
                <span className="mt-1 relative top-1">
                  <InfoIcon
                    color={
                      mode === el?.key
                        ? theme?.token?.colorPrimary
                        : "#000000E0"
                    }
                  />
                </span>
              </Tooltip>
            </Radio.Button>
          ))}

        </Radio.Group>

        <div className="flex  justify-start items-center gap-3">
          <RangePicker
            disabledDate={disabledDate}
            allowClear={false}
            defaultValue={[
              dayjs(dateRange?.startDate),
              dayjs(dateRange?.endDate),
            ]}
            onChange={onDateChange}
          />
        </div>
      </div>

      <Card className="mt-2">

        {mode === "criticalLogs" && (
          <div className="flex justify-between">
            <div className="flex flex-wrap mt-5 md:mt-0 gap-4 items-center">
              <Filter
                disable={!!filter?.[filterInitValue.Success]}
                message={(
                  <Text>
                    {filterInitValue.Success}
                    {' '}
                    &nbsp;
                    <Tooltip
                      className="cursor-pointer"
                      title="This is a Success event count set by YOU."
                    >
                      <span className="mt-1 -ml-2 mr-[5px] relative top-1">
                        <InfoIcon color="#000000E0" />
                      </span>
                    </Tooltip>
                    <Badge
                      count={
                        serviceCount?.[filterInitValue.Success]
                          ? formatAmount(+serviceCount?.[filterInitValue.Success])
                          : 0
                      }
                      showZero
                      overflowCount={<div />}
                      color={filter?.[filterInitValue.Success] ? "green" : "gray"}
                    />
                  </Text>
                )}
                type="success"
                onClick={() => onClickFilter(filterInitValue.Success)}
                icon={(
                  <BsCheckAll
                    color={filter?.[filterInitValue.Success] ? "" : "#919191"}
                  />
                )}
              />
              <Filter
                disable={!!filter?.[filterInitValue.Alert]}
                message={(
                  <Text>
                    {filterInitValue.Alert}
                    {' '}
                    &nbsp;
                    <Tooltip
                      className="cursor-pointer"
                      title="This is a Alert event count set by YOU."
                    >
                      <span className="mt-1 -ml-2 mr-[5px] relative top-1">
                        <InfoIcon color="#000000E0" />
                      </span>
                    </Tooltip>
                    <Badge
                      count={
                        serviceCount?.[filterInitValue.Alert]
                          ? formatAmount(+serviceCount?.[filterInitValue.Alert])
                          : 0
                      }
                      showZero
                      overflowCount={<div />}
                      color={filter?.[filterInitValue.Alert] ? "orange" : "gray"}
                    />
                  </Text>
                )}
                onClick={() => onClickFilter(filterInitValue.Alert)}
                type="warning"
                icon={(
                  <AiOutlineAlert
                    color={filter?.[filterInitValue.Alert] ? "" : "#919191"}
                  />
                )}
              />
              <Filter
                disable={!!filter?.[filterInitValue.Fail]}
                message={(
                  <Text>
                    {filterInitValue.Fail}
                    {' '}
                    &nbsp;
                    <Tooltip
                      className="cursor-pointer"
                      title="This is a Fail event count set by YOU."
                    >
                      <span className="mt-1 -ml-2 mr-[5px] relative top-1">
                        <InfoIcon color="#000000E0" />
                      </span>
                    </Tooltip>
                    <Badge
                      count={
                        serviceCount?.[filterInitValue.Fail]
                          ? formatAmount(+serviceCount?.[filterInitValue.Fail])
                          : 0
                      }
                      showZero
                      overflowCount={<div />}
                      color={filter?.[filterInitValue.Fail] ? "red" : "gray"}
                    />
                  </Text>
                )}
                onClick={() => onClickFilter(filterInitValue.Fail)}
                type="error"
                icon={(
                  <WarningOutlined
                    style={{
                      color: filter?.[filterInitValue.Fail] ? "" : "#919191",
                    }}
                  />
                )}
              />

              <Filter
                disable={!!filter?.[filterInitValue.Error]}
                message={(
                  <Text>
                    Uncaught Exception &nbsp;
                    <Tooltip
                      className="cursor-pointer"
                      title={(
                        <p>
                          This is the Unhandled Exception event count.
                          {" "}
                          <p className="font-bold">HIGH PRIORITY </p>
                          RESOLUTION
                          NEEDED. (This could breakdown your backend server!!!)
                          {" "}
                        </p>
                      )}
                    >
                      <span className="mt-1 -ml-2 mr-[5px] relative top-1">
                        <InfoIcon color="#000000E0" />
                      </span>
                    </Tooltip>
                    <Badge
                      count={
                        +serviceCount?.[filterInitValue.Error]
                          ? formatAmount(+serviceCount?.[filterInitValue.Error])
                          : 0
                      }
                      showZero
                      overflowCount={<div />}
                      color={filter?.[filterInitValue.Error] ? "red" : "gray"}
                    />
                  </Text>
                )}
                onClick={() => onClickFilter(filterInitValue.Error)}
                type="error"
                icon={(
                  <PiLinkBreakDuotone
                    color={filter?.[filterInitValue.Error] ? "" : "#919191"}
                  />
                )}
              />

              {Object.values(filter).length < 3 && (
                <Button
                  className="cursor-pointer defaultColor"
                  onClick={() => setFilter({ ...filterInitValue })}
                  type="link"
                >
                  Clear
                </Button>
              )}
              <Search
                width="auto"
                value={searchValue}
                className="d-flex lg:hidden "
                // defaultValue={filterText}
                onChange={(e) => {
                  searchHandler(e.target.value);
                  setSearchValue(e.target.value);
                }}
                placeholder="Search Critical logs"
              />

            </div>
            <div className="hidden lg:flex items-center">
              <Search
                width="auto"
                value={searchValue}
                className="w-52"
                // defaultValue={filterText}
                onChange={(e) => {
                  searchHandler(e.target.value);
                  setSearchValue(e.target.value);
                }}
                placeholder="Search Critical logs"
              />
            </div>

          </div>
        )}

        {mode === "serverActivityLog"
          && serviceDownTime?.averageDowntime?.time !== undefined
          && serviceDownTime?.averageDowntime?.time !== null && (
            <Row className="mt-5 md:mt-0" gutter={[15, 15]}>
              <Col span={24} md={12} lg={12} xl={6} xxl={6}>
                <AnalyticsCountCard
                  heading={(
                    <>
                      <PiArrowsCounterClockwiseBold />
                      &nbsp; RESTART COUNT
                      <Tooltip
                        className="cursor-pointer"
                        title="Count of the times Server is restarted between the selected date range."
                      >
                        <span className="mt-1.5   mr-[5px] ">
                          <InfoIcon />
                        </span>
                      </Tooltip>
                    </>
                  )}
                  count={serviceDownTime?.downtimeCount}
                />
              </Col>
              <Col span={24} md={12} lg={12} xl={6} xxl={6}>
                <AnalyticsCountCard
                  heading={(
                    <>
                      <PiClockCountdownDuotone />
                      &nbsp; AVERAGE DOWNTIME
                      <Tooltip
                        className="cursor-pointer "
                        title="Average Server downtime between the selected date range. (Lesser Avg Downtime means More Successful Business!)"
                      >
                        <span className="mt-1.5   mr-[5px] ">
                          <InfoIcon />
                        </span>
                      </Tooltip>
                    </>
                  )}
                  count={serviceDownTime?.averageDowntime?.time}
                  unit={serviceDownTime?.averageDowntime?.unit}
                />
              </Col>

              <Col span={24} md={12} lg={12} xl={6} xxl={6}>
                <AnalyticsCountCard
                  heading={(
                    <>
                      <FaArrowUpShortWide />
                      &nbsp; MAX DOWNTIME
                      <Tooltip
                        className="cursor-pointer"
                        title="Maximum Server downtime between the selected date range."
                      >
                        <span className="mt-1.5   mr-[5px] ">
                          <InfoIcon />
                        </span>
                      </Tooltip>
                    </>
                  )}
                  count={serviceDownTime?.maxDownTime?.time}
                  unit={serviceDownTime?.maxDownTime?.unit}
                />
              </Col>
              <Col span={24} md={12} lg={12} xl={6} xxl={6}>
                <AnalyticsCountCard
                  heading={(
                    <>
                      <FaArrowDownShortWide />
                      &nbsp; MIN DOWNTIME
                      <Tooltip
                        className="cursor-pointer"
                        title="Minimum Server downtime between the selected date range."
                      >
                        <span className="mt-1.5   mr-[5px] ">
                          <InfoIcon />
                        </span>
                      </Tooltip>
                    </>
                  )}
                  count={serviceDownTime?.minDownTime?.time}
                  unit={serviceDownTime?.minDownTime?.unit}
                />
              </Col>
            </Row>
          )}
        {/* {API?.isLoading && serviceLogDetails?.length === 0 && (
          <div className=" h-[120rem]  w-full">
            <Spin className="mt-48" tip="Loading..." size="large">
              <div className="content" />
            </Spin>
          </div>
        )} */}
        {API?.isLoading ? (
          <div className=" h-[10rem]  w-full">
            <Spin className="mt-24" tip="Loading..." size="large">
              <div className="content" />
            </Spin>
          </div>
        ) : (
          <Timeline
            className="ps-3 mt-5 md:mt-2 pt-5"
            items={serviceLogDetails?.map((el, i) => {
              let entry = {};
              if (el?.status !== "timeDiff") {
                entry = {
                  color: STATUS_CONFIG(el?.status).color,
                  dot: STATUS_CONFIG(el?.status).icon,
                  children: (
                    <>
                      <Text
                        style={{
                          color: STATUS_CONFIG(el?.status).color,
                          fontSize: "12px",
                        }}
                      >
                        {moment(el?.createdAt)
                          .local()
                          .format("hh:mm:ss a DD/MM/YYYY")}
                      </Text>
                      <br />
                      <Text>{el?.detail}</Text>
                      {!(
                        i + 1 < serviceLogDetails?.length
                        && serviceLogDetails[i + 1]?.status === "timeDiff"
                      ) && SpaceGap}
                      {i !== 0
                        && serviceLogDetails[i - 1]?.status === "timeDiff"
                        && SpaceGap}
                    </>
                  ),
                };
              } else {
                entry = {
                  color: STATUS_CONFIG(el?.status).color,
                  dot: STATUS_CONFIG(el?.status).icon,
                  children: (
                    <Text
                      style={{
                        color: STATUS_CONFIG(el?.status).color,
                        fontSize: "12px",
                      }}
                    >
                      {el?.value}
                    </Text>
                  ),
                };
              }

              return entry;
            })}
          />
        )}
        {API?.isLoading && page !== 1 && (
          <Spin color="primary" className="ms-3" indicator={antIcon} />
        )}
        {serviceLogDetails?.length > 0
          && !API?.isLoading
          && isButtonVisible && (
            <Button
              type="link"
              className="defaultColor"
              onClick={() => {
                page += 1;
                getServiceLog();
              }}
            >
              Load more
            </Button>
          )}

        {serviceLogDetails?.length === 0 && !API?.isLoading && <Empty />}
      </Card>
    </>
  );
};

export default ServiceLog;
