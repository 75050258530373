import React from "react";
import { Row } from "antd";
import Charts from "./component/charts";

const UtilisationHistory = (props) => {
  return (
    <Row>
      <Charts plan={props?.plan} />
    </Row>
  );
};

export default UtilisationHistory;
