/* eslint-disable no-unsafe-optional-chaining */
import {
  Card, Col, Row, Typography,
} from "antd";
import React, { useEffect, useState } from "react";

import { FieldTimeOutlined } from "@ant-design/icons";
import { SiSecurityscorecard } from "react-icons/si";
import { IoMdPulse } from "react-icons/io";
import { BsFillCpuFill } from "react-icons/bs";
import { useParams } from "react-router-dom";
import Progress from "../chart/progress";
import CpuInfo from "../common/cpu-info";
import GaugeChart from "../chart/gauge";
import CONSTANTS from "../../util/constant/CONSTANTS";

const { Text } = Typography;

const CpuUsage = (props) => {
  const { environmentID } = useParams();
  const [CpuData, setCPUData] = useState({});
  let currentService = {};
  let cpuUsagePercent = 0;

  useEffect(() => {
    props.socket.on("systemData", (systemData) => {
      if (
        environmentID in systemData
        && "Process" in systemData[environmentID]
        && systemData[environmentID]?.Process?.[+CONSTANTS.PROCESS.id]
      ) {
        setCPUData(systemData || {});
      }
    });
  }, [environmentID, props?.mode]);

  if (CpuData?.[environmentID]) {
    currentService = CpuData?.[environmentID];
  }

  let CoreCpuSum = +currentService?.CPU?.Core?.reduce((total, core) => {
    return +total + +core?.usage;
  }, 0) || 0;

  cpuUsagePercent = CoreCpuSum / (currentService?.CPU?.Core?.length * 100);

  if (props?.mode === "process") {
    cpuUsagePercent = 0;
    currentService.CPU = currentService?.Process?.[
      CONSTANTS.PROCESS.id
    ]?.reduce(
      (prev, current) => {
        return {
          ...prev,
          user: +prev?.cpu + +current?.cpu?.toFixed(2),
          cpu: +prev?.cpu + +current?.cpu?.toFixed(2),
        };
      },
      {
        cpu: 0,
        user: 0,
        System: 0,
        Idle: currentService?.CPU?.Idle,
        frequencyCounts: currentService?.CPU?.frequencyCounts,
        hardware: currentService?.CPU?.hardware,
        Core: currentService?.Process?.[CONSTANTS.PROCESS.id] || 0,
      },
    );

    if (currentService?.CPU?.user) {
      CoreCpuSum = currentService?.CPU?.cpu || 0;

      currentService.CPU.user = +(
        +currentService?.CPU?.user
        / currentService?.Process?.[CONSTANTS.PROCESS.id]?.length
      ).toFixed(2);

      cpuUsagePercent = +CoreCpuSum
        / (currentService?.Process?.[CONSTANTS.PROCESS.id]?.length * 100);
    }
  }

  return (
    <Card
      style={{
        overflow: "hidden",
        filter:
          props.socket.connected
            && props?.systems_status?.[environmentID]
            && (props?.mode === "cpu"
              || (currentService?.CPU && "cpu" in currentService?.CPU))
            && Object.keys(currentService).length > 0
            ? ""
            : "grayscale(100%)",
      }}
    >
      <Text type="secondary" className="mt-2">
        Current
        {' '}
        {props?.mode === "process" ? "Process" : "CPU"}
        {' '}
        Usage
      </Text>
      <Row gutter={[40, 40]}>
        <Col xs={24} sm={24} md={9} lg={10} xl={9}>
          <div className="h-64">
            <GaugeChart percent={cpuUsagePercent} />
          </div>
        </Col>
        <Col xs={24} sm={24} md={15} lg={14} xl={15}>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <CpuInfo
                icon={<BsFillCpuFill />}
                heading="CPU Hardware"
                description={currentService?.CPU?.hardware || "Not fetched"}
                infoMessage="Details of the processor on which the Server is running."
              />
            </Col>

          </Row>

          <Row>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <CpuInfo
                className="mt-5"
                icon={<SiSecurityscorecard />}
                heading="CPU Cores"
                description={currentService?.CPU?.Core?.length || "Not fetched"}
                infoMessage="Count of CPU Cores on which the Server is running."
              />
            </Col>

            {currentService?.CPU?.latency && (
              <Col xs={24} sm={12} md={12} lg={12} xl={8}>
                <CpuInfo
                  className="mt-5"
                  icon={<FieldTimeOutlined />}
                  heading="Latency (ms)"
                  description={currentService?.CPU?.latency || "Not fetched"}
                />
              </Col>
            )}
          </Row>

          <CpuInfo
            className="mt-5"
            icon={<IoMdPulse />}
            heading="CPU Frequency"
            description={currentService?.CPU?.frequencyCounts || "Not fetched"}
            infoMessage="Frequency(es) of Individual CPU Cores on which the Server is running."
          />
        </Col>
      </Row>
      <div
        className="h-10 mt-5 md:mt-2 lg:mt-4 xl:mt-2"
        style={{
          left: "0",
          marginBottom: "-56px",
          marginLeft: " -33px",
          marginRight: "-26px ",

          padding: "0",
        }}
      >
        <Progress
          height="8px"
          progress={[
            {
              color: "#1677FF",
              progress: `${currentService?.CPU?.user}%`,
              tooltip: `User usage ${currentService?.CPU?.user}%`,
            },
            {
              color: "#FF9416",
              progress: `${currentService?.CPU?.System}%`,
              tooltip: `System usage ${currentService?.CPU?.System}%`,
            },
          ]}
        />
      </div>
    </Card>
  );
};

export default React.memo(CpuUsage);
