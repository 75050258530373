import { Breadcrumb } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { appRoot } from "../util/constant/CONSTANTS";

const AutoBreadcrumb = ({ items = [] }) => {
  return (
    <Breadcrumb
      className=" text-base"
      items={[
        {
          title: (
            <Link
              to={appRoot}
              style={{ display: "flex", alignItems: "center", gap: "4px" }}
            >
              <FaHome />
              {' '}
              Home
            </Link>
          ),
        },
        ...items,
      ]}
    />
  );
};

export default AutoBreadcrumb;
