import React from "react";
import {
  Badge, Card, Row, Tooltip,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { SlackOutlined, DesktopOutlined } from "@ant-design/icons";
import { red, green } from "@ant-design/colors";
import { ROUTES } from "../../util/Route";
import { getServiceRoute } from "../../util/functions";

const { Meta } = Card;

const statusIcon = (status, name) => {
  switch (status) {
    case undefined:
      return (
        <Badge status="default" style={{ color: "gray" }} text="Loading..." />
      );

    case "trueLoading":
      return (
        <Badge status="default" style={{ color: "gray" }} text="Loading..." />
      );

    case true:
      return (
        <Badge
          showZero
          color={green[6]}
          style={{ color: green[6] }}
          text={`${name} - Connected`}
        />
      );

    default:
      return (
        <Badge
          showZero
          color={red[5]}
          style={{ color: red[5] }}
          text={`${name} - Disconnected`}
        />
      );
  }
};

function ServiceCard({
  system: service,
  production_mode,
  systems_status,
  loading,
  projectId,
}) {
  const navigate = useNavigate();
  const ProductionEnvID = service?.serviceEnvironments?.find(
    (el) => el.name === "Production",
  )?.id;

  const { serviceEnvironments = [] } = service;

  const navigationPath = getServiceRoute({
    projectId,
    serviceID: service?.id,
    environmentID: ProductionEnvID,
  });

  return (
    <Card
      className="service "
      hoverable="true"
      bodyStyle={{
        width: 300,
        height: 130,
      }}
      onClick={(e) => {
        e.stopPropagation();
        ROUTES.setup = ROUTES.setup.replace(":id", service?.id);
        navigate(navigationPath);
      }}
      actions={[
        <div
          className="flex gap-1"
          onClick={(e) => {
            e.stopPropagation();
            ROUTES.setup = ROUTES.setup.replace(":id", service?.id);
            navigate(`${navigationPath}/setting`);
          }}
        >
          <SlackOutlined key="Setting" />
          Setting
        </div>,
        <Tooltip title="Service Log">
          <DesktopOutlined
            key="log"
            onClick={(e) => {
              e.stopPropagation();
              ROUTES.log = ROUTES.log.replace(":id", service?.id);
              navigate(`${navigationPath}/log`);
            }}
          />
        </Tooltip>,
      ]}
    >
      <Meta title={service?.name} />

      <Row className="mt-2 gap-1 flex-col">
        {loading ? (
          <Badge
            status="default"
            style={{ color: "gray" }}
            text="Loading..."
          />
        ) : (
          serviceEnvironments
            ?.filter((env) => !production_mode || env.name === "Production")
            ?.map((env) => (
              <Tooltip
                title={`${env?.name} is ${systems_status[env?.id] ? "Running" : "Stop"
                  }`}
                key={env?.id}
              >
                <Link
                  to={getServiceRoute({
                    projectId,
                    serviceID: service?.id,
                    environmentID: env?.id,
                  })}
                  onClick={(e) => e.stopPropagation()}
                >
                  {statusIcon(systems_status[env?.id] || false, env?.name)}
                </Link>
              </Tooltip>
            ))
        )}
      </Row>
    </Card>
  );
}

export default ServiceCard;
