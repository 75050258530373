/* eslint-disable no-unsafe-optional-chaining */
import {
  Card,
  Col,
  Row,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// eslint-disable-next-line import/no-named-as-default
import LineChart from "../../../../../component/chart/line-chart";
import CONSTANTS from "../../../../../util/constant/CONSTANTS";
import {
  apiGenerator,
  formatAmount,
  getMaxTimeUnit,
  isLogin,
} from "../../../../../util/functions";
import useHttp from "../../../../../hooks/use-http";
import EndpointUtilization from "../../Endpoint";

const { Text } = Typography;

const AllApiUsage = ({
  value = {}, dateRange, showApiTableHandler,
}) => {
  const { id, environmentID } = useParams();
  const API = useHttp();
  const [serviceDetails, setUtilizations] = useState([]);
  const [analyticsVaiables, setAnalyticsVaiables] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (!isLogin()) return;
    let extraPerms = "";

    if (dateRange?.startDate && dateRange?.endDate) {
      extraPerms += `&startDate=${dateRange?.startDate}&endDate=${dateRange?.endDate}`;

      if (value?.extraPerms) {
        extraPerms += `&${value?.extraPerms}`;
      }
    }

    API.sendRequest(
      apiGenerator(
        CONSTANTS.API.utilization.requestChart,
        {
          serviceID: environmentID,
        },
        extraPerms,
      ),
      (res) => {
        setUtilizations(res?.data);
        const analyticsResult = res?.data?.reduce(
          (prev, curr) => {
            const currTotalCount = +curr?.totalCount;
            let currInternalCount = 0;
            if (curr?.type === "Internal") {
              currInternalCount += +curr?.totalCount;
            }
            let currThirdPartyCount = 0;
            if (curr?.type === "ThirdParty") {
              currThirdPartyCount += +curr?.totalCount;
            }

            return [
              currTotalCount + prev[0],
              currInternalCount + prev[1],
              currThirdPartyCount + prev[2],
            ];
          },
          [0, 0, 0],
        );
        const avgTime = (res?.analysis?.[0]?.averageResponseTime ?? 0)?.toFixed(2);
        const latency = getMaxTimeUnit(avgTime);
        const avgTimeInSec = (res?.analysis?.[0]?.averageResponseTime / 1000 ?? 0)?.toFixed(0);
        setAnalyticsVaiables([
          {
            id: 1,
            label: `Total API Call`,
            value: `${formatAmount(analyticsResult[0])}`,
          },
          {
            id: 2,
            label: `Internal API Call`,
            value: `${formatAmount(analyticsResult[1])}`,
          },
          {
            id: 3,
            label: `ThirdParty API Call`,
            value: `${formatAmount(analyticsResult[2])}`,
          },
          {
            id: 4,
            label: `Average Processing Time`,
            extraClass: `${avgTimeInSec <= 2 ? "text-[#5AD8A6]" : avgTimeInSec > 2 && avgTimeInSec <= 6 ? "text-[#ff5d1d]" : avgTimeInSec > 6 ? "text-[#FF4016]" : ""}`,
            value: `${latency?.time} ${latency?.unit}`,
          },
        ]);
      },
    );
  }, [id, environmentID, dateRange, refresh]);

  const utilization = [];
  serviceDetails?.map((el) => {
    const existingIndexTotal = utilization.findIndex(
      (item) => item.year === el?.date && item.name === "Total API Call",
    );

    if (existingIndexTotal !== -1) {
      utilization[existingIndexTotal].value
        += +(+el?.totalCount)?.toFixed(0) || 0;
    } else {
      utilization.push({
        year: el?.date,
        value: +(+el?.totalCount)?.toFixed(0) || 0,
        name: "Total API Call",
      });
    }

    if (el?.type === "Internal") {
      const existingIndexInternal = utilization.findIndex(
        (item) => item.year === el?.date && item.name === "Internal API Call",
      );

      if (existingIndexInternal !== -1) {
        utilization[existingIndexInternal].value
          += +(+el?.totalCount)?.toFixed(0) || 0;
      } else {
        utilization.push({
          year: el?.date,
          value: +(+el?.totalCount)?.toFixed(0) || 0,
          name: "Internal API Call",
        });
        utilization.push({
          year: el?.date,
          value: 0,
          name: "ThirdParty API Call",
        });
      }
    } else {
      const existingIndexThirdParty = utilization.findIndex(
        (item) => item.year === el?.date && item.name === "ThirdParty API Call",
      );

      if (existingIndexThirdParty !== -1) {
        utilization[existingIndexThirdParty].value
          += +(+el?.totalCount)?.toFixed(0) || 0;
      } else {
        utilization.push({
          year: el?.date,
          value: +(+el?.totalCount)?.toFixed(0) || 0,
          name: "ThirdParty API Call",
        });
        utilization.push({
          year: el?.date,
          value: 0,
          name: "Internal API Call",
        });
      }
    }
  });

  return (
    <Row gutter={[10, 10]}>
      <Col span={24} xl={24}>
        <Card className="w-full h-full">
          <Row className="justify-between flex-wrap items-center gap-3 mb-5">
            <Text>{`${value?.title} API Usage`}</Text>

          </Row>
          <div className="grid mt-3 mb-10 sm:grid-cols-4 grid-cols-2 gap-y-4">
            {analyticsVaiables.length > 0
              && analyticsVaiables?.map((el) => (
                <div
                  key={el.id}
                  className="flex flex-col border-r [&:last-child]:border-none items-center"
                >
                  <p className={`text-xl ${el.extraClass || ""} lg:text-2xl`}>
                    {el.value}
                  </p>
                  <p className="text-grayColor text-xs lg:text-sm text-center">
                    {el.label}
                  </p>
                </div>
              ))}
          </div>
          <LineChart
            data={utilization || []}
            label="request"
            isLoading={API.isLoading}
          />
        </Card>
      </Col>

      <Col span={24} xl={24}>
        <EndpointUtilization
          setReload={setRefresh}
          isVisible
          dateQuery={dateRange}
          showApiTableHandler={showApiTableHandler}
        />
      </Col>
    </Row>
  );
};

export default AllApiUsage;
