/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable no-sequences */
/* eslint-disable no-const-assign */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prefer-template */
/* eslint-disable no-plusplus */
/* eslint-disable operator-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unsafe-optional-chaining */
import React from "react";
import moment from "moment";
import dayjs from "dayjs";
import { CloseCircleTwoTone } from "@ant-design/icons";
import { Popconfirm } from "antd";
import CONSTANTS, { projectRoot, serviceRoot } from "./constant/CONSTANTS";
import { getAuthToken } from "./API/authStorage";

export const apiGenerator = (apiObject, exchangePair = {}, join = null) => {
  const apiObj = { ...apiObject };
  if (Object.keys(exchangePair).length) {
    Object.keys(exchangePair).forEach((el) => {
      apiObj.endpoint = apiObj.endpoint.replace(`:${el}`, exchangePair[el]);
    });
  }

  if (join) {
    apiObj.endpoint = `${apiObj.endpoint}${join}`;
  }
  return apiObj;
};

export const getColor = (percentage) => {
  // console.log(percentage);
  if (+percentage <= 70) return "#34B551";

  if (+percentage <= 90) return "#FF9416";
  return "#FF4016";
};

export const getChart = (percentage) => {
  if (percentage <= 70) return "#34B551";

  if (percentage <= 90) return "#FF9416";
  return "#FF4016";
};

export const getDurationMessage = (date) => {
  const currentDate = moment();
  let startDate = "";
  let endDate = "";
  const subScriptionStartDay = moment(date).local().format("D");

  if (currentDate.date() >= subScriptionStartDay) {
    startDate = `${currentDate.format("MMM")} ${subScriptionStartDay}`;
    endDate = `${currentDate
      .clone()
      .add(1, "month")
      .format("MMM")} ${subScriptionStartDay}`;
  } else {
    startDate = `${currentDate
      .clone()
      .subtract(1, "month")
      .format("MMM")} ${subScriptionStartDay}`;
    endDate = `${currentDate.format("MMM")} ${subScriptionStartDay}`;
  }

  // Create the date range string
  const dateRangeString = `Current usage period (${startDate} to ${endDate})`;

  return dateRangeString;
};

export const getServicePath = (
  pathName,
  projectID,
  ServiceID,
  EnvironmentID
) => {
  const root = pathName?.split("/");

  if (root.length > 3) root[3] = projectID;
  if (root.length > 4) root[4] = ServiceID;
  if (root.length > 5) root[5] = EnvironmentID;
  else {
    root.push(EnvironmentID);
  }

  return root?.toString()?.replaceAll(",", "/");
};

export const isLogin = () => {
  return getAuthToken() !== undefined && getAuthToken() !== null;
};

export const getRandomMessage = (maintencelines = []) => {
  const randomIndex = Math.floor(Math.random() * maintencelines.length);
  return maintencelines[randomIndex];
};

export function formatMemorySize(bytes) {
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;
  const gigabyte = megabyte * 1024;
  const terabyte = gigabyte * 1024;

  if (bytes >= terabyte) {
    return `${(bytes / terabyte)?.toFixed(2)} TB`;
  }
  if (bytes >= gigabyte) {
    return `${(bytes / gigabyte)?.toFixed(2)} GB`;
  }
  if (bytes >= megabyte) {
    return `${(bytes / megabyte)?.toFixed(2)} MB`;
  }
  if (bytes >= kilobyte) {
    return `${(bytes / kilobyte)?.toFixed(2)} KB`;
  }
  return `${bytes} Bytes`;
}

export function formatByteToGB(bytes) {
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;

  return `${(bytes / megabyte)?.toFixed(2)} MB`;
}
export function formatByteToMB(bytes) {
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;

  return +(bytes / megabyte)?.toFixed(2);
}

export function formatAmount(amount) {
  const absAmount = Math.abs(amount);

  if (absAmount >= 1e9) {
    return `${(amount / 1e9)?.toFixed(2)} B`; // Billion
  }
  if (absAmount >= 1e6) {
    return `${(amount / 1e6)?.toFixed(2)} M`; // Million
  }
  if (absAmount >= 1e3) {
    return `${(amount / 1e3)?.toFixed(2)} K`; // Thousand
  }
  return amount.toString(); // Less than a thousand
}

export function analyzeRequests(requests) {
  const urlCounts = {};
  const urlMethod = {};
  const urlEndpoint = {};
  // Count the occurrences of each originalUrl
  requests?.forEach((request) => {
    const url = request.originalUrl + request?.method;

    urlCounts[url] = (urlCounts[url] || 0) + 1;
    urlMethod[url] = request?.method;
    urlEndpoint[url] = request?.originalUrl;
  });

  // Calculate the total number of requests
  const totalRequests = requests.length;
  // Create an array of objects with originalUrl, count, and percentage
  const result = Object.keys(urlCounts).map((url) => {
    const count = urlCounts[url];
    const percentage = (count / totalRequests) * 100;
    return {
      originalUrl: urlEndpoint[url],
      count,
      percentage,
      method: urlMethod[url],
    };
  });

  return result?.slice(0)?.slice(-10)?.sort((a, b) => b.percentage - a.percentage);
}

export function getMethodBadgeColor(method) {
  method = method?.toString()?.toLowerCase();

  switch (method) {
    case "get":
      return "#00a718";

    case "post":
      return "#e5903a";

    case "patch":
      return "#7904e8";

    case "put":
      return "#274eeb";

    case "delete":
      return "#e80404";
    default:
      return "#ff743d";
  }
}

export function ifTimeAvailableConvertToLocalTime(selected, date) {
  // console.log(
  //   "date",
  //   date,
  //   moment.utc(date, "YYYY-MM-DD HH:mm").local().format("YYYY-MM-DD HH:mm")
  // );

  return selected === "Hourly" || selected === "Minutely"
    ? moment.utc(date, "YYYY-MM-DD HH:mm").local().format("YYYY-MM-DD HH:mm")
    : date;
}

export function getServiceRoute({ projectId, serviceID, environmentID }) {
  return `${projectRoot}/${serviceRoot}/${projectId}/${serviceID}/${environmentID}`;
}

export function getMaxTimeUnit(milliseconds) {
  const seconds = milliseconds / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;
  const months = days / 30; // Approximate value
  const years = days / 365; // Approximate value

  if (years >= 1) {
    return { unit: "years", time: Math.floor(years) };
  }
  if (months >= 1) {
    return { unit: "months", time: Math.floor(months) };
  }
  if (days >= 1) {
    return { unit: "days", time: Math.floor(days) };
  }
  if (hours >= 1) {
    return { unit: "hours", time: Math.floor(hours) };
  }
  if (minutes >= 1) {
    return { unit: "minutes", time: Math.floor(minutes) };
  }
  if (seconds >= 1) {
    return { unit: "seconds", time: Math.floor(seconds) };
  }
  return { unit: "milliseconds", time: Math.floor(milliseconds) };
}

export const disabledDate = (current) => {
  // Disable dates from the next day onwards
  return current && current > dayjs().endOf("day").add(0, "day");
};

export const getSelectionOption = (plan) => {
  return [
    {
      value: "Minutely",
      label: "Minutely",
      disabled: plan?.plan?.name === "Basic",
    },
    { value: "Hourly", label: "Hourly" },
    { value: "Daily", label: "Daily" },

    { value: "Weekly", label: "Weekly" },
    {
      value: "Biweekly",
      label: "Biweekly",
      disabled: plan?.plan?.name === "Basic",
    },
    {
      value: "Monthly",
      label: "Monthly",
      disabled: plan?.plan?.name === "Basic",
    },

    {
      value: "Quarterly",
      label: "Quarterly",
      disabled: plan?.plan?.name === "Basic",
    },
    {
      value: "Semi-Annually",
      label: "Semi-Annually",
      disabled: plan?.plan?.name === "Basic",
    },
    {
      value: "Yearly",
      label: "Yearly",
      disabled: plan?.plan?.name === "Basic",
    },
    {
      value: "AllLogs",
      label: "AllLogs",
      disabled: plan?.plan?.name === "Basic",
    },
  ];
};

export function isLinkValid(link) {
  const validProtocols = ["http", "https"];
  const lowerCaseLink = link.toLowerCase();

  return validProtocols.some((protocol) => lowerCaseLink.startsWith(protocol));
}

export function processInputs(inputs) {
  const root = { label: "root", id: "root", children: [] };

  inputs.forEach((input) => {
    let currentNode = root;

    input.forEach((segment) => {
      if (segment == null || typeof segment === "object" || !segment) {
        return;
      }

      const existingNode = currentNode.children.find(
        (node) => node.label === segment
      );

      if (existingNode) {
        currentNode = existingNode;
      } else {
        const newNode = {
          label: segment,
          id: `${currentNode.id}/${segment}`,
          children: [],
          usage: input.slice(-1)[0],
        };

        currentNode.children.push(newNode);
        currentNode.children.sort(
          (a, b) => b?.children.length - a?.children.length
        );
        currentNode = newNode;
      }
    });
    currentNode.children.sort(
      (a, b) => b?.children.length - a?.children.length
    );
  });

  root.children.sort((a, b) => b?.children.length - a?.children.length);
  return root;
}

export const levelViseMaxXValue = {};
export const min = {};

const confirmHandler = (value, environmentID, api, setRefersh) => {
  const method = Object.keys(value?.details)[0];
  const payload = {
    method,
    endPoint: `/${value?.details[method]?.endPoint}`,
    serviceEnvironmentId: environmentID,
    isVisible: false,
  };
  // console.log('payload', api);
  const VISIBILITY_API = { ...CONSTANTS.API.overview.updateApiVisibility };
  api.sendRequest(
    VISIBILITY_API,
    () => {
      setRefersh((prev) => !prev);
    },
    payload,
    "Endpoint Blocked Successfully !!!"
  );
};

const addOrUpdate = (key, value) => {
  levelViseMaxXValue[key] = value;
  key += 100;
  while (key in levelViseMaxXValue) {
    if (levelViseMaxXValue[key] < value) {
      levelViseMaxXValue[key] = value;
    }
    key += 100;
  }
};

const getStartingXFromParent = (level, value) => {
  let result = value;

  while (level >= 0) {
    if (levelViseMaxXValue?.[level] > result) {
      result = levelViseMaxXValue[level] + 200;
    }
    level -= 100;
  }

  return result;
};

export const traverseAndGenerateNodes = (
  node,
  x,
  y,
  setRefersh,
  environmentID,
  api
) => {
  const result = [];
  let nodeX = x;

  if (node) {
    const nodeId = node.id;
    const nodeLabel = node.label;
    const levelID = y;

    if (node.children && node.children.length > 0) {
      // let min = 0;
      let max = 0;

      node.children.forEach((child) => {
        const childId = levelID + 100;
        max = getStartingXFromParent(
          childId,
          childId in levelViseMaxXValue ? levelViseMaxXValue[childId] + 200 : 0
        );

        const childNodes = traverseAndGenerateNodes(
          child,
          max,
          y + 100,
          setRefersh,
          environmentID,
          api
        );
        result.push(...childNodes);
      });

      const minMaxValue = Object.keys(min)
        .filter((key) => {
          const keyLength = key.split("/");
          const nodeKeyLength = nodeId.split("/");
          keyLength.pop();

          return (
            key !== nodeId &&
            keyLength.join("/") === nodeKeyLength.join("/") &&
            nodeKeyLength.length === keyLength.length
          );
        })
        .reduce(
          (prev, curr, index) => {
            if (index === 0) {
              return [min[curr], min[curr]];
            }
            if (min[curr] < prev[1]) {
              prev[1] = min[curr];
            }

            if (min[curr] > prev[0]) {
              prev[0] = min[curr];
            }

            return prev;
          },
          [0, max]
        );

      if (nodeLabel === "auth") {
        /* empty */
      }

      nodeX = +minMaxValue[1] + (minMaxValue[0] - +minMaxValue[1]) / 2;
    }

    min[nodeId] = nodeX;
    addOrUpdate(levelID, nodeX);

    const currentNode = {
      id: nodeId,
      position: { x: nodeX, y },
      data: {
        label: (
          <div className="relative">
            <>{nodeLabel}</>
            {nodeLabel !== "root" && (
              <Popconfirm
                title="Sure to Block Endpoint?"
                className="absolute -top-[17px] -right-[18px]"
                placement="topRight"
                onConfirm={() => {
                  confirmHandler(node.usage, environmentID, api, setRefersh);
                }}
              >
                <div>
                  <CloseCircleTwoTone
                    className="absolute right-0 cursor-pointer  !text-[16px]"
                    twoToneColor="red"
                  />
                </div>
              </Popconfirm>
            )}
          </div>
        ),
      },
      usage: node.usage,
      style: { wordBreak: "break-all" },
    };
    result.push(currentNode);
  }

  return result;
};

export function parseEndpoint(url) {
  // // Case 1: Remove parameters
  let urlWithoutParams = url.split("?")[0];
  urlWithoutParams = urlWithoutParams.replace(/^\/|\/$/g, "");
  // Use a regular expression to replace numeric parts with ":id"
  const convertedUrl = urlWithoutParams.replace(/\b\d+\b/g, ":id");

  return convertedUrl;
}

export function generateSystem(parentObject) {
  let nodeList = [];
  const notConnectedNodes = {};
  Object.keys(parentObject).map((node) => {
    let completeRoute = node;
    completeRoute = node.split("/");
    if (completeRoute[0] === "") completeRoute.shift();
    const parentRoute = [...completeRoute];
    parentRoute.pop();
    if (parentRoute.length !== 0) {
      notConnectedNodes[parentRoute.join("/")] = true;
    }
    delete notConnectedNodes[completeRoute.join("/")];
    const isExist = nodeList.findIndex(
      (array) => array[array.length - 1]?.route === node || false
    );
    if (isExist === -1) {
      nodeList.push([...completeRoute, { route: node, details: { "": node } }]);
    }
    return "";
  });

  if (Object.keys(notConnectedNodes).length !== 0) {
    nodeList = [...generateSystem(notConnectedNodes), ...nodeList];
  }

  return nodeList;
}
// test
export const payloadGenerator = (value, fields, isFormData) => {
  let rawPayload = {};
  const formPayload = new FormData();

  if (isFormData) {
    fields?.forEach((ele) => {
      if (
        ele.type !== "file" &&
        ele.type !== "date" &&
        ele.type !== "multifield" &&
        ele.type !== "extraMultiSingle" &&
        ele.type !== "number" &&
        ele.type !== "fileWithPreview" &&
        ele.type !== "image-crop-upload"
      ) {
        value[ele.id] !== undefined && value[ele.id] !== null && formPayload.append(ele.id, value[ele.id]);
      }

      if (
        (ele.type === "file" ||
          ele.type === "fileWithPreview" ||
          ele.type === "image-crop-upload") &&
        value[ele?.id]
      ) {
        if (Array.isArray(value[ele.id])) {
          for (let i = 0; i < value[ele.id]?.length; i++) {
            formPayload.append(ele.id, value[ele.id][i].originFileObj);
          }
        } else {
          value[ele.id] &&
            formPayload.append(
              ele.id,
              value[ele.id][0].originFileObj || value[ele.id]
            );
        }
      }
      if (ele.type === "multifield" || ele.type === "extraMultiSingle") {
        if (ele?.handler) {
          value[ele.id] &&
            formPayload.append(ele.id, ele?.handler(value[ele.id]));
        } else {
          value[ele.id] &&
            formPayload.append(ele.id, JSON.stringify(value[ele.id]));
        }
      }

      if (ele.type === "number") {
        value[ele.id] && formPayload.append(ele.id, +value[ele.id]);
      }
      if (ele.type === "date") {
        // if (dateTime) {
        const dateTimeValue = `${moment(value[ele.id].$d).format(
          "YYYY-MM-DD"
        )} ${moment(value[ele.id].$d, "HH:mm:ss").utc().format("HH:mm:ss")}`;

        value[ele.id] && formPayload.append(ele.id, dateTimeValue);
      }
    });
  } else {
    fields.forEach((ele) => {
      if (ele?.type === "date") {
        rawPayload = {
          ...rawPayload,
          [ele?.id]: moment(value[ele?.id]?.$d, "YYYY-MM-DD").format(
            "YYYY-MM-DD"
          ),
        };
      }
      if (ele?.type === "time") {
        rawPayload = {
          ...rawPayload,
          [ele?.id]: moment(value[ele?.id]?.$d, "HH:mm:ss").format("HH:mm:ss"),
        };
      }
    });
    rawPayload = { ...value, ...rawPayload };
  }

  return isFormData ? formPayload : rawPayload;
};

export const validateDomain = (rule, value, callback) => {
  // if (!value) {
  //   callback("Please input your Organization URL!");
  //   return;
  // }
  if (!value) return callback();
  // Regular expression to validate lowercase letters and no special characters
  const domainRegex = /^[a-z]+$/;
  if (!domainRegex.test(value)) {
    callback(
      "Please enter a valid domain with lowercase letters only and no special characters!"
    );
    return;
  }

  callback(); // Validation passed
};

export function getContrastYIQ(hexcolor = "#ff743d") {
  const r = parseInt(hexcolor?.substring(1, 3), 16);
  const g = parseInt(hexcolor?.substring(3, 5), 16);
  const b = parseInt(hexcolor?.substring(5, 7), 16);
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return (yiq >= 128) ? hexcolor === "#ff743d" ? 'white' : 'black' : 'white';
}

export const generateSecondaryColor = (primaryColor) => {
  // Remove the '#' symbol if present
  primaryColor = primaryColor.replace("#", "");

  // Convert primary color from hex to RGB
  const r = parseInt(primaryColor.substr(0, 2), 16);
  const g = parseInt(primaryColor.substr(2, 2), 16);
  const b = parseInt(primaryColor.substr(4, 2), 16);

  // Calculate secondary color by subtracting each component from 255
  const rSecondary = 255 - r;
  const gSecondary = 255 - g;
  const bSecondary = 255 - b;

  // Convert secondary color back to hexadecimal format
  const secondaryColor = "#" +
    ("0" + rSecondary.toString(16)).slice(-2) +
    ("0" + gSecondary.toString(16)).slice(-2) +
    ("0" + bSecondary.toString(16)).slice(-2);

  return secondaryColor;
};

export const hsvToHex = (h, s, v) => {
  // Convert HSV to RGB
  let r = 0;
  let g = 0;
  let b = 0;
  const i = Math.floor(h * 6);
  const f = h * 6 - i;
  const p = v * (1 - s);
  const q = v * (1 - f * s);
  const t = v * (1 - (1 - f) * s);
  switch (i % 6) {
    case 0: r = v, g = t, b = p; break;
    case 1: r = q, g = v, b = p; break;
    case 2: r = p, g = v, b = t; break;
    case 3: r = p, g = q, b = v; break;
    case 4: r = t, g = p, b = v; break;
    case 5: r = v, g = p, b = q; break;
  }

  // Convert RGB to hexcode
  const hexR = Math.round(r * 255).toString(16).padStart(2, '0');
  const hexG = Math.round(g * 255).toString(16).padStart(2, '0');
  const hexB = Math.round(b * 255).toString(16).padStart(2, '0');
  return `#${hexR}${hexG}${hexB}`;
};

const removeSpaces = (str) => {
  if (typeof str === 'string') {
    return str.trim();
  }
  return str; // Return as is for non-string values
};

// Function to remove leading and trailing spaces from all string values within an object
export const removeSpacesFromObject = (obj) => {
  const cleanedObj = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(obj)) {
    cleanedObj[key] = removeSpaces(value);
  }
  return cleanedObj;
};

export const convertLocalString = (value) => {
  return value ? `₹ ${(+value || 0)?.toLocaleString('en-IN')}` : "₹ 0";
};

export const convertLocalStringWithoutRupee = (value) => {
  return value ? `${(+value || 0)?.toLocaleString('en-IN')}` : "0";
};

export const statusColor = (value) => {
  return <p className={`${value === "Active" ? "text-green-500" : value === "Reject" ? "text-red-500" : value === "Pending" ? "text-orange-400" : ""}`}>{value}</p>;
};

export const StarttoendDateConverter = (date) => {
  const createdAtDate = moment(date);
  const firstDayOfPreviousMonth = createdAtDate.clone().subtract(0, 'months').startOf('month');

  // Get the last day of the previous month
  const lastDayOfPreviousMonth = createdAtDate.clone().subtract(0, 'months').endOf('month');
  const formattedFirstDay = firstDayOfPreviousMonth.format('YYYY-MM-DD');
  const formattedLastDay = lastDayOfPreviousMonth.format('YYYY-MM-DD');

  return {
    startDate: formattedFirstDay,
    endDate: formattedLastDay
  };
};

export const rzpayObj = (res, userData, logo, paymentResponseHandler) => {
  return {
    key: res?.razorpay_key, // Enter the Key ID generated from the Dashboard
    amount: `${res?.amount / 100}`, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: "INR",
    name: "Wooffer", // your business name
    description: "Test Transaction",
    image: logo, // your business logo
    order_id: res?.data?.id, // This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    async handler(response) {
      const data = {
        orderCreationId: res?.data?.id,
        razorpayPaymentId: response.razorpay_payment_id,
        razorpayOrderId: response.razorpay_order_id,
        razorpaySignature: response.razorpay_signature,
      };
      paymentResponseHandler(data);

      // const result = await axios.post("http://localhost:5000/payment/success", data);

      // alert(result.data.msg);
    },
    prefill: { // We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
      name: userData?.username ?? "", // your customer's name
      email: userData?.email ?? "", // your customer's email address
      contact: "9000090000" // Provide the customer's phone number for better conversion rates 
    },
    notes: {
      address: "Razorpay Corporate Office"
    },
    theme: {
      color: userData?.color ?? "#ff743d",
    }
  };
};
