import { Button, Result } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Success = () => {
    const navigate = useNavigate();

    return (
        <>
            <Result
                status="success"
                title="Your payment has been Successfully done"
                subTitle="Cloud server configuration takes 1-5 minutes, please wait."
                extra={[
                    <Button onClick={() => navigate('/app')} type="primary" key="Home">
                        Home
                    </Button>,
                    <Button onClick={() => navigate('/app/billing')} key="back to billing">Back to Billing page</Button>,
                ]}
            />
        </>
    );
};

export default Success;
