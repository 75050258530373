import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Col, Radio, Row, Tooltip,
} from "antd";
import { isLogin } from "../../../util/functions";
import CpuUsage from "../../../component/Service/cpu-usage";
import MemoryUsage from "../../../component/Service/memory-usage";
import CoreList from "../../../component/Service/core-list";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import InfoIcon from "../../../component/common/info-icon";
// import primaryColor from '../../../theme.json';

const ServiceDashboard = (props) => {
  const { serviceID, environmentID } = useParams();

  const [mode, setMode] = useState("process");
  // pod functionllty
  const [currantPod, setCurrantPod] = useState(null);
  const [envIDs, setEnvIDs] = useState({});

  const tempEnvironmentIDRef = useRef(environmentID);
  const handleModeChange = (e) => {
    setMode(e.target.value);
  };
  // pod functionllty
  const handlePodChange = (e) => {
    setCurrantPod(e.target.value);
    CONSTANTS.PROCESS.id = e.target.value.replace("pod-", "");
    // console.log(CONSTANTS.PROCESS.id);
  };

  useEffect(() => {
    if (!isLogin()) return;

    props?.socket?.emit("homePage");

    props?.socket.on("connect", () => {
      CONSTANTS.PROCESS.id = null;
      props?.socket?.emit("servicePageView", {
        serviceToken: environmentID,
      });
    });
    CONSTANTS.PROCESS.id = null;
    props?.socket?.emit("servicePageView", {
      serviceToken: environmentID,
    });

    props?.socket?.on("disconnect", () => {
      CONSTANTS.PROCESS.id = null;
    });
  }, [serviceID, environmentID]);

  useEffect(() => {
    tempEnvironmentIDRef.current = environmentID;
  }, [environmentID]);
  useEffect(() => {
    if (!isLogin()) return;
    let envIDsObject = {};
    props.socket.on("systemData", (systemData) => {
      const tempEnvironmentID = tempEnvironmentIDRef?.current;
      const processID = systemData?.[tempEnvironmentID]?.Process ? Object?.keys(systemData?.[tempEnvironmentID]?.Process)?.[0] ?? null : null;
      if (processID !== null && !envIDsObject?.[`pod-${processID}`]) {
        envIDsObject = { ...envIDsObject, [`pod-${processID}`]: { status: true } };
        // pod functionllty
        setEnvIDs((prev) => ({ ...prev, [`pod-${processID}`]: { status: true } }));
      }
      if (CONSTANTS.PROCESS?.id == null
        && systemData?.[tempEnvironmentID]?.Process
        && Object.keys(systemData?.[tempEnvironmentID]?.Process).length > 0
      ) {
        // pod functionllty
        setCurrantPod(`pod-${processID}`);
        CONSTANTS.PROCESS.id = Object.keys(systemData?.[tempEnvironmentID]?.Process)[0] || null;
      }
    });
  }, []);

  return (
    <>
      <Radio.Group
        onChange={handleModeChange}
        value={mode}
        style={{ marginBottom: 8 }}
      >
        <Radio.Button
          className={` ${mode === "process" ? "defaultBorderColor defaultColor" : ""
            } `}
          value="process"
        >
          Process Usage
          <Tooltip
            className="cursor-pointer"
            title="Total Resources used by the Process."
          >
            <span className="mt-1 relative top-1">
              <InfoIcon
                color={
                  mode === "process"
                    ? props?.theme?.token?.colorPrimary
                    : "#000000E0"
                }
              />
            </span>
          </Tooltip>
        </Radio.Button>
        <Radio.Button
          className={` ${mode === "cpu" ? "defaultBorderColor defaultColor" : ""
            } `}
          value="cpu"
        >
          Overall CPU Usage
          <Tooltip
            className="cursor-pointer"
            title="Total Resources used by the CPU."
          >
            {" "}
            <span className="mt-1 relative top-1">
              <InfoIcon
                color={
                  mode === "cpu"
                    ? props?.theme?.token?.colorPrimary
                    : "#000000E0"
                }
              />
            </span>
          </Tooltip>
        </Radio.Button>
      </Radio.Group>

      <br />
      {/* // pod functionllty */}
      {props?.systems_status?.isKubernetes && (
        <Radio.Group
          onChange={handlePodChange}
          value={currantPod}
          style={{ marginBottom: 8 }}
        >
          {Object.keys(envIDs).map((pod) => (
            <Radio.Button
              key={pod}
              className={` ${currantPod === pod ? "defaultBorderColor defaultColor" : ""
                } `}
              value={pod}
            >
              {pod}
            </Radio.Button>
          ))}
        </Radio.Group>
      )}

      <Row gutter={[16, 24]} className="mb-10">
        <Col xs={24} sm={24} md={24} lg={15} xl={15}>
          <CpuUsage {...props} mode={mode} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={9} xl={9} {...props}>
          <MemoryUsage {...props} mode={mode} />
        </Col>
      </Row>
      <CoreList {...props} mode={mode} />
    </>
  );
};

export default ServiceDashboard;
