/* eslint-disable no-param-reassign */
import React, { useState } from "react";
import {
  Button, Form, Modal, Popconfirm,
} from "antd";
import FormFields from "./FormFields";
import CONSTANTS from "../../util/constant/CONSTANTS";

const ModalFormCreator = ({
  open,
  onCreate,
  Submit = true,
  onCancel,
  name = "",
  DeleteTitle,
  formData = {},
  menu,
  disabled = false,
  edit,
  changedFields = {},
  onChangedFields = () => { },
  SubmitName = "Submit",
  onEdit = () => { },
  Delete,
  disableSubmit = true,
  onDelete = () => { },
  formFields = [],
  children,
  inbuilt = true,
  loading = false,
  buttonRef,
  inputRef,
}) => {
  const [defaultData, setDefaultData] = useState(null);
  // const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const fieldChangeData = {};

  const handleEdit = () => {
    if (CONSTANTS.USER_ROLE) {
      return;
    }
    onEdit();
  };

  const handleDelete = () => {
    if (CONSTANTS.USER_ROLE) {
      return;
    }
    onDelete();
  };

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        setDefaultData(values);
        Object?.keys(fieldChangeData)?.forEach((key) => {
          if (formData[key] !== fieldChangeData[key]) {
            changedFields[key] = fieldChangeData[key];
          }
        });
        onCreate(values, () => {
          setDefaultData(null);
        });
        onChangedFields(changedFields);
      })
      .catch((info) => {
        console.error("Validate Failed:", info);
      });
  };

  const handleModalCancel = () => {
    setDefaultData(null);
    form.resetFields();
    onCancel();
  };

  const footer = {
    footer: [
      edit && (
        <Button
          className="textcolor"
          key="edit"
          style={{
            borderRadius: "4px",
            height: "40px",
            width: "200px",
          }}
          disabled={CONSTANTS.USER_ROLE}
          ghost
          type="primary"
          onClick={handleEdit}
        >
          Edit
        </Button>
      ),
      Delete && (
        <Popconfirm
          onConfirm={handleDelete}
          key="deleteConfirm"
          disabled={CONSTANTS.USER_ROLE}
          title={`Delete ${DeleteTitle || name.toLowerCase()}`}
          description={`Are you sure to delete ${DeleteTitle || name.toLowerCase()
            }`}
        >
          <Button
            key="delete"
            className="textcolor"
            style={{
              borderRadius: "4px",
              height: "40px",
              width: "200px",
            }}
            ghost
            type="primary"
            danger
          >
            Delete
          </Button>
        </Popconfirm>
      ),
      Submit && (
        <Button
          ref={buttonRef}
          key="submit"
          className="textcolor"
          disabled={CONSTANTS.USER_ROLE && disableSubmit}
          style={{
            borderRadius: "4px",
            height: "40px",
            width: "200px",
          }}
          form={`form_in_modal${menu}`}
          type="primary"
          onClick={handleFormSubmit}
          loading={loading}
        >
          {SubmitName}
        </Button>
      ),
    ],
  };

  return (
    <Modal
      open={open}
      title={<p className="form-modal-head mb25">{name}</p>}
      okText="Submit"
      width={1000}
      {...footer}
      cancelButtonProps={{ style: { display: "none" } }}
      className="form-modal-title"
      onCancel={handleModalCancel}
      onOk={handleFormSubmit}
    >
      <div ref={inputRef}>
        {inbuilt && (
          <FormFields
            formData={defaultData || formData}
            menu={menu}
            formFields={formFields}
            form={form}
            disabled={disabled}
            changedFields={fieldChangeData}
          />
        )}
      </div>
      {children}
    </Modal>
  );
};

export default ModalFormCreator;

ModalFormCreator.defaultProps = {
  open: false,
  onCreate: () => { },
  onCancel: () => { },
  name: "",
  formData: {},
  menu: "",
  edit: false,
  onEdit: () => { },
  formFields: [],
};
