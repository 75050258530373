import { Button, Result } from 'antd';
import React from 'react';
import { deleteAuthDetails } from '../util/API/authStorage';

const SuspendentUser = () => {
    const handleLogout = () => {
        deleteAuthDetails();
        localStorage.removeItem("name");
        localStorage.removeItem("email");
        localStorage.removeItem("token");
        localStorage.removeItem("ItemToken");
        localStorage.clear();
        window.location.assign("/");
    };
    return (
        <>
            <Result
                status="403"
                title="Account Suspended"
                subTitle={(
                    <p>
                        Your account has been suspended. For assistance, please contact the Wooffer support team at&nbsp;
                        <a href="mailto:support@wooffer.io">
                            support@wooffer.io
                        </a>
                        .
                    </p>
                )}
                extra={(
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="mt-5 w-40 h-10  "
                        onClick={handleLogout}
                    >
                        Logout
                    </Button>
                )}
            />
        </>
    );
};
export default SuspendentUser;
