/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable comma-dangle */
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Image,
  Layout,
  Menu,
  Row,
  Space,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import Sider from "antd/es/layout/Sider";
// import { Helmet } from "react-helmet";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { IoAnalyticsOutline } from "react-icons/io5";
import { BiReceipt } from "react-icons/bi";
import CONSTANTS, {
  appRoot,
  distributeRoot,
  loginRoot,
  projectRoot,
} from "../../util/constant/CONSTANTS";
// import Profile from "../../asset/image/dummy-avatar.jpg";
import { getAuthToken } from "../../util/API/authStorage";

import shortLogo from "../../asset/logos/logo.svg";
import logo from "../../asset/logos/icon.svg";
import RightDrawer from "./Component/right-drawer";
import useHttp from "../../hooks/use-http";
import { menu as items } from "../../util/constant/menu";
import AutoBreadcrumb from "../Breadcrumb";
import InfoIcon from "../common/info-icon";
import TooltipCustom from "../common/Tooltip";

const { Header, Content } = Layout;
const { Text } = Typography;
const AppLayout = (props) => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const API = useHttp();
  const location = useLocation();
  const currentPath = location.pathname;
  const [visible, setVisible] = useState(false);
  const [project, setProject] = useState([]);
  const breadcrumbItems = [];
  const [collapsed, setCollapsed] = useState(false);
  useEffect(() => {
    if (!(getAuthToken() !== undefined && getAuthToken() !== null)) {
      navigate(loginRoot);
      return;
    }
    if (localStorage.getItem("userRole") === "Distributer" && !currentPath?.includes("distribute")) {
      navigate(distributeRoot);
      return;
    }

    API.sendRequest(CONSTANTS.API.project.get, (res) => {
      if (res?.status === "success") {
        setProject(
          res?.data?.rows?.map((projectEl) => ({
            label: projectEl?.name,
            key: projectEl?.id,
            name: projectEl?.name,
          }))
        );
      }
    });
  }, []);

  const onProjectChange = (e) => {
    if (e?.key !== projectId) navigate(`${projectRoot}/${e?.key}`);
  };
  const menuProps = {
    items: project,
    onClick: onProjectChange,
  };

  const onMenuChange = (e) => {
    if (e.keyPath.length) {
      navigate(
        items[+e.key.replace("tmp-", "")]?.path?.replace(
          ":projectID",
          projectId
        )
      );
    }
  };

  const currantProject = project?.find(
    (projectEl) => projectEl?.key === projectId
  );

  let activeMenuItemIndex = items.findIndex(
    (item) => currentPath === item.path.replace(":projectID", projectId)
    // console.log(item)
  );

  // place it before activeMenuItemIndex update
  if (currentPath === `${projectRoot}/${projectId}/integration`) {
    breadcrumbItems.push({
      title: (
        <Link to={`${projectRoot}/${projectId}`}>{project?.[0]?.name}</Link>
      ),
    });
    breadcrumbItems.push({
      title: (
        <p>
          Integration{" "}
          <TooltipCustom title="Integrate collaborative apps with Wooffer" />
        </p>
      ),
    });
  } else if (currentPath === `${projectRoot}/${projectId}/setting`) {
    breadcrumbItems.push({
      title: (
        <Link to={`${projectRoot}/${projectId}`}>{project?.[0]?.name}</Link>
      ),
    });
    breadcrumbItems.push({
      title: (
        <p>
          Setting <TooltipCustom title="Edit or Delete your service details" />
        </p>
      ),
    });
  } else {
    breadcrumbItems.push({
      title: (
        <p>
          {project?.[activeMenuItemIndex]?.name}{" "}
          <TooltipCustom title="Find all services of your project here" />
        </p>
      ),
    });
  }

  // Place it Below breadcrumbItems
  activeMenuItemIndex = `tmp-${activeMenuItemIndex}`;

  return (
    <>
      {/* <Helmet>
       {props?.user_details?.organizationFavIcon ? <link rel="icon" href={props?.user_details?.organizationFavIcon} /> : <link rel="icon" href="https://wooffer-staging.s3.ap-south-1.amazonaws.com/Speech_Bubble_1_8ad412e2a7.png" />}
      </Helmet> */}
      <Layout>
        <Layout>
          <Layout
            hasSider
            style={{
              minHeight: "100vh",
            }}
          >
            <Sider
              className="hidden lg:block !sticky top-0 h-[100vh]"
              collapsible
              trigger={null}
              collapsed={collapsed}
              // onCollapse={(value) => setCollapsed(value)}
              theme="light"
            >
              {!collapsed ? (
                <div className="flex justify-center mt-3">
                  <Image
                    style={{
                      height: "50px",
                      width: "110px",
                      cursor: "pointer",
                    }}
                    preview={false}
                    src={props?.user_details?.organizationImage || logo}
                    onClick={() => {
                      navigate(`${appRoot}`);
                    }}
                  />
                </div>
              ) : (
                <div className="flex justify-center my-5 ">
                  <Image
                    style={{ height: "30px", cursor: "pointer" }}
                    preview={false}
                    src={props?.user_details?.organizationFavIcon || shortLogo}
                    onClick={() => {
                      navigate(`${appRoot}`);
                    }}
                  />
                </div>
              )}
              <Menu
                selectedKeys={activeMenuItemIndex}
                activeKey=""
                inlineCollapsed={collapsed}
                items={items}
                onClick={onMenuChange}
                expandIcon
                className=" hidden lg:block"
                mode="inline"
              />
              <Button
                className={`textcolor absolute top-[85px] right-0 translate-x-[50%] flex items-center justify-center ${""} `}
                type="primary"
                icon={collapsed ? <AiOutlineRight /> : <AiOutlineLeft />}
                size="small"
                shape="circle"
                onClick={() => setCollapsed(!collapsed)}
              />
            </Sider>
            <Layout className="site-layout">
              <Header
                className="top-0 sticky z-10  px-0 md:px-0 h-28 md:h-16   flex flex-col justify-center "
                style={{
                  backgroundColor: "#FFFFFF",
                }}
              >
                {/* Web View */}
                <Row
                  align="middle"
                  justify="space-between"
                  className="hidden md:flex"
                  style={{ padding: "0px 35px 0px 25px" }}
                >
                  <Col span={12} lg={12} className=" flex">
                    <div className="hidden md:block lg:hidden">
                      <Image
                        style={{
                          height: "50px",
                          width: "110px",
                          cursor: "pointer",
                        }}
                        preview={false}
                        src={props?.user_details?.organizationImage || logo}
                        className=""
                        onClick={() => {
                          navigate(`${appRoot}`);
                        }}
                      />
                    </div>
                    <div className=" hidden  lg:flex items-center gap-2">
                      <Text className="me-2">Project</Text>{" "}
                      <Tooltip
                        className="cursor-pointer"
                        title="Collection of Various Services."
                      >
                        <span className="-ml-4 relative top-1">
                          <InfoIcon color="#000000E0" />
                        </span>
                      </Tooltip>
                      <Dropdown className="hidden md:flex" menu={menuProps}>
                        <Button>
                          <Space>
                            {currantProject?.name || "Select Project"}
                            <DownOutlined />
                          </Space>
                        </Button>
                      </Dropdown>
                    </div>
                  </Col>

                  <Col
                    span={12}
                    lg={12}
                    className="flex  justify-end items-center gap-4"
                  >
                    <div className="flex   items-center gap-2">
                      {" "}
                      <Text>Environment</Text>{" "}
                      <Tooltip
                        className="cursor-pointer"
                        title='Select "Production" or "All" environment(s) for viewing.'
                      >
                        <span className="mt-2 -ml-2">
                          <InfoIcon color="#000000E0" />
                        </span>
                      </Tooltip>
                      <Switch
                        className="textcolor"
                        checkedChildren="Production"
                        unCheckedChildren="All"
                        defaultChecked={props?.production_mode || false}
                        onChange={() => {
                          props?.set_production_mode((prev) => !prev);
                        }}
                      />
                    </div>
                    {/* <div className="flex   items-center gap-4"> */}
                    <Tooltip
                      className="cursor-pointer"
                      title="Usage"
                    >
                      <IoAnalyticsOutline className="cursor-pointer" size={28} onClick={() => navigate("/app/log-analytics")} />
                    </Tooltip>
                    <Tooltip
                      className="cursor-pointer"
                      title="Billing Detail"
                    >

                      <BiReceipt className=" cursor-pointer" size={28} onClick={() => navigate("/app/billing")} />
                    </Tooltip>
                    {/* </div> */}

                    <div>
                      <Avatar
                        // src={Profile}
                        className="cursor-pointer card-primary-to-secondary textcolor border-[1px] border-gray-300"
                        shape="circle"
                        size={35}
                        style={{
                          // border: "1px solid gray",
                        }}
                        onClick={() => {
                          setVisible(true);
                        }}
                      >
                        {props?.user_details?.username?.charAt(0)?.toUpperCase()}
                      </Avatar>
                      {/* <Avatar
                        src={Profile}
                        size={35}
                        style={{
                          border: "1px solid black",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setVisible(true);
                        }}
                      /> */}
                    </div>
                  </Col>
                </Row>

                {/* Mobile View */}
                <div className="md:hidden flex   mx-5 -mb-4 justify-center">
                  <div>
                    <Image
                      style={{
                        height: "50px",
                        width: "110px",
                        cursor: "pointer",
                      }}
                      preview={false}
                      src={props?.user_details?.organizationImage || logo}
                      onClick={() => {
                        navigate(`${appRoot}`);
                      }}
                    />
                  </div>
                </div>
                <div className="md:hidden flex mx-5 justify-between">
                  <div>
                    <Dropdown menu={menuProps}>
                      <Button>
                        <Space>
                          {currantProject?.name || "Select Project"}
                          <DownOutlined />
                        </Space>
                      </Button>
                    </Dropdown>
                  </div>
                  <div className="flex items-center gap-2">
                    {" "}
                    <Switch
                      checkedChildren="Production"
                      unCheckedChildren="All"
                      defaultChecked={props?.production_mode || false}
                      onChange={() => {
                        props?.set_production_mode((prev) => !prev);
                      }}
                    />
                  </div>
                  <div className="flex items-center gap-4">
                    <Tooltip
                      className="cursor-pointer"
                      title="Usage"
                    >
                      <IoAnalyticsOutline className="cursor-pointer" size={28} onClick={() => navigate("/app/log-analytics")} />
                    </Tooltip>
                    <Tooltip
                      className="cursor-pointer"
                      title="Billing Detail"
                    >

                      <BiReceipt className=" cursor-pointer" size={28} onClick={() => navigate("/app/billing")} />
                    </Tooltip>
                    <Avatar
                      // src={Profile}
                      className="cursor-pointer card-primary-to-secondary textcolor border-[1px] border-gray-300"
                      shape="circle"
                      size={35}
                      style={{
                        // border: "1px solid gray",
                      }}
                      onClick={() => {
                        setVisible(true);
                      }}
                    >
                      {props?.user_details?.username?.charAt(0)?.toUpperCase()}
                    </Avatar>
                    {/* <Avatar
                      src={Profile}
                      size={35}
                      style={{
                        border: "1px solid black",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setVisible(true);
                      }}
                    /> */}
                  </div>
                </div>
              </Header>

              <Content className="mx-3 md:mx-6 ">
                <Row className="mt-5">
                  <AutoBreadcrumb items={breadcrumbItems} />
                </Row>

                <Outlet />
              </Content>

              <Menu
                className="flex justify-center fixed bottom-0 w-full z-50 lg:hidden"
                selectedKeys={activeMenuItemIndex}
                activeKey=""
                mode="horizontal"
                items={items}
                onClick={onMenuChange}
              />
            </Layout>
          </Layout>
        </Layout>
      </Layout>
      <RightDrawer
        username={props?.user_details?.username}
        email={props?.user_details?.email}
        visible={visible}
        setVisible={setVisible}
      />
    </>
  );
};

export default AppLayout;
