/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import {
    Button,
    Card, Col, DatePicker, Row, Statistic,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from "dayjs";
import moment from 'moment';
import {
    DeleteOutlined,
} from "@ant-design/icons";
import CRUDComponent from '../../../component/common/CRUD-Component';
import CONSTANTS from '../../../util/constant/CONSTANTS';
import {
    StarttoendDateConverter, apiGenerator, convertLocalString, isLogin, statusColor,
} from '../../../util/functions';
import useHttp from '../../../hooks/use-http';
import ModalFormCreator from '../../../component/common/ModalFormCreator';

const DistributeDashboard = () => {
    const data = {};
    const navigate = useNavigate();
    const [distributeAnalytic, setDistributeAnalytic] = useState({});
    const [summaryData, setSummaryData] = useState([]);
    const [notifyButton, setNotifyButton] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [dateFilter, setDateFilter] = useState(StarttoendDateConverter(moment()?.format("YYYY-MM-DD")));

    const [deleteOpen, setDeleteOpen] = useState(null);

    const api = useHttp();
    useEffect(() => {
        if (!isLogin()) return;
        api.sendRequest(CONSTANTS.API.distributerCredit.getAnalytic, (res) => {
            // debugger;
            setDistributeAnalytic({
                remainingCreditAmount: res?.data?.[0]?.totalCreditQuota,
                pendingCreditAmount: res?.creditData?.[0]?.totalPendingCredits,
                activeCreditAmount: res?.creditData?.[0]?.totalRemainingActiveCredits,
                creditAmount: res?.data?.[0]?.totalCredits,
                totalCouponCount: res?.creditData?.[0]?.creditCount,
                maxCreditLimit: res?.data?.[0]?.maxCreditLimit,
            });
            setSummaryData([
                { title: "Remaining Credit Quota", count: convertLocalString(res?.data?.[0]?.totalCreditQuota) || 0, id: "remainingCreditAmount" },
                {
                    title: "Pending Credit Amount",
                    count: convertLocalString(res?.creditData?.[0]?.totalPendingCredits) || 0,
                    id: "pendinggCreditAmount",
                },
                {
                    title: "Activated Credit Amount",
                    count: convertLocalString(res?.creditData?.[0]?.totalRemainingActiveCredits) || 0,
                    id: "activeCreditAmount",
                },
                {
                    title: "Credit Quota",
                    id: "creditAmount",
                    count: convertLocalString(res?.data?.[0]?.totalCredits) || 0,
                },
                // {
                //     title: "Total Coupon Count",
                //     count: distributeAnalytic?.totalCouponCount || 0,
                //     id: "totalCouponCount",
                // },
            ]);
        }, { ...dateFilter });
    }, [dateFilter, refresh]);
    // const DETAILS = [
    //     { title: "Remaining Credit Quota", count: distributeAnalytic?.remainingCreditAmount || 0, id: "remainingCreditAmount" },
    //     {
    //         title: "Pending Credit Amount",
    //         count: convertLocalString(distributeAnalytic?.pendinggCreditAmount) || 0,
    //         id: "pendinggCreditAmount",
    //     },
    //     {
    //         title: "Activated Credit Amount",
    //         count: convertLocalString(distributeAnalytic?.activeCreditAmount) || 0,
    //         id: "activeCreditAmount",
    //     },
    //     {
    //         title: "Credit Quota",
    //         id: "creditAmount",
    //         count: distributeAnalytic?.creditAmount || 0,
    //     },
    //     // {
    //     //     title: "Total Coupon Count",
    //     //     count: distributeAnalytic?.totalCouponCount || 0,
    //     //     id: "totalCouponCount",
    //     // },

    // ];
    // console.log(DETAILS);
    const onChange = (date, dateString) => {
        // console.log(date, dateString);
        setDateFilter(dateString !== "" ? StarttoendDateConverter(dateString) : {});
        setRefresh((pr) => !pr);
        // setPaginationData({ page: 1, limit: 10, month: dateString });
    };

    const deleteConfirmHandler = (payload) => {
        api.sendRequest(apiGenerator(CONSTANTS.API.distributerPanelCredit.delete, { dataId: deleteOpen }), (res) => {
            setRefresh((pr) => !pr);
        }, payload);
    };

    return (
        <div className="mt-5">
            <div className="my-5 text-base flex justify-between items-center font-semibold">
                <p>
                    Credit Usage Summary
                </p>

                <div className="flex gap-4">
                    <DatePicker defaultValue={dayjs(moment()?.format("YYYY-MM"))} onChange={onChange} picker="month" />
                </div>
            </div>

            <Row gutter={[16, 16]}>
                {summaryData?.map((el) => (
                    <Col span={24} xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Card
                            hoverable={el?.href}
                            size="small"
                            className={`p-5 ${el?.href ? "cursor-pointer" : ""}`}
                            onClick={() => el?.href && navigate(`${el?.href}`)}
                        >
                            <Statistic
                                title={el?.title}
                                value={el?.count}
                            />
                        </Card>
                    </Col>
                ))}
            </Row>
            <ModalFormCreator
                loading={api.isLoading}
                open={deleteOpen !== null}
                onCreate={deleteConfirmHandler}
                onCancel={() => {
                    setDeleteOpen(null);
                }}
                // menuFields={CREATE?.modalFields}
                menu="DELETE_DISTRIBUTER_CREDIT_MODAL"
                formData={{}}
                name="Delete Credit"
                SubmitName="Submit"
            />
            <Card className="mt-5">
                <p className="text-lg font-semibold">
                    Credit History
                    {" "}
                    {' '}
                    <span>
                        (
                        {distributeAnalytic?.totalCouponCount || 0}
                        )
                    </span>
                </p>
                <CRUDComponent
                    reload={refresh}
                    GET={{
                        API: CONSTANTS.API.distributerPanelCredit.get,
                        extraQuery: dateFilter,
                        DataModifier: (res, API, Setrefresh) => {
                            // console.log(notifyButton);
                            return res?.map((el) => {
                                return {
                                    ...el,
                                    couponCode: `WOOFF${el?.id?.toString().padStart(6, "0")}`,
                                    userEmail: el?.user?.email,
                                    status: statusColor(el?.status),
                                    amount: convertLocalString(el?.credits),
                                    activeDate: el?.activeDate && moment(el?.activeDate)?.format("DD MMM, YYYY | hh:mm A"),
                                    createdAt: el?.createdAt && moment(el?.createdAt)?.format("DD MMM, YYYY | hh:mm A"),
                                    notifyUser: <Button
                                        type="primary"
                                        disabled={!!((notifyButton && Array.isArray(notifyButton) && notifyButton?.length > 0 && notifyButton?.includes(el?.id)) || el?.status === "Active" || el?.status === "In Review")}
                                        onClick={() => {
                                            api.sendRequest(CONSTANTS.API.distributerPanelCredit.addNotifyUser, () => {
                                                setNotifyButton((pr) => [...pr, el?.id]);
                                                setRefresh((pr) => !pr);
                                            }, { creditId: el?.id }, "The notification has been successfully sent to the user's email");
                                        }}
                                    >
                                        Notify
                                    </Button>,
                                    // deleteWithReason: <Button onClick={() => setDeleteOpen(el?.id)} type="primary" className="textcolor">
                                    //     <DeleteOutlined />
                                    // </Button>,

                                };
                            });
                        },
                        column: CONSTANTS.TABLE.DISTRIBUTE_CREDIT_HISTORY,
                    }}
                    UPDATE={{
                        API: CONSTANTS.API.distributerPanelCredit.update,
                        message: "Updated User Credit successfully",
                        modaltitle: "Update Pricing",
                        modalFields: "DISTRIBUTE_CREDIT_EDIT_MODAL",
                        setRefresh,
                        // payloadModifier: (res) => res,
                    }}
                    CREATE={{
                        API: CONSTANTS.API.distributerPanelCredit.add,
                        message: "Created User Credit Successfully",
                        modaltitle: "Add User Credit",
                        modalFields: "DISTRIBUTE_CREDIT_MODAL",
                        name: "+ Add New Credit",
                        setRefresh,
                        // payloadModifier: (res) => res,
                    }}
                    DELETE={{
                        API: CONSTANTS.API.distributerPanelCredit.delete,
                        message: "Deleted Successfully",
                        setRefresh,
                    }}
                    isSearch
                />
            </Card>
        </div>
    );
    // return <>ff</>;
};

export default DistributeDashboard;
