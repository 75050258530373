/* eslint-disable no-unsafe-optional-chaining */
import {
  Card, Col, Row, Tooltip, Typography,
} from "antd";
import React, { useEffect, useState } from "react";

import { GrMemory } from "react-icons/gr";
import { MdMemory } from "react-icons/md";
import { useParams } from "react-router-dom";
import CpuInfo from "../common/cpu-info";
import Progress from "../chart/progress";
import { formatMemorySize, getColor } from "../../util/functions";
import CONSTANTS from "../../util/constant/CONSTANTS";
import InfoIcon from "../common/info-icon";

const { Text } = Typography;

const MemoryUsage = (props) => {
  let currentService = {};
  const { environmentID } = useParams();
  const [CpuData, setCPUData] = useState({});

  useEffect(() => {
    props.socket.on("systemData", (systemData) => {
      if (
        environmentID in systemData
        && "Process" in systemData[environmentID]
        && systemData[environmentID]?.Process?.[+CONSTANTS.PROCESS.id]
      ) setCPUData(systemData || {});
    });
  }, [environmentID, props?.mode]);

  if (CpuData?.[environmentID]) {
    currentService = CpuData?.[environmentID];
  }

  // Memory Available
  const availableMemory = +currentService?.Memory?.total || 0;

  const swaptotal = +currentService?.Memory?.swaptotal || 0;

  // Memory In Use
  let memoryInUse = +currentService?.Memory?.used || 0;
  const swapMemoryInUse = +currentService?.Memory?.swapused || 0;

  let percent = `${(
    ((swapMemoryInUse + memoryInUse) / (availableMemory + swaptotal))
    * 100
  )?.toFixed(2)}%`;

  if (availableMemory + swaptotal === 0) {
    percent = "0 %";
  }

  if (props?.mode === "process") {
    memoryInUse = 0;

    const processMemoryUsage = currentService?.Process?.[
      CONSTANTS.PROCESS.id
    ]?.reduce((prev, current) => {
      memoryInUse += +current?.memRss;

      return +prev + +current?.mem;
    }, 0);

    percent = `${processMemoryUsage?.toFixed(2) || 0}%`;
  }
  return (
    <Card
      style={{
        overflow: "hidden",
        filter:
          props.socket.connected
            && props?.systems_status?.[environmentID]
            && (props?.mode === "cpu"
              || (currentService?.CPU && "cpu" in currentService?.CPU))
            && Object.keys(currentService).length > 0
            ? ""
            : "grayscale(100%)",
      }}
      className="lg:h-80 xl:h-80 "
    >
      <Row className="justify-between">
        <div className="flex">
          <Text type="secondary" className="mt-2">
            Current Memory Usage
          </Text>
          <Tooltip className="cursor-pointer" title="Total Percentage of Memory used by the Server from total allocated memory.">
            <div className="mt-2.5">
              <InfoIcon />
            </div>
          </Tooltip>
        </div>
        <Text type="secondary" className="mt-2">
          {percent}
        </Text>
      </Row>
      <Progress
        className="mt-3"
        height="15px"
        progress={[
          { color: getColor(+percent.replace("%", "")), progress: percent },
        ]}
      />
      <Row className="flex-col gap-1 mt-5 justify-between">
        <Row className="mt-5">
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <CpuInfo
              icon={<GrMemory />}
              heading="Total Physical Memory"
              description={formatMemorySize(availableMemory)}
              infoMessage="Size of RAM allocated to the Server."
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <CpuInfo
              icon={<MdMemory />}
              heading="Total Swap Memory"
              description={formatMemorySize(swaptotal)}
              infoMessage="Size of Virtual RAM created from External Storage (Hard Drive) to support the Server."
            />
          </Col>
        </Row>
        <Row className="mt-0">
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <CpuInfo
              icon={<GrMemory />}
              heading="Total Memory In Use"
              description={formatMemorySize(memoryInUse)}
              infoMessage="Total Size of RAM used by the Server"
            />
          </Col>
          {props?.mode === "cpu" && (
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CpuInfo
                icon={<MdMemory />}
                heading="Total Swap In Use"
                description={formatMemorySize(swapMemoryInUse)}
                infoMessage="Total Size of Swap Memory used by the Server."
              />
            </Col>
          )}
        </Row>
      </Row>
    </Card>
  );
};

export default React.memo(MemoryUsage);
