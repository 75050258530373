/* eslint-disable react/no-unstable-nested-components */
import { Button, Modal } from "antd";
import React, { useState } from "react";
import Title from "antd/es/typography/Title";
import useHttp from "../hooks/use-http";
import CONSTANTS from "../util/constant/CONSTANTS";

const UpgradeButton = ({
  buttonName = null, buttonClassName = '', block = true, setFunction = () => { },
}) => {
  const [open, setOpen] = useState(false);

  const API = useHttp();
  const sendRequestMessage = () => {
    API.sendRequest(
      CONSTANTS.API.plan.update,
      () => {
        setOpen(false);
        setFunction(false);
        Modal.success({
          title: "Request Created",
          content: (
            <div>
              <Title className="text-justify mt-2 !text-sm">
                Your request has been successfully created. Our technical team
                will review it and get back to you shortly. Thank you for
                reaching out!
              </Title>

              <p className="m-0 p-0 mt-5">
                For other queries, you can reach out to us at:
              </p>
              <p>
                <strong>Email:</strong>
                {' '}
                support@wooffer.io
              </p>
            </div>
          ),
          onConfirm: () => {
            Modal.destroyAll();
          },
          onCancel: () => {
            Modal.destroyAll();
          },
          okButtonProps: {
            style: { color: "#fff" },
          },
          footer: (_, { OkBtn }) => {
            return (
              <OkBtn onClick={() => { }} />
            );
          },
        });
      },
      {},
    );
  };
  return (
    <>
      <Button
        block={block}
        className={buttonClassName || 'm-5 lg:m-1 textcolor'}
        type="primary"
        onClick={() => {
          setOpen(true);
        }}
      >
        {buttonName || 'Upgrade'}
      </Button>

      <Modal
        title="Contact Wooffer Team to Update Plan"
        okText="Send Request"
        open={open}
        okButtonProps={{
          style: { color: "#fff" },
        }}
        onCancel={() => {
          setOpen(false);
          setFunction(false);
        }}
        onOk={() => sendRequestMessage()}
        confirmLoading={API?.isLoading}
      >
        <div>
          <p className="mt-5 text-sm !text-red-600">
            Note : Our Self-Plan Purchase Module is coming soon.
          </p>
          <Title level={5}>
            To Initiate the plan update process, please click on the &quot;Send
            Request&quot; button below:
          </Title>
        </div>
      </Modal>
    </>
  );
};

export default UpgradeButton;
