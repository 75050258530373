import { Row, Tooltip, Typography } from "antd";
import Title from "antd/es/typography/Title";

import React from "react";
import InfoIcon from "./info-icon";

const { Text } = Typography;
function CpuInfo({
  icon, heading, description, className, infoMessage = '',
}) {
  return (
    <Row className={`flex-col ${className || ""}`}>
      {heading && (
        <div className="flex items-center">
          <Text type="secondary" className="mt-2">
            {heading}
          </Text>

          {heading !== 'Latency (ms)' && (
            <Tooltip className="cursor-pointer" title={infoMessage}>
              <div className="mt-[14px] ">
                <InfoIcon />
              </div>
            </Tooltip>
          )}

        </div>
      )}
      <div className="flex items-center">
        {icon || <div />}
        {description && description?.toString()?.includes('PID') ? (
          <>
            <Title className="cursor-default !m-0 !p-0 !ms-1 " level={5}>
              {description}
            </Title>
            <Tooltip className="cursor-pointer" title="ID of Processes running in the Server.">
              <div className="mt-1.5 ">
                <InfoIcon color="black" />
              </div>
            </Tooltip>
          </>
        ) : (
          <Title className="cursor-default !m-0 !p-0 !ms-1 " level={5}>
            {description}
          </Title>
        )}
      </div>
    </Row>
  );
}

export default CpuInfo;
