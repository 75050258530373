import React from 'react';
// import { Typography } from 'antd';
import FormFields from '../../../../../component/common/FormFields';

// const { Text } = Typography;

const TrelloaddIntegration = ({ form, defaultData }) => {
  return (
    <>
      <div className="mt-4">
        {" "}
        <FormFields
          formData={defaultData != null ? defaultData : {}} // edit
          menu="PROJECT_TRELLO_INTEGRATION_MODAL"
          form={form}
        />
        {/* <Text type="secondary" className="mt-2 flex justify-center items-center">
          Don&apos;t know about Trello API, Domain and ProjectId ?&nbsp;
          <a
            href="https://www.youtube.com/watch?v=Ta9yE3p7hAg"
            target="_blank"
            rel="noreferrer"
            className="defaultColor"
          >
            Click Here
          </a>
        </Text> */}
      </div>
    </>
  );
};

export default TrelloaddIntegration;
