import GooglePayButton from "@google-pay/button-react";
import React from "react";

function Payment() {
  (
    <div>

      <h1>Google Pay Integration</h1>
      <GooglePayButton
        environment="TEST"
        paymentRequest={{
          apiVersion: 2,
          apiVersionMinor: 0,
          allowedPaymentMethods: [
            {
              type: "CARD",
              parameters: {
                allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                allowedCardNetworks: ["MASTERCARD", "VISA"],
              },
              tokenizationSpecification: {
                type: "PAYMENT_GATEWAY",
                parameters: {
                  gateway: "example", // Replace with your gateway
                  gatewayMerchantId: "exampleGatewayMerchantId", // Replace with your gateway merchant ID
                },
              },
            },
          ],
          merchantInfo: {
            merchantId: "12345678901234567890", // Replace with your merchant ID
            merchantName: "Example Merchant",
          },
          transactionInfo: {
            totalPriceStatus: "FINAL",
            totalPriceLabel: "Total",
            totalPrice: "100.00",
            currencyCode: "USD",
            countryCode: "US",
          },
          shippingAddressRequired: false,
          callbackIntents: ["PAYMENT_AUTHORIZATION"],
        }}
        existingPaymentMethodRequired="false"
        onLoadPaymentData={() => {
          // console.log("onLoadPaymentData", paymentRequest);
        }}
        onError={() => {
          // console.log("onError", paymentRequest);
        }}
        onPaymentAuthorized={() => {
          // console.log("onPaymentAuthorized", paymentRequest);
          return { transactionState: "SUCCESS" };
        }}
        buttonType="Buy"
      />
    </div>

  );
}

export default Payment;
