import {
  Card, Row, Tooltip, Typography,
} from "antd";
import React from "react";
import Title from "antd/es/typography/Title";
import Progress from "../chart/progress";
import { getColor } from "../../util/functions";
import InfoIcon from "../common/info-icon";

const { Text } = Typography;
function TrackingCard({ title = "", currant = 0, total = 0 }) {
  return (
    <Card
      className="overflow-hidden tracking-card"
      style={{
        overflow: "hidden",
        borderRadius: "8px",
        background:
          "linear-gradient(96deg, rgba(22, 119, 255, 0.10) 0.75%, rgba(22, 119, 255, 0.04) 107.19%)",
      }}
    >
      <div
        style={{
          position: "absolute",
          width: "100%",
          left: "0",
          top: "0",
        }}
      >
        <Progress
          height="8px"
          progress={[
            {
              color: getColor((currant / total) * 100),
              progress: `${(currant / total) * 100}%`,
            },
          ]}
        />
      </div>
      <Row className="justify-between items-center">
        <Title className="!m-0 p-0" level={5}>
          {title}
          {' '}
          {title === 'Project' && (
            <Tooltip className="cursor-pointer" title="Collection of Various Services. ">
              <span className="mt-1 relative top-1">
                <InfoIcon color="#000000E0" />
              </span>
            </Tooltip>
          )}
        </Title>
      </Row>
      <Row className="items-baseline mt-1">
        <Title className="!m-0 p-0" level={5}>
          {currant}
          {' '}
          &nbsp;
        </Title>
        <Text>
          /
          {total}
        </Text>
      </Row>
    </Card>
  );
}

export default TrackingCard;
