/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable comma-dangle */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-restricted-globals */
import { Button, Popconfirm } from "antd";
import {
  CheckCircleTwoTone, CloseCircleTwoTone, DeleteOutlined, DownloadOutlined, EditOutlined, EyeOutlined
} from "@ant-design/icons";
import moment from "moment/moment";
import { validateDomain } from "../functions";

// const { Text } = Typography;
const alphanumericSort = (a, b, value) => {
  if (isNaN(parseInt(a[value], 10))) {
    return a[value]?.localeCompare(b[value]);
  }
  return a[value] - b[value];
};

// Delete Button
const RenderDeleteButton = (value = {}) => {
  const { id, onClick } = value;
  return (
    <Popconfirm title="Sure to delete?" onConfirm={() => { onClick(id); }}>
      <Button type="primary" className="textcolor">
        {value?.name ? value?.name : <DeleteOutlined />}
      </Button>
      {/* <Button type="primary">{value?.name}</Button> */}
    </Popconfirm>
  );
};

const RenderDeleteWithReasonButton = (value = {}) => {
  const { id, onClick } = value;
  return (
    <Button type="primary" className="textcolor">
      {value?.name ? value?.name : <DeleteOutlined />}
    </Button>
  );
};

// Edit Button
const RenderEditButton = (value = {}) => {
  const { id, onClick } = value;
  return (
    <Button
      type="primary"
      className="textcolor"
      onClick={() => {
        onClick(id);
      }}
    >
      <EditOutlined />
    </Button>
  );
};

const RenderLinkButton = (value) => {
  return (
    <Button className="textcolor" type="primary" href={value}>
      Config
    </Button>
  );
};

export const loginRoot = "/";
export const appRoot = "/app";
export const onBoard = "/onboard";
export const serviceRoot = "service";
export const projectRoot = "/project";
export const distributeRoot = "/distribute";

export const initialDateFilter = {
  startDate: moment().format("YYYY-MM-DD"),
  endDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
};

export const CPU_THRESHOLD_LEVEL = 70;
export const CPU_DANGEROUS_LEVEL = 90;

const RenderDownloadButton = (value) => {
  return (
    <DownloadOutlined
      onClick={() => {
        window.open(value, "Download");
      }}
    />
  );
};

// Render View Button
const RenderViewButton = (data = {}) => {
  const { id = null, onClick = () => { }, isDisable = false } = data;
  return (
    <Button
      onClick={() => {
        onClick(id);
      }}
      disabled={isDisable}
      type="primary"
    >
      <EyeOutlined />
    </Button>
  );
};

const RenderActionButtons = (value) => {
  const ButtonsArray = [];
  if (value?.Delete) {
    ButtonsArray.push(RenderDeleteButton(value.Delete));
  }
  if (value?.Edit) {
    ButtonsArray.push(RenderEditButton(value.Edit));
  }
  if (value?.View) {
    ButtonsArray.push(RenderViewButton(value.View));
  }
  if (value?.Download) {
    ButtonsArray.push(RenderDownloadButton(value.Download));
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        // paddingRight: "5px",
      }}
    >
      {ButtonsArray?.map((el, i) => (
        <div key={i}>{el}</div>
      ))}
    </div>
  );
};

// Delete Button

const CONSTANTS = {
  GETMe: null,
  TABLE: {
    // Service
    SERVICE: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 20,
      },

      {
        title: "Service Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 150,
      },
      {
        title: "Details",
        dataIndex: "details",
        render: RenderLinkButton,
        width: 30,
      },

      {
        title: "Edit",
        dataIndex: "edit",
        render: RenderEditButton,
        width: 20,
      },

      {
        title: "Action",
        dataIndex: "delete",
        render: RenderDeleteButton,
        width: 20,
      },
    ],
    CREDIT_HISTORY: [
      // {
      //   title: "Record ID",
      //   dataIndex: "no",
      //   key: "no",
      //   width: 100,
      // },
      {
        title: "Coupon Code",
        dataIndex: "couponCode",
        key: "couponCode",
        width: 150,
      },
      {
        title: "Amount",
        dataIndex: "credits",
        className: "!text-center",
        key: "credits",
        width: 90,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: 120,
        filters: [
          {
            text: "Active",
            value: "2",
          },
          {
            text: "Reject",
            value: "3",
          },
          {
            text: "In Review",
            value: "1",
          },
          // {
          //   text: "Pending",
          //   value: "0",
          // },
        ],
        filterMode: "tree",
        filterSearch: true,
      },
      {
        title: "Provider",
        dataIndex: "distributerName",
        className: "!text-center",
        key: "distributerName",
        width: 80,
        sorter: true,
      },
      {
        title: "Date of apply",
        dataIndex: "dateOfApply",
        className: "!text-center",
        key: "dateOfApply",
        width: 90,
        sorter: true,
      },
      {
        title: "Date of Activate",
        dataIndex: "dateOfActivate",
        className: "!text-center",
        key: "dateOfActivate",
        width: 90,
        sorter: true,
      },
      {
        title: "Reason",
        dataIndex: "notes",
        render: RenderViewButton,
        width: 110,
      },
      // {
      //   title: "Message",
      //   dataIndex: "rejectMsg",
      //   className: "!text-center",
      //   key: "rejectMsg",
      //   width: 110,
      //   sorter: true,
      // },
      {
        title: "Request",
        dataIndex: "request",
        className: "!text-center",
        key: "request",
        width: 80,
        sorter: true,
      },
    ],
    APIALLOWED: [
      {
        title: "No.",
        dataIndex: "no",
        key: "no",
        width: 100,
      },
      {
        title: "Endpoint",
        dataIndex: "endpointPattern",
        key: "endpointPattern",
        width: 150,
      },
      {
        title: "Action",
        dataIndex: "editTableRow",
        width: 80,
        render: RenderEditButton,
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        render: RenderDeleteButton,
        width: 80,
      },
    ],
    DISTRIBUTE_CREDIT_HISTORY: [
      {
        title: "Coupon Code",
        dataIndex: "couponCode",
        key: "couponCode",
        width: 150,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: 120,
        filters: [
          {
            text: "Active",
            value: "2",
          },
          {
            text: "Reject",
            value: "3",
          },
          {
            text: "In Review",
            value: "1",
          },
          {
            text: "Pending",
            value: "0",
          },
        ],
        filterMode: "tree",
        filterSearch: true,
      },
      {
        title: "Amount",
        dataIndex: "amount",
        className: "!text-center",
        key: "amount",
        width: 90,
      },
      {
        title: "User Email",
        dataIndex: "userEmail",
        className: "!text-center",
        key: "userEmail",
        width: 80,
        sorter: true,
      },
      {
        title: "Created at",
        dataIndex: "createdAt",
        className: "!text-center",
        key: "createdAt",
        width: 90,
        sorter: true,
      },
      {
        title: "Date of Activate",
        dataIndex: "activeDate",
        className: "!text-center",
        key: "activeDate",
        width: 90,
        sorter: true,
      },
      {
        title: "Action",
        dataIndex: "editTableRow",
        width: 80,
        render: RenderEditButton,
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        render: RenderDeleteButton,
        width: 80,
      },
      {
        title: "Notify User",
        dataIndex: "notifyUser",
        // render: (value) => <Button>Notify</Button>,
        width: 80,
      },
    ]
  },
  FORM_FIELD: {
    // Project
    PROJECT_MODAL: [
      {
        no: 1,
        Label: "Project Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Project Name",
        required: true,
      },
      {
        no: 1,
        Label: "Project Description",
        name: "description",
        id: "description",
        type: "text",
        placeholder: "Enter Project Description",
        required: false,
      },
    ],
    DISTRIBUTE_CREDIT_MODAL: [
      {
        no: 1,
        Label: "User Email ID",
        name: "email",
        id: "email",
        type: "email",
        placeholder: "Enter User Email Id",
        required: true,
      },
      {
        no: 1,
        Label: "Amount",
        name: "credits",
        id: "credits",
        type: "number",
        placeholder: "Enter Amount",
        required: true,
      },
      {
        no: 1,
        Label: "Notify User",
        name: "isNotifyUser",
        id: "isNotifyUser",
        type: "checkbox",
        placeholder: "Enter isNotifyUser",
        required: false,
      },
    ],
    DISTRIBUTE_CREDIT_EDIT_MODAL: [
      {
        no: 0,
        Label: "Amount",
        name: "credits",
        id: "credits",
        type: "number",
        placeholder: "Enter Amount",
        required: true,
      },
      {
        no: 1,
        Label: "Notify User",
        name: "isNotifyUser",
        id: "isNotifyUser",
        type: "checkbox",
        placeholder: "Enter isNotifyUser",
        required: false,
      },
    ],
    PROJECT_INFORMATION_MODAL: [
      {
        no: 1,
        Label: "Project Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Project Name",
        required: true,
      },
      {
        no: 1,
        Label: "Description",
        name: "description",
        id: "description",
        type: "text",
        placeholder: "Enter Project Description",
        required: false,
      },
    ],
    DELETE_DISTRIBUTER_CREDIT_MODAL: [
      {
        no: 1,
        Label: "Reason",
        name: "reason",
        id: "reason",
        type: "text",
        placeholder: "Enter Reason",
        required: true,
      },
    ],
    PROFILE_MODAL: [
      {
        no: 1,
        Label: "Organisation Name",
        name: "organizationName",
        id: "organizationName",
        type: "text",
        placeholder: "Enter Organisation Name",
        required: true,
      },
      {
        no: 2,
        Label: "Organization Domain",
        name: "subDomain",
        id: "subDomain",
        type: "text",
        placeholder: "Enter Domain",
        required: false,
        suffix: ".wooffer.io",
        rule: { validator: validateDomain }
      },
      // {
      //   no: 4,
      //   Label: "Domain",
      //   name: "subDomain",
      //   id: "subDomain",
      //   type: "text",
      //   placeholder: "Enter Domain",
      //   required: true,
      // },
      {
        no: 3,
        Label: "Organization Favicon",
        name: "organizationFavIcon",
        id: "organizationFavIcon",
        type: "image-crop-upload",
        imageId: "organizationFavIcon-pre",
        placeholder: "Enter Favicon",
        required: false,
        aspectRatio: "1",
        dimentionX: 50,
        width: 12,
        dimentionY: 50,
      },
      // email add
      {
        no: 4,
        Label: "Organization Logo",
        name: "organizationImage",
        id: "organizationImage",
        imageId: "organizationImage-pre",
        type: "image-crop-upload",
        placeholder: "Enter Logo",
        required: false,
        aspectRatio: "2.3",
        dimentionX: 100,
        width: 12,
        dimentionY: 55,
      },
      {
        no: 5,
        Label: "Theme color",
        name: "color",
        id: "color",
        type: "colorPicker",
        placeholder: "Enter Theme color",
        required: false,
      },
      // {
      //   item: [
      //     {
      //       no: 3,
      //       Label: "Organization Favicon",
      //       name: "organizationFavIcon",
      //       id: "organizationFavIcon",
      //       type: "image-crop-upload",
      //       placeholder: "Enter Favicon",
      //       required: false,
      //       aspectRatio: "1",
      //       dimentionX: "30",
      //       dimentionY: "30",
      //     },
      //     {
      //       no: 4,
      //       Label: "Organization Logo",
      //       name: "organizationImage",
      //       id: "organizationImage",
      //       type: "image-crop-upload",
      //       placeholder: "Enter Logo",
      //       required: false,
      //       aspectRatio: "1.89",
      //       dimentionX: "100",
      //       dimentionY: "55",
      //     },
      //   ],
      // },
    ],
    CONTECT_MODAL: [
      {
        no: 1,
        Label: "User Name",
        name: "username",
        id: "username",
        type: "text",
        placeholder: "Enter User Name",
        required: true,
      },
      {
        no: 2,
        Label: "Contect No.",
        name: "contactNo",
        id: "contactNo",
        type: "mobile",
        placeholder: "Enter Contect No.",
        required: false,
      },
    ],
    FEACTURE_REQUEST_MODAL: [
      {
        no: 1,
        Label: "Title",
        name: "featureName",
        id: "featureName",
        type: "text",
        placeholder: "Enter Title",
        required: true,
      },
      {
        no: 2,
        Label: "Description",
        name: "featureDescription",
        id: "featureDescription",
        type: "textarea",
        placeholder: "Enter Description",
        required: false,
      },
    ],
    REDEEM_COUPON_MODAL: [
      {
        no: 1,
        Label: "Organization Name",
        name: "organizationName",
        id: "organizationName",
        type: "text",
        placeholder: "Enter Organization Name",
        required: true,
      },
      {
        no: 2,
        Label: "Organization Type",
        name: "organizationType",
        id: "organizationType",
        type: "select",
        placeholder: "Enter Organization Type",
        option: [{ label: "Sole Proprietorship", value: "Sole Proprietorship" },
        { label: "One-Man or person Company", value: "One-Man or person Company" },
        { label: "Partnerships organizations", value: "Partnerships organizations" },
        { label: "A Limited Liability Partnership company", value: "A Limited Liability Partnership company" },
        { label: "A private limited entity", value: "A private limited entity" },
        { label: "Limited Liability organization", value: "Limited Liability organization" },
        { label: "Section 8: Business (like NGOs)", value: "Section 8: Business (like NGOs)" }],
        required: true,
      },
      {
        no: 3,
        Label: "Organization Domain",
        name: "organizationDomain",
        id: "organizationDomain",
        type: "select",
        placeholder: "Enter Organization Domain",
        option: [
          { label: "Technology", value: "Technology" },
          { label: "Healthcare", value: "Healthcare" },
          { label: "Education", value: "Education" },
          { label: "Finance", value: "Finance" },
          { label: "Lifestyle", value: "Lifestyle" },
          { label: "Shopping", value: "Shopping" },
          { label: "E-commerce", value: "E-commerce" },
          { label: "Sports Tech", value: "Sports Tech" },
          { label: "Logistics", value: "Logistics" },
          { label: "Renewable Energy", value: "Renewable Energy" },
          { label: "Consultancy", value: "Consultancy" },
          { label: "Mining", value: "Mining" },
          { label: "Social Networking", value: "Social Networking" },
          { label: "Manufacturing", value: "Manufacturing" },
          { label: "Gamification", value: "Gamification" },
          { label: "Media & Entertainment", value: "Media & Entertainment" },
          { label: "News Media", value: "News Media" },
          { label: "Hospitality & Travel", value: "Hospitality & Travel" },
          { label: "Non-Profit", value: "Non-Profit" },
          { label: "Government", value: "Government" },
          { label: "Law Firm", value: "Law Firm" }
        ],
        required: true,
      },
      {
        no: 4,
        Label: "Website Link",
        name: "webLink",
        id: "webLink",
        type: "text",
        placeholder: "Enter Website Link",
        required: false,
      },
      {
        no: 5,
        Label: "Redeem Code",
        name: "creditId",
        id: "creditId",
        type: "text",
        placeholder: "Enter Redeem Code",
        required: true,
      },
      {
        no: 6,
        Label: "Total Employee",
        name: "totalEmployee",
        id: "totalEmployee",
        type: "number",
        min: 1,
        rule: {
          min: 1,
          message: "Total Employee should be greater than 0",
        },
        placeholder: "Enter Total Employee",
        required: true,

      },
      {
        no: 7,
        Label: "Purpose of credit usage",
        name: "purposeOfCreditUsage",
        id: "purposeOfCreditUsage",
        type: "textarea",
        placeholder: "Enter Purpose of credit usage",
        required: true,
      },
      {
        no: 8,
        Label: "More about your organization",
        name: "moreAboutOrg",
        id: "moreAboutOrg",
        type: "textarea",
        placeholder: "Enter More about your organization",
        required: false,
      },
    ],
    PROJECT_TRELLO_INTEGRATION_MODAL: [
      {
        no: 1,
        Label: "Trello BoardId",
        name: "trelloBoardID",
        id: "trelloBoardID",
        type: "text",
        placeholder: "Enter Trello BoardId",
        required: true,
      },
      {
        no: 3,
        Label: "Trello API key",
        name: "trelloAPIKey",
        id: "trelloAPIKey",
        type: "text",
        placeholder: "Enter Trello API key",
        required: true,
      },
      {
        no: 4,
        Label: "Trello API Token",
        name: "trelloAPIToken",
        id: "trelloAPIToken",
        type: "text",
        placeholder: "Enter Trello API Token",
        required: true,
      },
    ],
    PROJECT_INTEGRATION_MODAL: [
      {
        no: 1,
        Label: "Jira Email",
        name: "jiraEmail",
        id: "jiraEmail",
        type: "text",
        placeholder: "Enter Jira Email",
        required: true,
        // extraBelow: (
        //   <Text
        //     type="secondary"
        //     className=" flex justify-center items-center w-full"
        //   >
        //     Don't know about Jira Integration ?{" "}
        //     <a
        //       href="https://www.youtube.com/watch?v=sxtC40gUS2A"
        //       target="_blank"
        //       rel="noreferrer"
        //     >
        //       Click Here
        //     </a>
        //   </Text>
        // ),
      },
      {
        no: 1,
        Label: "API Token",
        name: "jiraAPIToken",
        id: "jiraAPIToken",
        type: "text",
        placeholder: "Enter Jira API Token",
        required: true,
      },
      {
        no: 3,
        Label: "Jira Domain",
        name: "jiraDomain",
        id: "jiraDomain",
        type: "text",
        placeholder: "Enter Jira Domain",
        required: true,
      },
      {
        no: 4,
        Label: "Jira ProjectId",
        name: "jiraProjectId",
        id: "jiraProjectId",
        type: "text",
        placeholder: "Enter Jira ProjectId",
        required: true,
      },
    ],
    // Service
    SERVICE_MODAL: [
      {
        no: 1,
        Label: "Service Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Service Name",
        required: true,
      },
    ],
    ALLOWAPI_MODAL: [
      {
        no: 1,
        Label: "Endpoint pattern",
        name: "endpointPattern",
        id: "endpointPattern",
        type: "text",
        placeholder: "Enter Endpoint Pattern",
        required: true,
      },
    ],
    // Service Environment
    SERVICE_ENVIRONMENT_MODAL: [
      {
        no: 1,
        Label: "Environment Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Environment Name",
        required: true,
        rule: {
          validator: (_, value) => {
            if (!value.toLowerCase().includes("production")) {
              return Promise.resolve();
            }
            return Promise.reject(
              "Production is the default environment you can not Set “Production“ to a new environment. please choose another name"
            );
          },
        },
      },
    ],
    // Edit Service Modal
    EDIT_SERVICE_MODAL: [
      {
        no: 1,
        Label: "Service Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Service Name",
        required: true,
      },
      {
        no: 2,
        Label: "Slack Webhook",
        name: "slack",
        id: "slack",
        type: "regxtext",
        placeholder: "Enter Slack Channel Webhook",
        pattern:
          /^https:\/\/hooks\.slack\.com\/services\/[A-Z0-9]+\/[A-Z0-9]+\/[A-Za-z0-9]+$/,
        required: true,
      },
    ],

    EDIT_SERVICE_SETTING_MODAL: [
      {
        no: 1,
        Label: "Service Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Service Name",
        required: false,
      },
      {
        no: 2,
        Label: "Slack Webhook",
        name: "slack",
        id: "slack",
        type: "regxtext",
        placeholder: "Enter Slack Channel Webhook",
        pattern:
          /^https:\/\/hooks\.slack\.com\/services\/[A-Z0-9]+\/[A-Z0-9]+\/[A-Za-z0-9]+$/,
        required: false,
      },
    ],

    // Environment Modal
    EDIT_ENVIRONMENT_MODAL: [
      {
        no: 1,
        Label: "Environment Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Environment Name",
        required: false,
      },
    ],

    LOGIN_PAGE_MODAL: [
      {
        no: 1,
        // Label: "User Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "User name",
        required: true,
        width: 24,
      },
      {
        no: 1,
        // Label: "Password",
        name: "password",
        width: 24,
        id: "password",
        type: "password",
        placeholder: "Password",
        required: true,
      },
    ],
  },

  API: {
    environment: {
      getOne: {
        type: "GET",
        endpoint: "/serviceEnvironment/:dataId",
      },
      get: {
        type: "GET",
        endpoint: "/serviceEnvironment",
      },
      add: {
        type: "POST",
        endpoint: "/serviceEnvironment",
      },
      update: {
        type: "PATCH",
        endpoint: "/serviceEnvironment/:dataId",
      },
      delete: {
        type: "DELETE",
        endpoint: "/serviceEnvironment/:dataId",
      },
    },
    project: {
      getOne: {
        type: "GET",
        endpoint: "/project/:dataId",
      },
      get: {
        type: "GET",
        endpoint: "/project",
      },
      add: {
        type: "POST",
        endpoint: "/project",
      },
      transferOwnership: {
        type: "POST",
        endpoint: "/transferOwnership",
      },
      update: {
        type: "PATCH",
        endpoint: "/project/:dataId",
      },
      delete: {
        type: "DELETE",
        endpoint: "/project/:dataId",
      },
    },
    plan: {
      getMy: {
        type: "GET",
        endpoint: "/userPlan",
      },
      update: {
        type: "POST",
        endpoint: "/planUpdateRequest",
      },
    },
    language: {
      get: {
        type: "GET",
        endpoint: "/language",
      },
    },
    service: {
      getOne: {
        type: "GET",
        endpoint: "/service/:dataId",
      },
      get: {
        type: "GET",
        endpoint: "/service",
      },
      add: {
        type: "POST",
        endpoint: "/service",
      },
      update: {
        type: "PATCH",
        endpoint: "/service/:dataId",
      },
      delete: {
        type: "DELETE",
        endpoint: "/service/:dataId",
      },
    },
    serviceLog: {
      get: {
        type: "GET",
        endpoint: "/log",
      },
      getStartStopLog: {
        type: "GET",
        endpoint: "/downTime",
      },
      getOverview: {
        type: "GET",
        endpoint: "/log/overview",
      },
      getDownTime: {
        type: "GET",
        endpoint: "/downTime/overview",
      },
    },
    utilization: {
      requestChart: {
        type: "GET",
        endpoint: "/apiCallCountSummary/chart?serviceEnvironmentId=:serviceID",
      },
      requestDetails: {
        type: "GET",
        endpoint:
          "/apiCallCountSummary/overview?serviceEnvironmentId=:serviceID",
      },
      get: {
        type: "GET",
        endpoint:
          "/cpuLoad/chart?formate=:dataId&serviceEnvironmentId=:serviceID",
      },
      cpu: {
        type: "GET",
        endpoint:
          "/cpuLoad/cpuUtilizationHistory?formate=:dataId&serviceEnvironmentId=:serviceID",
      },
      process_cpu: {
        type: "GET",
        endpoint:
          "/cpuLoad/processCpuUtilizationHistory?formate=:dataId&serviceEnvironmentId=:serviceID",
      },
      process_memory: {
        type: "GET",
        endpoint:
          "/cpuLoad/processMemoryUtilizationHistory?formate=:dataId&serviceEnvironmentId=:serviceID",
      },
      memory: {
        type: "GET",
        endpoint:
          "/cpuLoad/memoryUtilizationHistory?formate=:dataId&serviceEnvironmentId=:serviceID",
      },
    },
    overview: {
      getApiCountOverView: {
        type: "GET",
        endpoint:
          "/apiCallCountSummary/overview?serviceEnvironmentId=:serviceID",
      },
      updateApiVisibility: {
        type: "PATCH",
        endpoint: "/blockAPI/changeVisibility",
      },
      updateBulkApiVisibility: {
        type: "PATCH",
        endpoint: "/blockAPI/bulkChangeVisibility",
      },
      updateAllBulkApiVisibility: {
        type: "PATCH",
        endpoint: "/blockAPI/updateAllVisibility",
      },
    },
    integration: {
      addPlugin: {
        type: "POST",
        endpoint: "/project/test-jira-integration",
      },
      getTrelloDetail: {
        type: "GET",
        endpoint: "/project/get-trello-list",
      },
      addTrelloPlugin: {
        type: "POST",
        endpoint: "/project/test-trello-integration",
      },
      updatePlugin: {
        type: "PATCH",
        endpoint: "/project/:dataId",
      },
    },
    featureRquest: {
      addFeature: {
        type: "POST",
        endpoint: "/featureRequest",
      },
      getFeature: {
        type: "GET",
        endpoint: "/featureRequest",
      },
      deleteFeature: {
        type: "DELETE",
        endpoint: "/featureRequest/:dataId",
      },
      updateFeature: {
        type: "PATCH",
        endpoint: "/featureRequest/:dataId",
      },
    },
    feedback: {
      updateFeedback: {
        type: "PATCH",
        endpoint: "/feedback",
      },
      getFeedback: {
        type: "GET",
        endpoint: "/feedback",
      },
    },
    maintanance: {
      get: {
        type: "GET",
        endpoint: "/maintenance",
      },
      getLatest: {
        type: "GET",
        endpoint: "/maintenance/latest",
      }
    },
    setting: {
      getMaintenance: {
        type: "GET",
        endpoint: "/settings/inMaintenance",
      },
    },
    credit: {
      getOne: {
        type: "GET",
        endpoint: "/credits/:dataId",
      },
      get: {
        type: "GET",
        endpoint: "/credits",
      },
      getDistributeCredit: {
        type: "GET",
        endpoint: "/distributer/credits",
      }
    },
    distributerPanelCredit: {
      getOne: {
        type: "GET",
        endpoint: "/distributer/credits/:dataId",
      },
      get: {
        type: "GET",
        endpoint: "/distributer/credits",
      },
      add: {
        type: "POST",
        endpoint: "/distributer/credits",
      },
      update: {
        type: "PATCH",
        endpoint: "/distributer/credits/",
      },
      updateNotify: {
        type: "PATCH",
        endpoint: "/distributer/credits/:dataId",
      },
      delete: {
        type: "DELETE",
        endpoint: "/distributer/credits/",
      },
      addNotifyUser: {
        type: "POST",
        endpoint: "/distributer/credits/notifyUser",
      }
    },
    creditrequest: {
      getOne: {
        type: "GET",
        endpoint: "/creditRequest/:dataId",
      },
      get: {
        type: "GET",
        endpoint: "/creditRequest",
      },
      add: {
        type: "POST",
        endpoint: "/creditRequest",
      },
      update: {
        type: "PATCH",
        endpoint: "/creditRequest/:dataId",
      },
      delete: {
        type: "DELETE",
        endpoint: "/creditRequest/:dataId",
      },
    },
    distributerCredit: {
      getOne: {
        type: "GET",
        endpoint: "/bill/:dataId",
      },
      get: {
        type: "GET",
        endpoint: "/bill",
      },
      add: {
        type: "POST",
        endpoint: "/bill",
      },
      update: {
        type: "PATCH",
        endpoint: "/bill/:dataId",
      },
      delete: {
        type: "DELETE",
        endpoint: "/bill/:dataId",
      },
      getAnalytic: {
        type: "GET",
        endpoint: "/distributer/credits/analysis"
      }
    },
    logAnatic: {
      get: {
        type: "GET",
        endpoint: "/dailyUsage/analysis",
      }
    },
    billing: {
      getOne: {
        type: "GET",
        endpoint: "/bill/:dataId",
      },
      get: {
        type: "GET",
        endpoint: "/bill",
      },
      add: {
        type: "POST",
        endpoint: "/bill",
      },
      update: {
        type: "PATCH",
        endpoint: "/bill/:dataId",
      },
      delete: {
        type: "DELETE",
        endpoint: "/bill/:dataId",
      },
      getCurrentPrice: {
        type: "GET",
        endpoint: "/current-pricing",
      },
      getbillAnalytic: {
        type: "GET",
        endpoint: "/bill/analysis",
      },
      getGatewayConfig: {
        type: "GET",
        endpoint: "/config",
      }
    },
    payment: {
      addMoney: {
        type: "POST",
        endpoint: "/payment/addMoney",
      },
      verifyPayment: {
        type: "POST",
        endpoint: "/payment/verifyPayment",
      },
      getPaymentSatus: {
        type: "GET",
        endpoint: "/payment/getPaymentStatus?order_id=:dataId",
      },
      addStripePayment: {
        type: "POST",
        endpoint: "/payment/stripe/createCheckOutSession",
      },
    },
    allowEndpoint: {
      get: {
        type: "GET",
        endpoint: "/allowedAPIs",
      },
      add: {
        type: "POST",
        endpoint: "/allowedAPIs",
      },
      update: {
        type: "PATCH",
        endpoint: "/allowedAPIs/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/allowedAPIs/",
      },
    },
    invoice: {
      getInvoice: {
        type: "GET",
        endpoint: "/invoice/:dataId",
      }
    },
    auth: {
      updateMe: {
        type: "PATCH",
        endpoint: "/me",
      },
      verify: {
        type: "POST",
        endpoint: "/verify",
      },
      login: {
        type: "POST",
        endpoint: "/google",
      },
      loginWithEmail: {
        type: "POST",
        endpoint: "/login",
      },
      getMe: {
        type: "GET",
        endpoint: "/me",
      },
      deleteorganizationImage: {
        type: "DELETE",
        endpoint: "/removeImage/organizationImage",
      },
      deleteorganizationFavIcon: {
        type: "DELETE",
        endpoint: "/removeImage/organizationFavIcon",
      },
    },
  },

  TAB_MENU: {
    USER: [
      {
        no: 1,
        Label: "About Info",
        name: "about",
        id: "about",
      },
      {
        no: 2,
        Label: "Previous Bookings",
        name: "booking",
        id: "booking",
      },
      {
        no: 3,
        Label: "Car valet",
        name: "valet",
        id: "valet",
      },
      {
        no: 4,
        Label: "Chat History",
        name: "chat",
        id: "chat",
      },
      {
        no: 5,
        Label: "User Post",
        name: "post",
        id: "post",
      },
    ],
  },

  UTILIZATION_NAME: {
    maxMemoryPhysical: "Physical Memory Used (GB)",
    maxMemorySwap: "Swap Memory Used (GB)",
    maxUserCPU: "User CPU Usage (%)",
    maxSystemCPU: "System CPU Usage (%)",
    totalMemory: "Total Memory Available (GB)",
  },
  UTILIZATION_COLOR: {
    "Physical Memory Used (GB)": "#5AD8A6",
    "Swap Memory Used (GB)": "#5D7092",
    "User CPU Usage (%)": "#5AD8A6",

    "System CPU Usage (%)": "#5D7092",
    "Total Memory Available (GB)": "#FF4016",
    "Threshold (%)": "#FF9416",
    "Dangerous Level (%)": "#FF4016",
    "Fail Count": "#FF4016",
    "Success Count": "#5AD8A6",
    "Fail Rate (%)": "#FF4016",
    "Success Rate (%)": "#5AD8A6",
    "Internal API Call": "#5AD8A6",
    "ThirdParty API Call": "#5D7092",

    "CPU History": "#5D7092",
    "Server Activity Log": "#FF9416",
    "Crash Log": "#5AD8A6",
    "Custom Log": "#5D7092",
    "API Log": "#FF4016",
    "Success Rate": "#5AD8A6",
    "Fail Rate": "#FF4016",

  },
  labelObject: {
    apiBackupCountSum: {
      name: "API Count",
      id: "apiBackupCountSum",
      color: "#E8684A",
    },
    cpuHistoryLogCountSum: {
      name: "CPU History",
      id: "cpuHistoryLogCountSum",
      color: "#FF9416",
    },
    customLogCountSum: {
      name: "Custom Log",
      id: "customLogCountSum",
      color: "#5AD8A6",
    },
    errorLogCountSum: {
      name: "Crash Log",
      id: "errorLogCountSum",
      color: "#5D7092",
    },
    serverActivityCountSum: {
      name: "Server Activity Log",
      id: "serverActivityCountSum",
      color: "#FF4016",
    },
  },
  PROCESS: {
    id: null,
  },
  Request: { list: [] },

  CPU_ANNOTATION: [
    {
      type: "regionFilter",
      start: ["min", CPU_THRESHOLD_LEVEL],
      end: ["max", CPU_DANGEROUS_LEVEL],
      color: "#FF9416",
    },
    {
      type: "text",
      position: ["min", CPU_THRESHOLD_LEVEL],
      content: `${CPU_THRESHOLD_LEVEL}% (Threshold)`,
      offsetY: -4,
      style: {
        textBaseline: "bottom",
      },
    },

    {
      type: "line",
      start: ["min", CPU_THRESHOLD_LEVEL],
      end: ["max", CPU_THRESHOLD_LEVEL],
      style: {
        stroke: "#FF9416",
        lineDash: [2, 2],
      },
    },
    {
      type: "regionFilter",
      start: ["min", CPU_DANGEROUS_LEVEL],
      end: ["max", "100"],
      color: "#FF4016",
    },
    {
      type: "text",
      position: ["min", CPU_DANGEROUS_LEVEL],
      content: `${CPU_DANGEROUS_LEVEL}% (Dangerous Level)`,
      offsetY: -4,
      style: {
        textBaseline: "bottom",
      },
    },
    {
      type: "line",
      start: ["min", CPU_DANGEROUS_LEVEL],
      end: ["max", CPU_DANGEROUS_LEVEL],
      style: {
        stroke: "#FF4016",
        lineDash: [2, 2],
      },
    },
  ],
};
export default CONSTANTS;
