/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import "./style.css";
import moment from 'moment';
import logoMain from "../../asset/logos/logo.png";
import rupee from "../../asset/image/ruppee.png";
import { convertLocalStringWithoutRupee } from '../../util/functions';

const Invoice = ({ downloadData }) => {
    return (
        <div className="invoice bilty" style={{ padding: "35px", width: "1240px", height: "1754px" }}>
            <div className="invoice-header">
                <img src={logoMain} alt="Wooffer Logo" width={380} height={95} className="invoice-logo" />
                <div className="invoice-address">
                    <p style={{ marginBottom: "0", lineHeight: "1.2" }}>A-1206, Corporate Titanium Heights, Corporate Rd, opp. Vodafone House, Prahlad Nagar, Ahmedabad, Gujarat 380015</p>
                    <p>
                        <strong>Invoice Number:</strong>
                        {' '}
                        {downloadData?.id?.toString().padStart(10, "0")}
                    </p>
                    {/* <p>
                        <strong>Date:</strong>
                        {' '}
                        03/04/2024
                    </p> */}
                </div>
            </div>
            <div className="bilty-sub-header">
                <div className="bilty-title">Invoice</div>
                <div className="bilty-sub-line" />
            </div>
            <div className="invoice-info">
                <div className="info-block">
                    <p className="mb-3"><span>Invoice From</span></p>
                    <p><strong>Company</strong></p>
                </div>
                <div className="info-block">
                    <p className="mb-3"><span>Legal Name</span></p>
                    <p><strong>TST Tech Matrix Pvt Ltd</strong></p>
                </div>
                <div className="info-block">
                    <p className="mb-3"><span>Service</span></p>
                    <p><strong>Server Monitoring</strong></p>
                </div>
                <div className="info-block">
                    <p className="mb-3"><span>Date Of Invoice</span></p>
                    <p>
                        <strong>
                            {downloadData?.createdAt ? moment(downloadData?.createdAt)?.format("DD MMM, YYYY") : ""}
                        </strong>
                    </p>
                </div>
            </div>
            <div className="invoice-to">
                <p>
                    <strong>Invoice to:</strong>
                    {' '}
                    {downloadData?.userName ?? ""}
                </p>
                <p>
                    <strong>Email:</strong>
                    {' '}
                    {downloadData?.email ?? ""}
                </p>
                {/* <p>
                    <strong>Contact:</strong>
                    {' '}
                    1234567890
                </p>
                <p>
                    <strong>Address:</strong>
                    {' '}
                    317 Henery street, Wichita, Texas, 618-525-8095
                </p> */}
            </div>
            <div className="table-container">
                <table className="styled-table">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Name</th>
                            <th>Per Log Price</th>
                            <th>Logs per Unit Time</th>
                            <th>Logs Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>CPU History</td>
                            <td>
                                {' '}
                                <img src={rupee} alt="₹" width={22} height={15} />
                                {' '}
                                {downloadData?.userPriceConfig?.cpuHistoryConst}
                            </td>
                            <td>
                                {downloadData?.cpuHistoryCount}
                                {' '}
                                Logs
                            </td>
                            <td>
                                {' '}
                                <img src={rupee} alt="₹" width={22} height={15} />
                                {' '}
                                {convertLocalStringWithoutRupee(`${downloadData?.cpuHistoryCount * downloadData?.userPriceConfig?.cpuHistoryConst}`)}
                            </td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Server Activity Log</td>
                            <td>
                                {' '}
                                <img src={rupee} alt="₹" width={22} height={15} />
                                {' '}
                                {downloadData?.userPriceConfig?.serverActivityConst}
                            </td>
                            <td>
                                {downloadData?.serverActivitiesCount}
                                {' '}
                                Logs
                            </td>
                            <td>
                                {' '}
                                <img src={rupee} alt="₹" width={22} height={15} />
                                {' '}
                                {convertLocalStringWithoutRupee(`${downloadData?.serverActivitiesCount * downloadData?.userPriceConfig?.serverActivityConst}`)}
                            </td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>Crash Log</td>
                            <td>
                                {' '}
                                <img src={rupee} alt="₹" width={22} height={15} />
                                {' '}
                                {downloadData?.userPriceConfig?.apiBackupConst}
                            </td>
                            <td>
                                {downloadData?.errorLogCount}
                                {' '}
                                Logs
                            </td>
                            <td>
                                {' '}
                                <img src={rupee} alt="₹" width={22} height={15} />
                                {' '}
                                {convertLocalStringWithoutRupee(`${downloadData?.errorLogCount * downloadData?.userPriceConfig?.apiBackupConst}`)}
                            </td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>Custom Log</td>
                            <td>
                                {' '}
                                <img src={rupee} alt="₹" width={22} height={15} />
                                {' '}
                                {downloadData?.userPriceConfig?.customLogConst}
                            </td>
                            <td>
                                {downloadData?.customLogCount}
                                {' '}
                                Logs
                            </td>
                            <td>
                                {' '}
                                <img src={rupee} alt="₹" width={22} height={15} />
                                {' '}
                                {convertLocalStringWithoutRupee(`${downloadData?.customLogCount * downloadData?.userPriceConfig?.customLogConst}`)}
                            </td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>API Log</td>
                            <td>
                                <img src={rupee} alt="₹" width={22} height={15} />
                                {' '}
                                {downloadData?.userPriceConfig?.apiBackupConst}
                            </td>
                            <td>
                                {downloadData?.apiBackupCount}
                                {' '}
                                Logs
                            </td>
                            <td>
                                {' '}
                                <img src={rupee} alt="₹" width={22} height={15} />
                                {' '}
                                {convertLocalStringWithoutRupee(`${downloadData?.apiBackupCount * downloadData?.userPriceConfig?.apiBackupConst}`)}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={{ display: "flex", justifyContent: "end" }}>
                <div />
                <div className="invoice-summary">
                    <div className="list">
                        <span>Sub Total</span>
                        <p>
                            <strong>
                                {' '}
                                <img src={rupee} alt="₹" width={22} height={15} />
                                {' '}
                                {convertLocalStringWithoutRupee(+downloadData?.subTotal?.toFixed(2))}
                            </strong>
                        </p>

                    </div>
                    <div className="list">
                        <span>Service Charge</span>
                        <p>
                            <strong>
                                {' '}
                                <img src={rupee} alt="₹" width={22} height={15} />
                                {' '}
                                {convertLocalStringWithoutRupee(downloadData?.otherCharges?.toFixed(2))}
                            </strong>
                        </p>

                    </div>
                    <div className="list">
                        <span>Credit</span>
                        <p>
                            <strong>
                                {' '}
                                -
                                {' '}
                                <img src={rupee} alt="₹" width={22} height={15} />
                                {' '}
                                {convertLocalStringWithoutRupee(+downloadData?.subtractionFromCredits?.toFixed(2))}
                            </strong>
                        </p>
                    </div>
                    <div className="verticle-line" />
                    <div className="list">
                        <span>Grand Total</span>
                        <p>
                            <strong>
                                {' '}
                                <img src={rupee} alt="₹" width={22} height={15} />
                                {' '}
                                {convertLocalStringWithoutRupee(downloadData?.TotalAmount)}
                            </strong>
                        </p>

                    </div>
                    <div className="list">
                        <span>GST</span>
                        <p>
                            <strong>
                                {' '}
                                <img src={rupee} alt="₹" width={22} height={15} />
                                {' '}
                                {convertLocalStringWithoutRupee(downloadData?.gstAmount?.toFixed(2))}
                            </strong>
                        </p>

                    </div>
                    <div className="verticle-line" />
                    <div className="list">
                        <span>Payable Amount</span>
                        <p className="primary-color font-size-28">
                            <strong>
                                <img src={rupee} alt="₹" width={22} height={15} />
                                {' '}
                                {convertLocalStringWithoutRupee(+(+downloadData?.TotalAmount + +downloadData?.gstAmount)?.toFixed(2))}
                            </strong>
                        </p>

                    </div>

                </div>
            </div>
            <footer>
                <p className="primary-color" style={{ marginBottom: "20px" }}>This is a system generated invoice - No physical signature or stamp needed.</p>
                <p>
                    By paying the convenience fees, you agree to and intend to be bound by our terms and conditions. To access our terms and conditions, please visit
                    {" "}
                    {' '}
                    <a href="https://wooffer.io/">https://wooffer.io/</a>
                    .
                </p>
            </footer>
        </div>
    );
};

export default Invoice;
