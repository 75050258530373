/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable comma-dangle */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from "react";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Image,
  Layout,
  Menu,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import Sider from "antd/es/layout/Sider";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { VscGitPullRequestCreate } from "react-icons/vsc";
// import { Helmet } from "react-helmet";
import { IoAnalyticsOutline } from "react-icons/io5";
import { BiReceipt } from "react-icons/bi";
import CONSTANTS, {
  appRoot,
  loginRoot,
  projectRoot,
  serviceRoot,
} from "../../util/constant/CONSTANTS";
import { data as items } from "../../util/constant/menu";
import { getAuthToken } from "../../util/API/authStorage";
import useCheckInternet from "../../hooks/use-checkInternet";
import useHttp from "../../hooks/use-http";
import { apiGenerator, getServicePath } from "../../util/functions";
import RightDrawer from "./Component/right-drawer";
import logo from "../../asset/logos/icon.svg";
import shortLogo from "../../asset/logos/logo.svg";
// import Profile from "../../asset/image/dummy-avatar.jpg";
import AutoBreadcrumb from "../Breadcrumb";
import EnvironmentDropdown from "../../page/app/Service/component/environment-dropdown";
import TooltipCustom from "../common/Tooltip";

const { Text } = Typography;
const { Header, Content } = Layout;

const ServiceLayout = (props) => {
  useCheckInternet("/noInternet");
  const navigate = useNavigate();

  const { projectID, serviceID, environmentID } = useParams();
  const [visible, setVisible] = useState(false);
  const [services, setServices] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [environment, setEnvironment] = useState([]);

  const breadcrumbItems = [];
  const API = useHttp();
  const location = useLocation();
  const currentPath = location.pathname;
  const generatePath = (path) => {
    return path
      .replace(":serviceID", serviceID)
      .replace(":environmentID", environmentID)
      .replace(":projectID", projectID);
  };
  let activeMenuItemIndex = items.findIndex(
    (item) => currentPath === generatePath(item.path)
  );

  activeMenuItemIndex = `tmp-${activeMenuItemIndex}`;

  useEffect(() => {
    if (!(getAuthToken() !== undefined && getAuthToken() !== null)) {
      navigate(loginRoot);
      return;
    }

    API.sendRequest(
      apiGenerator(CONSTANTS.API.service.get, {}, `?projectId=${projectID}`),
      (res) => {
        if (res?.status === "success") {
          setServices([
            ...res?.data?.rows?.map(
              (env) => ({
                label: env?.name,
                key: env?.id,
                name: env?.name,
                project: env?.project,
                productionenv: env?.serviceEnvironments?.find(
                  (el) => el.name === "Production"
                )?.id,
              }),
              {
                label: "Create New Environment",
                key: "-1",
              }
            ),
          ]);
        }
      }
    );
  }, []);

  // Environment - dropdown
  useEffect(() => {
    if (getAuthToken() && serviceID) {
      API.sendRequest(
        apiGenerator(CONSTANTS.API.environment.getOne, {
          dataId: serviceID,
        }),
        (res) => {
          if (res?.status === "success") {
            const menuOption = res?.data?.map((env) => ({
              label: env?.name,
              key: env?.id,
              name: env?.name,
            }));
            if (props?.plan?.maxEnvironments > res?.data?.length) {
              menuOption.push({
                label: (
                  <div>
                    <VscGitPullRequestCreate />
                    {' '}
                    Create New
                  </div>
                ),
                key: "-1",
              });
            }
            setEnvironment(menuOption);
          }
        },
      );
    }
  }, [serviceID]);
  // Environment - dropdown
  useEffect(() => {
    setEnvironment((prev) => {
      if (prev?.length === 0) return [...prev];
      const newMenu = [...prev];
      const idCreatePlanAdded = newMenu.findIndex((menu) => menu?.key === "-1");
      if (idCreatePlanAdded === -1) {
        if (props?.plan?.maxEnvironments > newMenu?.length) {
          newMenu.push({
            label: (
              <div>
                <VscGitPullRequestCreate />
                {' '}
                Create New
              </div>
            ),
            key: "-1",
          });
        }
      }
      return newMenu;
    });
  }, [props?.plan]);

  const onServiceChange = (e) => {
    if (e?.key !== serviceID) {
      navigate(
        getServicePath(
          location.pathname,
          projectID,
          e?.key,
          services?.find((el) => el.key === e?.key)?.productionenv
        )
      );
    }
  };

  const onMenuChange = (e) => {
    if (e.keyPath.length) {
      navigate(generatePath(items[+e.key.replace("tmp-", "")]?.path));
    }
  };

  const menuProps = {
    items: services,
    onClick: onServiceChange,
  };

  const currantService = services?.find(
    (service) => service?.key === serviceID
  );

  if (currantService) {
    breadcrumbItems.push({
      title: (
        <Link to={`${projectRoot}/${currantService?.project?.id}`}>
          {currantService?.project?.name}
        </Link>
      ),
    });
    breadcrumbItems.push({
      title: (
        <p>
          {currantService?.name}{" "}
          <TooltipCustom title="Monitor realtime CPU and memory usage" />
        </p>
      ),
    });
    if (currentPath?.includes("/log")) {
      breadcrumbItems.push({
        title: (
          <p>
            Log <TooltipCustom title={<p>Analytics of your Server</p>} />
          </p>
        ),
      });
    } else if (currentPath?.includes("/process")) {
      breadcrumbItems.push({
        title: (
          <p>
            Process Usage{" "}
            <TooltipCustom title="View process CPU utilization information. Values exceeding 100% indicateds overloading of the system." />
          </p>
        ),
      });
    } else if (currentPath?.includes("/cpu")) {
      breadcrumbItems.push({
        title: (
          <p>
            CPU Usage
            <TooltipCustom title="View CPU utilization information. Values exceeding 100% indicateds overloading of the system." />
          </p>
        ),
      });
    } else if (currentPath?.includes("/apiallow")) {
      breadcrumbItems.push({
        title: (
          <Link to={`${projectRoot}/${serviceRoot}/${projectID}/${serviceID}/${environmentID}/api`}>
            <p>
              API Usage <TooltipCustom title="View status of your API calls." />
            </p>
          </Link>

        ),
      });
      breadcrumbItems.push({
        title: (
          <p>
            Whitelisted Pattern
          </p>
        ),
      });
    } else if (currentPath?.includes("/api")) {
      breadcrumbItems.push({
        title: (
          <p>
            API Usage <TooltipCustom title="View status of your API calls." />
          </p>
        ),
      });
    } else if (currentPath?.includes("/block")) {
      breadcrumbItems.push({
        title: (
          <p>
            Blocked API{" "}
            <TooltipCustom title="APIs blocked from the analytics." />
          </p>
        ),
      });
    } else if (currentPath?.includes("/service-flow")) {
      breadcrumbItems.push({
        title: (
          <p>
            Service Diagram{" "}
            <TooltipCustom title="Visualize API routes in real-time" />
          </p>
        ),
      });
    } else if (currentPath?.includes("/history")) {
      breadcrumbItems.push({
        title: "Reports snapshot",
      });
    } else if (currentPath?.includes("/setup")) {
      breadcrumbItems.push({
        title: (
          <p>
            Setup <TooltipCustom title="Step by step setup guidlines" />
          </p>
        ),
      });
    } else if (currentPath?.includes("/setting")) {
      breadcrumbItems.push({
        title: (
          <p>
            Setting{" "}
            <TooltipCustom title="Edit or Delete your service details" />
          </p>
        ),
      });
    } else if (currentPath?.includes("/endpoint-utilization")) {
      breadcrumbItems.push({
        title: "Endpoint Utilization",
      });
    } else {
      /* empty */
    }
  }
  return (
    <>
      {/* <Helmet>
       {props?.user_details?.organizationFavIcon ? <link rel="icon" href={props?.user_details?.organizationFavIcon} /> : <link rel="icon" href="icon.svg" />}
      </Helmet> */}
      <Layout
        hasSider
        style={{
          minHeight: "100vh",
        }}
        className="site-layout"
      >
        <Sider
          className="hidden lg:block !sticky top-0 h-[100vh]"
          collapsible
          trigger={null}
          collapsed={collapsed}
          // onCollapse={(value) => setCollapsed(value)}
          theme="light"
        >
          {!collapsed ? (
            <div className="flex justify-center mt-3">
              <Image
                style={{ height: "50px", width: "110px", cursor: "pointer" }}
                preview={false}
                src={props?.user_details?.organizationImage || logo}
                onClick={() => {
                  navigate(`${appRoot}`);
                }}
              />
            </div>
          ) : (
            <div className="flex justify-center my-5 ">
              <Image
                style={{ height: "30px", cursor: "pointer" }}
                preview={false}
                src={props?.user_details?.organizationFavIcon || shortLogo}
                onClick={() => {
                  navigate(`${appRoot}`);
                }}
              />
            </div>
          )}
          <Menu
            mode="inline"
            className="hidden lg:block"
            selectedKeys={activeMenuItemIndex}
            activeKey=""
            inlineCollapsed={collapsed}
            items={items}
            onClick={onMenuChange}
          />
          <Button
            className={` textcolor absolute top-[85px] right-0 translate-x-[50%] flex items-center justify-center ${""} `}
            type="primary"
            icon={collapsed ? <AiOutlineRight /> : <AiOutlineLeft />}
            size="small"
            shape="circle"
            onClick={() => setCollapsed(!collapsed)}
          />
        </Sider>
        <Layout>
          <Header
            className="px-0 md:px-0 h-28 md:h-16 flex flex-col justify-center z-10 sticky top-0"
            style={{
              backgroundColor: "#FFFFFF",
            }}
          >
            {/* Web View */}
            <Row
              align="middle"
              justify="space-between"
              className="hidden md:flex"
              style={{ padding: "0px 35px 0px 25px" }}
            >
              <Col span={9} lg={10} className=" flex">
                <div className="hidden md:block lg:hidden">
                  <Image
                    style={{
                      height: "50px",
                      width: "110px",
                      cursor: "pointer",
                    }}
                    preview={false}
                    src={props?.user_details?.organizationImage || logo}
                    onClick={() => {
                      navigate(`${appRoot}`);
                    }}
                  />
                </div>
                <div className="hidden  lg:flex items-center gap-2">
                  <div className="flex items-center">
                    <Text className="me-0  xl:flex">Service</Text>
                    <TooltipCustom title="Collection of various Environments." />
                  </div>
                  <Dropdown className="hidden md:flex" menu={menuProps}>
                    <Button>
                      <Space>
                        {currantService?.name || "Select Environment"}
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
                  {!currentPath?.includes("/setting") && (
                    <>
                      <Text className="me-0">/</Text>
                      <div>
                        <EnvironmentDropdown environment={environment} />
                        <TooltipCustom title="Select/Create your required environment." />
                      </div>
                    </>
                  )}
                </div>
              </Col>

              <Col
                span={15}
                lg={14}
                className="flex justify-end items-center gap-5"
              >
                <div className="hidden md:flex lg:hidden  items-center gap-4">
                  <div>
                    <Dropdown menu={menuProps}>
                      <Button>
                        <Space>
                          {currantService?.name || "Select Environment"}
                          <DownOutlined />
                        </Space>
                      </Button>
                    </Dropdown>
                    <TooltipCustom title="Collection of Various Services." />
                  </div>
                  {!currentPath?.includes("/setting") && (
                    <>
                      <Text className="-me-2 -ms-2">/</Text>
                      <div>
                        <EnvironmentDropdown environment={environment} />
                        <TooltipCustom title="Select/Create your required environment." />
                      </div>
                    </>
                  )}
                </div>
                <Tooltip
                  className="cursor-pointer"
                  title="Usage"
                >
                  <IoAnalyticsOutline className="cursor-pointer" size={28} onClick={() => navigate("/app/log-analytics")} />
                </Tooltip>
                <Tooltip
                  className="cursor-pointer"
                  title="Billing Detail"
                >

                  <BiReceipt className=" cursor-pointer" size={28} onClick={() => navigate("/app/billing")} />
                </Tooltip>
                <Avatar
                  // src={Profile}
                  className="cursor-pointer card-primary-to-secondary textcolor border-[1px] border-gray-300"
                  shape="circle"
                  size={35}
                  onClick={() => {
                    setVisible(true);
                  }}
                >
                  {props?.user_details?.username?.charAt(0)?.toUpperCase()}
                </Avatar>
                {/* <Avatar
                  src={Profile}
                  size={35}
                  style={{
                    border: "1px solid black",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setVisible(true);
                  }}
                /> */}
              </Col>
            </Row>
            {/* Mobile View */}
            <div className="md:hidden flex  mx-5 -mb-4 justify-between items-center">
              <div>
                <Image
                  style={{ height: "50px", width: "110px", cursor: "pointer" }}
                  preview={false}
                  src={props?.user_details?.organizationImage || logo}
                  onClick={() => {
                    navigate(`${appRoot}`);
                  }}
                />
              </div>
              <div className="flex items-center gap-2">
                <Tooltip
                  className="cursor-pointer"
                  title="Usage"
                >
                  <IoAnalyticsOutline className="cursor-pointer" size={28} onClick={() => navigate("/app/log-analytics")} />
                </Tooltip>
                <Tooltip
                  className="cursor-pointer"
                  title="Billing Detail"
                >

                  <BiReceipt className=" cursor-pointer" size={28} onClick={() => navigate("/app/billing")} />
                </Tooltip>
                <Avatar
                  // src={Profile}
                  className="cursor-pointer card-primary-to-secondary textcolor border-[1px] border-gray-300"
                  shape="circle"
                  size={35}
                  onClick={() => {
                    setVisible(true);
                  }}
                >
                  {props?.user_details?.username?.charAt(0)?.toUpperCase()}
                </Avatar>
                {/* <Avatar
                  src={Profile}
                  size={35}
                  style={{
                    border: "1px solid black",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setVisible(true);
                  }}
                /> */}
              </div>
            </div>
            <div className="md:hidden flex mx-5 justify-between">
              <div className="flex items-center gap-3">
                <div className="flex items-center">
                  <Dropdown menu={menuProps}>
                    <Button>
                      <Space>
                        {currantService?.name || "Select Environment"}
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
                  <TooltipCustom title="Collection of Various Services." />
                </div>
                {!currentPath?.includes("/setting") && (
                  <>
                    <Text className="-me-2 -ms-2">/</Text>
                    <div className="flex items-center">
                      <EnvironmentDropdown environment={environment} />
                      <TooltipCustom
                        className="xs:hidden"
                        title="Select/Create your required environment."
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="flex items-center gap-4">
                {/* <Tooltip
                  className="cursor-pointer"
                  title="Usage"
                >
                  <IoAnalyticsOutline className="cursor-pointer" size={28} onClick={() => navigate("/app/log-analytics")} />
                </Tooltip>
                <Tooltip
                  className="cursor-pointer"
                  title="Billing Detail"
                >

                  <BiReceipt className=" cursor-pointer" size={28} onClick={() => navigate("/app/billing")} />
                </Tooltip> */}
                {/* <Avatar
                  src={Profile}
                  size={35}
                  style={{
                    border: "1px solid black",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setVisible(true);
                  }}
                /> */}
              </div>
            </div>
          </Header>
          <Content className="">
            <Row className="mx-3 h-full md:mx-6">
              <Col
                className="mb-20 md:mb-10"
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
              >
                <Row className="mt-5 flex-col md:flex-row gap-4 md:gap-0 items-start md:justify-between md:items-center mb-3">
                  <AutoBreadcrumb items={breadcrumbItems} />
                </Row>

                <Outlet />
              </Col>
            </Row>
          </Content>
          <Menu
            className="flex justify-center fixed bottom-0 w-full z-50 lg:hidden"
            selectedKeys={activeMenuItemIndex}
            activeKey=""
            mode="horizontal"
            items={items}
            onClick={onMenuChange}
          />
        </Layout>
      </Layout>

      <RightDrawer
        username={props?.user_details?.username}
        email={props?.user_details?.email}
        visible={visible}
        setVisible={setVisible}
      />
    </>
  );
};

export default ServiceLayout;
