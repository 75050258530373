/* eslint-disable import/no-named-as-default */
/* eslint-disable no-unsafe-optional-chaining */
import {
  Card, DatePicker, Row, Select, Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import LineChart from "../../../../../component/chart/line-chart";
import CONSTANTS from "../../../../../util/constant/CONSTANTS";
import {
  apiGenerator,
  disabledDate,
  formatByteToMB,
  getSelectionOption,
  ifTimeAvailableConvertToLocalTime,
  isLogin,
} from "../../../../../util/functions";
import useHttp from "../../../../../hooks/use-http";

const { Text } = Typography;
const { RangePicker } = DatePicker;

const ProcessMemory = ({ plan = {} }) => {
  const { id, environmentID } = useParams();
  const API = useHttp();
  const [serviceDetails, setUtilizations] = useState([]);
  const [analyticsVaiables, setAnalyticsVaiables] = useState([]);
  const [selected, setSelected] = useState("Daily");
  const [dateRange, setdateRange] = useState({
    startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  useEffect(() => {
    if (!isLogin()) return;

    let extraPerms = "";
    if (dateRange?.startDate && dateRange?.endDate) {
      extraPerms += `&startDate=${dateRange?.startDate}&endDate=${dateRange?.endDate}`;
    }
    API.sendRequest(
      apiGenerator(
        CONSTANTS.API.utilization.process_memory,
        {
          dataId: selected,
          serviceID: environmentID,
        },
        extraPerms,
      ),
      (res) => {
        setUtilizations(res?.data);
        const analyticsResult = res?.data?.reduce((prev, curr) => {
          const currValue = +curr?.maxProcessMemoryUsage / (1024 * 1024);
          return [currValue + prev[0], prev[1] < currValue ? currValue : prev[1], prev[2] > currValue ? currValue : prev[2]];
        }, [0, 0, 100]);
        setAnalyticsVaiables([
          {
            id: 1,
            label: 'Avg. Memory Usage',

            value: `${analyticsResult[0] ? (analyticsResult[0] / res?.data?.length)?.toFixed(2) : 0}  MB`,
          },
          {
            id: 2,
            label: 'Highest Memory Usage',
            value: `${analyticsResult[1] ? analyticsResult[1]?.toFixed(2) : 0} MB`,
          },
          {
            id: 3,
            label: 'Lowest Memory Usage',
            value: `${analyticsResult[2] === 100 ? 0 : analyticsResult[2]?.toFixed(2)} MB`,
          },

        ]);
      },
    );
  }, [id, environmentID, selected, dateRange]);

  const handleChange = (value) => {
    setSelected(value);
  };
  const utilization = [];

  serviceDetails?.map((el) => {
    utilization.push({
      year: ifTimeAvailableConvertToLocalTime(selected, el?.formattedCreatedAt),
      value: formatByteToMB(+el?.maxProcessMemoryUsage) || 0,
      name: "Process Memory Usage (MB)",
    });
  });

  const onDateChange = (value) => {
    if (!(value?.length > 0)) {
      setdateRange(null);
      return;
    }

    setdateRange({
      startDate: value?.[0]?.format("YYYY-MM-DD"),
      endDate: value?.[1]?.format("YYYY-MM-DD"),
    });
  };

  return (
    <Card className="w-full">
      <Row className="justify-between items-center mb-5">
        <Text>Process Memory Utilization</Text>

        <div className="flex  justify-start items-center gap-3">
          <Text className="hidden  md:block">Date Range</Text>
          <RangePicker
            allowClear={false}
            disabledDate={disabledDate}
            defaultValue={[
              dayjs(dateRange?.startDate),
              dayjs(dateRange?.endDate),
            ]}
            onChange={onDateChange}
          />
          <Select
            defaultValue={selected}
            style={{ width: 120 }}
            onChange={handleChange}
            options={getSelectionOption(plan)}
          />
        </div>
      </Row>
      <div className="grid mt-3 mb-10 grid-cols-3 gap-y-4">
        {analyticsVaiables.length > 0 && analyticsVaiables?.map((el) => (
          <div key={el.id} className="flex flex-col border-r [&:last-child]:border-none items-center">

            <p className={`${el?.extraClass} text-xl lg:text-2xl`}>
              {el.value}
            </p>
            <p className="text-grayColor text-xs lg:text-sm text-center">
              {el.label}
            </p>
          </div>
        ))}

      </div>
      <LineChart
        isLoading={API.isLoading}
        data={utilization || []}
        label="process Memory"
      />
    </Card>
  );
};

export default ProcessMemory;
