/* eslint-disable react/react-in-jsx-scope */
import { Navigate } from "react-router-dom";
import Error from "../page/error";
import UnAuthorize from "../page/unAuthorize";
import LogIn from "../page/user/login";
import AppLayout from "../component/layout/AppLayout";
import {
  appRoot,
  distributeRoot,
  onBoard,
  projectRoot,
  serviceRoot,
} from "./constant/CONSTANTS";
import Dashboard from "../page/app/project-page";

import ServiceLayout from "../component/layout/ServiceLayout";
import ServiceSetup from "../page/app/Service/Setup";
import ServiceLog from "../page/app/Service/Log";
import ServiceDashboard from "../page/app/Service";
import NoInternet from "../page/noInternet";
import HomeLayout from "../component/layout/Home_layout";

import OnBoard from "../page/user/onboard";
import UtilisationHistory from "../page/app/Service/History";
import ServiceSetting from "../page/app/Service/Setting";
import Setting from "../page/app/project-page/Setting";
import Payment from "../page/app/payment";
import Price from "../page/app/home-page/component/price";
import EndpointUtilization from "../page/app/Service/Endpoint";
import Project from "../page/app/home-page";
import ServiceFlow from "../page/app/Service/service-flow";
import BlockApi from "../page/app/Service/Block-Api";
import Integration from "../page/app/project-page/Integration";
import Profile from "../page/app/profile";
import BlockUser from "../page/blockUser";
import Feedback from "../page/app/feedback";
import MaintenancePage from "../page/maintenance";
import Billing from "../page/app/billing";
import CreditSummary from "../page/app/creditSummary";
import DistributerLayout from "../component/layout/DistributerLayout";
import DistributeDashboard from "../page/app/distributerRole";
import LogAnalytic from "../page/app/logAnalytic";
import ApiEndpointSelect from "../page/app/Service/History/apiendpoint";
import SuspendentUser from "../page/suspended";
import Success from "../page/app/billing/status/success";
import Failed from "../page/app/billing/status/failed";

export const ROUTES = {
  dashboard: "/dashboard",
  services: "/:id",
  log: "/:id/log",
  setup: "/:id/setup",
  history: "/:id/history",
  feedback: "/feedback/:token",
};

const LOGIN_ROUTES = [
  {
    index: true,
    element: <LogIn />,
  },
];

const ALL_ROUTES = (appProps) => [
  ...LOGIN_ROUTES,
  // {
  //   index: true,
  //   element: <LogIn  />,
  // },
  {
    path: `${onBoard}`,
    element: <OnBoard {...appProps} />,
  },
  // Feedback
  {
    path: `${ROUTES.feedback}`,
    element: <Feedback {...appProps} />,
  },
  // Distributer
  {
    path: `${distributeRoot}`,
    element: <DistributerLayout {...appProps} />,
    children: [
      {
        index: true,
        // path: "dashboard",
        element: <DistributeDashboard {...appProps} />,
      },
      {
        // index: true,
        path: "dashboard",
        element: <LogAnalytic {...appProps} />,
      },
      {
        path: "profile", // Child route for /service/:id/log
        element: <Profile {...appProps} />,
      },
    ],
  },
  {
    path: `${appRoot}`,
    element: <HomeLayout {...appProps} />,
    children: [
      {
        index: true,
        element: <Project to={`${appRoot}${ROUTES.dashboard}`} {...appProps} />,
      },
      {
        path: "profile", // Child route for /service/:id/log
        element: <Profile {...appProps} />,
      },
      {
        path: "pay", // Child route for /service/:id/log
        element: <Payment {...appProps} />,
      },
      {
        path: "pricing", // Child route for /service/:id/log
        element: <Price {...appProps} />,
      },
      {
        path: "billing", // Child route for /service/:id/log
        element: <Billing {...appProps} />,
        children: [
        ],
      },
      {
        path: "success",
        element: <Success {...appProps} />,
      },
      {
        path: "fail", // Child route for /service/:id/log
        element: <Failed {...appProps} />,
      },
      {
        path: "creditsummary", // Child route for /service/:id/log
        element: <CreditSummary {...appProps} />,
      },
      {
        path: "log-analytics", // Child route for /service/:id/log
        element: <LogAnalytic {...appProps} />,
      },
      // {
      //   // index: true,
      //   path: "logAnalytic",
      //   element: <LogAnalytic {...appProps} />,
      // },
    ],
  },

  // Service
  {
    path: `${projectRoot}`,
    element: <ServiceLayout {...appProps} />,
    children: [
      {
        index: true,
        element: <Navigate to="/app" {...appProps} />,
      },
      {
        path: `${serviceRoot}`,
        children: [
          {
            index: true,
            element: <Navigate to="/app" {...appProps} />,
          },
          {
            path: ":projectID", // Child route with a dynamic parameter
            children: [
              {
                path: ":serviceID", // Child route with a dynamic parameter
                children: [
                  {
                    index: true, // Default child route for /service/:id
                    element: <ServiceDashboard {...appProps} />,
                  },
                  {
                    path: ":environmentID", // Child route with a dynamic parameter
                    children: [
                      {
                        index: true, // Default child route for /service/:id
                        element: <ServiceDashboard {...appProps} />,
                      },
                      {
                        path: "setup", // Child route for /service/:id/setup
                        element: <ServiceSetup {...appProps} />,
                      },
                      {
                        path: "setup/:tour", // Child route for /service/:id/setup
                        element: <ServiceSetup {...appProps} />,
                      },
                      {
                        path: "log", // Child route for /service/:id/log
                        element: <ServiceLog {...appProps} />,
                      },

                      {
                        path: "service-flow", // Child route for /service/:id/log
                        element: <ServiceFlow {...appProps} />,
                      },

                      {
                        path: ":mode", // Child route for /service/:id/log
                        // element: <UtilisationHistory {...appProps} />,
                        children: [
                          {
                            index: true, // Default child route for /service/:id
                            element: <UtilisationHistory {...appProps} />,
                          },
                          {
                            path: "apiallow", // Default child route for /service/:id
                            element: <ApiEndpointSelect {...appProps} />,
                          },
                        ],
                      },
                      {
                        path: "setting", // Child route for /service/:id/log
                        element: <ServiceSetting {...appProps} />,
                      },
                      {
                        path: "endpoint-utilization", // Child route for /service/:id/log
                        element: <EndpointUtilization {...appProps} />,
                      },
                      {
                        path: "block-api", // Child route for /service/:id/log
                        element: <BlockApi {...appProps} />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },

  // Project
  {
    path: `${projectRoot}`,
    element: <AppLayout {...appProps} />,
    children: [
      {
        index: true,
        element: <Navigate to="/app" {...appProps} />,
      },
      {
        path: ":projectId", // Child route with a dynamic parameter
        children: [
          {
            index: true, // Default child route for /service/:id
            element: <Dashboard {...appProps} />,
          },
          {
            path: "integration", // Child route for /service/:id/log
            element: <Integration {...appProps} />,
          },
          {
            path: "setting", // Child route for /service/:id/log
            element: <Setting {...appProps} />,
          },
        ],
      },
    ],
  },
  {
    path: "/error",
    element: <Error />,
  },
  {
    path: "/unAuthorize",
    element: <UnAuthorize />,
  },
  {
    path: "/noInternet",
    element: <NoInternet />,
  },
  {
    path: "/blockuser",
    element: <BlockUser />,
  },
  {
    path: "/suspendeduser",
    element: <SuspendentUser />,
  },
  {
    path: "/undermaintenance",
    element: <MaintenancePage />,
  },
  {
    path: "*",
    element: <Navigate to="/error" />,
  },
];

export default ALL_ROUTES;
