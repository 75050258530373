import { Button, Result } from 'antd';
import React from 'react';
import { deleteAuthDetails } from '../util/API/authStorage';

const MaintenancePage = () => {
    const handleLogout = () => {
        deleteAuthDetails();
        localStorage.removeItem("name");
        localStorage.removeItem("email");
        localStorage.removeItem("token");
        localStorage.removeItem("ItemToken");
        window.location.assign("/");
    };
    return (
        <>
            <Result
                status="500"
                title="Under Maintenance"
                subTitle={(
                    <p>
                        Sorry for the inconvenience! Our website is temporarily unavailable for maintenance. We&apos;ll be up and running shortly!
                    </p>
                )}
                extra={(
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="mt-5 w-40 h-10  "
                        onClick={handleLogout}
                    >
                        Logout
                    </Button>
                )}
            />
        </>
    );
};

export default MaintenancePage;
