import React from "react";
import { CloudServerOutlined, SettingOutlined } from "@ant-design/icons";
import { AiOutlineDashboard } from "react-icons/ai";
import { BsDatabaseFillGear } from "react-icons/bs";
import {
  MdViewInAr,
  MdBlock,
  MdOutlineIntegrationInstructions,
} from "react-icons/md";
import { GiCycle } from "react-icons/gi";
import { TbApi } from "react-icons/tb";
import { FaNetworkWired } from "react-icons/fa";
import { FiCpu } from "react-icons/fi";
import { projectRoot, serviceRoot } from "./CONSTANTS";

export const data = [
  {
    id: "dashboard",
    icon: <AiOutlineDashboard />,
    label: "Real-time",
    path: `${projectRoot}/${serviceRoot}/:projectID/:serviceID/:environmentID`,
  },
  {
    id: "log",
    icon: <CloudServerOutlined />,
    label: "Log",
    path: `${projectRoot}/${serviceRoot}/:projectID/:serviceID/:environmentID/log`,
  },
  {
    id: "ProcessUsage",
    icon: <GiCycle />,
    label: "Process Usage",
    path: `${projectRoot}/${serviceRoot}/:projectID/:serviceID/:environmentID/process`,
  },
  {
    id: "CPUUsage",
    icon: <FiCpu />,
    label: "CPU Usage",
    path: `${projectRoot}/${serviceRoot}/:projectID/:serviceID/:environmentID/cpu`,
  },
  {
    id: "EndpointUsage",
    icon: <TbApi />,
    label: "API Usage",
    path: `${projectRoot}/${serviceRoot}/:projectID/:serviceID/:environmentID/api`,
  },
  {
    id: "blockApi",
    icon: <MdBlock />,
    label: "Blocked API",
    path: `${projectRoot}/${serviceRoot}/:projectID/:serviceID/:environmentID/block-api`,
  },

  {
    id: "EndpointUsage",
    icon: <FaNetworkWired />,
    label: "Service  Diagram",
    path: `${projectRoot}/${serviceRoot}/:projectID/:serviceID/:environmentID/service-flow`,
  },

  {
    id: "setup",
    icon: <BsDatabaseFillGear />,
    label: "Setup",
    path: `${projectRoot}/${serviceRoot}/:projectID/:serviceID/:environmentID/setup`,
  },
  {
    id: "setting",
    icon: <SettingOutlined />,
    label: "Setting",
    path: `${projectRoot}/${serviceRoot}/:projectID/:serviceID/:environmentID/setting`,
  },
];

export const menu = [
  {
    id: "dashboard",
    icon: <MdViewInAr />,
    label: "Overview",
    path: `${projectRoot}/:projectID`,
  },
  {
    id: "integration",
    icon: <MdOutlineIntegrationInstructions />,
    label: (
      // <div className="flex justify-between py-0">
      <p className="gap-1 ">
        Integration
        <span className="defaultsecondbgColor hidden md:inline ml-2 xl:ml-4 px-2 py-0 rounded-md bg-[#fde9e1] text-primary ">
          new
        </span>
      </p>

      // </div>
    ),
    path: `${projectRoot}/:projectID/integration`,
  },
  {
    id: "setting",
    icon: <SettingOutlined />,
    label: "Setting",
    path: `${projectRoot}/:projectID/setting`,
  },
];
