/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable operator-linebreak */
import React from "react";

import { Line } from "@ant-design/plots";
import { Empty, Spin } from "antd";
import CONSTANTS from "../../util/constant/CONSTANTS";

const getUnit = (label) => {
  switch (label) {
    case "CPU":
      return " %";
    case "Memory":
      return " GB";
    case "process Memory":
      return " MB";
    default:
      return "";
  }
};

export function LineChart({
  data = [],
  label = "",
  isLoading = false,
  annotations = [],
}) {
  const config = {
    data,
    xField: "year",
    yField: "value",
    seriesField: "name",
    color: ({ name }) => CONSTANTS.UTILIZATION_COLOR?.[name] || "#5B8FF9",
    legend: {
      position: "bottom-left",
      title: {
        text: "",
        spacing: 25,
      },
    },
    smooth: true,
    lineStyle: ({ name }) => {
      if (
        name === CONSTANTS.UTILIZATION_NAME.maxMemoryPhysical ||
        name === CONSTANTS.UTILIZATION_NAME.maxMemorySwap ||
        name === CONSTANTS.UTILIZATION_NAME.maxSystemCPU ||
        name === CONSTANTS.UTILIZATION_NAME.maxUserCPU
      ) {
        return {
          lineDash: [4, 4],
          opacity: 0.5,
        };
      }

      if (name === "Threshold") {
        return {
          opacity: 0.7,
        };
      }

      if (name === "Total Memory") {
        return {
          opacity: 0.7,
        };
      }

      return {
        opacity: 1,
      };
    },
    slider: {
      start: 0,
      end: 1,
    },

    yAxis: {
      label: {
        formatter: (v) =>
          `${v}${getUnit(label)}`.replace(
            /\d{1,3}(?=(\d{3})+$)/g,
            (s) => `${s},`
          ),
      },
    },
    annotations,
  };

  if (label === "CPU") {
    config.yAxis = {
      ...config?.yAxis,
      min: 0,
      max: 100,
      nice: true,
    };
  }

  if (isLoading) {
    return (
      <div className=" h-96  w-full">
        <Spin className="mt-48" tip="Loading..." size="large">
          <div className="content" />
        </Spin>
      </div>
    );
  }

  if (data?.length === 0) {
    return (
      <div className=" h-96 flex items-center justify-center w-full">
        <Empty />
      </div>
    );
  }
  return <Line {...config} />;
}

export default LineChart;
