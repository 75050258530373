import {
    Button, Card, Col, Row,
} from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const HeaderAnalyticCard = ({ title, showAnalyticData, btnController }) => {
    const navigate = useNavigate();
    return (
        <Card title={title}>
            <Row gutter={[16, 16]}>
                <Col span={24} md={16}>
                    <Row gutter={[16, 16]}>
                        {
                            showAnalyticData?.map((el) => (
                                <Col key={el?.id} span={24} sm={12} md={8}>
                                    <p className="text-grayColor">
                                        {el?.title ?? ""}
                                    </p>
                                    <p className="font-semibold">
                                        {el?.link ? <span className="text-primaryBg cursor-pointer" onClick={() => navigate("/app/creditsummary")}>View Credit History</span> : el?.amount}
                                    </p>
                                </Col>
                            ))
                        }
                        {/* <Col span={24} md={8}>
                                <p className="text-grayColor">
                                    Total Amount Remaining
                                </p>
                                <p className="font-semibold">
                                    ₹ 4,000
                                </p>
                            </Col>
                            <Col span={12} md={8}>
                                <p className="text-grayColor">
                                    Total Amount Used
                                </p>
                                <p className="font-semibold">
                                    ₹ 1,000
                                </p>
                            </Col>
                            <Col span={12} md={8}>
                                <p className="text-grayColor">
                                    Amount Processing
                                </p>
                                <p className="font-semibold">
                                    ₹ 1,000
                                </p>
                            </Col> */}
                    </Row>
                </Col>
                <Col className="flex  justify-end" span={24} md={8}>
                    <Button className="w-full md:w-auto" onClick={btnController} type="primary">
                        Redeem Credit
                    </Button>
                </Col>
            </Row>
        </Card>
    );
};

export default HeaderAnalyticCard;
