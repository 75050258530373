import React, { useState } from "react";
import { DatePicker, Radio } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import ApiUsageCard from "./api-usage-card";
import AllApiUsage from "./all-api-usage";
import { disabledDate } from "../../../../../util/functions";

const { RangePicker } = DatePicker;

const RequestCountChart = () => {
  const [mode, setMode] = useState("all");
  const [dateRange, setdateRange] = useState({
    startDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  const handleModeChange = (e) => {
    setMode(e.target.value);
  };

  const onDateChange = (value) => {
    if (!(value?.length > 0)) {
      setdateRange(null);
      return;
    }

    setdateRange({
      startDate: value?.[0]?.format("YYYY-MM-DD"),
      endDate: value?.[1]?.format("YYYY-MM-DD"),
    });
  };

  return (
    <>
      {mode !== "apiUsage" ? (
        <div className="flex gap-4 mb-2">
          <Radio.Group onChange={handleModeChange} value={mode} className="mr-3">
            <Radio.Button value="all">All API </Radio.Button>
            <Radio.Button value="Internal">Internal API </Radio.Button>
            <Radio.Button value="ThirdParty">ThirdParty API </Radio.Button>
          </Radio.Group>

          <div className="flex  justify-start items-center gap-3">
            <RangePicker
              disabledDate={disabledDate}
              allowClear={false}
              defaultValue={[
                dayjs(dateRange?.startDate),
                dayjs(dateRange?.endDate),
              ]}
              onChange={onDateChange}
            />
          </div>
        </div>
      ) : <></>}

      {mode === "all" ? (
        <AllApiUsage
          dateRange={dateRange}
          value={{
            title: "All",
          }}
        />
      ) : (
        <ApiUsageCard dateRange={dateRange} mode={mode} />
      )}
    </>
  );
};

export default RequestCountChart;
