import React from "react";
import { Result } from "antd";

const Done = () => {
  return (
    <div className="py-5 px-4">
      <Result
        status="success"
        title="Successfully Setup Wooffer Service!"
        subTitle={(
          <div className="flex flex-col items-center">
            <div className="max-w-lg">
              Wow! No need to worry about crashes anymore, whether it&apos;s an
              instance crash or a code error we&apos;ll notify you on Slack, the
              wooffer is always awake for your business safety
            </div>
            <div className="max-w-lg mt-2 text-red-400">
              Note : Please use one identifier to track one service, create a
              different identifier to monitor another service
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default Done;
