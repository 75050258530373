/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable comma-dangle */
import {
    Popconfirm, Row, Select, Table, notification
} from "antd";
import React, { useEffect, useState } from "react";
import { MdOutlineDeleteSweep } from "react-icons/md";
import useHttp from "../../hooks/use-http";
import CONSTANTS from "../../util/constant/CONSTANTS";

const CRUDTable = (propUp) => {
    const {
        dataSource,
        Other = {},
        onChange,
        extraclass,
        isLoading = false,
        APIendpoint,
        DeleteSelectedRow = false,
        onConfirm = (value) => {
            // console.info(value);
        },
        scrollAutoOff = false,
        scroll,
        setChanges = () => { },
        columns = [],
        selectionOff,
        rowSelectionObj,
        props,
    } = propUp;
    const API = useHttp();
    const [selectedRows, setSelectedRows] = useState([]);
    const [SelectedColumns, setSelected] = useState([]);
    // const [hideColumn, sethideColumn] = useState({});
    // console.log(props?.profile?.role);
    const columnsFiltered = columns?.filter((col) => {
        if (!col?.role) {
            return true;
        }
        if (
            col?.role
            && Array.isArray(col?.role)
            && col?.role?.includes(props?.profile?.role)
        ) {
            return true;
        }
        return false;
    });
    const nonSelectedColumns = columnsFiltered?.filter((col) => {
        return SelectedColumns.find((co) => col?.dataIndex === co);
    });
    // const dynamicColumns = columnsFiltered?.filter((column) => {
    //   // Exclude the 'Address' column if hideColumn is true
    //   return !hideColumn?.[column?.dataIndex];
    // });

    const rowSelection = rowSelectionObj ?? {
        type: "checkbox",
        onChange: DeleteSelectedRow
            ? (selectedRowKeys, selectedRows) => {
                setSelectedRows(selectedRowKeys);
            }
            : onChange,
    };
    useEffect(() => {
        setSelected(columnsFiltered?.map((opt) => opt?.dataIndex));
    }, [columns]);
    return (
        <>
            {selectedRows.length && DeleteSelectedRow ? (
                <Row className="mb20">
                    <Popconfirm
                        title="Sure to delete selected rows in bulk?"
                        onConfirm={() => {
                            const rows = [...selectedRows];
                            rows?.forEach((el, i) => {
                                if (CONSTANTS?.API[APIendpoint]) {
                                    const DeleteAPIBulk = { ...CONSTANTS?.API[APIendpoint] };
                                    DeleteAPIBulk.endpoint = DeleteAPIBulk?.endpoint?.replace(
                                        ":id",
                                        el
                                    );
                                    API.sendRequest(DeleteAPIBulk, () => {
                                        if (rows?.length - 1 === i) {
                                            onConfirm(rows);
                                            setSelectedRows([]);
                                            notification.success({
                                                message: "All deleted successfully",
                                            });
                                        }
                                    });
                                }
                            });
                        }}
                    >
                        <MdOutlineDeleteSweep
                            style={{ color: "black" }}
                            fontSize={35}
                            className="ml20 cursor-pointer"
                        />
                    </Popconfirm>
                </Row>
            ) : (
                ""
            )}
            <div>
                {/* {columnsFiltered?.map((el) => (
          <label key={el?.dataIndex}>
            <input
              type="checkbox"
              checked={!!hideColumn?.[el?.dataIndex]}
              onChange={() =>
                sethideColumn((prev) => ({
                  ...prev,
                  [el?.dataIndex]: !!!prev?.[el?.dataIndex],
                }))
              }
            />
            {el?.title}
          </label>
        ))} */}
                {/* <label className=" text-md text-black">Filter Table : </label>
        <Select
          mode="multiple"
          // defaultValue={columnsFiltered?.map((opt) => opt?.dataIndex)}
          value={SelectedColumns}
          onChange={(v) => {
            if (v.length > 0) {
              setSelected(v);
            }
          }}
          className="min-w-[170px] mb-3"
        >
          {columnsFiltered?.map((opt) => (
            <Select.Option key={opt?.dataIndex}> {opt?.title}</Select.Option>
          ))}

        </Select> */}
                <Table
                    rowClassName={`rows-custom ${extraclass}`}
                    loading={isLoading}
                    pagination={false}

                    onChange={(page, filter, sort) => {
                        const newFilter = [];
                        for (const property in filter) {
                            if (filter[property]) {
                                newFilter.push([property, filter[property]]);
                            }
                        }
                        const NewSort = {
                            sort: sort?.field,
                            sortBy:
                                sort?.order === "ascend"
                                    ? "ASC"
                                    : sort?.order === "descend"
                                        ? "DESC"
                                        : null,
                        };
                        const NewChanges = {};
                        if (NewSort.sortBy && NewSort?.sort !== "no") {
                            NewChanges.sort = NewSort;
                        }
                        if (newFilter.length) {
                            NewChanges.filter = newFilter;
                        }
                        setChanges(NewChanges);
                    }}
                    // scroll={
                    //   !scrollAutoOff
                    //     ? {
                    //         x: "80vw",
                    //         y: "60vh",
                    //       }
                    //     : scroll
                    //     ? scroll
                    //     : {}
                    // }
                    scroll={
                        scroll || (!scrollAutoOff ? { x: "80vw", y: "52vh" } : {})
                    }
                    // rowSelection={selectionOff ? false : rowSelection}
                    dataSource={dataSource}
                    // columns={columnsFiltered}
                    columns={nonSelectedColumns}
                    // columns={dynamicColumns}
                    {...Other}
                />
            </div>
        </>
    );
};
CRUDTable.defaultProps = {
    onChange: (selectedRowKeys, selectedRows) => {
        console.info(
            `selectedRowKeys: ${selectedRowKeys}`,
            "selectedRows: ",
            selectedRows
        );
    },
};
export default CRUDTable;
