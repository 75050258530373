/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/no-unstable-nested-components */
import React from "react";
import {
  Button, Col, Divider, Row,
} from "antd";
import UpgradeButton from "../../../../component/upgradeButton";

const PlanPage = () => {
  const PricingList = ({ title = "", list = [], fontClass = "" }) => {
    return (
      <>
        <p className=" sm:text-sm font-medium text-gray-900 ">{title}</p>
        <ul className={`p-0 pt-2  text-left ${fontClass}`}>
          {list?.map((el) => (
            <li className="flex items-center my-2">
              <svg
                className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="ml-3 font-medium">{el?.name}</span>
            </li>
          ))}
        </ul>
      </>
    );
  };
  const PricingCard = ({ data, offers = [], cardClassName = "" }) => {
    return (
      <div
        className={`flex flex-col p-6 mx-auto max-w-lg  text-gray-900 bg-white rounded-lg border h-full shadow  xl:p-8    ${cardClassName}`}
      >
        <h3 className="mb-1.5 text-xl font-semibold">{data?.name}</h3>
        {data?.price && (
          <div className="flex justify-start items-baseline ">
            <span className=" text-4xl font-semibold">
              ₹
              {data?.price}
            </span>
            <span className="text-gray-900 font-semibold ">/Month</span>
          </div>
        )}
        {data?.name !== "Enterprise" ? (
          <Button
            block
            type={data?.buttonType || "primary"}
            className={`textcolor p-5 flex items-center justify-center    my-5 font-semibold ${data?.buttonClassName} `}
            onClick={() => {
              data?.onClick();
            }}
          >
            {data?.buttonName}
          </Button>
        ) : (
          <UpgradeButton {...data} />
        )}

        {offers?.map((el) => (
          <>
            <Divider className="pt-0 mt-0" />
            <PricingList

              fontClass={el?.fontClass || ""}
              list={el?.list}
              title={el?.title}
            />
          </>
        ))}
      </div>
    );
  };
  return (
    <section className="bg-thirdBg">
      <div className="text-center font-semibold text-2xl">
        Pricing that helps you succeed!
      </div>
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        {/* Pricing Card */}
        <Row className="flex justify-center" gutter={[16, 8]}>
          <Col span={8} sm={24} md={24} lg={10} xl={8}>
            <PricingCard
              data={{
                name: "Basic",
                price: "0",
                buttonName: "Currently Running",
                buttonClassName:
                  "!bg-runningBtn hover:!bg-runningBtn hover:opacity-75",
                onClick: () => { },
              }}
              offers={[
                {
                  list: [
                    { name: "1 Project", type: true },
                    { name: "3 Services/Project", type: true },
                    { name: "2 Environment/Services", type: true },
                    { name: "500 Log/Month Backup", type: true },
                  ],
                  title: "Included in the plan",
                },
                {
                  list: [
                    { name: "Real-time monitoring dashboard", type: true },
                    { name: "Email Notification", type: true },
                    { name: "Slack Integration", type: true },
                    { name: "Email Support", type: true },
                    { name: "Weekly usage report", type: true },
                    { name: "API Management Service", type: true },
                    { name: "Log Management Service", type: true },
                    { name: "Resource usage Analytics", type: true },

                  ],
                  title: "Free includes:",
                  fontClass: "text-gray-500 dark:text-gray-400",
                },
              ]}
            />
          </Col>

          <Col span={8} sm={24} md={24} lg={10} xl={8}>
            <PricingCard
              data={{
                name: "Enterprise",
                price: null,
                buttonName: "Contact Us",
                buttonClassName:
                  "p-5 flex items-center justify-center    my-5 font-semibold",
              }}
              offers={[
                {
                  list: [
                    { name: "Project", type: true },
                    { name: "Services/Project", type: true },
                    { name: "Environment/Services", type: true },
                    { name: "Yearly Log Backup", type: true },
                    { name: "Usage Report", type: true },

                  ],
                  title: "Set your requirement according to your usage",
                },
                {
                  list: [
                    { name: "Real-time monitoring dashboard", type: true },
                    { name: "Email Notification", type: true },
                    { name: "Slack Integration", type: true },
                    { name: "Email Support", type: true },
                    { name: "Weekly usage report", type: true },
                    { name: "API Management Service", type: true },
                    { name: "Log Management Service", type: true },
                    { name: "Resource usage Analytics", type: true },
                  ],
                  title: "Free includes:",
                  fontClass: "text-gray-500 dark:text-gray-400",
                },
              ]}
            />
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default PlanPage;
