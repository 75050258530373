import React from "react";
import { Gauge } from "@ant-design/plots";
import { getChart } from "../../util/functions";

function GaugeChart({ percent }) {
  const config = {
    percent: +percent || 0,
    type: "meter",
    innerRadius: 0.8,
    range: {
      ticks: [0, 7 / 10, 9 / 10, 1],
      color: ["#34B551", "#FF9416", "#FF4016"],
    },
    axis: {
      label: {
        formatter(v) {
          return Number(v) * 100;
        },
      },
      subTickLine: {
        count: 3,
      },
    },
    indicator: {
      pointer: {
        style: {
          stroke: getChart(+percent * 100),
          lineWidth: 3,
        },
      },
      pin: {
        style: {
          stroke: getChart(+percent * 100),
          lineWidth: 3,
        },
      },
    },
    statistic: {
      content: {
        style: {
          fontSize: "15px",
          lineHeight: "15px",
        },
      },
    },
  };
  return <Gauge {...config} />;
}

export default GaugeChart;
