import React from 'react';
import { Typography } from 'antd';
import Proptyps from 'prop-types';

const { Text } = Typography;

function AnalyticsCountCard({ heading, count, unit }) {
  return (
    <div
      className="inline-flex flex-col w-full gap-2 flex-wrap "
      style={{
        padding: '15px 15px 10px 15px',
        border: '1px solid #0000000f',
        borderRadius: '6px',
        background: '#fff',
        boxShadow: '1px 0px 10px 6px rgb(202 202 202 / 13%) inset',
      }}
    >
      <Text
        type="secondary"
        className="inline-flex items-center flex-wrap "
        style={{
          fontSize: '14px',
          fontWeight: '600',
        }}
      >
        {heading}
      </Text>
      <div className="inline-flex flex-wrap  items-baseline">
        <Text
          style={{
            fontSize: '30px',
            fontWeight: '600',
          }}
        >
          {count ?? ''}
        </Text>
        &nbsp;
        <Text
          type="secondary"
          style={{
            fontSize: '14px',
            fontWeight: '400',
          }}
        >
          {unit}
        </Text>
      </div>
    </div>
  );
}

AnalyticsCountCard.propTypes = {
  heading: Proptyps.string,
  count: Proptyps.string || Proptyps.number,
  unit: Proptyps.string || Proptyps.number,
};

AnalyticsCountCard.defaultProps = {
  heading: '',
  count: '',
  unit: '',
};

export default AnalyticsCountCard;
