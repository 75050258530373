/* eslint-disable brace-style */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Helmet } from "react-helmet";
import { io } from "socket.io-client";
import { ConfigProvider, notification } from "antd";
import ALL_ROUTES from "./util/Route";
import { getAuthToken } from "./util/API/authStorage";
import useHttp from "./hooks/use-http";
import CONSTANTS from "./util/constant/CONSTANTS";
import { getContrastYIQ, isLogin } from "./util/functions";
import useIndexedDB from "./hooks/index-db";
import theme from "./theme.json";

const socket = io(process.env.REACT_APP_SOCKET_URL);

function App() {
  const [systemsStatus, setSystemsStatus] = useState({});
  const [productionMode, setProductionMode] = useState(false);
  const [themes, setThemes] = useState({ ...theme });
  const [errorStatus, setErrorStatus] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [maintenance, setMaintenance] = useState(null);
  // const [isMaintenance, setIsMaintenance] = useState("0");
  const [userDetails, setUserDetails] = useState({});
  const [plan, setPlan] = useState({});
  const [loading, setLoading] = useState(true);
  const API = useHttp();
  const { db, addData, deleteRecordsOlderThanTenMinutes } = useIndexedDB(
    "wooffer",
    "wooffer-request",
  );

  useEffect(() => {
    API.sendBulkRequest([CONSTANTS.API.maintanance.getLatest, CONSTANTS.API.setting.getMaintenance], (res) => {
      setMaintenance(res[0]?.data?.data);
      // setIsMaintenance(res[1]?.data?.value);
      if (res[1]?.data?.data?.value === "1" && window.location.pathname !== "/undermaintenance") {
        window.location.assign("/undermaintenance");
      } else if (res[1]?.data?.data?.value === "0" && window.location.pathname === "/undermaintenance") {
        window.location.assign("/");
      }
    });
    if (!isLogin()) return;
    // if (localStorage.getItem("userRole") === "Distributer") return;
    if (window.location.pathname === "/blockuser" || window.location.pathname === "/suspendeduser") return;
    API.sendBulkRequest(
      localStorage.getItem("userRole") !== "Distributer" ? [CONSTANTS.API.auth.getMe, CONSTANTS.API.plan.getMy] : [CONSTANTS.API.auth.getMe],
      (res) => {
        if (res?.length > 0) {
          setUserDetails(res[0]?.data?.data);
          // if (res[0]?.data?.data?.isBlocked && window.location.pathname !== "/blockuser") {
          //   window.location.assign("/blockuser");
          // }
          // else if (res[0]?.data?.data?.isSuspended && window.location.pathname !== "/suspendeduser") {
          //   window.location.assign("/suspendeduser");
          // }
          // else if (!res[0]?.data?.data?.isBlocked && !res[0]?.data?.data?.isSuspended) {
          //   if (window.location.pathname === "/blockuser" || window.location.pathname === "/suspendeduser") {
          //     window.location.assign("/");
          //   }
          // }
          res[1]?.data && setPlan(res[1]?.data?.data?.rows[0]);
          setThemes((prev) => ({
            ...prev,
            token: {
              ...prev?.token,
              colorPrimary: res[0]?.data?.data?.color || theme?.token?.colorPrimary,
            },
            components: {
              ...prev?.components,
              Spin: {
                ...prev?.components?.Spin,
                colorPrimary: res[0]?.data?.data?.color || theme?.token?.colorPrimary,
              },
            },
            // textcolor: getContrastYIQ(prev?.token?.colorPrimary),
          }));
        }
      },
    );
  }, [refresh]);

  useEffect(() => {
    if (db == null) return;
    socket.on("requestStart", (systemData) => {
      const EnvironmentID = Object.keys(systemData)[0];
      addData({ ...systemData[EnvironmentID], env: EnvironmentID });
    });
    setInterval(() => {
      deleteRecordsOlderThanTenMinutes();
    }, 1000);
  }, [db]);

  useEffect(() => {
    if (!isLogin()) return;

    const joinRoomEvent = () => {
      socket.emit("join-user", {
        token: getAuthToken() || "",
      });
    };

    socket.on("connect", joinRoomEvent);

    socket.on("disconnect", () => {
      CONSTANTS.PROCESS.id = null;
      setLoading(true);
    });

    socket.on("log", (data) => {
      // console.log(data);
      setLoading(false);
      if (data?.connected) {
        let systemObj = {};
        data?.message?.map((el) => {
          systemObj = { ...systemObj, [el?.serviceToken]: true, isKubernetes: el?.isKubernetes ?? false };
        });
        setSystemsStatus(systemObj);
      }
    });

    socket.on("error", (data) => {
      setErrorStatus((prev) => ({
        ...prev,
        [data?.message?.service_id]: data || true,
      }));
    });

    socket.on("remove", (data) => {
      setLoading(false);
      if (data?.connected) {
        setSystemsStatus((prev) => ({ ...prev, [data?.message]: false }));
      }
    });

    socket.on("add", (data) => {
      setLoading(false);
      if (data?.connected) {
        setSystemsStatus((prev) => ({ ...prev, [data?.message]: true }));
      }
    });
  }, []);

  const updateProfile = (payload) => {
    API.sendRequest(
      CONSTANTS.API.auth.updateMe,
      (res) => {
        if (res?.status === "success") {
          notification.success({
            message: "Successfully Updated Info!",
          });
          setUserDetails({});
          setRefresh((prev) => !prev);
        }
      },
      payload,
    );
  };

  const router = createBrowserRouter(
    ALL_ROUTES({
      socket,
      systems_status: systemsStatus,
      set_production_mode: setProductionMode,
      production_mode: productionMode,
      loading,
      user_details: userDetails,
      is_loading: API.isLoading,
      plan,
      error_status: errorStatus,
      update_profile: updateProfile,
      theme: themes,
      setRefresh: setRefresh,
      maintenance: maintenance,
      // isMaintenance: isMaintenance,
    }),
  );
  const styles = `
  .ant-btn-primary:disabled {
    cursor: not-allowed;
    border-color: #d9d9d9;
    color: #00000040 !important;
    background-color: #0000000a;
  }
  .ant-switch .ant-switch-inner .ant-switch-inner-unchecked {
    color : ${getContrastYIQ(themes?.token?.colorPrimary || theme?.token?.colorPrimary)} !important;
  }
  .ant-switch .ant-switch-inner .ant-switch-inner-checked {
    color : ${getContrastYIQ(themes?.token?.colorPrimary || theme?.token?.colorPrimary)} !important;
  }
  .textcolor {
    color : ${getContrastYIQ(themes?.token?.colorPrimary || theme?.token?.colorPrimary)} !important;
  }
  .defaultColor {
    color: ${themes?.token?.colorPrimary || theme?.token?.colorPrimary} !important;
  }
  .defaultbgColor {
    background: ${themes?.token?.colorPrimary || theme?.token?.colorPrimary} !important;
  }
  .defaultsecondbgColor {
    background: ${themes?.token?.colorPrimary || theme?.token?.colorPrimary}2a !important;
  }
  .defaultthirdbgColor {
    background: ${themes?.token?.colorPrimary || theme?.token?.colorPrimary}4a !important;
  }
  .defaultBorderColor {
    border-color: ${themes?.token?.colorPrimary || theme?.token?.colorPrimary} !important;
  }
  .ant-menu-item-selected::before {
    background: ${themes?.token?.colorPrimary || theme?.token?.colorPrimary} !important;
  }
  .errorbgcolor {
    background: #ff4016 !important;
  }
  .ant-menu-light .ant-menu-item-selected {
    background: ${themes?.token?.colorPrimary || theme?.token?.colorPrimary}0f !important;
  }
  .ant-typography .ant-typography-copy {
    color: ${themes?.token?.colorPrimary || theme?.token?.colorPrimary} !important;
  }

  .warning-primary-to-secondary {
    background: linear-gradient(90deg, ${themes?.token?.colorPrimary || theme?.token?.colorPrimary}33 0%, ${themes?.token?.colorPrimary || theme?.token?.colorPrimary}00 135.23%);
    color : ${getContrastYIQ(themes?.token?.colorPrimary || theme?.token?.colorPrimary)} !important;
  }
  .card-primary-to-secondary {
    background: linear-gradient(116deg, ${themes?.token?.colorPrimary || theme?.token?.colorPrimary} 0%, ${themes?.token?.colorPrimary || theme?.token?.colorPrimary}b0 52.08%, ${themes?.token?.colorPrimary || theme?.token?.colorPrimary}82 100%);
    color : ${getContrastYIQ(themes?.token?.colorPrimary || theme?.token?.colorPrimary)} !important;
  }
 
  `;

  return (<ConfigProvider theme={themes}>
    <style>{styles}</style>
    <Helmet>
      <link
        rel="shortcut icon"
        href={
          userDetails?.organizationFavIcon
            ? userDetails?.organizationFavIcon
            : process.env.REACT_APP_FAVICON
        }
      />
    </Helmet>
    <RouterProvider router={router} />
  </ConfigProvider>);
}

export default App;
