/* eslint-disable react/jsx-props-no-multi-spaces */
import React, { useState } from "react";
import {
  Button, Card, Col, Empty, Row, Tooltip,
} from "antd";
import Search from "antd/es/input/Search";
import Title from "antd/es/typography/Title";

import ProjectCard from "../../../../component/card/project-card";
import InfoIcon from "../../../../component/common/info-icon";

const ProjectList = ({
  projects = [],
  isLoading = false,
  setIsModalOpen = () => { },
  isButtonVisible = false,
  ref1,
  theme = {},
}) => {
  const [filterText, setFilterText] = useState("");
  const filterProject = projects?.filter((project) => project?.name?.toLowerCase()?.includes(filterText?.toLowerCase()));

  return (
    <Card
      className="h-full"
      title={(
        <Row className="gap-2 items-center justify-between">
          <Title className="!m-0 p-0" level={5}>
            Projects
            <Tooltip className="cursor-pointer" title="Collection of Various Services.">
              <span className="mt-1 relative top-1">
                <InfoIcon color="black" />
              </span>
            </Tooltip>
          </Title>

          <Tooltip className="cursor-pointer" title={!isButtonVisible ? 'Please Upgrade your Plan to track more Projects.' : 'Click here to Create a New Project'}>
            <Button
              ref={ref1}
              onClick={() => {
                setIsModalOpen(true);
              }}
              className="textcolor"
              disabled={!isButtonVisible}
              type="primary"
            >
              + Add New Project
            </Button>
          </Tooltip>
        </Row>
      )}
    >
      <Row className="flex">
        <Search
          width="auto"
          onChange={(e) => {
            setFilterText(e.target.value);
          }}
          placeholder="Search by project name"
        />
      </Row>
      <Row className="mt-5" gutter={[14, 10]}>
        {filterProject?.map((project) => (
          <Col span={24} sm={12} xl={8} key={`project_${project?.id}`}>
            <ProjectCard {...project} theme={theme} />
          </Col>
        ))}
      </Row>
      {filterProject.length === 0 && !isLoading && <Empty />}
    </Card>
  );
};

export default ProjectList;
