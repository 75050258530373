/* eslint-disable comma-dangle */
import React from "react";
import ReactDOM from "react-dom/client";
// import { ConfigProvider } from "antd";
import App from "./App";
// import theme from "./theme.json";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    {/* <ConfigProvider theme={theme}> */}
      <App />
    {/* </ConfigProvider> */}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
