import { Tooltip } from "antd";
import React from "react";
import InfoIcon from "./info-icon";

const TooltipCustom = ({ title, className = "" }) => {
  return (
    <Tooltip
      className={`cursor-pointer ${className || ""}`}
      title={title}
    >
      <span className=" relative top-1">
        <InfoIcon color="#000000E0" />
      </span>
    </Tooltip>
  );
};

export default TooltipCustom;
