/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
/* eslint-disable no-promise-executor-return */
/* eslint-disable comma-dangle */
/* eslint-disable no-shadow */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable operator-linebreak */
import { useState, useEffect } from "react";
import moment from "moment";
import CONSTANTS from "../util/constant/CONSTANTS";

const idb =
  window.indexedDB ||
  window.mozIndexedDB ||
  window.webkitIndexedDB ||
  window.msIndexedDB ||
  window.shimIndexedDB;

const useIndexedDB = (dbName, storeName) => {
  const [db, setDb] = useState(null);
  const [error, setError] = useState(null);

  const deleteRecordsOlderThanTenMinutes = async () => {
    if (!db) {
      return;
    }

    var tx = db.transaction(storeName, "readwrite");
    var objectStore = tx.objectStore(storeName);
    const getRequest = objectStore.openCursor();

    const tenMinutesAgo = moment().utc().subtract(10, "minutes");

    getRequest.onsuccess = (event) => {
      const cursor = event.target.result;

      if (cursor) {
        const record = cursor.value;

        // Replace 'reciveTime' with the actual property name
        const recordTime = moment.utc(record.requestReceivedTime);
        if (recordTime.isBefore(tenMinutesAgo)) {
          cursor.delete();
        }

        cursor.continue();
      } else {
        const getRequest = objectStore.getAll();
        getRequest.onsuccess = () => {
          const records = getRequest.result;
          CONSTANTS.Request.list = records;
        };
      }
    };
  };

  useEffect(() => {
    if (!idb) {
      console.log("This browser doesn't support IndexedDB");
      return;
    }

    const request = idb.open(dbName, 1);

    request.onerror = function (event) {
      setError(event);
      console.error(event);
    };

    // eslint-disable-next-line no-unused-vars
    request.onupgradeneeded = function (event) {
      const dbData = request.result;
      if (!dbData.objectStoreNames.contains(storeName)) {
        dbData.createObjectStore(storeName, {
          autoIncrement: true,
        });
      }
    };

    request.onsuccess = function () {
      setDb(request.result);
      var tx = request.result.transaction(storeName, "readwrite");
      var objectStore = tx.objectStore(storeName);
      const getRequest = objectStore.getAll();
      deleteRecordsOlderThanTenMinutes(request.result);
      getRequest.onsuccess = () => {
        const records = getRequest.result;
        CONSTANTS.Request.list = records;
      };
    };
  }, []);

  const addData = (data) => {
    if (!db) {
      return;
    }
    var tx = db.transaction(storeName, "readwrite");
    var userData = tx.objectStore(storeName);
    const operation = userData.put(data);

    operation.onerror = (err) => console.log(err);

    operation.onsuccess = () => {
      CONSTANTS.Request.list?.push(data);
    };
  };

  const getDataByID = (id) => {
    if (!db) {
      return;
    }

    const transaction = db.transaction(storeName, "readwrite");
    const store = transaction.objectStore(storeName);

    const getRequest = store.get(id);

    getRequest.onerror = (error) => {
      console.error(
        `Error retrieving data from IndexedDB: ${error.target.error}`
      );
    };
  };

  const getDataByKey = async (key, value) => {
    return new Promise((resolve, reject) => {
      if (!db) {
        resolve(0);
        return 0;
      }

      const transaction = db.transaction(storeName, "readwrite");
      const store = transaction.objectStore(storeName);

      const getRequest = store.openCursor();

      let count = 0;

      getRequest.onsuccess = (event) => {
        const cursor = event.target.result;

        if (cursor) {
          const record = cursor.value;

          // Replace 'yourNonIndexKey' with the actual non-indexed key you want to count
          if (record?.[key] === value) {
            count++;
          }

          cursor.continue();
        } else {
          resolve(count);
        }
      };

      getRequest.onerror = (event) => {
        reject(`Error counting records: ${event.target.error}`);
      };
    });
  };

  return {
    db,
    error,
    addData,
    getDataByID,
    getDataByKey,
    deleteRecordsOlderThanTenMinutes,
  };
};

export default useIndexedDB;
