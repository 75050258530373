import {
  Card, Col, Empty, Row, Timeline, Typography,
} from "antd";
import Title from "antd/es/typography/Title";

import React from "react";
import moment from "moment";
import { STATUS_CONFIG } from "../../Service/Log";

export const { Text } = Typography;
const LogList = ({ log = [] }) => {
  return (
    <Card
      className="h-full"
      title={(
        <Row className="gap-2 items-center">
          <Title className="!m-0 p-0" level={5}>
            Logs
          </Title>
        </Row>
      )}
    >
      <Timeline
        className=""
        items={log?.map((el) => {
          return {
            color: STATUS_CONFIG(el?.status).color,
            dot: STATUS_CONFIG(el?.status).icon,
            children: (
              <>
                <Row gutter={[10, 5]}>
                  <Col>
                    {" "}
                    <Text
                      style={{
                        color: STATUS_CONFIG(el?.status).color,
                        fontSize: "12px",
                      }}
                    >
                      {moment(el?.createdAt)
                        .local()
                        .format("hh:mm:ss a DD/MM/YYYY")}
                    </Text>
                  </Col>
                  <Col>
                    <Text
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      {el.serviceEnvironment?.service?.project?.name
                        ? `${el.serviceEnvironment?.service?.project?.name} / ${el.serviceEnvironment?.service?.name} / ${el.serviceEnvironment?.name}`
                        : "This Environment, server or project may be deleted"}
                    </Text>
                    {" "}
                  </Col>
                </Row>

                <Text className="mt-2">{el?.detail}</Text>
              </>
            ),
          };
        })}
      />

      {log?.length === 0 && <Empty />}
    </Card>
  );
};

export default LogList;
