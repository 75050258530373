import { Tooltip } from "antd";
import React from "react";

function Progress({ progress = [], height, className }) {
  return (
    <div
      className={`w-full custom-progress-track ${className || ""}`}
      style={{
        height,
      }}
    >
      <div
        className="flex"
        style={{
          height,
        }}
      >
        {progress.map((el) => (
          <Tooltip
            title={el?.tooltip}
            color={el?.color}
            // eslint-disable-next-line no-unsafe-optional-chaining
            key={((el?.color + el?.progress) || 0)}
          >
            <div
              className="progress-fill"
              style={{
                transition: "0.5s",
                width: el?.progress,
                background: el?.color,
              }}
            />
          </Tooltip>
        ))}
      </div>
    </div>
  );
}

export default Progress;
