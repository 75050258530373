import { Pie } from '@ant-design/plots';
import React from 'react';
import { Empty, Spin } from 'antd';
// import CONSTANTS from '../../util/constant/CONSTANTS';

const DonutChart = ({
    data = [],
    // label = "chart",
    isLoading = false,
    // annotations = [],
}) => {
    const config = {
        data,
        // color: ({ name }) => CONSTANTS.UTILIZATION_COLOR?.[name] || "#5B8FF9",
        color: ({ name }) => {
            const item = data.find((d) => d.name === name);
            return item ? item.color : "#a2a2a2";
        },
        angleField: 'value',
        colorField: 'name',
        paddingRight: 80,
        innerRadius: 0.6,
        label: {
            type: 'inner',
            offset: '-30%',
            text: 'value',
            style: {
                fontWeight: 'bold',
            },
        },
        legend: {
            color: {
                title: false,
                position: 'right',
                rowPadding: 5,
            },
        },
        annotations: [
            {
                type: 'text',
                style: {
                    text: "value",
                    x: '50%',
                    y: '50%',
                    textAlign: 'center',
                    fontSize: 40,
                    fontStyle: 'bold',
                },
            },
        ],
    };

    if (isLoading) {
        return (
            <div className=" h-96  w-full">
                <Spin className="mt-48" tip="Loading..." size="large">
                    <div className="content" />
                </Spin>
            </div>
        );
    }
    if (data?.length === 0) {
        return (
            <div className=" h-96 flex items-center justify-center w-full">
                <Empty />
            </div>
        );
    }
    return <Pie {...config} />;
};

export default DonutChart;
