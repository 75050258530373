/* eslint-disable no-debugger */
/* eslint-disable comma-dangle */
/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Avatar, Col, Image, Layout, Row, Tooltip } from "antd";
// import { Helmet } from "react-helmet";
import { BiReceipt } from "react-icons/bi";
import { IoAnalyticsOutline } from "react-icons/io5";
import RightDrawer from "./Component/right-drawer";
import { appRoot, distributeRoot, loginRoot, onBoard } from "../../util/constant/CONSTANTS";
import { getAuthToken } from "../../util/API/authStorage";
import useCheckInternet from "../../hooks/use-checkInternet";
import logo from "../../asset/logos/icon.svg";
// import Profile from "../../asset/image/dummy-avatar.jpg";

const { Header, Content } = Layout;

const ProjectLayout = (props) => {
  useCheckInternet("/noInternet");
  // const domain = new URL(process.env.REACT_APP_WEB_DOMAIN).hostname;
  const domain = process.env.REACT_APP_WEB_DOMAIN ? process.env.REACT_APP_WEB_DOMAIN?.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i)?.[2] : "";
  const { user_details } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const isLogin = getAuthToken() !== undefined && getAuthToken() !== null;
    if (!isLogin) {
      navigate(loginRoot);
      return;
    }
    if (localStorage.getItem("userRole") === "Distributer" && !currentPath?.includes("distribute")) {
      navigate(distributeRoot);
      return;
    }
    if (!Object.keys(user_details).length > 0) return;
    if (+process.env.REACT_APP_DOMAIN_NAVIGATION_ONOFF === 0) return;
    // if (+process.env.REACT_APP_DOMAIN_NAVIGATION_ONOFF === 0) return;

    if (user_details?.isDomainCreated &&
      !user_details?.domain?.includes(window.location.host)) {
      const url = `https://${user_details?.domain}?token=${localStorage.getItem(
        "token"
      )}`;
      window.open(url, "_self");
      return;
    }
    // if (user_details?.subDomain !== null && user_details?.subDomain?.trim() !== "") return;
    // if ((user_details?.username == null ||
    //   user_details?.username?.trim() === "" ||
    //   user_details?.subDomain == null ||
    //   user_details?.subDomain?.trim() === "")) {
    if (!localStorage.getItem("isDomainSkip") || +localStorage.getItem("isDomainSkip") !== 1) {
      navigate(onBoard);
      return;
    }
    if (user_details?.username !== null && user_details?.username?.trim() !== "") return;
    // return;
    // }

    if (!user_details?.isDomainCreated && domain !== "" && !domain?.includes(window.location.host)) {
      const url = `https://${domain}?token=${localStorage.getItem(
        "token"
      )}`;
      window.open(url, "_self");
    }
  }, [user_details]);

  return (
    <>
      {/* <Helmet>
       {user_details?.organizationFavIcon ?
       <link rel="icon" href={user_details?.organizationFavIcon} /> :
        <link rel="icon" href="icon.svg" />}
      </Helmet> */}
      <Layout
        hasSider
        style={{
          minHeight: "100vh",
        }}
        className="site-layout max-w-screen-2xl m-auto"
      >
        <Layout>
          <Header
            className="px-0 md:px-0 top-0 sticky z-10"
            style={{
              backgroundColor: "#FFFFFF",
            }}
          >
            <Row
              align="middle"
              justify="space-between"
              className="mx-5 md:mx-14"
            >
              <Col span={4} md={4} className="center flex">
                <Image
                  style={{
                    height: "50px",
                    width: "110px",
                    cursor: "pointer",
                    objectFit: "contain",
                  }}
                  preview={false}
                  src={user_details?.organizationImage || logo}
                  onClick={() => {
                    navigate(`${appRoot}`);
                  }}
                />
              </Col>

              <Col
                span={20}
                md={10}
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Tooltip
                  className="cursor-pointer"
                  title="Usage"
                >

                  <IoAnalyticsOutline className="mr-5 cursor-pointer" size={28} onClick={() => navigate("/app/log-analytics")} />

                </Tooltip>
                <Tooltip
                  className="cursor-pointer"
                  title="Billing Detail"
                >

                  <BiReceipt className="mr-5 cursor-pointer" size={28} onClick={() => navigate("/app/billing")} />
                </Tooltip>

                <Avatar
                  // src={Profile}
                  className="cursor-pointer card-primary-to-secondary textcolor border-[1px] border-gray-300"
                  shape="circle"
                  size={35}
                  // style={{
                  //   // border: "1px solid gray",
                  // }}
                  onClick={() => {
                    setVisible(true);
                  }}
                >
                  {user_details?.username?.charAt(0)?.toUpperCase()}
                </Avatar>
              </Col>
            </Row>
          </Header>
          <Content className="">
            <Row className="mx-3 md:mx-6">
              <Col
                className="mt-5 mb-20 md:mb-10"
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
              >
                <Outlet />
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>

      <RightDrawer
        username={props?.user_details?.username}
        email={props?.user_details?.email}
        visible={visible}
        setVisible={setVisible}
      />
    </>
  );
};

export default ProjectLayout;
