import { Button, Result } from "antd";
import React from "react";

const NoInternet = () => {
  return (
    <Result
      status="warning"
      subTitle="There are some problems with connections check your internet connections and try again."
      extra={(
        <Button disabled type="primary" key="console">
          Do not need to reload the page we will redirect you to your last
          location when connection is established
        </Button>
      )}
    />
  );
};

export default NoInternet;
