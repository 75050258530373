import React from 'react';
// import { loadStripe } from '@stripe/stripe-js';
import { Button, Image } from 'antd';
// import { useNavigate } from 'react-router-dom';
import useHttp from '../../../hooks/use-http';
import stripepay from "../../../asset/image/stripebgb.png";
import CONSTANTS from '../../../util/constant/CONSTANTS';

// const stripePromise = loadStripe('pk_test_51PLNFSSEYpDjP9THZnWYr9JhWKYU2UdqjTymGp0z1Dl1Z8LvVLt3A601RpHLDyywDiuE4xqMb1R3UnmCjWG444mL00nNvBkGD3');

const Testing = (props) => {
    const api = useHttp();
    // const navigate = useNavigate();
    const makePayment = async () => {
        // const stripe = await stripePromise;

        // const body = {
        //     // products: carts, // Add your products or cart details here if necessary
        // };
        // const headers = {
        //     'Content-Type': 'application/json',
        // };
        api?.sendRequest(
            CONSTANTS.API.payment.addStripePayment,
            (res) => {
                // const result = res?.data?.json();

                if (res?.success === 'success') {
                    // if (res?.data?.url) navigate(res?.data?.url);
                    // window.location(res?.data?.url);
                    window.location.href = res?.data?.url;
                    // const sessionId = result.data.id;
                    // const stripeResult = stripe.redirectToCheckout({
                    //     sessionId,
                    // });

                    // if (stripeResult.error) {
                    //     console.error(stripeResult.error.message);
                    // }
                } else {
                    console.error('Failed to create checkout session');
                }
            },
            {
                userId: props?.userId,
            },
        );
    };

    return (
        <>
            <Button onClick={makePayment} className="py-0 px-2 razorpay-btn  bg-purple-500 hover:!bg-purple-700 text-white font-medium rounded transition duration-300" type="primary">
                <div className="flex items-center gap-2">
                    <div className="rounded">
                        <Image preview={false} width={30} src={stripepay} alt="Razorpay Logo" class="razorpay-logo h-6 mr-2" />

                    </div>
                    <span>Pay with Stripe</span>
                </div>
            </Button>
            {/* <Button className="w-full md:w-auto" onClick={makePayment} type="primary">
                Pay with Stripe
            </Button> */}
        </>
    );
};

export default Testing;
