/* eslint-disable no-shadow */
/* eslint-disable new-cap */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-comment-textnodes */
import {
    Avatar,
    Button, Card, Col, DatePicker, Divider, Empty, Image, Modal, Pagination, Result, Row,
    Spin,
    notification,
} from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import jsPDF from 'jspdf';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement } from '@stripe/react-stripe-js';
import useHttp from "../../../hooks/use-http";
import BillingCard from '../../../component/card/billingCard';
import CONSTANTS from '../../../util/constant/CONSTANTS';
import { apiGenerator, rzpayObj } from '../../../util/functions';
import logo from "../../../asset/logos/icon.svg";
import razorpay from "../../../asset/image/razorpay.png";
import stripepay from "../../../asset/image/stripe.png";
import "../../../asset/css/font.css";

import Invoice from '../../../component/invoice/invoice';
import { binaryCode } from '../../../component/invoice/ambitfontBinary';
import Testing from './testing';

const Billing = (props) => {
    const reportTemplateRef = useRef(null);

    const [billingData, setBillingData] = useState([]);
    const [gatewayData, setGatewayData] = useState({});
    const [billingAnalyticData, setBillingAnalyticData] = useState({});
    const [downloadData, setDownloadData] = useState({});

    // const [detailView, setDetailView] = useState(null);
    const [isProcess, setIsProcess] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);

    const navigate = useNavigate();
    const [paginationData, setPaginationData] = useState({ page: 1, limit: 10 });
    const api = useHttp();
    const onChange = (date, dateString) => {
        setPaginationData({ page: 1, limit: 10, month: dateString });
    };
    const stripePromise = loadStripe('pk_test_51PLNFSSEYpDjP9THZnWYr9JhWKYU2UdqjTymGp0z1Dl1Z8LvVLt3A601RpHLDyywDiuE4xqMb1R3UnmCjWG444mL00nNvBkGD3');
    const options = {
        // passing the client secret obtained from the server
        clientSecret: 'sk_test_51PLNFSSEYpDjP9TH5O8unJukO36cFZZLNZ5xDQUHL4zydgyTUThzTQcxZDTUD1tKkbDajAt0imQ1PMrTQqvdIxOb00keRd3KGp',
    };
    // let totaolDueAmount = 0;
    // let creditRemaining = 0;
    // let totalGST = 0;
    useEffect(() => {
        api?.sendBulkRequest(
            [CONSTANTS.API.billing.getbillAnalytic, CONSTANTS.API.billing.getGatewayConfig],
            (res) => {
                const payment = {};
                res?.[1]?.data?.data?.rows?.map((el) => {
                    payment[el?.name] = el?.value;
                });
                setGatewayData(payment);
                setBillingAnalyticData({
                    totalDuoAmount: res?.[0]?.data?.data?.[0]?.totalUnpaidAmount,
                    totalPaidAmount: res?.[0]?.data?.data?.[0]?.totalPaidAmount,
                    totalPayBills: res?.[0]?.data?.data?.[0]?.totalPaidBills,
                    totalDueBills: res?.[0]?.data?.data?.[0]?.totalUnpaidBills,
                    totalRemainingActiveCredit: res?.[0]?.data?.creditData?.[0]?.totalRemainingActiveCredits,
                    totalRemainingInactiveCredits: res?.[0]?.data?.creditData?.[0]?.totalRemainingInactiveCredits,
                });
                // totaolDueAmount = res?.data?.[0]?.totalUnpaidAmount;
                // creditRemaining = res?.creditData?.[0]?.totalRemainingActiveCredits;
            },
        );
    }, [refresh]);
    useEffect(() => {
        api?.sendRequest(
            CONSTANTS.API.billing.get,
            (res) => {
                setBillingData(res?.data);
                // totalDueAmountWithGST = res?.data?.[0]?.CGST;
            },
            {
                ...paginationData,
            },
        );
    }, [paginationData, refresh]);
    const handleChangePage = (page, pageSize) => {
        setPaginationData((pr) => ({ ...pr, page, limit: pageSize }));
    };

    useEffect(() => {
        if (!localStorage.getItem("orderId") || isProcess === null) return;
        // getPaymentStatusHandler();
        const fetchData = () => {
            getPaymentStatusHandler();
        };

        const intervalId = setInterval(() => {
            fetchData();
        }, 10000); // 10 seconds in milliseconds

        // Clear the interval when the component unmounts or when orderId is not present anymore
        return () => clearInterval(intervalId);
    }, [isProcess]);

    const paymentResponseHandler = (data = {}) => {
        setIsProcess(data);
        getPaymentStatusHandler();
        // console.log(data);
        // api?.sendRequest(
        //     apiGenerator(CONSTANTS.API.payment.getPaymentSatus, { dataId: data?.orderCreationId }),
        //     (res) => {
        //         console.log(res);
        //         if (res?.data?.count > 0) {
        //             const findSuccess = res?.data?.items?.find((el) => el?.id === data?.razorpayPaymentId);
        //             if (findSuccess) {
        //                 setIsProcess(false);
        //                 localStorage.removeItem("orderId");
        //                 setRefresh((pr) => !pr);
        //             } else {
        //                 setIsProcess(false);
        //                 localStorage.removeItem("orderId");
        //             }
        //         } else if (res?.data?.count === 0) {
        //             // // If count is 0, start fetching data every 10 seconds
        //             // const intervalId = setInterval(() => {
        //             //     getPaymentStatusHandler();
        //             // }, 10000); // 10 seconds in milliseconds

        //             // // Clear the interval when count becomes greater than 0 or when orderId is not present anymore
        //             // return () => clearInterval(intervalId);
        //         }
        //     },
        // );
    };
    const getPaymentStatusHandler = () => {
        const oId = localStorage.getItem("orderId");
        if (oId) {
            api?.sendRequest(
                apiGenerator(CONSTANTS.API.payment.getPaymentSatus, { dataId: oId }),
                (res) => {
                    if (res?.data?.count > 0) {
                        const findSuccess = res?.data?.items?.find((el) => el?.status === "captured");
                        if (findSuccess) {
                            setIsProcess(null);
                            localStorage.removeItem("orderId");
                            setRefresh((pr) => !pr);
                            notification.success({
                                message: "Success",
                                description: "Payment Successful",
                            });
                        } else {
                            setIsProcess(null);
                            localStorage.removeItem("orderId");
                        }
                    }
                },
            );
        }
    };

    const checkoutHandler = () => {
        // const res = {
        //     success: "success",
        //     data: {
        //         id: "order_OAP2wPfKXqOM6p",
        //         entity: "order",
        //         amount: 10000,
        //         amount_paid: 0,
        //         amount_due: 10000,
        //         currency: "INR",
        //         receipt: null,
        //         offer_id: null,
        //         status: "created",
        //         attempts: 0,
        //         notes: {
        //             userId: "75cf26f8-99be-4a26-8937-3fec605cb318",
        //             bills: "3",
        //         },
        //         created_at: 1715696085,
        //     },
        //     razorpay_key: "kpHeR5VCw49%J9c$CI6IpX",
        // };

        // const option = rzpayObj(res, props?.user_details, logo, paymentResponseHandler);

        // const rzp = new window.Razorpay(option);
        // rzp?.open();
        api?.sendRequest(
            CONSTANTS.API.payment.addMoney,
            (res) => {
                const option = rzpayObj(res, props?.user_details, logo, paymentResponseHandler);
                localStorage.setItem("orderId", res?.data?.id);

                const rzp = new window.Razorpay(option);
                rzp?.open();
            },
            {
                userId: props?.user_details?.id,
            },
        );
    };

    const invoiceHandler = (billDetail, subTotal, TotalAmount, gstAmount, gstPercentage) => {
        api.sendRequest(apiGenerator(CONSTANTS.API.invoice.getInvoice, { dataId: billDetail?.id }), (res) => {
            setDownloadData({
                ...billDetail, id: res?.data?.id, subTotal, userName: res?.data?.user?.username, email: res?.data?.user?.email, TotalAmount, gstAmount, gstPercentage,
            });
            setTimeout(() => {
                handleGeneratePdf();
            }, 2000);
        });
    };

    const handleGeneratePdf = () => {
        const doc = new jsPDF({
            orientation: "1",
            // format: [1240, 1754],
            format: [1240, 1754],
            unit: "px",
        });
        // doc.addFileToVFS("Ambit-Regular.ttf", binaryCode);
        // doc.addFont("Ambit-Regular.ttf", "Ambit", "normal", 400, binaryCode);
        // doc.setFont("Ambit");
        // console.log(doc.getFontList());

        // Adding the fonts.
        doc.setFont("Inter-Regular", "normal");

        doc.html(reportTemplateRef.current, {
            async callback(doc) {
                await doc.save("document");
            },
        });
    };
    console.log(billingAnalyticData?.totalDuoAmount);
    return (
        <>
            <div className="hidden">
                <div className="" id="builty" ref={reportTemplateRef}>
                    <Invoice downloadData={downloadData} />
                </div>
            </div>
            <Card title="Billing Summary">
                <Row gutter={[16, 16]}>
                    <Col span={24} md={14}>
                        <Row gutter={[16, 16]}>
                            <Col span={24} md={10}>
                                <p className="text-grayColor">
                                    Total Due
                                </p>
                                <p className="font-semibold">
                                    ₹
                                    {' '}
                                    {(billingAnalyticData?.totalDuoAmount || 0)?.toLocaleString('en-IN')}
                                    <span>
                                        {" "}
                                        (GST Include)
                                    </span>
                                </p>
                            </Col>
                            <Col span={24} md={6}>
                                <p className="text-grayColor">
                                    Credit Remaining
                                </p>
                                <p className="font-semibold gap-4">
                                    <span className="font-semibold mr-4">
                                        ₹
                                        {" "}
                                        {(billingAnalyticData?.totalRemainingActiveCredit || 0)?.toLocaleString('en-IN')}
                                    </span>
                                </p>
                            </Col>
                            <Col span={24} md={8} className="flex items-end">
                                <p className="text-grayColor" />
                                <p className="font-semibold gap-4">
                                    <span className="text-primaryBg cursor-pointer" onClick={() => navigate("/app/creditsummary")}>View Credit History</span>
                                </p>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="flex  justify-end" span={24} md={10}>
                        {+gatewayData?.razorpay === 1 || +gatewayData?.stripe === 1 ? (
                            <Button
                                className="w-full md:w-auto"
                                onClick={() => {
                                    setIsOpenModal((pr) => !pr);
                                    // window.history.pushState({}, "fgf", "/app/billing?checkout=true");
                                    // window.location.href = "/app/billing?checkout=true";
                                }}
                                type="primary"
                            >
                                Pay Now
                            </Button>
                        ) : null}
                    </Col>
                </Row>
            </Card>
            <Modal
                title={<p className="text-black flex items-center justify-center">Choose Your Payment gateway</p>}
                open={isOpenModal}
                centered
                onCancel={() => setIsOpenModal((pr) => !pr)}
                // closable={false}
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ style: { display: "none" } }}
            >
                <div className="flex  items-center justify-center py-5 gap-3">
                    {+gatewayData?.razorpay === 1 && (
                        <Button onClick={checkoutHandler} className="py-0 px-2 razorpay-btn  bg-blue-500 hover:!bg-blue-700 text-white font-medium  rounded transition duration-300" type="primary">
                            <div className="flex items-center  gap-2">
                                <div className="bg-white rounded">
                                    <Image preview={false} width={30} src={razorpay} alt="Razorpay Logo" class="razorpay-logo h-6 mr-2" />

                                </div>
                                <span>Pay with Razorpay</span>
                            </div>
                        </Button>
                    )}
                    {/* <Divider plain>OR</Divider> */}
                    {+gatewayData?.stripe === 1 && <Testing userId={props?.user_details?.id} />}
                    {/* <Button className="w-full md:w-auto" onClick={checkoutHandler} type="primary">
                        Pay with Stripe
                    </Button>
                    <Elements stripe={stripePromise} options={options}>
                        <PaymentElement />
                    </Elements> */}
                </div>
            </Modal>
            <Modal
                title={<p className="text-black flex items-center justify-center">Payment</p>}
                open={isProcess !== null}
                centered
                closable={false}
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ style: { display: "none" } }}
            >
                <Result
                    status="success"
                    icon={(
                        <Spin tip="Payment processing" size="large">
                            <div className="content h-20" />
                        </Spin>
                    )}
                    subTitle={(
                        <div className="flex flex-col items-center">
                            <div className="max-w-lg mt-2 text-red-400">
                                Please do not refresh the page while payment is processing.
                            </div>
                        </div>
                    )}
                />
            </Modal>
            <div className="my-5 text-base flex justify-between items-center font-semibold">
                <p>Billing History</p>
                <div className="flex gap-4">
                    <Pagination
                        className="flex justify-center  items-center"
                        current={paginationData?.page}
                        total={billingData?.count}
                        pageSize={paginationData?.limit}
                        onChange={handleChangePage}
                        pageSizeOptions={[10, 20, 30]}
                    />
                    <DatePicker onChange={onChange} picker="month" />
                </div>
            </div>

            {billingData?.rows?.length > 0
                ? billingData?.rows?.map((el) => (
                    <BillingCard billingData={el} setDownloadData={setDownloadData} invoiceHandler={invoiceHandler} />
                ))
                : <Empty description={<p className="text-center text-2xl text-slate-500">No any bill history</p>} />}
        </>
    );
};

export default Billing;
