/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-param-reassign */
/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */

import { Button, Form, Input, Spin, Typography } from "antd";
import React, { useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";
// import { useNavigate } from "react-router-dom";
import TooltipCustom from "../../../../component/common/Tooltip";
import { validateDomain } from "../../../../util/functions";
// import { appRoot } from "../../../../util/constant/CONSTANTS";
// import LoadingModal from "../../../../component/loading_modal";

const { Text } = Typography;
const antIcon = <LoadingOutlined style={{ fontSize: 15 }} spin />;

const OnBoardForm = ({ onOtpFormHandler, sendOtpAPI, user_details }) => {
  // const [submitData, setSubmitData] = useState({});
  const [form] = Form.useForm();
  // const naviagte = useNavigate();

  useEffect(() => {
    form.setFieldsValue(user_details);
  }, [user_details]);
  return (
    <div className="mt-9 w-64 sm:w-80">
      <Text>
        Welcome aboard! To personalize your experience, we&apos;d love to know
        your chosen username. Enter it below and let&apos;s get started!
      </Text>
      <Form
        form={form}
        className="mt-10 w-64 sm:w-80 flex-col items-center"
        name="basic"
        onFinish={(payload) => {
          if (user_details?.username === null) {
            // eslint-disable-next-line dot-notation
            payload["isFirstTime"] = 1;
          }
          onOtpFormHandler(payload);
          localStorage.setItem("isDomainSkip", 1);
        }}
        autoComplete="off"
      >
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your Name!",
            },
          ]}
        >
          <Input
            className="w-full"
            placeholder="Your Name"
            defaultValue={user_details?.username}
          />
        </Form.Item>
        <Form.Item
          name="subDomain"
          rules={[
            { validator: validateDomain, },
          ]}
        >
          <Input
            className="w-full"
            placeholder="Your Organization URL"
            required={false}
            // defaultValue={user_details?.subDomain}
            suffix={
              <>
                <p>.wooffer.io </p>
                <TooltipCustom title="Please enter a subdomain using lowercase letters only" /></>
            }
          />
        </Form.Item>
        <Form.Item className="flex justify-center">
          <Button className="mr-4" disabled={sendOtpAPI} type="primary" htmlType="submit">
            {sendOtpAPI && (
              <Spin size="small" indicator={antIcon} className="me-2" />
            )}
            Let&apos;s Start
          </Button>
          <Button
            disabled={sendOtpAPI}
            // htmlType="submit"
            onClick={() => {
              const dd = form?.getFieldValue();
              const payload = {};
              // eslint-disable-next-line dot-notation
              payload["username"] = dd?.username;
              if (user_details?.username === null) {
                // eslint-disable-next-line dot-notation
                payload["isFirstTime"] = 1;
              }
              onOtpFormHandler(payload);
              localStorage.setItem("isDomainSkip", 1);
              // localStorage.setItem("isDomainSkip", 1);
              // naviagte(appRoot);
            }}
          >
            {/* {sendOtpAPI && (
              <Spin size="small" indicator={antIcon} className="me-2" />
            )} */}
            Skip
          </Button>
        </Form.Item>
      </Form>
      <p className="text-sm mt-0  text-[#f5222d] transition-opacity duration-500">
        Note: You will receive an email once your domain is registered and is live! This process might take a few hours. You can click on &apos;Let&apos;s Start&apos; button to continue analyzing your projects on app.wooffer.io
      </p>
      {/* ==========================future Work For Domain Creation Time taken 20 to 25 sec  so Dont Remove it============================= */}
      {/* <LoadingModal visible={sendOtpAPI} submitData={submitData} /> */}
    </div>
  );
};

export default OnBoardForm;
