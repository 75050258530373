import { Alert } from "antd";
import React from "react";

const disableStyle = {
  backgroundColor: "#eeeeee",
  border: "1px solid #e8e8e8",
  color: "#919191",
};
const Filter = ({
  disable = false,
  message = "",
  type = "",
  onClick = () => {},
  icon,
}) => {
  return (
    <Alert
      className="cursor-pointer"
      style={disable ? {} : disableStyle}
      message={message}
      type={type}
      onClick={onClick}
      showIcon
      icon={icon}
    />
  );
};

export default Filter;
