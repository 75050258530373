/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import { Button } from "antd";
import { CSVLink } from "react-csv";

function CustomButton(props) {
  switch (props.type) {
    case "icon":
      return (
        <Button
          className="btn-dashboard-icon textcolor"
          type="primary"
          icon={props.icon}
          size="large"
          style={{ margin: "0px 5px" }}
          onClick={props.action}
          {...props.ButtonDefault}
        />
      );

    case "linkicon":
      return (
        <CSVLink data={props?.data ?? []}>
          <Button
            className="btn-dashboard-icon textcolor"
            type="primary"
            // ghost
            icon={props.icon}
            // size="large"
            style={{ margin: "0px 5px" }}
            onClick={props.action}
            {...props.ButtonDefault}
          >
            {props?.name}
          </Button>
        </CSVLink>
      );
    case "asynclinkicon":
      return (
        <CSVLink asyncOnClick={true} data={props?.data ?? []} onClick={props.action}>
          <Button
            className="btn-dashboard-icon textcolor"
            type="primary"
            // ghost
            icon={props.icon}
            // size="large"
            style={{ margin: "0px 5px" }}
            // onClick={props.action}
            {...props.ButtonDefault}
          >
            {props?.name}
          </Button>
        </CSVLink>
      );
    default:
      return (
        <Button
          className="btn-dashboard textcolor"
          type="primary"
          size="large"
          onClick={props.action}
          style={{ margin: "0px 5px" }}
          {...props.ButtonDefault}
        >
          {props.name}
        </Button>
      );
  }
}

export default CustomButton;

CustomButton.defaultProps = {
  action: () => { },
  name: "",
};
