import React from "react";
import {
  Button, Form, Input, Spin,
} from "antd";

import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 15 }} spin />;
const LoginForm = ({ sendOtpAPI, onEmailFormHandler }) => {
  return (
    <Form
      className="mt-14 w-60"
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onEmailFormHandler}
      autoComplete="off"
    >
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: "Please input your email id!",
          },
        ]}
      >
        <Input placeholder="Email ID" />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button
          disabled={sendOtpAPI.isLoading}
          type="primary"
          htmlType="submit"
        >
          {sendOtpAPI.isLoading && (
            <Spin size="small" indicator={antIcon} className="me-2" />
          )}
          Continue
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
