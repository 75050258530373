/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-debugger */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable object-curly-newline */
import { useParams } from 'react-router-dom';
import { Button, Col, Form, Modal, Result, Row, Select, Spin, Steps, notification } from 'antd';
import React, { useState } from 'react';
import useHttp from '../../../../hooks/use-http';
import { apiGenerator } from '../../../../util/functions';
import CONSTANTS from '../../../../util/constant/CONSTANTS';
import TrelloaddIntegration from './modalStep/trelloaddIntegration';
import Label from '../../../../component/common/Label';

const TrelloModal = ({ projectDetails, setOpen, setRefresh, open }) => {
  const API = useHttp();
  const [form] = Form.useForm();
  const [defaultData, setDefaultData] = useState(null);
  const [addIntegration, setAddIntegration] = useState(null);
  const [trelloDetail, setTrelloDetail] = useState([]);
  const [current, setCurrent] = useState(0);
  const { projectId } = useParams();

  const steps = [
    {
      title: "Create Identifier",
      content: API.isLoading ? (
        <Spin tip="Loading" size="large">
          <div className="content h-60" />
        </Spin>
      ) : (
        <TrelloaddIntegration form={form} defaultData={defaultData} />
      ),
    },
    {
      title: "select Trello card",
      content: API.isLoading ? (
        <Spin tip="Loading" size="large">
          <div className="content h-60" />
        </Spin>
      ) : (
        <>
          <Row gutter={[24, 24]} className="mt-8">
            <Col sm={24}>
              <Label required={true}>
                Trello Card
              </Label>
              <Select
                showSearch
                className="w-full"
                placeholder="Select a Trello Card"
                optionFilterProp="children"
                onChange={(value) => setAddIntegration((pr) => { return { ...pr, trelloCardAddListID: value }; })}
                // onSearch={onSearch}
                // filterOption={filterOption}
                options={
                  trelloDetail?.map((el) => {
                    return {
                      label: el?.name,
                      value: el?.id,
                    };
                  })
                }
              />
            </Col>
            <Col sm={24}>
              <Label required={true}>
                Trello Resolved Card
              </Label>
              <Select
                showSearch
                className="w-full "
                placeholder="Select a Trello Resolve Card"
                optionFilterProp="children"
                onChange={(value) => setAddIntegration((pr) => { return { ...pr, trelloCardResolveID: value }; })}
                // onSearch={onSearch}
                // filterOption={filterOption}
                options={
                  addIntegration?.trelloCardAddListID ? trelloDetail?.filter((ell) => ell?.id !== addIntegration?.trelloCardAddListID)?.map((el) => {
                    return {
                      label: el?.name,
                      value: el?.id,
                    };
                  }) : []
                }
              />
            </Col>
          </Row>
        </>
      ),
    },

    {
      title: "Done",
      content: (
        <Result
          status="success"
          title="Trello Integrated Successfully!"
          subTitle={(
            <div className="flex flex-col items-center">
              <div className="max-w-lg">
                Congratulations! Wooffer is now linked with your Trello board! A
                ticket will be created as soon as any uncaught exception occurs
                or the server malfunctions.
              </div>
              <div className="max-w-lg mt-2 text-red-400">
                Please confirm if a default ticket has been created in the
                linked Trello board. If not, please unlink and then relink the
                Trello board with the same URL.
              </div>
            </div>
          )}
        />
      ),
    },
  ];

  const next = (payload) => {
    // api call for add
    // if(payload?.trelloBoardID  && payload?.trelloAPIKey && payload?.trelloAPIToken)
    const api = {
      type: "GET",
      endpoint: `/project/get-trello-list?boardId=${payload?.trelloBoardID}&apiKey=${payload?.trelloAPIKey}&apiToken=${payload?.trelloAPIToken}`,
    };
    // api.endpoint = `${api.endpoint}?boardId=${payload?.trelloBoardID}&apiKey=${payload?.trelloAPIKey}&apiToken=${payload?.trelloAPIToken}`;
    API.sendRequest(api, (res) => {
      if (res?.status === "success") {
        setTrelloDetail(res?.data);
        setAddIntegration(payload);
        setCurrent(current + 1);
        setDefaultData(null);
      }
    });
  };
  const secNextIntegration = () => {
    addIntegration?.trelloCardAddListID && addIntegration?.trelloCardResolveID ? API.sendRequest(
      apiGenerator(CONSTANTS.API.integration.addTrelloPlugin),
      (res) => {
        if (res?.status === "success") {
          setCurrent(current + 1);
        }
      },
      { ...addIntegration },
      "Trello integrated successfully !!!",
    ) : notification.error({ description: "Please Select Card!!" });
  };
  const doneIntegration = () => {
    // jiraIntegrated add this key

    API.sendRequest(
      apiGenerator(CONSTANTS.API.integration.updatePlugin, {
        dataId: projectId,
      }),
      (res) => {
        if (res?.status === "success") {
          setAddIntegration(null);
          setOpen(null);
          setCurrent(0);
          setRefresh((pr) => !pr);
        }
      },
      { ...addIntegration, isTrelloIntegrated: true },
      "Trello integrated successfully !!!",
    );
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  return (
    <>
      <Modal
        open={!projectDetails?.isTrelloIntegrated && open === "trello"}
        title={
          <p className="text-2xl mb-10 text-slate-500">Add Trello Details</p>
        }
        // okText="Submit"
        width={700}
        closable={addIntegration == null && current === 0}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        className="form-modal-title"
        onCancel={() => {
          if (addIntegration !== null || current !== 0) return;
          setOpen(null);
        }}
      >
        <>
          <Steps current={current} items={items} className="mt-4 px-10" />
          <div className="mx-10">
            {steps[current].content}
          </div>

          <div
            style={{
              marginTop: 24,
            }}
            className="flex justify-center"
          >
            <Button
              className="textcolor"
              type="primary"
              onClick={() => {
                if (current === steps.length - 3) {
                  form
                    .validateFields()
                    .then((values) => {
                      form.resetFields();
                      next(values);
                      setDefaultData(values);
                      //   onCreate(values);
                    })
                    .catch(() => {
                      // console.log("Validate Failed:", info);
                    });
                } else if (current === steps.length - 2) {
                  secNextIntegration();
                } else if (current === steps.length - 1) {
                  doneIntegration();
                }
              }}
            >
              {current === steps.length - 1 ? "Done" : "Next"}
            </Button>
            {/* {current === steps.length - 1 && (
              <Button
              className="textcolor"
                type="primary"
                onClick={() => {
                  doneIntegration();
                }}
              >
                Done
              </Button>
            )} */}
          </div>
        </>
      </Modal>
    </>
  );
};
export default TrelloModal;
