import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

let lastPath = null;
const useCheckInternet = (redirectUrl = "") => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleOnlineStatusChange = () => {
    const isInternetOn = window.navigator.onLine;
    if (isInternetOn) {
      try {
        if (lastPath != null) navigate(lastPath);
      } catch (error) {
        console.log(error);
      }
    } else {
      lastPath = location?.pathname;

      navigate(redirectUrl);
    }
  };
  useEffect(() => {
    window.addEventListener("offline", handleOnlineStatusChange);
    window.addEventListener("online", handleOnlineStatusChange);
  }, []);
  return {};
};

export default useCheckInternet;
