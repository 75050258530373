/* eslint-disable no-debugger */
/* eslint-disable brace-style */
/* eslint-disable comma-dangle */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from "react";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import {
  Card,
  Col,
  Divider,
  Image,
  Row,
  Spin,
  Typography,
  notification,
} from "antd";
import { FcGoogle } from "react-icons/fc";
import Title from "antd/es/typography/Title";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
// import { Helmet } from "react-helmet";
import CONSTANTS, { appRoot, distributeRoot } from "../../../util/constant/CONSTANTS";
import { deleteAuthDetails, getAuthToken, setAuthDetails } from "../../../util/API/authStorage";
import useHttp from "../../../hooks/use-http";
import logo from "../../../asset/logos/icon.svg";
import { auth } from "../../../util/firebase-config";
import useCheckInternet from "../../../hooks/use-checkInternet";
import LoginForm from "./component/login-form";
import OtpForm from "./component/otp-form";
import { removeSpacesFromObject } from "../../../util/functions";

const { Text } = Typography;
let otpToken = "";
const LogIn = () => {
  const [loading, setLoading] = useState(false);
  const [otpSend, setOtpSend] = useState(false);
  const [userEmail, setUserEmail] = useState(null);

  const API = useHttp();
  const sendOtpAPI = useHttp();
  const location = useLocation();
  const urlParams = new URLSearchParams(location?.search);

  useCheckInternet("/noInternet");

  auth.useDeviceLanguage();
  const navigate = useNavigate();
  useEffect(() => {
    if (urlParams.get("token")) {
      setAuthDetails(urlParams.get("token"));
      window.location.assign(appRoot);
    }
    if (urlParams.get("userRole")) {
      deleteAuthDetails();
      localStorage.clear();
    }

    if (urlParams.get("userToken")) {
      try {
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}${CONSTANTS.API.auth.getMe.endpoint}`,
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${urlParams.get("userToken")}`,
              },
            }
          )
          .then(() => {
            setAuthDetails(urlParams.get("userToken"));
            window.location.assign(`${appRoot}`);
          })
          .catch((e) => {
            console.log(e);
          });
      } catch (error) {
        console.log(error);
      }
    }
    const isLogin = getAuthToken() !== undefined && getAuthToken() !== null;

    if (isLogin) {
      localStorage.getItem("userRole") === "Distributer" && !location?.pathname?.includes("distribute") ? navigate(distributeRoot) : navigate(appRoot);
    }
  }, []);

  const handleGooglClick = async () => {
    const provider = new GoogleAuthProvider();
    provider.addScope("email");
    try {
      setLoading(true);
      const result = await signInWithPopup(auth, provider);
      if (result && result.user && result.user.accessToken) {
        const { user } = result;
        const payload = {
          firebase_token: user?.accessToken,
        };
        localStorage.setItem("ItemToken", user.accessToken);
        API.sendRequest(
          CONSTANTS.API.auth.login,
          (res) => {
            if (res?.status === 200) {
              setAuthDetails(res?.token);
              localStorage.setItem("userRole", res?.role);
              // window.location.assign("/");

              window.location.assign(appRoot);
            }
          },
          payload,
          ""
        );
      }
    } catch (error) {
      console.error("Google Login Error:", error);
      setLoading(false);
    }
  };

  const onEmailFormHandler = (values) => {
    setUserEmail(values?.email?.trim());

    sendOtpAPI.sendRequest(
      CONSTANTS.API.auth.loginWithEmail,
      (res) => {
        if (res?.status === "success") {
          otpToken = res?.token;
          setOtpSend(true);
        }
      },
      removeSpacesFromObject(values),
      ""
    );
  };

  const onOtpFormHandler = (payload) => {
    setLoading(true);
    (async () => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}${CONSTANTS.API.auth.verify.endpoint}`,
          { otp: +payload?.otp },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${otpToken}`,
            },
          }
        );
        setAuthDetails(res?.data?.token);
        localStorage.setItem("userRole", res?.data?.role);
        window.location.assign(appRoot);
        // window.location.assign("/");
      } catch (error) {
        setLoading(false);
        notification.error({
          message: error?.response?.data?.message,
          duration: "3",
        });
      }
    })();
  };

  return (
    <>
      {/* <Helmet>
          <link
            rel="shortcut icon"
            href="icon.svg"
          />
        </Helmet> */}
      <Row className="flex-col md:flex-row justify-center items-center w-screen h-full gap-5  flex-nowrap fixed">
        <Col>
          <Image
            src={logo}
            alt="Woo"
            preview={false}
            width={300}
            className="p-5"
          />
        </Col>

        <Card className={`w-72 lg:w-80 flex justify-center ${otpSend ? "h-96" : "h-80"}`}>
          <div
            style={{
              background: "#ff743d",
              padding: "10px 12px 5px 12px",
              position: "absolute",
              top: "0",
              borderBottomLeftRadius: "8px",
              borderBottomRightRadius: "8px",
            }}
          >
            <Title level={5} style={{ color: "#fff" }}>
              Let&apos;s get started
            </Title>
          </div>
          {loading ? (
            <div className="mt-40 w-60">
              <Spin tip="Loading..." size="large">
                <div className="content" />
              </Spin>
            </div>
          ) : otpSend ? (
            <OtpForm
              onOtpFormHandler={onOtpFormHandler}
              sendOtpAPI={sendOtpAPI}
              userEmail={userEmail}
            />
          ) : (
            <>
              <LoginForm
                sendOtpAPI={sendOtpAPI}
                onEmailFormHandler={onEmailFormHandler}
              />

              <Divider>
                <Text disabled>Or</Text>
              </Divider>

              <button
                style={{
                  cursor: "pointer",
                  padding: "10px 15px",
                  borderRadius: "8px",
                  display: "inline-flex",
                  width: "250px",
                  gap: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                  alignSelf: "center",

                  background: "rgb(243, 243, 243)",
                  border: "1px solid rgb(220, 220, 220)",
                }}
                onClick={() => {
                  handleGooglClick();
                }}
              >
                <FcGoogle className="tw-w-[26px] tw-h-[26px]" />{" "}
                <Text>Login With Google</Text>
              </button>
            </>
          )}
        </Card>
      </Row>
    </>
  );
};
export default LogIn;
