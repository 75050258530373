/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unsafe-optional-chaining */
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import {
  Badge, Card, Col, Empty, Row, Typography,
} from "antd";
import { useParams } from "react-router-dom";
import CoreCard from "../card/core-card";
import CONSTANTS from "../../util/constant/CONSTANTS";

import {
  analyzeRequests,
  formatAmount,
  getMethodBadgeColor,
} from "../../util/functions";
import Progress from "../chart/progress";

const { Text } = Typography;
const CoreList = (props) => {
  const { environmentID } = useParams();
  const [CpuData, setCPUData] = useState({});
  let currentService = {};

  useEffect(() => {
    props.socket.on("systemData", (systemData) => {
      if (
        environmentID in systemData
        && "Process" in systemData[environmentID]
        && systemData[environmentID]?.Process?.[+CONSTANTS.PROCESS.id]
      ) setCPUData(systemData || {});
    });
  }, [environmentID]);
  if (CpuData?.[environmentID]) {
    currentService = CpuData?.[environmentID];
  }

  if (props?.mode === "process") {
    if (
      "CPU" in currentService
      && currentService.CPU !== undefined
      && "Core" in currentService?.CPU
    ) {
      currentService.CPU.Core = currentService?.Process?.[
        CONSTANTS.PROCESS.id
      ]?.map((current) => {
        return {
          id: current?.pid,
          usage: current?.cpu,
        };
      });
    }
  }
  const requestArray = CONSTANTS.Request.list?.filter(
    (el) => el.env === environmentID,
  );

  const resultArray = analyzeRequests(requestArray || []);
  const requestCount = resultArray.reduce((sum, ele) => {
    return sum + (ele?.count || 0);
  }, 0);
  return currentService?.CPU?.Core?.length > 0 ? (
    <Row gutter={[14, 14]}>
      <Col span={24} sm={24} md={12} lg={8} xl={6}>
        <Title type="secondary" level={5}>
          Top 10 API Usage (Last 30 minutes)
        </Title>
        <Card className="h-72 overflow-y-scroll">
          <Row className="justify-between items-center mb-2">
            <Title className="!m-0 !p-0 !ms-1 " level={5}>
              {" "}
              Request Count
              {" "}
            </Title>
            <Text type="secondary">
              {' '}
              {formatAmount(requestCount)}
            </Text>
          </Row>
          <div className="flex flex-col gap-4">
            {resultArray?.map((process, i) => (
              <div key={`${process?.originalUrl}-${i}`}>
                <Row className="justify-between items-center">
                  <Text
                    className="!m-0 !p-0 !ms-0 "
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      maxWidth: "90%",
                    }}
                  >
                    <Badge
                      count={process?.method}
                      size="small"
                      color={getMethodBadgeColor(process?.method)}
                    />
                    {" "}
                    {process?.originalUrl}
                  </Text>
                  <Text type="secondary">
                    {formatAmount(process?.count || 0)}
                  </Text>
                </Row>
                <Progress
                  height="5px"
                  progress={[
                    {
                      color: "#ff743d",
                      progress: `${+process?.percentage?.toFixed(0)}%`,
                    },
                  ]}
                />
              </div>
            ))}
          </div>

          {resultArray?.length === 0 && (
            <div className="w-full h-52 flex justify-center items-center">
              <Empty />
            </div>
          )}
        </Card>
      </Col>

      <Col span={24} sm={24} md={12} lg={16} xl={18} className="w-full">
        <Title type="secondary" level={5}>
          CPU Cores Usage
        </Title>
        <Row gutter={[14, 14]} className="w-full">
          {currentService?.CPU?.Core?.map((coresInfo, index) => (
            <Col
              style={{
                filter:
                  props.socket.connected
                    && props?.systems_status?.[environmentID]
                    && (props?.mode === "cpu"
                      || (currentService?.CPU && "cpu" in currentService?.CPU))
                    && Object.keys(currentService).length > 0
                    ? ""
                    : "grayscale(100%)",
              }}
              xs={24}
              sm={12}
              md={24}
              lg={12}
              xl={8}
              key={`coresInfo_${index}`}
            >
              <CoreCard {...coresInfo} />
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  ) : (
    <div />
  );
};

export default React.memo(CoreList);
