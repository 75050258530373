/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { AutoComplete, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

function CustomSearchBar({
  setKeyword = () => { },

  debounceTime = 500,
  isSearch = false,
}) {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [fetchTimeout, setFetchTimeout] = useState(null);
  const handleSearchChange = (value) => {
    clearTimeout(fetchTimeout);
    setSearchKeyword(value);
    const timeout = setTimeout(() => {
      if (isSearch) {
        setKeyword(value);
      }
    }, debounceTime);
    setFetchTimeout(timeout);
  };
  useEffect(() => () => {
    clearTimeout(fetchTimeout);
  }, [fetchTimeout]);
  const getNestedValue = (obj, path) => {
    let value = obj;
    for (let i = 0; i < path.length; i += 1) {
      value = value[path[i]];
      if (!value) {
        return undefined;
      }
    }
    return value;
  };
  return (
    <AutoComplete
      className="z-40"
      onChange={handleSearchChange}
      onClick={() => {
      }}
      value={searchKeyword}
      style={{ width: "100%" }}
    >
      <Input
        placeholder="Search..."
        prefix={<SearchOutlined className="text-gray-500" />}
      />
    </AutoComplete>
  );
}
export default CustomSearchBar;
