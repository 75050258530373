/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useRef, useState } from "react";
import {
  Button, Card, Col, Image, Row, Spin, Tooltip, Tour,
} from "antd";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { PlusOutlined } from "@ant-design/icons";
import ProgressBar from "../../../component/chart/progress";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import useHttp from "../../../hooks/use-http";
import ModalFormCreator from "../../../component/common/ModalFormCreator";
import {
  apiGenerator,
  getColor,
  getServiceRoute,
  isLogin,
} from "../../../util/functions";
import icons from "../../../asset/image/icons.png";

import tourStep1 from "../../../asset/image/tour/tour-step-1.png";
import ServiceCard from "../../../component/card/serviceCard";
import InfoIcon from "../../../component/common/info-icon";

const Dashboard = ({
  loading,
  systems_status,
  socket,
  plan = {},
  production_mode = false,
}) => {
  const { projectId } = useParams();

  const navigate = useNavigate();
  const [services, setServices] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [tour, setTour] = useState(-1);
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();

  const steps = [
    {
      title: "Create a new service instance",
      description: "Click Here to create a new wooffer service instance",
      cover: (
        <img
          style={{ background: "#ff743d33", borderRadius: "8px" }}
          alt="tour.png"
          src={tourStep1}
        />
      ),
      target: () => ref1.current,
    },
    {
      title: "Create service instance identifier",
      description:
        "Enter a unique service instance identifier to distinguish your service instance.",
      target: () => ref2.current,
    },
    {
      title: "Save service",
      description: "Click on submit to see create service instance.",
      target: () => ref3.current,
    },
  ];

  const API = useHttp();

  useEffect(() => {
    if (socket) socket.emit("homePage", {});
  }, []);

  const addServiceHandler = (value) => {
    if (CONSTANTS.API.service.add) {
      API.sendRequest(
        CONSTANTS.API.service.add,
        (res) => {
          setTour(-1);
          localStorage.setItem("tour", "Done");
          setRefresh((prev) => !prev);
          setIsModalOpen(false);
          if (res?.status === "success") {
            navigate(
              `${getServiceRoute({
                projectId,
                serviceID: res?.data?.id,
                environmentID: res?.serviceEnvironment?.id,
              })}/setup/1`,
            );
          }
        },
        {
          ...value,
          projectId,
          languageId: process.env.REACT_APP_NODE_JS,
        },
        "Add Data Successfully!!!",
      );
    }
  };

  const deleteTableData = (dataId) => {
    if (CONSTANTS.API.service.delete) {
      const DELETE_API_CALL = apiGenerator(CONSTANTS.API.service.delete, {
        dataId,
      });

      API.sendRequest(
        DELETE_API_CALL,
        () => {
          setRefresh((prev) => !prev);
        },
        {},
        "Delete Data Successfully!!!",
      );
    }
  };

  useEffect(() => {
    if (!isLogin()) return;
    const getProjectService = { ...CONSTANTS.API.service.get };
    getProjectService.endpoint += `?projectId=${projectId}`;
    API.sendRequest(getProjectService, (res) => {
      if (res?.status === "success") {
        setServices(res?.data?.rows);
        if ((!localStorage.getItem("tour")) && res?.data?.rows?.length === 0) {
          setTour(0);
        } else {
          localStorage.setItem("tour", "Done");
        }
      }
    });
  }, [refresh, projectId]);

  // useEffect(() => {
  // }, [services]);

  useEffect(() => {
    if (ref2?.current && isModalOpen) {
      setTour((prev) => (prev >= 0 ? 1 : -1));
    }
  }, [isModalOpen]);
  const progress = (services?.length / plan?.maxService) * 100;

  return (
    <div className="px50 py30">
      <div className="my-5">
        <Row className="flex content-center justify-center ">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            {API?.isLoading ? (
              <div style={{ padding: "72px" }}>
                <Spin tip="Loading" size="large" className="defaultColor">
                  <div className="content" />
                </Spin>
              </div>
            ) : (
              <>
                <Row
                  className="flex justify-center md:justify-start"
                  gutter={[16, 16]}
                >
                  <Col>
                    <Card
                      hoverable="true"
                      className="overflow-hidden"
                      bodyStyle={{
                        width: 300,
                        height: 180,
                      }}
                      ref={ref1}
                    >
                      <div
                        style={{
                          position: "absolute",
                          width: "101%",
                          left: "-2px",
                          top: "0",
                        }}
                      >
                        <ProgressBar
                          height="8px"
                          progress={[
                            {
                              color: getColor(progress),
                              progress: `${progress}%`,
                            },
                          ]}
                        />
                      </div>
                      <div
                        onClick={() => {
                          plan?.maxService > services?.length
                            && setIsModalOpen((prev) => !prev);
                        }}
                        className="flex justify-center items-center flex-col h-full cursor-pointer"
                      >
                        {(plan?.maxService > services?.length) ? (
                          <>
                            {" "}
                            <PlusOutlined className="text-3xl" />
                            <div className="mt-4 ">
                              Add Service
                              <Tooltip className="cursor-pointer" title="Service identifies/tracks your Server Data.">
                                <span className="mt-1 relative top-1">
                                  <InfoIcon color="#000000E0" />
                                </span>
                              </Tooltip>
                            </div>
                          </>
                        ) : (
                          <div className="flex flex-col gap-3 justify-center items-center">
                            Your service usage has exhausted
                            <Button
                              block
                              className="m-5 lg:m-1 textcolor"
                              type="primary"
                              onClick={() => {
                                navigate("/app/pricing");
                              }}
                            >
                              Upgrade
                            </Button>
                          </div>
                        )}
                      </div>
                    </Card>
                  </Col>

                  {services?.map((service) => {
                    return (
                      <Col key={service?.id}>
                        <ServiceCard
                          system={service}
                          deleteTableData={deleteTableData}
                          systems_status={systems_status}
                          loading={loading}
                          projectId={projectId}
                          production_mode={production_mode}
                        />
                      </Col>
                    );
                  })}
                </Row>
                <div className="">
                  <hr className="h-px mobile:mx-8 my-8 bg-gray-300" />

                  <Card
                    className=" py-2 border max-w-xl border-slate-400 bg-transparent"
                    bodyStyle={{}}
                  >
                    <Row gutter={[20, 20]}>
                      <Col className="" span={24} md={16}>

                        <div className="text-base font-semibold">
                          Wooffer service is a watchdog for your Project
                        </div>
                        <div
                          className="my-1"
                          style={{ color: " rgba(0, 0, 0, 0.45)" }}
                        >
                          Real-time CPU Surveillance for Unrivaled Reliability,
                          Shielding Your Service from Crashes!
                        </div>
                      </Col>

                      <Col span={0} md={6} className="mobile:hidden">
                        <div className="">
                          <Image
                            preview={false}
                            src={icons}
                            width={150}
                            alt="icons"
                            className=" h-full object-cover"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </div>
              </>
            )}
          </Col>
        </Row>
      </div>
      <Tour
        open={tour >= 0}
        onClose={() => {
          setTour(-1);
          localStorage.setItem("tour", "Done");
        }}
        arrow
        onFinish={() => []}
        steps={steps}
        current={tour}
        onChange={(tourCount) => {
          if (tour > tourCount) {
            setTour(tourCount);
          } else if (tourCount === 1) {
            setIsModalOpen(true);
          } else {
            setTour(tourCount);
          }

          if (tourCount === 0) {
            setIsModalOpen(false);
          }
        }}
      />
      <ModalFormCreator
        inputRef={ref2}
        open={isModalOpen}
        onCreate={addServiceHandler}
        onCancel={() => {
          setIsModalOpen((prev) => !prev);
        }}
        name="Add Service"
        menu="SERVICE_MODAL"
        buttonRef={ref3}
      />
    </div>
  );
};

export default Dashboard;
