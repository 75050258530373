import {
    Alert,
    Col, Row,
} from 'antd';
import React from 'react';
import Marquee from 'react-fast-marquee';
import moment from 'moment';

const Maintenance = ({ maintenance }) => {
    const stratDateTime = moment(maintenance?.startTime);

    const enddateTime = moment(maintenance?.endTime);
    // const endTime =  moment(maintenance?.endTime)?.format('h:mm A');
    let endDateTimeFormatted;

    if (stratDateTime?.isSame(enddateTime, 'day')) {
        // If start date and end date are the same, show only time
        endDateTimeFormatted = enddateTime.format('h:mm A');
    } else {
        // If start date and end date are different, show both date and time
        endDateTimeFormatted = enddateTime.format("dddd, MMMM D, YYYY h:mm A");
    }

    const maintencelines = `We're working hard to improve your experience! Our website will be temporarily down for maintenance from ${stratDateTime?.format('dddd, MMMM D, YYYY h:mm A')} to ${endDateTimeFormatted}. We'll be back soon!`;
    // [
    // `Our website is currently undergoing maintenance. We apologize for any inconvenience caused. Maintenance is scheduled from ${moment(maintenance?.startTime)?.format('dddd, MMMM D, YYYY h:mm A')} to ${moment(maintenance?.endTime)?.format('dddd, MMMM D, YYYY h:mm A')}.`,
    //     `Please excuse the interruption! Our website is undergoing scheduled maintenance from ${moment(maintenance?.startTime)?.format("dddd, MMMM D, YYYY h:mm A")} to ${moment(maintenance?.endTime)?.format('dddd, MMMM D, YYYY h:mm A')}. We appreciate your patience.`,
    // ];
    return (
        <>
            <Row>
                <Col span={24}>
                    <Alert
                        banner
                        message={(
                            <Marquee pauseOnHover gradient={false}>
                                {maintencelines}
                            </Marquee>
                        )}
                    />
                </Col>
            </Row>
        </>
    );
};

export default Maintenance;
