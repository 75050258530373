/* eslint-disable prefer-promise-reject-errors */
import React, { useState } from 'react';
import {
    Button, Col, Form, Input, Modal, Popconfirm, Row,
} from 'antd';
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import theme from '../../theme.json';
import CONSTANTS from '../../util/constant/CONSTANTS';
import useHttp from '../../hooks/use-http';

const OwenershipModal = ({ details, setDetails }) => {
    const [form] = Form.useForm();
    const [isbuttonDisable, setIsbuttonDisable] = useState({
        isTextValid: false,
        isVerifyTextValid: false,
    });
    const [newEmail, setNewEmail] = useState('');
    const api = useHttp();

    const footer = {
        footer: [

            <Popconfirm
                onConfirm={() => {
                    const TRANSFER_PROJECT_API = { ...CONSTANTS.API.project.transferOwnership };
                    api.sendRequest(TRANSFER_PROJECT_API, (res) => {
                        if (res?.status === 'success') {
                            setDetails(null);
                            window.location.assign("/app");
                        }
                    }, { email: newEmail, projectId: details?.id });
                }}
                key="submitConfirm"
                title="transfer"
                description={(
                    <p>
                        The ownership of the project&nbsp;
                        <span className="font-semibold">{details?.name}</span>
                        &nbsp;
                        if transferred to
                        <span className="ml-1 font-semibold">{newEmail}</span>
                    </p>
                )}
            >
                <Button
                    key="transfer"
                    className="w-full textcolor"
                    style={{
                        color: "#fff",
                        background: !(isbuttonDisable.isTextValid && isbuttonDisable.isVerifyTextValid) ? `${theme.token.colorPrimary}80` : theme.token.colorPrimary,
                    }}
                    type="primary"
                    disabled={!(isbuttonDisable.isTextValid && isbuttonDisable.isVerifyTextValid)}
                >
                    Transfer
                </Button>
            </Popconfirm>,

        ],
    };

    return (
        <Modal
            open={details !== null}
            title={(
                <p className="font-semibold flex items-center gap-1.5">
                    <FaArrowRightArrowLeft className="" />
                    Transfer Ownership of
                    {' '}
                    {details?.name}
                </p>
            )}
            okText="Delete"
            okButtonProps={{ style: { display: "none" } }}
            width={430}
            {...footer}
            className="form-modal-title"
            onCancel={() => {
                setDetails(null);
                form.resetFields();
                setIsbuttonDisable({
                    isTextValid: false,
                    isVerifyTextValid: false,
                });
            }}
        >

            <div className="mb-3 mt-5">
                <p className="font-medium mb-2">
                    {details?.showText}

                </p>
                <p className="bg-[#ff743d] text-sm rounded-[8px] px-2.5 py-1.5 text-[#fff]">
                    <span className="font-bold">Warning:</span>
                    This action is not reversible. Are you sure you want to proceed?
                </p>
            </div>
            <Form form={form}>

                <Row>
                    <Col span={24}>
                        <p className="font-medium">
                            Enter Project name&nbsp;
                            <span className="font-bold">{details?.name}</span>
                            &nbsp;
                            to continue
                        </p>
                    </Col>
                </Row>
                <Form.Item
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "",
                        },
                        {
                            validator: (_, value) => {
                                if (value === details?.text || value === details?.name) {
                                    setIsbuttonDisable((prev) => {
                                        return {
                                            ...prev,
                                            isTextValid: true,
                                        };
                                    });
                                    return Promise.resolve();
                                }

                                setIsbuttonDisable((prev) => {
                                    return {
                                        ...prev,
                                        isTextValid: false,
                                    };
                                });
                                return Promise.reject('Enter a valid Project Name');
                            },
                        },
                    ]}
                >
                    <Input placeholder="" />
                </Form.Item>
                <Row className="-mt-2.5">
                    <Col span={24}>
                        <p className="font-medium">
                            Please enter the new email address to transfer ownership to
                        </p>
                    </Col>
                </Row>
                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: "",
                        },
                        {
                            type: 'email',
                            message: "",
                        },
                        {
                            validator: (_, value) => {
                                if (!value || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                                    setIsbuttonDisable((prev) => {
                                        return {
                                            ...prev,
                                            isVerifyTextValid: true,
                                        };
                                    });
                                    setNewEmail(value);
                                    return Promise.resolve();
                                }
                                setIsbuttonDisable((prev) => {
                                    return {
                                        ...prev,
                                        isVerifyTextValid: false,
                                    };
                                });
                                return Promise.reject('Invalid Email');
                            },
                        },
                    ]}
                >
                    <Input placeholder="" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default OwenershipModal;
