/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
import React, { useEffect } from "react";
import { Card, Col, Image, Row, Spin } from "antd";
import Title from "antd/es/typography/Title";
import { useNavigate } from "react-router-dom";
import useHttp from "../../../hooks/use-http";
import logo from "../../../asset/logos/icon.svg";
import useCheckInternet from "../../../hooks/use-checkInternet";
import OnBoardForm from "./component/otp-form";
import { appRoot } from "../../../util/constant/CONSTANTS";

const OnBoard = ({
  update_profile = () => { },
  is_loading = false,
  user_details = {},
}) => {
  const API = useHttp();
  useCheckInternet("/noInternet");
  const navigate = useNavigate();
  // sandee SiV2 country
  useEffect(() => {
    // if (process.env.REACT_DOMAIN_NAVIGATION_ONOFF === 1) return;
    if (
      Object.keys(user_details).length > 0 &&
      (user_details?.username != null &&
        user_details?.username?.trim() !== "" && user_details?.subDomain != null &&
        user_details?.subDomain?.trim() !== "")) {
      localStorage.setItem("isDomainSkip", 1);
      navigate(appRoot);
    }
    if (!localStorage.getItem("isDomainSkip")) return;
    if (
      (Object.keys(user_details).length > 0 &&
        user_details?.username != null &&
        user_details?.username?.trim() !== "") && +localStorage.getItem("isDomainSkip") === 1
    ) {
      navigate(appRoot);
    }
  }, [user_details]);

  return (
    <Row className="flex-col md:flex-row justify-center items-center w-screen h-full gap-5  flex-nowrap fixed">
      <Col>
        <Image
          src={logo}
          alt="Wooffer"
          preview={false}
          width={300}
          className="p-5"
        />
      </Col>

      <Card className="sm:w-[380px] w-80 lg:w-[380px] flex justify-center ">
        <div
          style={{
            background: "#ff743d",
            padding: "10px 12px 5px 12px",
            position: "absolute",
            top: "0",
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
          }}
        >
          <Title level={5} style={{ color: "#fff" }}>
            Your Name
          </Title>
        </div>
        {API.isLoading ? (
          <div className="mt-40 w-60">
            <Spin tip="Loading..." size="large">
              <div className="content" />
            </Spin>
          </div>
        ) : (
          <OnBoardForm
            onOtpFormHandler={update_profile}
            sendOtpAPI={is_loading}
            user_details={user_details}
          />
        )}
      </Card>
    </Row>
  );
};
export default OnBoard;
