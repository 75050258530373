/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
    Button, Col, Pagination, Row,
} from "antd";
import moment from "moment";
import PropTypes from "prop-types";

import useHttp from "../../hooks/use-http";
import ModalFormCreator from "./ModalFormCreator";
import CRUDTable from "./CRUD-Table";
import CustomSearchBar from "./Custom-search";
import { apiGenerator, isLogin } from "../../util/functions";
import CONSTANTS from "../../util/constant/CONSTANTS";
// import CustomTable from "../../../../component/common/Custom-Table";
// import CONSTANTS from "../../../../util/constant/CONSTANTS";
// import useHttp from "../../../../hooks/use-http";
// import ModalFormCreator from "../../../../component/common/ModalFormCreator";
const CRUDComponent = React.memo((propUp) => {
    const {
        GET,
        CREATE,
        UPDATE,
        DELETE,
        isSearch = false,
        AddOnComponent = null,
        selectionOff = false,
        FILTERSORTKEY = {},
        maxPageCount = 90000,
        rowSelectionObj,
        props,
        reload = false,
    } = propUp;
    const [data, setData] = useState([]);
    const [Allfilter, setAllFilter] = useState(null);
    const [search, setSearch] = useState("");
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
        total: 0,
    });
    const [createOpen, setCreateOpen] = useState(false);
    const [updateOpen, setUpdateOpen] = useState(null);
    const [formData, setFormData] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const API = useHttp();

    useEffect(() => {
        if (!isLogin()) return;
        let QuaryParams = {
            limit: pagination.pageSize,
            page: pagination.current,
        };
        if (GET?.extraQuery) {
            QuaryParams = { ...QuaryParams, ...GET?.extraQuery };
        }
        if (Allfilter?.sort) {
            const sorter = { ...Allfilter?.sort };
            sorter.sortBy = FILTERSORTKEY?.[sorter?.sortBy] || sorter?.sortBy;
            QuaryParams = { ...QuaryParams, ...sorter };
        }
        if (Allfilter?.filter) {
            Allfilter?.filter?.map((el) => {
                QuaryParams = {
                    ...QuaryParams,
                    [`${FILTERSORTKEY?.[el[0]] || el[0]}`]: el[1]?.toString(),
                };
            });
        }
        if (search?.trim().length) {
            QuaryParams = { ...QuaryParams, search };
        }
        GET
            && GET?.API
            && API.sendRequest(
                apiGenerator(GET?.API, GET?.extraReplaceObject),
                (res) => {
                    setPagination((prevPagination) => {
                        return {
                            ...prevPagination,
                            total: res?.data?.totalRecords
                                ? res?.data?.totalRecords
                                : prevPagination.current > 1
                                    ? maxPageCount
                                    : 0,
                        };
                    });
                    let ResultData = res?.data?.rows ?? res?.data ?? res;
                    if (GET?.DataModifier) {
                        ResultData = GET?.DataModifier(ResultData, API, setRefresh);
                    }
                    ResultData = ResultData?.map((el, i) => {
                        const SingleRow = {
                            ...el,
                            key: el.id,

                        };
                        if (
                            DELETE
                            && DELETE?.API
                            && GET?.column?.findIndex(
                                (el) => el?.dataIndex === "deleteTableRow",
                            ) !== -1
                        ) {
                            SingleRow.deleteTableRow = {
                                id: el.id,
                                key: el.id,
                                message: DELETE?.confirm_message,
                                onClick: (key) => {
                                    const DeleteAPITableRow = { ...DELETE?.API };
                                    DeleteAPITableRow.endpoint = `${DeleteAPITableRow.endpoint}${key}`;
                                    API.sendRequest(
                                        DeleteAPITableRow,
                                        (res) => {
                                            // console.log(res);
                                            setData((prev) => prev.filter((item) => item.key !== key));
                                            DELETE?.setRefresh && DELETE?.setRefresh((prev) => !prev);
                                        },
                                        "",
                                        DELETE?.message,
                                    );
                                },
                            };
                        }
                        if (
                            UPDATE
                            && UPDATE?.API
                            && UPDATE?.modalFields
                            && GET?.column?.findIndex(
                                (el) => el?.dataIndex === "editTableRow",
                            ) !== -1
                        ) {
                            SingleRow.editTableRow = {
                                id: el.id,
                                key: el.id,
                                onClick: () => {
                                    setUpdateOpen({ ...SingleRow });
                                    setFormData({ ...SingleRow });
                                },
                            };
                        }
                        return { ...SingleRow };
                    });
                    // if (GET?.DataModifier) {
                    //   setData(GET?.DataModifier(ResultData, API, setRefresh));
                    // } else {
                    //   setData(ResultData);
                    // }
                    setData(ResultData);
                },
                QuaryParams,
            );
    }, [
        refresh,
        pagination.current,
        pagination.pageSize,
        Allfilter,
        search,
        reload,
        GET?.extraQuery,
    ]);

    const onCreate = (value, clear) => {
        const AllFieldsData = CONSTANTS.FORM_FIELD[UPDATE?.modalFields];
        if (CREATE && CREATE?.API && AllFieldsData) {
            let payload = payloadGenerator(
                value,
                AllFieldsData,
                CREATE?.isFormData,
            );

            if (CREATE?.payloadModifier) {
                payload = CREATE?.payloadModifier(payload, value);
            }

            API.sendRequest(
                CREATE?.API,
                () => {
                    setRefresh((prev) => !prev);
                    setCreateOpen(false);
                    CREATE?.setRefresh && CREATE?.setRefresh((prev) => !prev);
                    clear();
                },
                payload,
                CREATE?.message,
            );
        }
    };
    const onUpdate = (value, clear) => {
        const AllFieldsData = CONSTANTS.FORM_FIELD[UPDATE?.modalFields];
        if (UPDATE && UPDATE?.API && AllFieldsData) {
            let payload = payloadGenerator(
                value,
                AllFieldsData,
                UPDATE?.isFormData,
            );

            if (UPDATE?.payloadModifier) {
                payload = UPDATE?.payloadModifier(payload, formData);
            }

            const UpdateAPIEnd = { ...UPDATE?.API };
            UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${updateOpen?.id}`;
            API.sendRequest(
                UpdateAPIEnd,
                () => {
                    setUpdateOpen(null);
                    setFormData(null);
                    setRefresh((prev) => !prev);
                    UPDATE?.setRefresh && UPDATE?.setRefresh((prev) => !prev);
                    clear();
                },
                payload,
                UPDATE?.message,
            );
        }
    };

    return (
        <Row gutter={[16, 16]} className=" mt-6">
            {CREATE && CREATE?.API && CREATE?.modalFields && (
                <ModalFormCreator
                    loading={API.isLoading}
                    open={createOpen}
                    onCreate={onCreate}
                    onCancel={() => {
                        setCreateOpen(false);
                    }}
                    // menuFields={CREATE?.modalFields}
                    menu={CREATE?.modalFields}
                    formData={{}}
                    name={CREATE?.modaltitle || `Add `}
                    SubmitName="Submit"
                />
            )}
            {UPDATE && UPDATE?.API && UPDATE?.modalFields && (
                <ModalFormCreator
                    loading={API.isLoading}
                    open={updateOpen !== null}
                    onCreate={onUpdate}
                    onCancel={() => {
                        setUpdateOpen(null);
                        setFormData(null);
                    }}
                    // menuFields={UPDATE?.modalFields}
                    menu={UPDATE?.modalFields}
                    formData={formData}
                    name={UPDATE?.modaltitle || `Edit`}
                    SubmitName="Update "
                />
            )}
            {GET?.column?.length && (
                <>
                    {isSearch && (
                        <Col className="" span={24} sm={12} md={12} lg={8} xl={5} xxl={4}>
                            <CustomSearchBar
                                setKeyword={(v) => setSearch(v)}
                                isSearch={isSearch}
                            />
                        </Col>
                    )}
                    {CREATE && CREATE?.API ? (
                        <Col className="flex justify-end" span={24} sm={12} md={12} lg={16} xl={19} xxl={20}>
                            <Row gutter={[8, 8]}>
                                <Col>
                                    <Button
                                        loading={API.isLoading}
                                        type="primary"
                                        onClick={() => {
                                            setCreateOpen(true);
                                        }}
                                    >
                                        {CREATE?.name ?? "Add"}
                                    </Button>
                                </Col>

                                {CREATE?.AddAfterAddButton}
                            </Row>
                        </Col>
                    ) : (
                        CREATE?.AddAfterAddButton && CREATE?.AddAfterAddButton
                    )}

                    {AddOnComponent}
                    <Col span={24}>
                        <CRUDTable
                            dataSource={data}
                            isLoading={API.isLoading}
                            columns={GET?.column}
                            selectionOff={selectionOff}
                            //   DeleteSelectedRow
                            //   APIendpoint="checkupDelete"
                            //   onConfirm={() => {
                            //     setRefresh((prev) => !prev);
                            //   }}
                            setChanges={(v) => {
                                setAllFilter(v);
                                setPagination((prev) => ({
                                    ...prev,
                                    current: 1,
                                }));
                            }}
                            // onChange={(selectedRowKeys, selectedRows) => {
                            //   console.info(
                            //     `selectedRowKeys: ${selectedRowKeys}`,
                            //     "selectedRows: ",
                            //     selectedRows
                            //   );
                            // }}
                            rowSelectionObj={rowSelectionObj}
                            props={props}
                        />
                    </Col>
                    <Col span={24} className="mb-4">
                        <Pagination
                            current={pagination?.current}
                            pageSize={pagination?.pageSize}
                            total={pagination?.total}
                            showSizeChanger
                            onChange={(page, pageSize) => {
                                setPagination((prev) => ({ ...prev, pageSize, current: page }));
                            }}
                        />
                    </Col>
                </>
            )}
        </Row>
    );
});
CRUDComponent.propTypes = {
    GET: PropTypes.shape({
        API: PropTypes.shape({
            type: PropTypes.string,
            endpoint: PropTypes.string,
        }),
        extraQuery: PropTypes.object,
        DataModifier: PropTypes.func,
        column: PropTypes.array,
    }),
    CREATE: PropTypes.shape({
        API: PropTypes.shape({
            type: PropTypes.string,
            endpoint: PropTypes.string,
        }),
        payloadModifier: PropTypes.func,
        modalFields: PropTypes.array,
        modaltitle: PropTypes.string,
        isFormData: PropTypes.bool,
        message: PropTypes.string,
        name: PropTypes.string,
    }),
    UPDATE: PropTypes.shape({
        API: PropTypes.shape({
            type: PropTypes.string,
            endpoint: PropTypes.string,
        }),
        payloadModifier: PropTypes.func,
        modalFields: PropTypes.array,
        modaltitle: PropTypes.string,
        isFormData: PropTypes.bool,
        message: PropTypes.string,
    }),

    DELETE: PropTypes.shape({
        API: PropTypes.shape({
            type: PropTypes.string,
            endpoint: PropTypes.string,
        }),
        message: PropTypes.string,
    }),
    isSearch: PropTypes.bool,
    reload: PropTypes.bool,
    selectionOff: PropTypes.bool,
    FILTERSORTKEY: PropTypes.object,
    maxPageCount: PropTypes.number,
    rowSelectionObj: PropTypes.shape({
        type: PropTypes.oneOf(["checkbox", "radio"]),
        onChange: PropTypes.func,
    }),
};
export default CRUDComponent;

export const payloadGenerator = (value, fields, isFormData) => {
    let rawPayload = {};
    const formPayload = new FormData();

    if (isFormData) {
        fields?.forEach((ele) => {
            // console.log(ele.id);
            if (
                ele.type !== "file"
                && ele.type !== "date"
                && ele.type !== "multifield"
                && ele.type !== "extraMultiSingle"
                && ele.type !== "number"
                && ele.type !== "fileWithPreview"
            ) {
                value[ele.id] && formPayload.append(ele.id, value[ele.id]);
            }

            if (
                (ele.type === "file" || ele.type === "fileWithPreview")
                && value[ele?.id]
            ) {
                if (Array.isArray(value[ele.id])) {
                    for (let i = 0; i < value[ele.id]?.length; i++) {
                        formPayload.append(ele.id, value[ele.id][i].originFileObj);
                    }
                } else {
                    value[ele.id]
                        && formPayload.append(
                            ele.id,
                            value[ele.id][0].originFileObj || value[ele.id],
                        );
                }
            }
            if (ele.type === "multifield" || ele.type === "extraMultiSingle") {
                if (ele?.handler) {
                    value[ele.id]
                        && formPayload.append(ele.id, ele?.handler(value[ele.id]));
                } else {
                    value[ele.id]
                        && formPayload.append(ele.id, JSON.stringify(value[ele.id]));
                }
            }

            if (ele.type === "number") {
                value[ele.id] && formPayload.append(ele.id, +value[ele.id]);
            }
            if (ele.type === "date") {
                // if (dateTime) {
                const dateTimeValue = `${moment(value[ele.id].$d).format(
                    "YYYY-MM-DD",
                )} ${moment(value[ele.id].$d, "HH:mm:ss").utc().format("HH:mm:ss")}`;

                value[ele.id] && formPayload.append(ele.id, dateTimeValue);
            }
        });
    } else {
        fields.forEach((ele) => {
            if (ele?.type === "date") {
                rawPayload = {
                    ...rawPayload,
                    [ele?.id]: moment(value[ele?.id]?.$d, "YYYY-MM-DD").format(
                        "YYYY-MM-DD",
                    ),
                };
            }
            if (ele?.type === "daterange") {
                rawPayload = {
                    ...rawPayload,
                    startTime: moment(value[ele?.id]?.[0]?.$d, "YYYY-MM-DD HH:mm:ss").format(
                        "YYYY-MM-DD HH:mm:ss",
                    ),
                    endTime: moment(value[ele?.id]?.[1]?.$d, "YYYY-MM-DD HH:mm:ss").format(
                        "YYYY-MM-DD HH:mm:ss",
                    ),

                };
                delete value[ele?.id];
                delete rawPayload[ele?.id];
            }
            if (ele?.type === "time") {
                rawPayload = {
                    ...rawPayload,
                    [ele?.id]: moment(value[ele?.id]?.$d, "HH:mm:ss").format("HH:mm:ss"),
                };
            }
        });
        rawPayload = { ...value, ...rawPayload };
    }

    return isFormData ? formPayload : rawPayload;
};
