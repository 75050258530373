/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
import React from "react";
import { Typography } from "antd";
import FormFields from "../../../../../component/common/FormFields";

const { Text } = Typography;
const AddIntegration = ({ form, defaultData }) => {
  return (
    <div className="mt-4">
      {" "}
      <FormFields
        formData={defaultData != null ? defaultData : {}} // edit
        menu="PROJECT_INTEGRATION_MODAL"
        form={form}
      />
      <Text type="secondary" className="mt-2 flex justify-center items-center">
        Don't know about Jira API, Domain and ProjectId ?&nbsp;
        <a
          href="https://www.youtube.com/watch?v=Ta9yE3p7hAg"
          target="_blank"
          rel="noreferrer"
          className="defaultColor"
        >
          Click Here
        </a>
      </Text>
    </div>
  );
};

export default AddIntegration;
