/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-const-assign */
/* eslint-disable radix */
// import {
//     Button, Card, Col, Row,
// } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import Search from 'antd/es/input/Search';
import {
    Button, Card, Col, Modal, Pagination, Popconfirm, Row, Table, Typography,
    notification,
} from 'antd';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import HeaderAnalyticCard from '../../../component/pricingSection/headerAnalyticCard';
import CONSTANTS from '../../../util/constant/CONSTANTS';
import { apiGenerator, convertLocalString, statusColor } from '../../../util/functions';
import useHttp from '../../../hooks/use-http';
import ModalFormCreator from '../../../component/common/ModalFormCreator';

const { Text } = Typography;

const CreditSummary = () => {
    const [creditData, setCreditData] = useState([]);
    const [filterText, setFilterText] = useState("");
    const [extraQuery, setExtraQuery] = useState("");
    const [typeQuery, setTypeQuery] = useState("");
    const [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 10,
        total: 0,
    });
    // const [refresh, setRefresh] = useState(false);
    // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [billingAnalyticData, setBillingAnalyticData] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [detailView, setDetailView] = useState(null);
    const [refresh, setRefresh] = useState(false);

    // const [switchStates, setSwitchStates] = useState({});
    const data = [
        {
            title: "Total Amount Remaining",
            id: "totalAmountRemaining",
            amount: convertLocalString(billingAnalyticData?.totalRemainingCredits),
        },
        {
            title: "Total Amount Used",
            id: "totalAmountUsed",
            amount: convertLocalString(billingAnalyticData?.totalAmountUsed),
        },
        {
            title: "Amount Processing",
            id: "amountProcessing",
            amount: convertLocalString(billingAnalyticData?.totalAmountPending),
        },
    ];
    const debounceTimeout = useRef(null);

    const { environmentID } = useParams();
    const api = useHttp();

    useEffect(() => {
        api?.sendRequest(
            CONSTANTS.API.billing.getbillAnalytic,
            (res) => {
                setBillingAnalyticData({
                    totalDuoAmount: res?.data?.[0]?.totalUnpaidAmount,
                    totalPaidAmount: res?.data?.[0]?.totalPaidAmount,
                    totalPayBills: res?.data?.[0]?.totalPaidBills,
                    totalDueBills: res?.data?.[0]?.totalUnpaidBills,
                    totalRemainingActiveCredit: res?.creditData?.[0]?.totalRemainingActiveCredits,
                    totalRemainingInactiveCredits: res?.creditData?.[0]?.totalRemainingInactiveCredits,
                    totalAmountUsed: res?.creditHistoryData?.[0]?.totalUsedCredits,
                    totalRemainingCredits: res?.creditData?.[0]?.totalRemainingActiveCredits,
                    totalAmountPending: res?.creditData?.[0]?.totalProcessingCredits,

                });
            },
        );
    }, [refresh]);
    // const rowSelection = {
    //     selectedRowKeys,
    //     onChange: (value) => {
    //         // console.log(value);
    //         setSelectedRowKeys(value);
    //     },
    // };

    const handleTableChange = (filters, sorter) => {
        if (filters?.type || sorter?.status) {
            setTypeQuery(`&status=${filters?.type?.toString() ?? sorter?.status?.toString()}`);
        } else {
            setTypeQuery("");
        }

        if (sorter?.order) {
            setExtraQuery(
                `&sort=${sorter?.columnKey}&sortBy=${sorter?.order === "descend" ? "DESC" : "ASC"
                }`,
            );
        } else {
            setExtraQuery("");
        }
    };
    const searchHandler = (value) => {
        // Clear the previous timeout if it exists
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        // Set a new timeout to call the debounced function after a certain delay
        debounceTimeout.current = setTimeout(() => {
            setPagination((prev) => ({ ...prev, currentPage: 1 }));
            const newValue = value?.includes("WOOFF") ? parseInt(value.match(/\d+/)[0]) : value;
            setFilterText(newValue);
        }, 500); // Adjust the delay as needed
    };

    const removeWithDraw = (creditRequestData) => {
        const findId = creditRequestData?.[creditRequestData?.length - 1]?.id;
        api.sendRequest(
            apiGenerator(CONSTANTS.API.creditrequest.delete, { dataId: findId }),
            () => {
                notification.success({ message: "Sucess", description: "Coupon Request Withdraw Successfully!" });
                setRefresh((pr) => !pr);
            },

        );
    };

    const redeemCouponHandler = (payload, clearValue) => {
        // const payloadData = payload;
        const regex = /^WOOFF0*([1-9][0-9]*)$/;
        const match = payload?.creditId.match(regex);
        const payloadData = match ? { ...payload, creditId: match?.[1] } : { ...payload };
        api.sendRequest(
            CONSTANTS.API.creditrequest.add,
            () => {
                notification.success({ message: "Sucess", description: "Coupon Redeem Request Successfully!" });
                setIsModalOpen((pr) => !pr);
                setRefresh((pr) => !pr);
                clearValue();
            },
            payloadData,
        );
    };
    const redeemButtonOpen = () => {
        setIsModalOpen((pr) => !pr);
    };

    useEffect(() => {
        const CREDIT_API = { ...CONSTANTS.API.credit.get };

        CREDIT_API.endpoint = `${CREDIT_API.endpoint}?page=${pagination.currentPage}&limit=${pagination.pageSize}`;

        if (filterText != null && filterText !== "") {
            const query = `&search=${filterText}`;
            CREDIT_API.endpoint += query;
        }
        CREDIT_API.endpoint = CREDIT_API.endpoint + extraQuery + typeQuery;
        api.sendRequest(CREDIT_API, (res) => {
            setPagination((prevPagination) => ({
                ...prevPagination,
                total: res?.data?.count ?? 90000,
            }));
            setCreditData(
                res?.data?.rows?.map((ele) => {
                    return {
                        ...ele,
                        key: ele,
                        // no:
                        //     pagination.currentPage === 1
                        //         ? i + 1
                        //         : pagination.pageSize * (pagination.currentPage - 1) + i + 1,
                        couponCode: ele?.id && `WOOFF${ele?.id?.toString().padStart(7, '0')}`,
                        credits: convertLocalString(ele?.credits),
                        status: statusColor(ele?.status),
                        distributerName: ele?.distributer?.username,
                        dateOfApply: moment(ele?.createdAt)?.format("DD MMM, YYYY"),
                        dateOfActivate: moment(ele?.updatedAt)?.format("DD MMM, YYYY"),
                        rejectMsg: ele?.notes,
                        notes: {
                            id: ele?.id,
                            onClick: () => {
                                setDetailView({
                                    reason: ele?.reason,
                                });
                            },
                            isDisable: !ele?.reason,
                        },
                        request: <>
                            {' '}
                            <Popconfirm
                                placement="top"
                                title="Are you sure to withdraw this request?"
                                disabled={ele?.status !== "In Review"}
                                // description={description}
                                onConfirm={() => removeWithDraw(ele?.creditRequests)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="primary" disabled={ele?.status !== "In Review"}>Withdraw</Button>
                            </Popconfirm>
                        </>,
                    };
                }),
            );
        });
    }, [
        filterText,
        extraQuery,
        typeQuery,
        pagination.currentPage,
        pagination.pageSize,
        refresh,
        // switchStates,
        environmentID,
    ]);

    return (
        <>
            <HeaderAnalyticCard
                title=" Credit Usage Summary"
                showAnalyticData={data}
                btnController={redeemButtonOpen}
            />
            <Modal
                title={<p className="text-black">Reason</p>}
                open={detailView !== null}
                centered
                onCancel={() => setDetailView(null)}
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ style: { display: "none" } }}
            >
                <p className="text-black text-base">{detailView?.reason}</p>
            </Modal>
            <Card className="mt-5">
                <div className="flex gap-3">
                    <Text className="hidden  md:block">Credit  History</Text>
                </div>

                <div className="mt-3">
                    <Row>
                        <Col span={24} lg={12} xl={8} xxl={8}>
                            <Search
                                width="auto"
                                onChange={(e) => {
                                    searchHandler(e.target.value);
                                }}
                                placeholder="Search"
                            />
                        </Col>
                    </Row>
                </div>
                <div className="mt-5">
                    <Table
                        dataSource={creditData}
                        columns={CONSTANTS.TABLE.CREDIT_HISTORY}
                        onChange={handleTableChange}
                        pagination={false}
                        scroll={{ x: 800, y: 1300 }}
                    // rowSelection={{
                    //     type: "checkbox",
                    //     ...rowSelection,
                    // }}
                    />
                    <Pagination
                        current={pagination?.currentPage}
                        pageSize={pagination?.pageSize}
                        total={pagination?.total}
                        className="mt-10"
                        showSizeChanger
                        onChange={(page, pageSize) => {
                            setPagination((prev) => ({
                                ...prev,
                                currentPage: page,
                                pageSize,
                            }));
                        }}
                    />
                </div>
            </Card>
            <ModalFormCreator
                open={isModalOpen}
                onCreate={redeemCouponHandler}
                onCancel={() => {
                    setIsModalOpen((prev) => !prev);
                }}
                name="Redeem Coupon"
                menu="REDEEM_COUPON_MODAL"
            />
        </>
    );
};

export default CreditSummary;
