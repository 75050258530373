/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prefer-promise-reject-errors */
import React, { useState } from 'react';
import {
    Button, Col, Form, Input, Modal, Popconfirm, Row,
} from 'antd';
import { DeleteFilled } from "@ant-design/icons";

const DeleteModal = ({ details, setDetails }) => {
    const [form] = Form.useForm();
    const [isbuttonDisable, setIsbuttonDisable] = useState({
        isTextValid: false,
        isVerifyTextValid: false,
    });

    const footer = {
        footer: [

            <Popconfirm
                onConfirm={() => {
                    details?.callBack(details?.id);
                }}
                key="deleteConfirm"
                title={`Delete `}
                description="Are you sure to Delete ?"
            >
                <Button
                    key="delete"
                    className="w-full"
                    style={{
                        color: "#fff",
                        background: !(isbuttonDisable.isTextValid && isbuttonDisable.isVerifyTextValid) ? "#EE181880" : "#EE1818",
                    }}
                    type="primary"
                    disabled={!(isbuttonDisable.isTextValid && isbuttonDisable.isVerifyTextValid)}
                >
                    Delete
                </Button>
            </Popconfirm>,

        ],
    };

    return (
        <Modal
            open={details !== null}
            title={(
                <p className="font-semibold flex items-center gap-1.5">
                    <DeleteFilled className="!text-[#EE1818] " />
                    {' '}
                    Delete
                    {' '}
                    {details?.title}
                </p>
            )}
            okText="Delete"
            okButtonProps={{ style: { display: "none" } }}
            width={430}
            {...footer}
            className="form-modal-title"
            onCancel={() => {
                setDetails(null);
                form.resetFields();
                setIsbuttonDisable({
                    isTextValid: false,
                    isVerifyTextValid: false,
                });
            }}
        >

            <div className="mb-3 mt-5">
                <p className="font-medium mb-2">
                    {details?.showText}
                </p>
                <p className="bg-[#ff743d] text-sm rounded-[8px] px-2.5 py-1.5 text-[#fff]">
                    <span className="font-bold">Warning:</span>
                    {' '}
                    This action is not reversible. Are you sure you want to proceed?
                </p>
            </div>
            <Form form={form}>

                <Row>
                    <Col span={24}>
                        <p className="font-medium">
                            Enter{' '}
                            {details?.title}
                            {' '} name <span className="font-bold">{details?.text || details?.name}</span>
                            {' '}
                            to continue
                        </p>
                    </Col>
                </Row>
                <Form.Item
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "",
                        },
                        {
                            validator: (_, value) => {
                                if (value === details?.text || value === details?.name) {
                                    setIsbuttonDisable((prev) => {
                                        return {
                                            ...prev,
                                            isTextValid: true,
                                        };
                                    });

                                    return Promise.resolve();
                                }
                                setIsbuttonDisable((prev) => {
                                    return {
                                        ...prev,
                                        isTextValid: false,
                                    };
                                });
                                return Promise.reject(`Enter a valid text`);
                            },
                        },
                    ]}
                >
                    <Input placeholder="" />
                </Form.Item>
                <Row className="-mt-2.5">
                    <Col span={24}>
                        <p className="font-medium">
                            To verify type <span className="font-bold">{details?.verificationText}</span> below
                        </p>
                    </Col>
                </Row>
                <Form.Item
                    name="envName"
                    rules={[
                        {
                            required: true,
                            message: "",
                        },
                        {
                            validator: (_, value) => {
                                if (value?.includes(details?.verificationText)) {
                                    setIsbuttonDisable((prev) => {
                                        return {
                                            ...prev,
                                            isVerifyTextValid: true,
                                        };
                                    });
                                    return Promise.resolve();
                                }
                                setIsbuttonDisable((prev) => {
                                    return {
                                        ...prev,
                                        isVerifyTextValid: false,
                                    };
                                });
                                return Promise.reject('');
                            },
                        },
                    ]}
                >
                    <Input placeholder="" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default DeleteModal;
