import { Typography } from "antd";
import Title from "antd/es/typography/Title";
import moment from "moment";
import React from "react";
import { AiFillProject } from "react-icons/ai";
import { Link } from "react-router-dom";
import { projectRoot } from "../../util/constant/CONSTANTS";

const { Text } = Typography;
function ProjectCard(project) {
  return (
    <Link to={`${projectRoot}/${project?.id}`}>
      <div
        className="p-3 cursor-pointer  hover:bg-hoverBlue duration-500"
        style={{ borderRadius: "8px", border: "1px solid #D3D3D3" }}
      >
        <Title className="!mb-0 p-0 flex items-center gap-1" level={5}>
          <AiFillProject color={`${project?.theme?.token?.colorPrimary}`} />
          {' '}
          {project?.name}
        </Title>
        <Text style={{ color: "#4A4A4A", fontSize: "12px" }}>
          {project?.description}
        </Text>
        <br />

        <Text
          style={{ color: "#aba7a7", lineHeight: "12px", fontSize: "10px" }}
        >
          Created on
          {' '}
          {moment(project?.createdAt).format("MMM DD, YYYY")}
        </Text>
      </div>
    </Link>
  );
}

export default ProjectCard;
