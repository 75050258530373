/* eslint-disable prefer-const */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useRef, useState } from "react";
import {
  Button, Card, Col, Pagination, Popconfirm, Row, Switch, Table, Typography,
} from "antd";
import { useParams } from "react-router-dom";
import { FaDownload } from "react-icons/fa";
import Search from "antd/es/input/Search";
import { CSVLink } from "react-csv";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import useHttp from "../../../../hooks/use-http";
import { convertLocalStringWithoutRupee, formatAmount, getMaxTimeUnit } from "../../../../util/functions";

const { Text } = Typography;

let totalEndpointCounts = 0;

const BlockApi = () => {
  const [apiOverView, setApiOverView] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [extraQuery, setExtraQuery] = useState("");
  const [methodQuery, setMethodQuery] = useState("");
  const [typeQuery, setTypeQuery] = useState("");
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    total: 0,
  });
  const [refresh, setRefresh] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [switchStates, setSwitchStates] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [allData, setAllData] = useState([]);
  const excelRef = useRef();

  const debounceTimeout = useRef(null);

  const { environmentID } = useParams();
  const api = useHttp();

  const headers = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
      width: 100,
    },
    {
      title: "Endpoint",
      dataIndex: "endPointD",
      key: "endPointD",
      width: 150,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 120,
      filters: [
        {
          text: "Internal",
          value: "Internal",
        },
        {
          text: "ThirdParty",
          value: "ThirdParty",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
    },
    {
      title: "Method",
      dataIndex: "method",
      className: "!text-center",
      key: "method",
      width: 90,
      filters: [
        {
          text: "GET",
          value: "GET",
        },
        {
          text: "POST",
          value: "POST",
        },
        {
          text: "PATCH",
          value: "PATCH",
        },
        {
          text: "PUT",
          value: "PUT",
        },
        {
          text: "DELETE",
          value: "DELETE",
        },
        {
          text: "HEAD",
          value: "HEAD",
        },
        {
          text: "OPTIONS",
          value: "OPTIONS",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
    },

    {
      title: "Total Count",
      dataIndex: "totalCount",
      className: "!text-center",
      key: "totalCount",
      width: 80,
      sorter: true,
    },
    {
      title: "Success Count",
      dataIndex: "successCount",
      className: "!text-center",
      key: "successCount",
      width: 90,
      sorter: true,
    },
    {
      title: "Fail Count",
      dataIndex: "failCount",
      className: "!text-center",
      key: "failCount",
      width: 90,
      sorter: true,
    },
    {
      title: "Average Processing Time",
      dataIndex: "avgResponseTime",
      className: "!text-center",
      key: "avgResponseTime",
      width: 110,
      sorter: true,
    },
    {
      title: "Visible",
      dataIndex: "isVisible",
      className: "!text-center",
      key: "isVisible",
      width: 80,
      sorter: true,
    },
  ];
  const rowSelection = {
    selectedRowKeys,
    onChange: (value) => {
      // console.log(value);
      setSelectedRowKeys(value);
    },

  };
  const updataeVisibility = (payload) => {
    // console.log(CONSTANTS.API.overview.updateApiVisibility);
    const VISIBILITY_API = { ...CONSTANTS.API.overview.updateApiVisibility };
    // console.log(VISIBILITY_API);
    api.sendRequest(
      VISIBILITY_API,
      () => {
        setRefresh((prev) => !prev);
      },
      payload,
      "Endpoint Unblocked Successfully !!!",
    );
  };

  const blukVisibilityHandler = () => {
    const payload = {
      serviceEnvironmentId: environmentID,
      isVisible: true,
      data: selectedRowKeys?.map((ele) => {
        return {
          method: ele?.method,
          endPoint: ele?.endPoint,
        };
      }),
    };
    const BULK_VISIBILITY_API = { ...CONSTANTS.API.overview.updateBulkApiVisibility };
    // console.log(VISIBILITY_API);
    api.sendRequest(
      BULK_VISIBILITY_API,
      () => {
        setRefresh((prev) => !prev);
      },
      payload,
      "Endpoints Unblocked Successfully !",
    );
  };

  const blukAllVisibilityHandler = () => {
    const payload = {
      serviceEnvironmentId: environmentID,
      isVisible: true,
    };
    if (filterText !== "" && filterText !== undefined && filterText !== null) {
      payload.endPointPattern = filterText;
    }
    const BULK_VISIBILITY_API = { ...CONSTANTS.API.overview.updateAllBulkApiVisibility };
    api.sendRequest(
      BULK_VISIBILITY_API,
      () => {
        setRefresh((prev) => !prev);
        setFilterText("");
        setSearchValue("");
        setSelectedRowKeys([]);
      },
      payload,
      "All Endpoints Unblocked Successfully !",
    );
  };

  const handleTableChange = (filters, sorter) => {
    if (filters?.method) {
      setMethodQuery(`&method=${filters?.method?.toString()}`);
    } else {
      setMethodQuery("");
    }

    if (filters?.type) {
      setTypeQuery(`&type=${filters?.type?.toString()}`);
    } else {
      setTypeQuery("");
    }

    if (sorter?.order) {
      setExtraQuery(
        `&sort=${sorter?.columnKey}&sortBy=${sorter?.order === "descend" ? "DESC" : "ASC"
        }`,
      );
    } else {
      setExtraQuery("");
    }
  };

  const searchHandler = (value) => {
    // Clear the previous timeout if it exists
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set a new timeout to call the debounced function after a certain delay
    debounceTimeout.current = setTimeout(() => {
      setPagination((prev) => ({ ...prev, currentPage: 1 }));
      setFilterText(value);
    }, 500); // Adjust the delay as needed
  };

  useEffect(() => {
    const OVERVIEW_API = { ...CONSTANTS.API.overview.getApiCountOverView };
    OVERVIEW_API.endpoint = OVERVIEW_API.endpoint.replace(
      ":serviceID",
      environmentID,
    );
    OVERVIEW_API.endpoint = `${OVERVIEW_API.endpoint}&isVisible=0&page=${pagination.currentPage}&limit=${pagination.pageSize}`;

    if (filterText != null && filterText !== "") {
      const query = `&search=${filterText}`;
      OVERVIEW_API.endpoint += query;
    }
    OVERVIEW_API.endpoint = OVERVIEW_API.endpoint + extraQuery + methodQuery + typeQuery;
    api.sendRequest(OVERVIEW_API, (res) => {
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: res?.data?.count ?? 90000,
      }));
      totalEndpointCounts = res?.data?.count;
      setApiOverView(
        res?.data?.rows?.map((ele, i) => {
          const latency = getMaxTimeUnit(ele?.avgResponseTime);
          return {
            ...ele,
            key: ele,
            no:
              pagination.currentPage === 1
                ? i + 1
                : pagination.pageSize * (pagination.currentPage - 1) + i + 1,
            totalCount: formatAmount(ele?.totalCount) || "0",
            successCount: (
              <Text className="text-green-700">
                {formatAmount(ele?.successCount) || "0"}
                {' '}
                (
                {ele?.successCount && ele?.totalCount !== 0
                  ? ((ele?.successCount / ele?.totalCount) * 100)?.toFixed(0)
                  : "0"}
                %)
              </Text>
            ),
            failCount: (
              <Text className="text-red-700">
                {formatAmount(ele?.failCount) || "0"}
                {' '}
                (
                {ele?.failCount && ele?.totalCount !== 0
                  ? ((ele?.failCount / ele?.totalCount) * 100)?.toFixed(0)
                  : "0"}
                %)
                {" "}
              </Text>
            ),
            avgResponseTime: `${latency.time} ${latency.unit}`,
            method: ele?.method || "-",
            endPointD:
              <p className="overflow-hidden break-all">{ele?.endPoint}</p>
              || "-",
            isVisible: (
              <div onKeyDown={(e) => {
                if (e.key === 'Escape') {
                  setSwitchStates((prevState) => ({
                    ...prevState,
                    [ele?.endPoint]: ele?.isVisible,
                  }));
                }
              }}
              >
                <Popconfirm
                  key={ele?.endPoint + ele?.menthod}
                  title="Are you sure you want to unblock the API data?"
                  description={(
                    <p>
                      Unblocking the API data will make it visible on the map.However,
                      <br />
                      you can block it again from the API section if needed.
                    </p>
                  )}
                  onConfirm={() => {
                    const value = !ele?.isVisible;
                    const payload = {
                      endPoint: ele?.endPoint,
                      method: ele?.method,
                      serviceEnvironmentId: environmentID,
                      isVisible: value,
                    };
                    updataeVisibility(payload);
                  }}
                  onCancel={() => {
                    // Revert switch state if cancelled
                    setSwitchStates((prevState) => ({
                      ...prevState,
                      [ele?.endPoint]: ele?.isVisible,
                    }));
                  }}
                >
                  <Switch
                    key={ele?.endPoint + ele?.menthod}
                    checked={switchStates[ele?.endPoint]}
                    onChange={(checked) => {
                      setSwitchStates((prevState) => ({
                        ...prevState,
                        [ele?.endPoint]: checked,
                      }));
                    }}
                  />
                </Popconfirm>
              </div>
            ),
          };
        }),
      );
    });
  }, [
    filterText,
    extraQuery,
    methodQuery,
    typeQuery,
    pagination.currentPage,
    pagination.pageSize,
    refresh,
    switchStates,
    environmentID,
  ]);

  useEffect(() => {
    if (allData?.length <= 0) return;
    allData?.length > 0 && excelRef.current.link.click();
    setAllData([]);
  }, [allData]);

  const exportData = async () => {
    const OVERVIEW_API = { ...CONSTANTS.API.overview.getApiCountOverView };
    OVERVIEW_API.endpoint = OVERVIEW_API.endpoint.replace(
      ":serviceID",
      environmentID,
    );
    OVERVIEW_API.endpoint = `${OVERVIEW_API.endpoint}&isVisible=${0
      }`;
    if (filterText != null && filterText !== "") {
      const query = `&search=${filterText}`;
      OVERVIEW_API.endpoint += query;
    }
    await api.sendRequest(OVERVIEW_API, async (res) => {
      const data = res?.data?.rows?.map((ele) => {
        return {
          endPoint: ele?.endPoint,
          type: ele?.type,
          method: ele?.method,
          totalCount: ele?.totalCount,
          successCount: ele?.successCount,
          failCount: ele?.failCount,
          avgResponseTime: ele?.avgResponseTime,
          // isVisible: ele?.isVisible ? "Yes" : "No",
        };
      });
      await setAllData(data);
    });
  };

  return (
    <>
      <Card>
        <div className="flex items-center justify-between gap-3">
          <Text className="hidden  md:block">Blocked Endpoint Utilization</Text>
          {apiOverView?.length > 0 && (
            <Button
              className="btn-dashboard-icon textcolor"
              type="primary"
              // ghost
              icon={<FaDownload size={13} />}
              // size="large"
              style={{ margin: "0px 5px" }}
              onClick={exportData}
            >
              Export Data
            </Button>
          )}
          <CSVLink className="hidden" ref={excelRef} data={allData} />
        </div>

        <div className="mt-3">
          <Row>
            <Col span={24} lg={12} xl={8} xxl={8}>
              <Search
                value={searchValue}
                width="auto"
                onChange={(e) => {
                  searchHandler(e.target.value);
                  setSearchValue(e.target.value);
                }}
                placeholder="Search by Endpoint name"
              />
            </Col>
          </Row>
        </div>
        {selectedRowKeys?.length > 0 && (
          <div className="gap-4 flex">

            <Popconfirm
              placement="top"
              title="Are you sure you want to unblock the API data?"
              description={(
                <p>
                  Unblocking the API data will make it visible on the map.However,
                  <br />
                  you can block it again from the API section if needed.
                </p>
              )}
              onConfirm={blukVisibilityHandler}
            >
              <Button type="primary" className="mt-5 textcolor">
                Unblock Selected Endpoints
              </Button>
            </Popconfirm>
            <Popconfirm
              placement="top"
              title="Are you sure you want to unblock the all API data?"
              description={(
                <p>
                  Unblocking the API data will make it visible on the map.However,
                  <br />
                  you can block it again from the API section if needed.
                </p>
              )}
              onConfirm={blukAllVisibilityHandler}
            >
              <Button type="primary" className="mt-5 textcolor">
                {`Unblock all ${convertLocalStringWithoutRupee(totalEndpointCounts)} endpoints`}
              </Button>
            </Popconfirm>
          </div>
        )}
        <div className="mt-5">
          <Table
            dataSource={apiOverView}
            columns={headers}
            onChange={handleTableChange}
            pagination={false}
            scroll={{ x: 800, y: 1300 }}
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
          />
          <Pagination
            current={pagination?.currentPage}
            pageSize={pagination?.pageSize}
            total={pagination?.total}
            className="mt-10"
            showSizeChanger
            onChange={(page, pageSize) => {
              setPagination((prev) => ({
                ...prev,
                currentPage: page,
                pageSize,
              }));
            }}
          />
        </div>
      </Card>
    </>
  );
};

export default BlockApi;
