/* eslint-disable no-unsafe-optional-chaining */
import {
    Button, Card, Col, Collapse, Divider, Row, Table,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { GoDownload } from 'react-icons/go';
import { convertLocalString } from '../../util/functions';

const paymentStatus = {
    0: <span className="text-[#ffa500]">Pending</span>,
    1: <span className="text-green-600">Success</span>,
    3: <span className="text-[#ff4016]">Failed</span>,
    // 3: "Pending",

};

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 180,
    },
    {
        title: 'Per Log Price',
        dataIndex: 'perLogPrice',
        key: 'perLogPrice',
        width: 20,
    },
    {
        title: 'Logs per Unit Time',
        dataIndex: 'logPerUnitTime',
        key: 'logPerUnitTime',
        width: 20,
    },
    {
        title: 'Logs Price',
        key: 'logsPrice',
        dataIndex: 'logsPrice',
        width: 20,

    },
];
const data = [
    {
        key: '1',
        name: 'CPU History',
        id: "cpuHistoryCount",
        priceperLogKey: "cpuHistoryConst",
        logTotalPrice: "cpuHistoryCharges",

        // perLogPrice: "₹ 0.004",
        // logPerUnitTime: '30 min | 2880 Logs',
        // logsPrice: "₹ 11.52",
    },
    {
        key: '2',
        name: 'Server Activity Log',
        id: "serverActivitiesCount",
        priceperLogKey: "serverActivityConst",
        logTotalPrice: "serverActivitiesCharges",
        // perLogPrice: "₹ 0.06",
        // logPerUnitTime: '300 Logs',
        // logsPrice: "₹ 18",
    },
    {
        key: '3',
        id: "errorLogCount",
        name: 'Crash Log',
        priceperLogKey: "apiBackupConst",
        logTotalPrice: "errorLogCharges",
        // perLogPrice: "₹ 0.199",
        // logPerUnitTime: '300 Logs',
        // logsPrice: "₹ 59.07",
    },
    {
        key: '4',
        name: 'Custom Log',
        priceperLogKey: "customLogConst",
        id: "customLogCount",
        logTotalPrice: "customLogCharges",
        // perLogPrice: "₹ 0.1",
        // logPerUnitTime: '2400 Logs',
        // logsPrice: "₹ 240",
    },
    {
        key: '5',
        name: 'API Log',
        id: "apiBackupCount",
        priceperLogKey: "apiBackupConst",
        logTotalPrice: "apiBackupCharges",
        perLogPrice: "₹ 0.01",
        logPerUnitTime: '1500 Logs',
        logsPrice: "₹ 150",
    },
];

const BillingCard = ({ billingData, invoiceHandler }) => {
    // Example createdAt date from backend
    const createdAtDate = moment(billingData?.createdAt);
    const [tableData, setTableData] = useState([]);

    const newData = [];
    // const [billingAllData, setBillingAllData] = useState([]);

    // Get the first day of the previous month
    const firstDayOfPreviousMonth = createdAtDate.clone().subtract(1, 'months').startOf('month');

    // Get the last day of the previous month
    const lastDayOfPreviousMonth = createdAtDate.clone().subtract(1, 'months').endOf('month');

    const subTotal = billingData?.apiBackupCharges + billingData?.customLogCharges + billingData?.errorLogCharges + billingData?.serverActivitiesCharges + billingData?.cpuHistoryCharges;

    // Format the dates
    const formattedFirstDay = firstDayOfPreviousMonth.format('D MMM, YYYY');
    const formattedLastDay = lastDayOfPreviousMonth.format('D MMM, YYYY');
    const gstPercentage = billingData?.totalWithoutTax === 0 ? 0 : (((billingData?.CGST + billingData?.IGST + billingData?.SGST + billingData?.UGST) * 100) / billingData?.totalWithoutTax)?.toFixed(0);
    const gstAmount = billingData?.totalAfterTax - billingData?.totalWithoutTax;
    const TotalAmount = ((subTotal + billingData?.otherCharges) - billingData?.subtractionFromCredits)?.toFixed(2);

    useEffect(() => {
        setTableData(data?.map((el) => {
            newData?.push({
                ...el,
                perLogPrice: convertLocalString(billingData?.userPriceConfig[el?.id]),
                logPerUnitTime: `${billingData?.[el?.id]} Logs`,
                logsPrice: convertLocalString(`${billingData?.[el?.id] * billingData?.userPriceConfig[el?.id]}`),
            });
            return {
                ...el,
                perLogPrice: convertLocalString(`${billingData?.userPriceConfig[el?.priceperLogKey]}`),
                logPerUnitTime: `${billingData?.[el?.id]} Logs`,
                logsPrice: convertLocalString(`${+billingData?.[el?.logTotalPrice]}`),
            };
        }));
        data?.map((el) => {
            newData?.push({
                ...el,
                perLogPrice: convertLocalString(billingData?.userPriceConfig[el?.id]),
                logPerUnitTime: `${billingData?.[el?.id]} Logs`,
                logsPrice: convertLocalString(`${billingData?.[el?.id] * billingData?.userPriceConfig[el?.id]}`),
            });
            return {
                ...el,
                perLogPrice: convertLocalString(billingData?.userPriceConfig[el?.id]),
                logPerUnitTime: `${billingData?.[el?.id]} Logs`,
                logsPrice: convertLocalString(`${+billingData?.[el?.id] * +billingData?.userPriceConfig[el?.id]}`),
            };
        });
    }, [billingData]);
    return (
        <>
            <Card
                className="my-4"
                bodyStyle={{ padding: "10px 0px 24px 0px" }}
                title={(
                    <>
                        <div className="flex justify-between items-center my-3">
                            <div>
                                {/* <p>Feb 1 - Feb 28,2024</p> */}
                                <p>
                                    {formattedFirstDay}
                                    {' '}
                                    -
                                    {' '}
                                    {formattedLastDay}
                                </p>
                                {' '}
                                <p className="text-grayColor text-base font-normal">
                                    {billingData?.id?.toString().padStart(10, "0")}
                                    {' '}
                                    |
                                    {' '}
                                    {paymentStatus[billingData?.isPaid]}
                                </p>
                                <p className="text-grayColor text-base font-normal">
                                    Created at :
                                    {' '}
                                    <span className="text-black">{createdAtDate?.format("D MMM, YYYY | hh:mm A")}</span>
                                </p>
                            </div>
                            <Button
                                onClick={() => {
                                    invoiceHandler(billingData, subTotal, TotalAmount, gstAmount, gstPercentage);
                                }}
                                disabled={billingData?.isPaid !== 1}
                                icon={<GoDownload />}
                                type="primary"
                            >
                                Dowload Invoice
                            </Button>
                        </div>
                    </>
                )}
            >
                <Collapse
                    ghost
                    className="billingCollapse"
                    // eslint-disable-next-line react/no-unstable-nested-components
                    // expandIcon={({ isActive }) => <AiOutlineDown style={{ }} rotate={isActive ? 90 : 0} />}
                    expandIconPosition="right"
                    style={{ width: '100%' }}
                    items={[{
                        key: '1',
                        label: <p className="font-semibold text-base">
                            Item Details
                            {" "}
                            <span className="text-grayColor text-xs font-normal">(5 Items)</span>
                        </p>,
                        children: <Table pagination={false} columns={columns} dataSource={tableData} />,
                    }]}
                />
                <Divider className="p-0 m-0 mb-5" />
                <Row gutter={[16, 16]} className="px-5">
                    <Col span={24} xs={24} sm={24} md={7} />
                    <Col span={24} xs={24} sm={24} md={8}>
                        <Row gutter={[16, 16]}>
                            <Col className="flex justify-between items-center" span={24}>
                                <p className="text-grayColor text-base">Sub Total</p>
                                <p className="text-lg font-bold">
                                    +
                                    {convertLocalString(+subTotal?.toFixed(2))}
                                </p>
                            </Col>
                            <Col className="flex justify-between items-center " span={24}>
                                <p className="text-grayColor text-base">Service Charge</p>
                                <p className="text-lg font-bold">
                                    +
                                    {convertLocalString(+billingData?.otherCharges?.toFixed(2))}
                                </p>
                            </Col>
                            <Col className="flex justify-between items-center" span={24}>
                                <p className="text-grayColor text-base">Credit</p>
                                <p className="text-lg font-bold">
                                    -
                                    {convertLocalString(billingData?.subtractionFromCredits?.toFixed(2))}
                                </p>
                            </Col>

                        </Row>

                    </Col>
                    <Divider className="h-32 hidden md:block" type="vertical" />
                    <Divider className="block md:hidden" />
                    <Col span={24} xs={24} sm={24} md={8}>
                        <Row gutter={[16, 16]}>
                            <Col className="flex justify-between items-center" span={24}>
                                <p className="text-grayColor text-base">Grand Total</p>
                                <p className="text-lg font-bold">
                                    {convertLocalString(+TotalAmount)}
                                </p>
                            </Col>
                            <Col className="flex justify-between items-center " span={24}>
                                <p className="text-grayColor text-base">
                                    GST (
                                    {gstPercentage ?? 0}
                                    %)
                                </p>
                                <p className="text-lg font-bold">
                                    {convertLocalString(+gstAmount?.toFixed(2))}
                                </p>
                            </Col>
                            <Divider className="m-0" />
                            <Col className="flex justify-between items-center" span={24}>
                                <p className="text-grayColor text-base">Payable Amount</p>
                                <p className="text-lg font-bold text-primaryBg">
                                    {convertLocalString(+(+TotalAmount + +gstAmount)?.toFixed(2))}
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </>
    );
};

export default BillingCard;
