/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useRef, useState } from "react";
import {
  Button, Card, Col, Form, Menu, Result, Row, Spin,
} from "antd";

import { useParams } from "react-router-dom";
import moment from "moment";
import ModalFormCreator from "../../../../component/common/ModalFormCreator";
import useHttp from "../../../../hooks/use-http";
import { apiGenerator } from "../../../../util/functions";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import DeleteModal from "../../Service/Setting/deleteModal";
import OwenershipModal from "../../../../component/common/Owenership-Modal";
import FormFields from "../../../../component/common/FormFields";

const Setting = (props) => {
  const [editProjectDetails, setEditProjectDetails] = useState(null);
  const [projectDetails, setProjectDetails] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [deleteProjectData, setDeleteProjectData] = useState(null);
  const [ownershipDetails, setOwnershipDetails] = useState(null);
  const [featureReq, setFeatureReq] = useState(null);
  const deleteProjectCardRef = useRef(null);
  // const featureCardRef = useRef(null);

  const api = useHttp();
  const [form] = Form.useForm();

  const { projectId } = useParams();

  const detials = [
    {
      id: 1,
      label: "Name",
      value: projectDetails?.name || "-",
    },
    {
      id: 2,
      label: "PID",
      value: projectDetails?.id || "-",
    },
    {
      id: 3,
      label: "Created",
      value:
        (projectDetails?.createdAt
          && `${moment(projectDetails?.createdAt)
            .utc()
            .format("MMM DD")} at ${moment
              .utc(projectDetails?.createdAt)
              .local()
              .format("hh:mm:ss A")}`)
        || "-",
    },
    {
      id: 4,
      label: "Last Update",
      value:
        (projectDetails?.updatedAt
          && `${moment(projectDetails?.updatedAt)
            .utc()
            .format("MMM DD")} at ${moment
              .utc(projectDetails?.updatedAt)
              .local()
              .format("hh:mm:ss A")}`)
        || "-",
    },
    {
      id: 5,
      label: "Created By",
      value: projectDetails?.user?.username || "-",
    },
    {
      id: 6,
      label: "Description",
      value: projectDetails?.description || "-",
    },
  ];

  const scrollToDeleteProject = () => {
    deleteProjectCardRef.current.scrollIntoView({ behavior: "smooth" });
  };
  // const scrollToFeature = () => {
  //   featureCardRef.current.scrollIntoView({ behavior: "smooth" });
  // };
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  useEffect(() => {
    if (deleteProjectCardRef?.current && featureReq === true) {
      scrollToDeleteProject();
    }
  }, [featureReq, deleteProjectCardRef?.current]);
  const items = [
    getItem(
      "General",
      "grp",
      null,
      [
        getItem("Project Information", "13"),
        getItem("Transfer Ownership", "14"),
        getItem("Feature Request", "3"),
        getItem("Delete Project", "4"),
      ],
      "group",
    ),
  ];

  const projectDetailHandler = (value) => {
    // console.log(value);
    const UPDATE_PROJECT_INFO_API = apiGenerator(CONSTANTS.API.project.update, {
      dataId: projectId,
    });

    api.sendRequest(
      UPDATE_PROJECT_INFO_API,
      () => {
        setEditProjectDetails(null);
        setRefresh((prev) => !prev);
      },
      value,
      "Edit Details Successfully!!!",
    );
  };

  const deleteProjectHandler = (dataId) => {
    const DELETE_PROJECT_API = apiGenerator(CONSTANTS.API.project.delete, {
      dataId,
    });
    api.sendRequest(DELETE_PROJECT_API, () => {
      setDeleteProjectData(null);
      window.location.assign("/app");
    });
  };
  const feactureRequestHandler = (payload) => {
    api.sendRequest(CONSTANTS.API.featureRquest.addFeature, () => {
      setFeatureReq(true);
    }, payload, "Add Data Successfully!!!");
  };

  useEffect(() => {
    const PROJECT_INFO_API = apiGenerator(CONSTANTS.API.project.getOne, {
      dataId: projectId,
    });
    api.sendRequest(PROJECT_INFO_API, (res) => {
      setProjectDetails(res?.data);
    });
  }, [refresh]);

  return (
    <div>
      {api.isLoading ? (
        <Spin tip="Loading" size="large">
          <div className="content" />
        </Spin>
      ) : (
        <div className="my-5">
          <ModalFormCreator
            open={editProjectDetails !== null}
            onCreate={projectDetailHandler}
            onCancel={() => {
              setEditProjectDetails(null);
            }}
            name="Edit Project Details"
            menu="PROJECT_INFORMATION_MODAL"
            formData={editProjectDetails}
          />
          <DeleteModal details={deleteProjectData} setDetails={setDeleteProjectData} />
          <OwenershipModal details={ownershipDetails} setDetails={setOwnershipDetails} />

          <Row gutter={[16, 24]}>
            <Col span={6} xs={0} sm={0} md={0} lg={0} xl={6}>
              <Card
                bodyStyle={{
                  height: 250,
                }}
              >
                <Menu
                  onClick={(value) => {
                    if (value.key === '4' || value.key === '3') {
                      scrollToDeleteProject();
                    }
                    // if (value.key === '3') {
                    //   scrollToFeature();
                    // }
                  }}
                  defaultSelectedKeys={["13"]}
                  defaultOpenKeys={["grp"]}
                  className="-mt-4 !border-none"
                  mode="inline"
                  items={items}
                  triggerSubMenuAction={false}
                />
              </Card>
            </Col>
            <Col span={18} xs={24} sm={24} md={24} lg={24} xl={18}>
              <Card id="project" title="Project Information">
                {detials?.map((ele) => (
                  <div key={ele?.id}>
                    <p className="mb-1.5 font-medium flex items-center gap-1">
                      {ele?.label} : {ele?.value}
                    </p>
                  </div>
                ))}
                <Button
                  className="my-4 px-4 textcolor"
                  type="primary"
                  onClick={() => {
                    setEditProjectDetails(projectDetails);
                  }}
                >
                  Change Project Details
                </Button>
              </Card>
              <Card className="my-4" title="Transfer Ownership">
                <Row className="mb-1.5 -mt-2 font-medium ">
                  Transfer Ownership your project to another account without
                  downtime or workflow interruptions.
                </Row>
                <Button
                  className="my-2 px-4 textcolor"
                  type="primary"
                  onClick={() => {
                    setOwnershipDetails({
                      name: projectDetails?.name,
                      id: projectDetails?.id,
                      showText: `This action will lead to the transfer of the ownership of the entire project, including all associated services, its environment, and generated analytics.`,

                    });
                  }}
                >
                  Transfer Ownership
                </Button>
              </Card>
              <Card id="feature" ref={deleteProjectCardRef} className="my-4" title="Submit a Feature Request">
                <Row className="mb-1.5 -mt-2 font-medium ">
                  {featureReq === null ? <>
                    <Col sm={24}>
                      <FormFields
                        menu="FEACTURE_REQUEST_MODAL"
                        form={form}
                      />
                    </Col>
                    <Col sm={24}>
                      <Button
                        type="primary"
                        className="textcolor"
                        onClick={() => {
                          form
                            .validateFields()
                            .then((values) => {
                              feactureRequestHandler(values);
                            })
                            .catch(() => {
                              // console.error("Validate Failed:", info);
                            });
                        }}
                      >
                        Submit
                      </Button>
                    </Col></> : <Col sm={24}>
                    <Result
                      status="success"
                      title="Thank You for Your Feedback!"
                      subTitle="Thanks for sharing your thoughts with us! Your suggestion helps Wooffer get better! We have received your feature request. Our team will prioritize improvements that best serve our users."
                      extra={[
                        <Button type="primary" className="textcolor" key="console" onClick={() => setFeatureReq(null)}>
                          Submit a Feature Request
                        </Button>,
                      ]}
                    />
                  </Col>}
                </Row>
              </Card>
              <Card id="delete" ref={deleteProjectCardRef} className="my-4" title="Delete Project">
                <Row className="mb-1.5 -mt-2 font-medium ">
                  The project will be permanently deleted, including its
                  deployments and domains. This action is irreversible and can
                  not be undone.
                </Row>

                <Button
                  className="my-2 px-4 hover:opacity-75 textcolor"
                  type="primary"
                  style={{
                    background: "#EE1818",
                    boxShadow: `0 2px 0 ${props?.theme?.token?.colorPrimary}2a`,
                  }}
                  onClick={() => {
                    setDeleteProjectData({
                      name: projectDetails?.name,
                      id: projectDetails?.id,
                      callBack: deleteProjectHandler,
                      title: "Project",
                      showText: `This action will lead to the permanent deletion of the entire project, including all associated services, its environment, and generated analytics.`,
                      verificationText: `delete my project`,
                    });
                  }}
                >
                  Delete
                </Button>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default Setting;
