import React from "react";

const Label = (props) => {
  return props.required ? (
    <p className="font-medium reuired-field mb-2">
      {props.children}
      <span className="text-red-600 ml-1 ">
        *
      </span>
    </p>
  ) : (
    <p className="font-medium title-head">{props.children}</p>
  );
};

export default Label;
