/* eslint-disable object-curly-newline */
import { Avatar, Button, Drawer, Space } from "antd";
import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
// import Profile from "../../../asset/image/dummy-avatar.jpg";
import { deleteAuthDetails } from "../../../util/API/authStorage";

const RightDrawer = ({
  username,
  email,
  visible = false,
  setVisible = () => { },
}) => {
  const navigate = useNavigate();
  const onClose = () => {
    setVisible(false);
  };
  const handleLogout = () => {
    deleteAuthDetails();
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    localStorage.removeItem("token");
    localStorage.removeItem("ItemToken");
    localStorage.clear();
    window.location.reload();
  };
  return (
    <Drawer
      placement="right"
      // className="mobile:w-100"
      closeIcon={<CloseOutlined className="float-right" onClick={onClose} />}
      onClose={onClose}
      open={visible}
      width={450}
      extra={<Space>{/* <CloseOutlined onClick={onClose} /> */}</Space>}
    >
      <div className="flex-x center text-center profile-drawer">
        <div>
          <Avatar
            size={100}
            // style={{ color: "#fffff" }}
            className="mt-10 card-primary-to-secondary textcolor border-[2px] border-gray-300 shadow-lg"
            shape="circle"
          // src={Profile}
          >
            <div style={{ fontWeight: "500", fontSize: "3rem" }}>
              {username?.charAt(0).toUpperCase()}
            </div>
          </Avatar>
          <div className="mt-5 text-2xl font-medium">
            {username || "Website Admin"}
          </div>
          {/* <div className="an-24 regular-text mt20">{UserData?.siteName}</div> */}
          <div className="text-slate-500">{email || "admin@test.com"}</div>
          <Button
            type="primary"
            htmlType="submit"
            className="mt-5 w-64 h-10 textcolor"
            onClick={() => {
              if (localStorage.getItem("userRole") === "Distributer") {
                navigate("/distribute/profile");
              } else {
                navigate("/app/profile");
              }
              onClose();
            }}
          >
            Manage Your Wooffer Account
          </Button>
          <Button
            danger
            htmlType="submit"
            className="mt-5 w-40 h-10 defaultColor defaultBorderColor"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </div>
      </div>
      <hr className="my30" style={{ background: "#E4E8F0" }} />
    </Drawer>
  );
};

export default RightDrawer;
