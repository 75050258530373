import React, { useRef } from "react";
import {
  Col, Form, Input, Row, Typography,
} from "antd";
import Title from "antd/es/typography/Title";

const { Text } = Typography;
const Identifier = (props) => {
  const {
    serviceDetails,
    setServiceDetails,
    setChannelId,

    channelId,
  } = props;
  const debounce = useRef(null);
  const handelRefresher = (value) => {
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      setChannelId(value);
    }, 500);
  };

  const regex = /^https:\/\/hooks\.slack\.com\/services\/[A-Z0-9]+\/[A-Z0-9]+\/[A-Za-z0-9]+$/;

  return (
    <div>
      <div className="px-10 pt-5 flex items-center flex-col">
        <Title level={5} className="!mb-7">
          Create Identifier
        </Title>
        {serviceDetails && "name" in serviceDetails && (
          <Form
            initialValues={{
              name: serviceDetails?.name || "",
              slack: serviceDetails?.slack || channelId,
            }}
            className="mb-1 w-100  block "
          >
            <Row>
              <Col span={24}>
                <Form.Item name="name" className="mt-3 mb-2">
                  <Input
                    value={serviceDetails?.name || ""}
                    placeholder="Enter Your Service Name you want to track"
                    prefix={`Service Name    | `}
                    onChange={(e) => {
                      if (e.target.value) {
                        setServiceDetails((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }));
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item required={false} name="slack" className="mt-1 mb-0">
                  <Input
                    name="slack"
                    onChange={(e) => {
                      if (e.target.value && regex.test(e.target.value)) {
                        handelRefresher(e.target.value);
                      }
                    }}
                    placeholder="Enter Your Webhook (Optional)"
                    prefix="Slack Webhook | "
                    required
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
        <Text type="secondary" className="">
          Don&apos;t know about Slack Webhook ?
          {" "}
          <a
            href="https://www.youtube.com/watch?v=sxtC40gUS2A"
            target="_blank"
            rel="noreferrer"
            className="defaultColor"
          >
            Click Here
          </a>
        </Text>
        <div className="mt-5" />
      </div>
    </div>
  );
};

export default Identifier;
