/* eslint-disable react/jsx-closing-bracket-location */
import {
  Badge, Button, Card, Col, Row, Typography,
} from "antd";
import Title from "antd/es/typography/Title";
import React, { useState } from "react";
import TrackingCard from "../../../../component/card/tracking-card";
import ModalFormCreator from "../../../../component/common/ModalFormCreator";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import useHttp from "../../../../hooks/use-http";

const { Text } = Typography;
const UsageCard = ({
  plan = {},
  logLength = 0,
  // serviceLength = 0,
  projectsLength = 0,
  durationMessage = "",
  userName = "",
  theme = {},
}) => {
  const api = useHttp();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const feactureRequestHandler = (payload) => {
    api.sendRequest(CONSTANTS.API.featureRquest.addFeature, () => {
      setIsModalOpen((pr) => !pr);
    }, payload, "Thank You for Your Feedback!");
  };
  return (
    <Row gutter={[12, 12]}>
      <Col span={24} lg={18} xl={20} className="usage-Details">
        <Card
          title={(
            <Row className="gap-2 items-center justify-center md:justify-between">
              <Col className="mt-4 md:mt-0 flex gap-2">
                <Title className="!m-0 p-0" level={5}>
                  {userName ? `${userName}'s` : "Your"}
                  {' '}
                  Project
                  {" "}
                </Title>
                <Badge count={plan?.plan?.name} color={theme?.token?.colorPrimary} showZero className="textcolor badgeTextColor" />
              </Col>

              <Text className="mb-4 md:mb-0" style={{ color: "#767676" }}>
                {durationMessage}
              </Text>
            </Row>
          )}
        >
          <Row gutter={[12, 12]}>
            <Col span={24} sm={16} lg={16} xl={17} xxl={18}>
              <Row gutter={[12, 12]}>
                <Col span={24} sm={12} lg={10} xl={10} xxl={10}>
                  <TrackingCard
                    title="Project"
                    currant={projectsLength}
                    total={plan?.maxProjects}
                  />
                </Col>
                <Col span={24} sm={12} lg={10} xl={10} xxl={10}>
                  <TrackingCard
                    title="Log"
                    currant={logLength}
                    total={plan?.maxErrorLogs}
                  //   title="Log"
                  // currant={logLength}
                  // total={plan?.maxErrorLogs}

                  />
                </Col>
              </Row>
            </Col>
            <Col span={24} sm={8} lg={8} xl={7} xxl={4} className="flex justify-end">
              <Button className="textcolor" type="primary" onClick={() => setIsModalOpen((prev) => !prev)}>Submit a Feature Request</Button>
              <ModalFormCreator
                open={isModalOpen}
                onCreate={feactureRequestHandler}
                onCancel={() => {
                  setIsModalOpen((prev) => !prev);
                }}
                name="Submit a Feature Request"
                menu="FEACTURE_REQUEST_MODAL"

              />
            </Col>
          </Row>
        </Card>
      </Col>

      <Col span={24} lg={6} xl={4} className="hidden lg:flex  h-full">
        <Card
          style={{
            borderRadius: "4px",
          }}
          className="card-primary-to-secondary p-5 w-full h-full flex justify-center items-center"
        >
          <Title
            className="!m-0 p-0 textcolor"
            style={{ fontSize: "80px" }}
          >
            {userName?.charAt(0)
              .toUpperCase()}
          </Title>
        </Card>
      </Col>
    </Row>
  );
};

export default UsageCard;
