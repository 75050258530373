/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import {
  Col, DatePicker, Modal, Row, Spin,
} from "antd";
import Search from "antd/es/input/Search";
import ReactFlow, { Background } from "reactflow";
import moment from "moment";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import useHttp from "../../../../hooks/use-http";
import {
  apiGenerator,
  disabledDate,
  getColor,
  isLinkValid,
  isLogin,
  levelViseMaxXValue,
  min,
  parseEndpoint,
  processInputs,
  traverseAndGenerateNodes,
} from "../../../../util/functions";
import "reactflow/dist/style.css";

const { RangePicker } = DatePicker;
function ServiceFlow() {
  const pastValue = localStorage.getItem("serviceFlow_searchValue");

  const [apiOverView, setApiOverView] = useState([]);
  const [filterText, setFilterText] = useState(
    pastValue && pastValue !== null && pastValue !== undefined ? pastValue : "",
  );
  const [initialNodes, setInitialNodes] = useState({ nodes: [], edges: [] });
  const [modelDetails, setModelDetails] = useState(null);
  const [refersh, setRefersh] = useState(false);
  const { environmentID } = useParams();
  const [dateRange, setdateRange] = useState({
    startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const api = useHttp();
  const debounceTimeout = useRef(null);
  //

  const searchHandler = (value) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    localStorage.setItem("serviceFlow_searchValue", value);
    debounceTimeout.current = setTimeout(() => {
      setFilterText(value);
    }, 500); // Adjust the delay as needed
  };

  useEffect(() => {
    (async () => {
      if (!isLogin()) return;
      setApiOverView([]);
      let extraPerms = "";

      if (dateRange?.startDate && dateRange?.endDate) {
        extraPerms += `&startDate=${dateRange?.startDate}&endDate=${dateRange?.endDate}`;
      }

      const OVERVIEW_API = { ...CONSTANTS.API.overview.getApiCountOverView };

      OVERVIEW_API.endpoint = OVERVIEW_API.endpoint.replace(
        ":serviceID",
        environmentID,
      );

      OVERVIEW_API.endpoint = `${OVERVIEW_API.endpoint}&serviceDiagram=1&isVisible=1&limit=10000`;
      // if (filterText !== null && filterText !== "") {
      const pastValues = localStorage.getItem("serviceFlow_searchValue");
      if (pastValues && pastValues !== null && pastValues !== undefined) {
        const query = `&search=${pastValues}`;
        OVERVIEW_API.endpoint += query;
      } else if (filterText !== null && filterText !== "") {
        const query = `&search=${filterText}`;
        OVERVIEW_API.endpoint += query;
      }
      // }
      api.sendRequest(apiGenerator(OVERVIEW_API, {}, extraPerms), (res) => {
        setApiOverView(res?.data?.rows);
      });
    })();
  }, [environmentID, filterText, dateRange, refersh]);

  useEffect(() => {
    const apiTree = [];

    apiOverView.forEach((apis) => {
      let completeRoute = apis.endPoint;
      if (!isLinkValid(apis.endPoint)) {
        apis.endPoint = parseEndpoint(apis.endPoint);
        completeRoute = apis.endPoint.split("/");
        if (completeRoute[0] === "") completeRoute.shift();
        if (completeRoute[completeRoute.length - 1] === "") completeRoute.pop();

        const isExist = apiTree.findIndex(
          (array) => array[array.length - 1]?.route === apis.endPoint || false,
        );
        if (isExist !== -1) {
          apiTree[isExist][apiTree[isExist].length - 1].details[apis.method] = apis;
        } else {
          apiTree.push([
            ...completeRoute,
            { route: apis.endPoint, details: { [apis.method]: apis } },
          ]);
        }
      }
    });

    apiTree.sort((a, b) => b?.length - a?.length);

    const result = processInputs(apiTree);

    Object.keys(levelViseMaxXValue).map((key) => {
      delete levelViseMaxXValue[key];
      return 0;
    });

    Object.keys(min).map((key) => {
      delete min[key];
      return 0;
    });

    const nodes = traverseAndGenerateNodes(
      result,
      0,
      0,
      setRefersh,
      environmentID,
      api,
    );

    const edges = [];

    const newNodes = nodes.map((node) => {
      let nodeBgColor = "#fff";
      const nodeRoute = node.id.split("/");
      if (nodeRoute?.length > 1) {
        const [totalApiCount, , failApiCount] = Object.values(
          node?.usage?.details,
        ).reduce(
          (pre, curr) => [
            pre[0] + curr.totalCount,
            pre[1] + curr.successCount,
            pre[2] + curr.failCount,
          ],
          [0, 0, 0],
        );
        //
        const failureRatio = (failApiCount * 100) / totalApiCount;
        nodeBgColor = getColor(failureRatio);
        const parent = node.id.split("/");
        parent.pop();
        const parentKey = parent.join("/");
        edges.push({
          id: node.id,
          source: parentKey,
          target: node.id,
          style: { stroke: getColor(failureRatio) },
          sourceHandle: parentKey,
          targetHandle: node.id,
          animated: true,
          data: node?.usage?.details,
          label: `${node?.usage?.details
            ? Object.values(node?.usage?.details)
              .map((curr) => curr.method)
              .join(" | ")
            : ""
            } `,

          labelBgStyle: { fill: "#f5f5f5" },
          labelBgPadding: [5, 5],
          markerEnd: node.id,
        });
      }
      return {
        ...node,
        style: {
          wordBreak: "break-all",
          background: `${nodeBgColor}80`,
          borderColor: nodeBgColor,
        },
      };
    });

    setInitialNodes({
      nodes: newNodes,
      edges,
    });
  }, [apiOverView]);

  const onDateChange = (value) => {
    if (!(value?.length > 0)) {
      setdateRange(null);
      return;
    }

    setdateRange({
      startDate: value?.[0]?.format("YYYY-MM-DD"),
      endDate: value?.[1]?.format("YYYY-MM-DD"),
    });
  };
  return (
    <>
      <Row>
        <Col className="mb-5" span={24} lg={12} xl={8} xxl={8}>
          <Search
            width="auto"
            // value={pastValue}
            defaultValue={filterText}
            onChange={(e) => {
              searchHandler(e.target.value);
            }}
            placeholder="Filter by route start with like. api/v1"
          />
        </Col>
        <Col className="mb-5" span={24} lg={12} xl={8} xxl={8}>
          <RangePicker
            allowClear={false}
            disabledDate={disabledDate}
            defaultValue={[
              dayjs(dateRange?.startDate),
              dayjs(dateRange?.endDate),
            ]}
            onChange={onDateChange}
          />
        </Col>
      </Row>

      <div className="h-5/6">
        {api.isLoading ? (
          <div className="h-full border border-gray-300 flex justify-center items-center  w-full">
            <div className="block w-full mx-3">
              <Spin
                tip="Please wait while we are creating block the API map for you"
                className="mt-auto"
              >
                <div className="content " />
              </Spin>
            </div>
          </div>
        ) : (
          <ReactFlow
            nodes={initialNodes?.nodes || []}
            edges={initialNodes?.edges || []}
            showFitView
            minZoom={0.01}
            maxZoom={30}
            className="border border-gray-300"
          >
            <Background showInteractive={false} expandParent={false} />
          </ReactFlow>
        )}

        <Modal
          title="Basic Modal"
          open={modelDetails != null}
          onOk={() => setModelDetails(null)}
          onCancel={() => setModelDetails(null)}
        >
          <p>Some contents...</p>
          <p>Some contents...</p>
          <p>Some contents...</p>
        </Modal>
      </div>
    </>
  );
}

export default ServiceFlow;
