import { Col, Row } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import ChartCard from "./chart-card";
import CpuCard from "./cpu-card";
import ProcessCard from "./process-cpu";
import ProcessMemory from "./process-memory";
import RequestCountChart from "./request-chart";

const Charts = ({ plan = {} }) => {
  const { mode } = useParams();

  return (
    <>

      <Row className="w-full" gutter={[10, 10]}>
        {mode === "process" && (
          <>
            <Col className="mt-2 mb-4" span={24} xl={24}>
              <ProcessCard plan={plan} />
            </Col>
            <Col span={24} xl={24}>
              <ProcessMemory plan={plan} />
            </Col>
          </>
        )}
        {mode === "cpu" && (
          <>
            <Col className="mt-2 mb-4" span={24} xl={24}>
              <CpuCard plan={plan} />
            </Col>
            <Col span={24} xl={24}>
              <ChartCard plan={plan} />
            </Col>
          </>
        )}

        {mode === "api" && (
          <Col className="mt-2 mb-4" span={24} xl={24}>
            <RequestCountChart plan={plan} />
          </Col>
        )}
      </Row>
    </>
  );
};

export default Charts;
