import { Button, Result } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Failed = () => {
    const navigate = useNavigate();
    return (
        <>
            <Result
                status="error"
                title="Your payment has been Failed"
                subTitle="Oops, something went wrong. Please try again."
                extra={[
                    <Button onClick={() => navigate('/app')} type="primary" key="Home">
                        Home
                    </Button>,
                    <Button onClick={() => navigate('/app/billing')} key="back to billing">Back to Billing page</Button>,
                ]}
            />
        </>
    );
};

export default Failed;
