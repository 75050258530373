import {
  Button, Card, Col, Image, Popconfirm,
} from "antd";
import React, { useState } from "react";
import { getContrastYIQ } from "../../util/functions";
import JiraModal from "../../page/app/project-page/Integration/jiraModal";
import TrelloModal from "../../page/app/project-page/Integration/trelloModal";
// import JiraModal from "../../page/app/project-page/Integration/jiraModal";

function IntegrationCard({
  // setOpen,
  // open,
  projectDetails,
  confirmRemove,
  cardDetail,
  theme,
  setRefresh,
}) {
  const [open, setOpen] = useState(null);

  return (
    <>
    <Col xs={24} sm={12} md={5} key={cardDetail?.key}>
      <Card
        className="h-full"
        bodyStyle={{
          padding: "0 0 10px 0",
        }}
      >
        <div className="w-full h-28 rounded-md relative">
          {cardDetail?.disable ? null : cardDetail?.projectIntegrated ? (
            <p className="defaultthirdbgColor absolute  right-0  rounded-l-xl top-2 z-10 bg-[#fa7540d9] px-2 py-[5px] text-[10px] text-white">
              Integrated
            </p>
          ) : null}
          <Image
            src={cardDetail?.image}
            className="rounded-t-md"
            style={{ objectFit: "cover" }}
            width="100%"
            height="100%"
            preview={false}
          />
        </div>
        <div className="px-4  py-2 ">
          <p className=" my-1 h-full">{cardDetail?.description}</p>
          <Popconfirm
            title="Delete the task"
            description="Are you sure to Remove this plugin?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              confirmRemove(cardDetail?.key);
              setOpen(null);
            }}
            onCancel={() => {
              setOpen(null);
            }}
            open={
              !cardDetail?.disable && cardDetail?.projectIntegrated && open !== null
            }
          >
            <Button
              className=" my-2 w-full textcolor"
              type="primary"
              disabled={cardDetail?.disable}
              style={{
                background: `${cardDetail?.disable
                    ? ``
                    : !cardDetail?.projectIntegrated
                      ? `${theme?.token?.colorPrimary}`
                      : "#FF4016"
                  }`,
                  boxShadow: `${!cardDetail?.disable ? `0 2px 0 ${theme?.token?.colorPrimary}2a` : "none"}`,
                  color: `${cardDetail?.disable ? `${theme?.token?.colorPrimary}` : `${getContrastYIQ(theme?.token?.colorPrimary)}`} !important`,
              }}
              title="Add"
              onClick={() => {
                setOpen(cardDetail?.key);
              }}
            >
              {cardDetail?.disable
                ? "Coming soon !"
                : (!cardDetail?.projectIntegrated)
                  ? "Add +"
                  : "Remove"}
            </Button>
          </Popconfirm>
        </div>
      </Card>
    </Col>
    {cardDetail?.key === "jira" ? <JiraModal projectDetails={projectDetails} setRefresh={setRefresh} setOpen={setOpen} open={open} /> : cardDetail?.key === "trello" ? <TrelloModal projectDetails={projectDetails} setRefresh={setRefresh} setOpen={setOpen} open={open} /> : <></>}

    </>
  );
}

export default IntegrationCard;
