import React, { useEffect, useState } from "react";
import { Row } from "antd";
import { useParams } from "react-router-dom";
// import { Helmet } from "react-helmet";
import IntegrationCard from "../../../../component/card/integration-project-card";
// import AddIntegration from "./modalStep/addIntegration";
import useHttp from "../../../../hooks/use-http";
import { apiGenerator } from "../../../../util/functions";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import jiraMain from "../../../../asset/image/jirabg.png";
import trelloMain from "../../../../asset/image/trellomain.jpg";
// import JiraModal from "./jiraModal";
import TrelloModal from "./trelloModal";
import JiraModal from "./jiraModal";

const Integration = (props) => {
  const [projectDetails, setProjectDetails] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = useState(null);
  const API = useHttp();
  const { projectId } = useParams();

  const confirmRemove = (id) => {
    let payload = {};
    if (id === "jira") {
      payload = {
        jiraAPIToken: null,
        jiraDomain: null,
        jiraEmail: null,
        jiraProjectId: null,
        jiraIntegrated: false,
      };
    }
    if (id === "trello") {
      payload = {
        trelloCardResolveID: null,
        trelloCardAddListID: null,
        trelloBoardID: null,
        trelloAPIKey: null,
        trelloAPIToken: null,
        isTrelloIntegrated: false,
      };
    }
    API.sendRequest(
      apiGenerator(CONSTANTS.API.integration.updatePlugin, {
        dataId: projectId,
      }),
      (res) => {
        if (res?.status === "success") {
          setRefresh((pr) => !pr);
        }
      },
      payload,
      `${id} integrated Remove successfully !!!`,
    );
  };

  useEffect(() => {
    const PROJECT_INFO_API = apiGenerator(CONSTANTS.API.project.getOne, {
      dataId: projectId,
    });
    API.sendRequest(PROJECT_INFO_API, (res) => {
      setProjectDetails(res?.data);
    });
  }, [refresh]);

  const integratedCardList = [
    {
      name: "Jira",
      key: "jira",
      image: jiraMain,
      description:
        "Create tickets directly on Jira as soon as an uncaught exception occurs or server crashes!",
      disable: false,
      projectIntegrated: projectDetails?.jiraIntegrated || false,
      modal: <JiraModal projectDetails={projectDetails} setRefresh={setRefresh} setOpen={setOpen} open={open} />,
    },
    {
      name: "Trello",
      key: "trello",
      image: trelloMain,
      description:
        "Connet your communication and collbration apps so that no ideas or task fall through the cracks",
      disable: false,
      projectIntegrated: projectDetails?.isTrelloIntegrated || false,
      modal: <TrelloModal projectDetails={projectDetails} setRefresh={setRefresh} setOpen={setOpen} open={open} />,
    },

  ];
  return (
    <>
    <div className="my-5">
      <Row gutter={[16, 24]}>
        {integratedCardList?.map((el) => {
          return (
            <IntegrationCard
              setOpen={setOpen}
              open={open}
              projectDetails={projectDetails}
              confirmRemove={confirmRemove}
              cardDetail={el}
              theme={props?.theme}
              setRefresh={setRefresh}
            />
          );
        })}
      </Row>
    {/* <JiraModal projectDetails={projectDetails} setRefresh={setRefresh} setOpen={setOpen} open={open} /> */}
    {/* <TrelloModal projectDetails={projectDetails} setRefresh={setRefresh} setOpen={setOpen} open={open} /> */}
    </div>
    </>
  );
};

export default Integration;
