/* eslint-disable import/no-named-as-default */
/* eslint-disable no-unsafe-optional-chaining */
import {
    Card, Col, Row, Tooltip, Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LineChart from "../../../../../component/chart/line-chart";
import CONSTANTS from "../../../../../util/constant/CONSTANTS";
import {
    apiGenerator,
    formatAmount,
    getMaxTimeUnit,
    isLogin,
} from "../../../../../util/functions";
import useHttp from "../../../../../hooks/use-http";
import InfoIcon from "../../../../../component/common/info-icon";
import EndpointUtilization from "../../Endpoint";

const { Text } = Typography;
const ApiUsageCard = ({ mode = null, dateRange }) => {
    const { id, environmentID } = useParams();

    const API = useHttp();
    const [serviceDetails, setUtilizations] = useState([]);
    const [analyticsVaiables, setAnalyticsVaiables] = useState([]);
    const [refresh, setRefresh] = useState(false);

    let value = {};

    mode === 'Internal' ? value = {
        extraPerms: 'type=Internal',
        title: 'Internal',
    } : value = {
        extraPerms: 'type=ThirdParty',
        title: 'ThirdParty',
    };

    let dateQuery = '';

    useEffect(() => {
        if (!isLogin()) return;
        let extraPerms = "";

        if (dateRange?.startDate && dateRange?.endDate) {
            dateQuery = `&startDate=${dateRange?.startDate}&endDate=${dateRange?.endDate}`;
            extraPerms += dateQuery;

            if (value?.extraPerms) {
                extraPerms += `&${value?.extraPerms}`;
            }
        }

        API.sendRequest(
            apiGenerator(
                CONSTANTS.API.utilization.requestChart,
                {
                    serviceID: environmentID,
                },
                extraPerms,
            ),
            (res) => {
                setUtilizations(res?.data);
                const analyticsResult = res?.data?.reduce(
                    (prev, curr) => {
                        const currTotalCount = +curr?.totalCount;
                        const currSuccessCount = +curr?.successCount;
                        const currFailCount = +curr?.failCount;

                        return [
                            currTotalCount + prev[0],
                            currSuccessCount + prev[1],
                            currFailCount + prev[2],
                        ];
                    },
                    [0, 0, 0],
                );
                const avgTime = (res?.analysis?.[0]?.averageResponseTime ?? 0)?.toFixed(2);
                const latency = getMaxTimeUnit(avgTime);
                const avgTimeInSec = (res?.analysis?.[0]?.averageResponseTime / 1000 ?? 0)?.toFixed(0);
                setAnalyticsVaiables([
                    {
                        id: 1,
                        label: `Total API Call Count`,
                        value: `${formatAmount(analyticsResult[0])}`,
                        infoMessage: 'Sum of API Call Count between the selected date range.',
                    },
                    {
                        id: 2,
                        label: `Total Success Call Count`,
                        extraClass: `text-[#5AD8A6]`,
                        value: `${formatAmount(analyticsResult[1]?.toFixed(0))} (${((+analyticsResult[1]?.toFixed(0) / analyticsResult[0]) * 100) ? ((+analyticsResult[1]?.toFixed(0) / analyticsResult?.[0]) * 100)?.toFixed(2) : 0}%)`,
                        infoMessage: 'When API response Status Code was 199-299.',
                    },
                    {
                        id: 3,
                        label: `Total Fail Call Count`,
                        extraClass: `text-[#FF4016]`,
                        value: `${formatAmount(analyticsResult[2]?.toFixed(0))} (${((+analyticsResult[2]?.toFixed(0) / analyticsResult[0]) * 100) ? ((+analyticsResult[2]?.toFixed(0) / analyticsResult?.[0]) * 100)?.toFixed(2) : 0}%)`,
                        infoMessage: 'When API response Status Code was 399-599.',
                    },
                    {
                        id: 4,
                        label: `Average Processing Time`,
                        extraClass: `${avgTimeInSec <= 2 ? "text-[#5AD8A6]" : avgTimeInSec > 2 && avgTimeInSec <= 6 ? "text-[#ff5d1d]" : avgTimeInSec > 6 ? "text-[#FF4016]" : ""}`,
                        value: `${latency?.time} ${latency?.unit}`,
                        infoMessage: 'Average of API Processing Time between selected date',
                    },

                ]);
            },
        );
    }, [id, environmentID, dateRange, mode, refresh]);

    const utilization = [];

    serviceDetails?.map((el) => {
        const totalCount = +el?.totalCount || 0;
        const successCount = +el?.successCount || 0;
        const failCount = +el?.failCount || 0;

        // const successRate = totalCount ? (successCount / totalCount) * 100 : 0;
        // const failRate = totalCount ? (failCount / totalCount) * 100 : 0;

        utilization.push({
            year: el?.date,
            value: +totalCount.toFixed(0),
            name: "API Call Count",
        });
        utilization.push({
            year: el?.date,
            value: +successCount.toFixed(0),
            name: `Success Count`,
        });
        utilization.push({
            year: el?.date,
            value: +failCount.toFixed(0),
            name: `Fail Count`,
        });
        // utilization.push({
        //     year: el?.date,
        //     value: +successRate.toFixed(2),
        //     name: "Success Rate (%)",
        // });
        // utilization.push({
        //     year: el?.date,
        //     value: +failRate.toFixed(2),
        //     name: "Fail Rate (%)",
        // });
    });

    return (
        <Row gutter={[10, 10]}>
            <Col span={24} xl={24}>
                <Card className="w-full h-full">
                    <Row className="justify-between flex-wrap items-center gap-3 mb-5">
                        <Text>{`${value?.title} API Usage`}</Text>

                    </Row>
                    <div className="grid mt-3 mb-10 sm:grid-cols-4 grid-cols-2 gap-y-4">
                        {analyticsVaiables.length > 0
                            && analyticsVaiables?.map((el) => (
                                <div
                                    key={el.id}
                                    className="flex flex-col border-r [&:last-child]:border-none items-center"
                                >
                                    <p className={`${el?.extraClass} text-xl lg:text-2xl`}>
                                        {el.value}
                                    </p>
                                    <p className="text-grayColor text-xs flex  lg:text-sm text-center">
                                        {el.label}
                                        <Tooltip className="cursor-pointer" title={el?.infoMessage}>
                                            <div className="mt-0.5 ">
                                                <InfoIcon />
                                            </div>
                                        </Tooltip>
                                    </p>

                                </div>
                            ))}
                    </div>
                    <LineChart
                        data={utilization || []}
                        label="request"
                        isLoading={API.isLoading}
                    />
                </Card>
            </Col>

            <Col span={24} xl={24}>
                <EndpointUtilization
                    isVisible
                    setReload={setRefresh}
                    mode={mode}
                    dateQuery={dateRange}
                />
            </Col>

        </Row>
    );
};

export default ApiUsageCard;
