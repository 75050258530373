import { Card, Row, Typography } from "antd";
import React from "react";
import { SiSecurityscorecard } from "react-icons/si";
import CpuInfo from "../common/cpu-info";
import Progress from "../chart/progress";
import { getColor } from "../../util/functions";

const { Text } = Typography;
function CoreCard(props) {
  return (
    <Card style={{ overflow: "hidden" }}>
      <Row className="justify-between">
        {props?.model && (
          <CpuInfo
            icon={<SiSecurityscorecard />}
            description={`${props?.model}`}
          />
        )}
        {props?.id && (
          <CpuInfo
            icon={<SiSecurityscorecard />}
            description={`PID: ${props?.id}`}
          />
        )}
        <Text type="secondary">
          {`${props?.usage?.toFixed(2)}% ${
            // eslint-disable-next-line no-unsafe-optional-chaining
            props?.speed ? `(${(props?.speed / 1000)?.toFixed(2)} GHz)` : ""
            }`}
        </Text>
      </Row>
      <Progress
        className="mt-3"
        height="10px"
        progress={[
          { color: getColor(props?.usage), progress: `${props?.usage}%` },
        ]}
      />
    </Card>
  );
}

export default CoreCard;
