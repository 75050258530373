/* eslint-disable import/no-named-as-default */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable no-unsafe-optional-chaining */
import {
  Card, DatePicker, Row, Select, Tooltip, Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import LineChart from "../../../../../component/chart/line-chart";
import CONSTANTS, { CPU_DANGEROUS_LEVEL, CPU_THRESHOLD_LEVEL } from "../../../../../util/constant/CONSTANTS";
import {
  apiGenerator,
  disabledDate,
  getColor,
  getSelectionOption,
  ifTimeAvailableConvertToLocalTime,
  isLogin,
} from "../../../../../util/functions";
import useHttp from "../../../../../hooks/use-http";
import InfoIcon from "../../../../../component/common/info-icon";

const { Text } = Typography;
const { RangePicker } = DatePicker;

const ProcessCard = ({ plan = {} }) => {
  const { id, environmentID } = useParams();
  const API = useHttp();
  const [serviceDetails, setUtilizations] = useState([]);
  const [analyticsVaiables, setAnalyticsVaiables] = useState([]);
  const [selected, setSelected] = useState("Daily");
  const [dateRange, setdateRange] = useState({
    startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    if (!isLogin()) return;
    let extraPerms = "";
    if (dateRange?.startDate && dateRange?.endDate) {
      extraPerms += `&startDate=${dateRange?.startDate}&endDate=${dateRange?.endDate}`;
    }
    API.sendRequest(
      apiGenerator(
        CONSTANTS.API.utilization.process_cpu,
        {
          dataId: selected,
          serviceID: environmentID,
        },
        extraPerms,
      ),
      (res) => {
        setUtilizations(res?.data);
        const analyticsResult = res?.data?.reduce((prev, curr) => {
          const currValue = +curr?.maxProcessCPUUsage;
          return [currValue + prev[0], prev[1] < currValue ? currValue : prev[1], prev[2] > currValue ? currValue : prev[2], currValue > CPU_THRESHOLD_LEVEL ? ++prev[3] : prev[3], currValue > CPU_DANGEROUS_LEVEL ? ++prev[4] : prev[4]];
        }, [0, 0, 100, 0, 0]);
        setAnalyticsVaiables([
          {
            id: 1,
            label: 'Avg. CPU Usage',
            style: {
              color: getColor(
                analyticsResult[0] ? (analyticsResult[0] / res?.data?.length)?.toFixed(0) : 0,
              ),
            },
            value: `${analyticsResult[0] ? (analyticsResult[0] / res?.data?.length)?.toFixed(0) : 0}%`,
            infoMessage: 'Average Process CPU Usage Count between the selected date range.',
          },
          {
            id: 2,
            label: 'Highest CPU Usage',
            value: `${analyticsResult[1] ? analyticsResult[1]?.toFixed(0) : 0}%`,
            style: {
              color: getColor(analyticsResult[1] ? analyticsResult[1]?.toFixed(0) : 0),
            },
            infoMessage: 'Highest Process CPU Usage Count between the selected date range.',
          },
          {
            id: 3,
            label: 'Lowest CPU Usage',
            style: {
              color: getColor(analyticsResult[2] === 100 ? 0 : analyticsResult[2]?.toFixed(0)),
            },
            value: `${analyticsResult[2] === 100 ? 0 : analyticsResult[2]?.toFixed(0)}%`,
            infoMessage: 'Lowest Process CPU Usage Count between the selected date range.',
          },
          {
            id: 4,
            label: "Threshold Crossings",
            style: {
              color: getColor(+analyticsResult[3]?.toFixed(0) ? +((+analyticsResult[3]?.toFixed(0) / res?.data?.length) * 100)?.toFixed(0) : 0),
            },
            value: `${analyticsResult[3]?.toFixed(0)} (${((+analyticsResult[3]?.toFixed(0) / res?.data?.length) * 100) ? ((+analyticsResult[3]?.toFixed(0) / res?.data?.length) * 100)?.toFixed(0) : 0}%)`,
            infoMessage: 'The number of times the Process CPU Usage has touched/crossed the threshold range of 70%-90%.',
          },
          {
            id: 5,
            label: "Dangerous Crossings",
            value: `${analyticsResult[4]?.toFixed(0)} (${((+analyticsResult[4]?.toFixed(0) / res?.data?.length) * 100) ? ((+analyticsResult[4]?.toFixed(0) / res?.data?.length) * 100)?.toFixed(0) : 0}%)`,
            style: {
              color: getColor(+analyticsResult[4]?.toFixed(0) ? +((+analyticsResult[4]?.toFixed(0) / res?.data?.length) * 100)?.toFixed(0) : 0),
            },
            infoMessage: 'The number of times the Process CPU Usage has touched/crossed the threshold range of 91%-100%.',
          },
        ]);
      },
    );
  }, [id, environmentID, selected, dateRange]);

  const handleChange = (value) => {
    setSelected(value);
  };
  const utilization = [];

  // console.log(serviceDetails);

  serviceDetails?.map((el) => {
    utilization.push({
      year: ifTimeAvailableConvertToLocalTime(selected, el?.formattedCreatedAt),

      value: +el?.maxProcessCPUUsage?.toFixed(0) || 0,
      name: "CPU Usage (%)",
    });
  });

  const onDateChange = (value) => {
    if (!(value?.length > 0)) {
      setdateRange(null);
      return;
    }

    setdateRange({
      startDate: value?.[0]?.format("YYYY-MM-DD"),
      endDate: value?.[1]?.format("YYYY-MM-DD"),
    });
  };
  return (
    <Card className="w-full">
      <Row className="justify-between items-center mb-5">
        <Text>Process CPU Utilization</Text>

        <div className="flex  justify-start items-center gap-3">
          <Text className="hidden  md:block">Date Range</Text>
          <RangePicker
            allowClear={false}
            defaultValue={[
              dayjs(dateRange?.startDate),
              dayjs(dateRange?.endDate),
            ]}
            disabledDate={disabledDate}
            onChange={onDateChange}
          />
          <Select
            defaultValue={selected}
            style={{ width: 120 }}
            onChange={handleChange}
            options={getSelectionOption(plan)}
          />
        </div>
      </Row>
      <div className="grid mt-3 mb-10 mobile:grid-cols-3 grid-cols-5 gap-y-4">
        {analyticsVaiables.length > 0 && analyticsVaiables?.map((el) => (
          <div key={el.id} className="flex flex-col border-r [&:last-child]:border-none items-center">

            <p className={` text-xl lg:text-2xl`} style={el?.style || {}}>
              {el.value}
            </p>
            <p className="text-grayColor text-xs flex  lg:text-sm text-center">
              {el.label}
              <Tooltip className="cursor-pointer" title={el?.infoMessage}>
                <div className="mt-0.5 ">
                  <InfoIcon />
                </div>
              </Tooltip>
            </p>
          </div>
        ))}

      </div>
      <LineChart
        isLoading={API.isLoading}
        data={utilization || []}
        label="CPU"
        annotations={CONSTANTS.CPU_ANNOTATION}
      />
    </Card>
  );
};

export default ProcessCard;
