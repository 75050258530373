/* eslint-disable no-undef */
import {
    Card,
} from "antd";
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import CONSTANTS from "../../../../../util/constant/CONSTANTS";
import CRUDComponent from "../../../../../component/common/CRUD-Component";

const ApiEndpointSelect = (props) => {
    const [refresh, setRefresh] = useState(false);
    const { environmentID } = useParams();
    useEffect(() => {
        if (Object?.keys(props?.user_details)?.length <= 0) return;
        setTimeout(() => {
            props?.socket?.emit("updateServiceEnvironmentInformation", {
                serviceToken: environmentID,
                token: props?.user_details?.id,
            });
        }, 2000);

        // props?.socket?.on("updateServiceEnvironmentInformationResponse", (data) => {
        //     if (data?.connected) {
        //         //   let systemObj = {};
        //         //   data?.message?.map((el) => {
        //         //     systemObj = { ...systemObj, [el?.serviceToken]: true };
        //         //   });
        //         //   setSystemsStatus(systemObj);
        //     }
        // });
    }, [refresh]);
    return (
        <Card className="mt-5">
            <p className="text-lg font-semibold">
                Whitelisted Pattern
                {" "}
                {' '}
                {/* <span>
                    (
                    {distributeAnalytic?.totalCouponCount || 0}
                    )
                </span> */}
            </p>
            <CRUDComponent
                reload={refresh}
                GET={{
                    API: CONSTANTS.API.allowEndpoint.get,
                    extraQuery: environmentID ? {
                        serviceEnvironmentId: environmentID,
                    } : {},
                    DataModifier: (res) => {
                        // console.log(notifyButton);
                        return res?.map((el, i) => {
                            return {
                                ...el,
                                key: el?.id,
                                no: i + 1,
                                // notifyButton:
                                // deleteWithReason: <Button onClick={() => setDeleteOpen(el?.id)} type="primary" className="textcolor">
                                //     <DeleteOutlined />
                                // </Button>,

                            };
                        });
                    },
                    column: CONSTANTS.TABLE.APIALLOWED,
                }}
                UPDATE={{
                    API: CONSTANTS.API.allowEndpoint.update,
                    message: "Endpoint Updated successfully",
                    modaltitle: "Update Endpoint",
                    modalFields: "ALLOWAPI_MODAL",
                    setRefresh,
                    // payloadModifier: (res) => res,
                }}
                CREATE={{
                    API: CONSTANTS.API.allowEndpoint.add,
                    message: "Endpoint Created Successfully",
                    modaltitle: "Add New Endpoint",
                    modalFields: "ALLOWAPI_MODAL",
                    name: "+ Add New Endpoint",
                    setRefresh,
                    payloadModifier: (res) => ({ ...res, serviceEnvironmentId: environmentID }),
                }}
                DELETE={{
                    API: CONSTANTS.API.allowEndpoint.delete,
                    message: "Deleted Successfully",
                    setRefresh,
                }}
                isSearch
            />
        </Card>
    );
};

export default ApiEndpointSelect;
