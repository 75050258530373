/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable object-curly-newline */
import { useParams } from 'react-router-dom';
import { Button, Form, Modal, Result, Spin, Steps } from 'antd';
import React, { useState } from 'react';
import useHttp from '../../../../hooks/use-http';
import { apiGenerator } from '../../../../util/functions';
import CONSTANTS from '../../../../util/constant/CONSTANTS';
import AddIntegration from './modalStep/addIntegration';

const JiraModal = ({ projectDetails, setOpen, setRefresh, open }) => {
  const API = useHttp();
  const [form] = Form.useForm();
  const [defaultData, setDefaultData] = useState(null);
  const [addIntegration, setAddIntegration] = useState(null);
  const [current, setCurrent] = useState(0);
  const { projectId } = useParams();

  const steps = [
    {
      title: "Create Identifier",
      content: API.isLoading ? (
        <Spin tip="Loading" size="large">
          <div className="content h-60" />
        </Spin>
      ) : (
        <AddIntegration form={form} defaultData={defaultData} />
      ),
    },

    {
      title: "Done",
      content: (
        <Result
          status="success"
          title="Jira Integrated Successfully!"
          subTitle={(
            <div className="flex flex-col items-center">
              <div className="max-w-lg">
                Congratulations! Wooffer is now linked with your Jira board! A
                ticket will be created as soon as any uncaught exception occurs
                or the server malfunctions.
              </div>
              <div className="max-w-lg mt-2 text-red-400">
                Please confirm if a default ticket has been created in the
                linked Jira board. If not, please unlink and then relink the
                Jira board with the same URL.
              </div>
            </div>
          )}
        />
      ),
    },
  ];

  const next = (payload) => {
    // api call for add
    API.sendRequest(
      apiGenerator(CONSTANTS.API.integration.addPlugin),
      (res) => {
        if (res?.status === "success") {
          setAddIntegration(payload);
          setCurrent(current + 1);
          setDefaultData(null);
        }
      },
      payload,
      "Jira Added successfully !!!",
    );
  };
  const doneIntegration = () => {
    // jiraIntegrated add this key

    API.sendRequest(
      apiGenerator(CONSTANTS.API.integration.updatePlugin, {
        dataId: projectId,
      }),
      (res) => {
        if (res?.status === "success") {
          setAddIntegration(null);
          setOpen(null);
          setCurrent(0);
          setRefresh((pr) => !pr);
        }
      },
      { ...addIntegration, jiraIntegrated: true },
      "Jira integrated successfully !!!",
    );
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  return (
    <>
      <Modal
        open={!projectDetails?.jiraIntegrated && open === "jira"}
        title={
          <p className="text-2xl mb-10 text-slate-500">Add Jira Details</p>
        }
        // okText="Submit"
        width={700}
        closable={addIntegration == null && current !== 1}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        className="form-modal-title"
        onCancel={() => {
          if (addIntegration !== null && current === 1) return;
          setOpen(null);
        }}
      >
        <>
          <Steps current={current} items={items} className="mt-4 px-32" />
          <div className="mx-10">
            {steps[current].content}
          </div>

          <div
            style={{
              marginTop: 24,
            }}
            className="flex justify-center"
          >
            {current < steps.length - 1 && (
              <Button
                className="textcolor"
                type="primary"
                onClick={() => {
                  form
                    .validateFields()
                    .then((values) => {
                      form.resetFields();
                      next(values);
                      setDefaultData(values);
                      //   onCreate(values);
                    })
                    .catch(() => {
                      // console.log("Validate Failed:", info);
                    });
                }}
              >
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button
                className="textcolor"
                type="primary"
                onClick={() => {
                  doneIntegration();
                }}
              >
                Done
              </Button>
            )}
          </div>
        </>
      </Modal>
    </>
  );
};

export default JiraModal;
